import React from 'react'
import classNames from 'clsx'

import {Pagination} from './Pagination'
import {styled} from "@mui/material/styles";

const PREFIX = 'Pager'

const classes = {
	pager: `${PREFIX}-pager`,
}

const Root = styled('div')(() => ({
	[`&.${classes.pager}`]: {
		display: 'flex',
		padding: '10px',
		alignItems: 'center',
		backgroundColor: 'white',
		justifyContent: 'flex-end',
		borderTop: '1px solid #e0e0e0'
	},
}))

export const SimplePager = ({
	currentPage,
	pageSizes,
	totalPages,
	pageSize,
	onCurrentPageChange,
	onPageSizeChange,
	totalCount,
	getMessage,
	className,
	...restProps
}: any
) => {
	return (
		<Root
			className={classNames(classes.pager, className)}
			{...restProps}
		>
			<Pagination
				pageSize={pageSize}
				totalPages={totalPages}
				totalCount={totalCount}
				getMessage={getMessage}
				currentPage={currentPage}
				onCurrentPageChange={(page: any) => onCurrentPageChange(page)}
			/>
		</Root>
	)
}
