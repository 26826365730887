import {HttpService} from "../../App/services/http"

export const FloorService = {
  rooms,
  workload,
  repair,
  quality
}

function rooms(id: number) {
  return HttpService.get(`/statistic/floor/${id}/rooms`)
    .then(
      response => {
        return response
      })
}

function workload(id: number) {
  return HttpService.get(`/statistic/floor/${id}/workload`)
    .then(
      response => {
        return response
      })
}

function repair(id: number) {
  return HttpService.get(`/statistic/floor/${id}/repair`)
    .then(
      response => {
        return response
      })
}

function quality(id: number) {
  return HttpService.get(`/statistic/floor/${id}/quality`)
    .then(
      response => {
        return response
      })
}