import React, {useEffect} from "react"
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  useMediaQuery, useTheme,
} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../../../App/components/Page/DialogContent";
import TextField from "../../../../App/components/Input/TextField"
import {Submit} from "../../../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Autocomplete} from "../../../../App/components/Input/AsyncAutocomplete";
import {CloseDialog} from "../../../../App/components/Dialog/CloseDialog";
import {IItem} from "../../../../Dictionaries/interfaces/item";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {EquipmentActions} from "../../../actions/equipment";
import {transformData} from "../../../../App/helpers/transformData";
import IResponseError from "../../../../App/interfaces/responseError";
import {InventoryFunctionActions} from "../../../../Dictionaries/actions/inventory.function";
import {EquipmentStatusActions} from "../../../../Dictionaries/actions/equipment.status";
import {IEquipment} from "../../../interfaces/equipment";
import {ILand} from "../../../../Realty/interfaces/land";
import {LandActions} from "../../../../Realty/actions/land";
import {IBuilding} from "../../../../Realty/interfaces/building";
import {BuildingActions} from "../../../../Realty/actions/building";
import {IFloor} from "../../../../Realty/interfaces/floor";
import {FloorActions} from "../../../../Realty/actions/floor";
import {IRoom} from "../../../../Realty/interfaces/room";
import {RoomActions} from "../../../../Realty/actions/room";
import {IRegistry} from "../../../interfaces/registry";
import {IEquipmentInput} from "../../../interfaces/input/equipment";
import {EquipmentTypeActions} from "../../../../Dictionaries/actions/equipment.type";
import {IEmployee} from "../../../../Company/interfaces/employee";
import {CompanyEmployeeActions} from "../../../../Company/actions/company.employee";
import {Autocomplete as CustomAutocomplete} from "../../../../App/components/Input/Autocomplete";
import {equippedOptions, IEquippedItem} from "../../../../Dictionaries/interfaces/Common/input/equippedItem";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  [theme.breakpoints.down('md')] : {
    fontWeight: 500,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')] : {
    fontWeight: 600,
    fontSize: '20px',
  },
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Equipment(props: { registry: IRegistry, equipment: IEquipment, open: boolean, handleClose: () => void, onClick?: (equipment: IEquipment) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {registry, onClick} = props
  const [equipment, setEquipment] = React.useState<IEquipment>(props.equipment);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [savedEquipment, setSavedEquipment]: any = React.useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    if (onClick && savedEquipment) {
      onClick(savedEquipment)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      name: equipment.equipment.name,
      inventoryNumber: equipment.equipment.inventoryNumber,
      price: equipment.equipment.price,
      type: equipment.equipment.type,
      responsible: equipment.equipment.responsible,
      user: equipment.user,
      availability: equipment.availability,
      company: registry.company,
      land: equipment.room ? equipment.room?.floor?.building?.land : equipment.equipment.room?.floor?.building?.land,
      building: equipment.room ? equipment.room?.floor?.building : equipment.equipment.room?.floor?.building,
      floor: equipment.room ? equipment.room?.floor : equipment.equipment.room?.floor,
      room: equipment.room ?? equipment.equipment.room,
      function: equipment.function,
      status: equipment.status,
      quantity: equipment.quantity
    },
    validationSchema: Yup.object().shape({
      company: Yup.object().required('Поле обязательно к заполнению'),
      land: Yup.object().required('Поле обязательно к заполнению'),
      building: Yup.object().required('Поле обязательно к заполнению'),
      floor: Yup.object().required('Поле обязательно к заполнению'),
      room: Yup.object().required('Поле обязательно к заполнению'),
      user: Yup.object().nullable(),
      function: Yup.object().nullable(),
      status: Yup.object().nullable(),
      quantity: Yup.number().min(0, 'Значение не может быть меньше 0'),
      availability: Yup.bool().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = transformData(values)
      dispatch(EquipmentActions.update(registry.id, equipment.equipment.id, (data as IEquipmentInput)))
        .then(
          (equipment: IEquipment) => {
            setSubmitting(true)
            setEquipment(equipment)
            setSavedEquipment(equipment)
            resetForm({
              values: {
                name: equipment.equipment.name,
                inventoryNumber: equipment.equipment.inventoryNumber,
                price: equipment.equipment.price,
                type: equipment.equipment.type,
                responsible: equipment.equipment.responsible,
                availability: equipment.availability,
                user: equipment.user,
                function: equipment.function,
                status: equipment.status,
                room: equipment.room ?? equipment.equipment.room,
                floor: equipment.room ? equipment.room?.floor : equipment.equipment.room?.floor,
                building: equipment.room ? equipment.room?.floor?.building : equipment.equipment.room?.floor?.building,
                land: equipment.room ? equipment.room?.floor?.building?.land : equipment.equipment.room?.floor?.building?.land,
                company: equipment.room ? equipment.room?.floor?.building?.land?.company : equipment.equipment.room?.floor?.building?.land?.company,
                quantity: equipment.quantity,
              }
            })
          },
          (error: IResponseError) => setErrors(error.errors)
        )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullScreen={mobile}
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit} style={{ height: "100%"}}>
          <DialogTitle>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Title>Редактировать оборудование</Title>
              <IconButton
                onClick={handleClose}
              >
                <Close/>
              </IconButton>
            </Grid>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  disabled
                  name="name"
                  label="Наименование"
                  type="text"
                  formik={formik}
                />
              </Item>
              {((!equipment.room || !equipment.equipment.room) || equipment.surplus) ? (
                <React.Fragment>
                  <Item item xs={mobile ? 12 : 6}>
                    <Autocomplete
                      required
                      name="land"
                      label="Земельный участок"
                      setValue={(name: string, value: ILand) => formik.setValues({
                        ...formik.values,
                        [name]: value,
                        building: null,
                        floor: null,
                        room: null
                      })}
                      getValues={(search: string) => LandActions.lands({
                        take: 20,
                        companyId: registry.company.id,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.land}
                      getOptionLabel={(option: ILand) => option?.name ?? option?.cadastralNumber ?? option}
                      formik={formik}
                    />
                  </Item>
                  <Item item xs={mobile ? 12 : 6}>
                    <Autocomplete
                      required
                      disabled={!formik.values.land}
                      label="Здание"
                      name="building"
                      setValue={(name: string, value: IBuilding) => formik.setValues({
                        ...formik.values,
                        [name]: value,
                        floor: null,
                        room: null
                      })}
                      getValues={(search: string) => BuildingActions.buildings({
                        take: 20,
                        landId: formik.values.land?.id,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.building}
                      subscribeFields={[formik.values.land]}
                      getOptionLabel={(option: IBuilding) => option?.name ?? option}
                      formik={formik}
                    />
                  </Item>
                  <Item item xs={mobile ? 12 : 6}>
                    <Autocomplete
                      required
                      disabled={!formik.values.building}
                      name="floor"
                      label="Этаж"
                      setValue={(name: string, value: IFloor) => formik.setValues({
                        ...formik.values,
                        [name]: value,
                        room: null
                      })}
                      getValues={(search: string) => FloorActions.floors({
                        take: 20,
                        buildingId: formik.values.building?.id,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.floor}
                      subscribeFields={[formik.values.land, formik.values.building]}
                      getOptionLabel={(option: IFloor) => option?.name ?? option}
                      formik={formik}
                    />
                  </Item>
                  <Item item xs={mobile ? 12 : 6}>
                    <Autocomplete
                      required
                      disabled={!formik.values.floor}
                      name="room"
                      label="Помещение"
                      setValue={(name: string, value: IRoom) => formik.setFieldValue(name, value)}
                      getValues={(search: string) => RoomActions.rooms({
                        take: 20,
                        floorId: formik.values.floor?.id,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.room}
                      subscribeFields={[formik.values.land, formik.values.building, formik.values.floor]}
                      getOptionLabel={(option: IRoom) => option?.name ?? option}
                      formik={formik}
                    />
                  </Item>
                </React.Fragment>
              ) : null}
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  disabled
                  name="inventoryNumber"
                  label="Инвентарный номер"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  disabled
                  name="price"
                  required
                  label="Стоимость"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  disabled
                  name="type"
                  label="Тип оборудования"
                  required
                  setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => EquipmentTypeActions.items({
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  getOptionLabel={(option: IItem) => option?.code ? `${option.code} ${option.name}` : option.name}
                  value={formik.values.type}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name={"responsible"}
                  disabled
                  label="Ответственный"
                  setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => CompanyEmployeeActions.items(registry.company.id, {
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.responsible}
                  getOptionLabel={(option: IEmployee) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name={"user"}
                  label="Пользователь"
                  setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => CompanyEmployeeActions.items(registry.company.id, {
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.user}
                  getOptionLabel={(option: IEmployee) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <CustomAutocomplete
                  name={'availability'}
                  required
                  options={equippedOptions}
                  label="Наличие"
                  formik={formik}
                  value={
                    (formik.values.availability !== undefined)
                      ? formik.values.availability
                        ? 'Да'
                        : 'Нет'
                      : undefined
                  }
                  getOptionLabel={(option: IEquippedItem) =>
                    option?.name ?? option
                  }
                  setValue={(name: string, value: IEquippedItem) => {
                    formik.setFieldValue(name, value?.value, true);
                  }}
                />
              </Item>
              {!equipment.surplus ? (
                <React.Fragment>
                  <Item item xs={mobile ? 12 : 6}>
                    <TextField
                      name="quantity"
                      label="Количество"
                      type="number"
                      formik={formik}
                    />
                  </Item>
                  <Item item xs={mobile ? 12 : 6}>
                    <Autocomplete
                      name="function"
                      label="Целевая функция"
                      setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                      getValues={(search: string) => InventoryFunctionActions.items({
                        take: 20,
                        ...(search ? {search: search} : {})
                      })}
                      getOptionLabel={(option: IItem) => option?.name ?? option}
                      value={formik.values.function}
                      formik={formik}
                    />
                  </Item>
                  <Item item xs={mobile ? 12 : 6}>
                    <Autocomplete
                      name="status"
                      label="Статус"
                      setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                      getValues={(search: string) => EquipmentStatusActions.items({
                        take: 20,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.status}
                      getOptionLabel={(option: IItem) => option?.name ?? option}
                      formik={formik}
                    />
                  </Item>
                </React.Fragment>
              ) : null}
            </Grid>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
              <Grid item>
                <Submit
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Сохранить
                </Submit>
              </Grid>
              {!mobile ? (
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              ) : null}
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {openDialog ? (
        <CloseDialog
          open={openDialog}
          onClose={onCloseDialog}
          onCloseWithoutSaving={onCloseWithoutSaving}
        />
      ) : null}
    </React.Fragment>
  ) : null;
}