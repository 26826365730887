import React from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import {Link as RouterLink} from "react-router-dom";
import {styled} from "@mui/material/styles";

const Link = styled(RouterLink)(() => ({
  textDecoration: 'auto',
}));

type Props = {
  id: number
}

export function Edit(props: Props): JSX.Element {
  const {id} = props

  return (
    <Access roles={['admin']}>
      <Link to={`/objects/${id}/markup`}>
        <Submit
          variant="contained"
        >
          Редактировать
        </Submit>
      </Link>
    </Access>
  )
}
