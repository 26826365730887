import React from 'react'
import Select from '../../Input/Select';
import {MenuItem} from "@mui/material";
import {Item} from './PageSelector';

export const PageSizeSelector = ({
  pageSize,
  pageSizes,
  getMessage,
  onPageSizeChange,
}: any) => {

  const showAll = getMessage('showAll')
  return (
    <React.Fragment>
      <Item>{getMessage('rowsPerPage')}</Item>
      <Select
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {onPageSizeChange(event.target.value)}}
        value={pageSize}
      >
        {pageSizes.map((item: number) => (
          
          <MenuItem key={item} value={item}>
            {item !== 0 ? item : showAll}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  )
}
