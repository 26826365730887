import React from "react"
import Account from "../../Account";
import {IAccount} from "../../../interfaces/account";
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../../Auth/components/Access";

type Props = {
  account: IAccount,
  onClick?: (account: IAccount) => void
}

export default function Edit(props: Props): JSX.Element {
  const {account, onClick} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <React.Fragment>
        <IconButton
          onClick={handleOpen}
        >
          <EditIcon/>
        </IconButton>
        {open ? <Account account={account} open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </React.Fragment>
    </Access>
  )
}
