import React from "react"
import {styled} from "@mui/material/styles";
import {Box, Card, CardContent, Divider, ToggleButton, ToggleButtonGroup, Tooltip, Typography,} from "@mui/material";
import {HelpOutline as HelpIcon} from "@mui/icons-material";
import {Paper} from "../../../App/components/Paper";

const Component = styled('div')(() => ({
  position: 'fixed',
  bottom: '38px',
  right: '298px',
  backgroundColor: 'white'
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.25),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

type Props = {
  buttons: Array<{ value: string, disabled: boolean, icon: JSX.Element, onClick: () => void }>
}

export function Tools(props: Props): JSX.Element {
  const {buttons} = props

  return (
    <Component>
      <Paper
        sx={{
          display: 'fixed',
        }}
      >
        <StyledToggleButtonGroup size="small">
          {buttons.map((button, index) => (
            <ToggleButton
              key={index}
              value={button.value}
              disabled={button.disabled}
              onClick={button.onClick}
            >
              {button.icon}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <StyledToggleButtonGroup size="small">
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  margin: 0,
                  padding: 0,
                  borderRadius: '2px',
                  backgroundColor: 'transparent'
                },
              },
            }}
            title={
              <Box sx={{ minWidth: 275 }}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Клик (точка)</Box> - Активировать точку
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Клик (изображение)</Box> - Деактивировать точку
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Клик и удержание (точка)</Box> - Изменить позицию активной точки
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Клик и удержание (изображение)</Box> - Переместить изображение
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Ctrl + Клик (изображение)</Box> - Добавить точку
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Delete</Box> - Удалить активную точку
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Shift</Box> - Примагнитить точку
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div" gutterBottom>
                      <Box sx={{ color: 'primary.main' }} component="span">Shift + Ctrl + Клик (изображение)</Box> - Добавить примагниченную точку
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            }
            placement="top-end"
          >
            <ToggleButton value="Help">
              <HelpIcon />
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
      </Paper>
    </Component>
  )
}
