import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close, Delete as DeleteIcon} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Submit} from "../../App/components/Buttons/Submit";
import TextField from "../../App/components/Input/TextField";
import {IEmployee} from "../interfaces/employee";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {IEmployeeInput} from "../interfaces/input/employee";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import {ISubdivision} from "../interfaces/subdivision";
import {CompanySubdivisionActions} from "../actions/company.subdivision";
import {IItem} from "../../Dictionaries/interfaces/item";
import {ICompany} from "../interfaces/company";
import {CompanyPositionActions} from "../actions/company.position";
import AddIcon from "../../App/components/Svg/AddIcon";
import {IPosition} from "../interfaces/position";

const Column = styled(Grid)(() => ({
  height: "80px",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

type Props = {
  company: ICompany,
  item?: IEmployee,
  actions: any,
  name: string,
  open: boolean,
  handleClose: () => void,
  onClick?: (item: IEmployee) => void
}

export default function Employee(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {company, item, actions, name, onClick} = props
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      name: item?.name,
      subdivisions: (item?.subdivisions && item.subdivisions.length) ? item.subdivisions : [{
        subdivision: null,
        position: null,
      }],
      contact: item?.contact
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению'),
      subdivisions: Yup.array().of(Yup.object().shape({
        subdivision: Yup.object().required('Поле обязательно к заполнению'),
        position: Yup.object().nullable()
      })),
    }),
    onSubmit: (values: FormikValues) => {
      dispatch(item ? actions.update(company.id, item.id, (values as IEmployeeInput)) : actions.create(company.id, values as IEmployeeInput)).then(
        (item: IEmployee) => {
          if (onClick) {
            onClick(item)
          }
          handleClose()
        }
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={10}>
                  <Title noWrap>{item?.id ? 'Редактировать' : 'Добавить'} {name}</Title>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClose}
                  >
                    <Close/>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                <Column item xs={6}>
                  <TextField
                    required
                    name="name"
                    label="ФИО"
                    type="text"
                    formik={formik}
                  />
                </Column>
                <Column item xs={6}>
                  <TextField
                    name="contact"
                    label="Контакт"
                    type="text"
                    formik={formik}
                  />
                </Column>
                {formik.values.subdivisions.map((el, index) => (
                  <React.Fragment key={index}>
                    <Column item xs={5}>
                      <Autocomplete
                        name={`subdivisions.${index}.subdivision`}
                        label="Подразделение"
                        setValue={(name: string, value: ISubdivision) => formik.setFieldValue(name, value)}
                        getValues={(search: string) => CompanySubdivisionActions.items(company.id,{
                          take: 20,
                          ...(formik.values.subdivisions.filter(e => e.subdivision).length ? {exclude: formik.values.subdivisions.filter(e => e.subdivision).map((e: {subdivision: null | ISubdivision}) => e.subdivision?.id).join(',')} : {}),
                          ...(search ? {search: search} : {})
                        })}
                        getOptionLabel={(option: IItem) => option?.name ?? option}
                        value={formik.values.subdivisions[index].subdivision}
                        formik={formik}
                      />
                    </Column>
                    <Column item xs={5}>
                      <Autocomplete
                        name={`subdivisions.${index}.position`}
                        label="Должности"
                        setValue={(name: string, value: IPosition) => formik.setFieldValue(name, value)}
                        getValues={(search: string) => CompanyPositionActions.items(company.id, {
                          take: 20,
                          ...(search ? {search: search} : {})
                        })}
                        getOptionLabel={(option: IPosition) => option?.name ?? option}
                        value={formik.values.subdivisions[index].position}
                        formik={formik}
                      />
                    </Column>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          formik.setFieldValue('subdivisions', formik.values.subdivisions.filter((e, i) => i !== index))
                        }}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}
                <Column item xs={2}>
                  <Submit
                    onClick={() => formik.setFieldValue('subdivisions', [...formik.values.subdivisions, {subdivision: null, position: null}])}
                    variant="contained"
                    type="button"
                    endIcon={<AddIcon width={12} height={12}/>}
                  >
                    Добавить
                  </Submit>
                </Column>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Сохранить
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null;
}