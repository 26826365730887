import {useSelector} from "react-redux";
import {IRootState} from "../../App/reducers/store";
import {AuthorizationServices} from "../services/authorization";

export default function Access(props: { children: JSX.Element, roles?: Array<string> | string , permissions?: Array<string> | string }): JSX.Element | null {
  const {roles, permissions, children} = props
  const {account} = useSelector((state: IRootState) => state.account)

  return (account && AuthorizationServices.access(account, roles, permissions)) ? children : null
}
