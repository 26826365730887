import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Submit} from "../../App/components/Buttons/Submit";
import TextField from "../../App/components/Input/TextField";
import {IPosition} from "../interfaces/position";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {IPositionInput} from "../interfaces/input/position";
import {ICompany} from "../interfaces/company";

const Column = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

type Props = {
  company: ICompany,
  item?: IPosition,
  actions: any,
  name: string,
  open: boolean,
  handleClose: () => void,
  onClick?: (item: IPosition) => void
}

export default function Position(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {company, item, actions, name, onClick} = props
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      name: item?.name,
      priority: item?.priority
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению'),
      priority: Yup.number().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues) => {
      dispatch(item ? actions.update(company.id, item.id, (values as IPositionInput)) : actions.create(company.id, values as IPositionInput)).then(
        (item: IPosition) => {
          if (onClick) {
            onClick(item)
          }
          handleClose()
        }
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={10}>
                  <Title noWrap>{item?.id ? 'Редактировать' : 'Добавить'} {name}</Title>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClose}
                  >
                    <Close/>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Column>
                  <TextField
                    required
                    name="name"
                    label="Наименование"
                    type="text"
                    formik={formik}
                  />
                </Column>
                <Column>
                  <TextField
                      required
                      name="priority"
                      label="Приоритет"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: 1
                      }}
                      formik={formik}
                  />
                </Column>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Сохранить
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null;
}