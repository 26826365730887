import {useEffect, useState} from "react"
import * as AppStorage from "../App/helpers/storage"
import {useSelector} from "react-redux";
import {NavigateFunction, useNavigate} from "react-router";
import {IRootState} from "../App/reducers/store";

export default function Guest(props: { children: JSX.Element }): JSX.Element | null {
    const {children} = props
    const navigate: NavigateFunction = useNavigate();
    const {account} = useSelector((state: IRootState) => state.account)
    const [allowed, setAllowed] = useState(false);

    // eslint-disable-next-line
    useEffect(() => {
        const token: string | null = AppStorage.get('token.access');

        if (!token && !account) {
            setAllowed(true);
        } else {
            navigate('/');
        }
    });

    return allowed ? children : null
}
