import { CompanyActions } from "../../Company/actions/company";
import { BuildingActions } from "../../Realty/actions/building";
import { EquipmentActions } from "../../Realty/actions/equipment";
import { FloorActions } from "../../Realty/actions/floor";
import { LandActions } from "../../Realty/actions/land";
import { RoomActions } from "../../Realty/actions/room";

export const getAction = (type: string | undefined): any => {
  switch (type) {
    case 'land':
      return LandActions
    case 'building':
      return BuildingActions
    case 'floor':
      return FloorActions
    case 'room':
      return RoomActions
    case 'equipment':
      return EquipmentActions
    case 'company':
      return CompanyActions
    default:
      return LandActions
  }
}
