import {styled} from '@mui/material/styles';
import {InputAdornment, TextField as MuiTextField} from "@mui/material";
import React from "react";

const CustomMuiTextField = styled(MuiTextField)({
  width: "100%",
  height: "100%",
  '& .MuiFormLabel-root': {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000 !important",
    '&.Mui-disabled': {
      marginLeft: "0px",
      paddingLeft: "0px",
      marginTop: "-25px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "11px",
      lineHeight: "20px",
      transform: "translate(0, 20px) scale(1)"
    }
  },
  '& .MuiInputBase-input': {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    marginRight: "3px",
    marginLeft: "3px",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  '& .MuiInputBase-root': {
    height: "auto",
    fontFamily: "Open Sans",
    border: "1px solid #E5E5E5",
    borderRadius: "2px",
    '& .MuiInputAdornment-positionEnd' : {
      marginRight: "10px"
    }
  },
}) as typeof MuiTextField;

export default function TextField(props: any): JSX.Element {
  const {formik, name} = props

  return <CustomMuiTextField
    onBlur={formik?.handleBlur}
    focused={!!props.label}
    value={formik?.values?.[name]}
    onChange={formik?.handleChange}
    helperText={formik?.touched?.[name] && formik?.errors?.[name]}
    error={formik?.touched?.[name] && Boolean(formik?.errors?.[name])}
    variant="standard"
    InputProps={{
      disableUnderline: !(formik?.touched?.[name] && formik?.errors?.[name]),
      ...(props.icon ? {
        startAdornment: (
          <InputAdornment
            position="start"
            style={{marginLeft: "10px"}}
          >
            {props.icon}
          </InputAdornment>
        )
      } : {})
    }}
    {...props}
  />
}