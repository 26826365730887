import React, {useEffect, useState} from "react";
import {Table} from "../App/components/Table";
import {TableHeader} from "../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import Actions from "../App/components/Table/Actions";
import TextField from "../App/components/Input/TextField";
import {List as ListIcon, Search} from "@mui/icons-material";
import Title from "../App/components/Table/Title";
import {useDebounce} from "use-debounce";
import {styled} from "@mui/material/styles";
import {Grid, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {TableRow} from "@devexpress/dx-react-grid";
import {NavigateFunction, useNavigate} from "react-router";
import {RegistryActions} from "./actions/registry";
import {IRegistry} from "./interfaces/registry";
import Edit from "./components/Registry/Buttons/Edit";
import Delete from "./components/Registry/Buttons/Delete";
import {Download} from "./components/Registry/Buttons/Download";

const PREFIX = 'Companies'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')] : {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')] : {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Registries(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {filter} = useSelector((state: IRootState) => state.filters.registries)

  const [page, setPage] = useState(1)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [loading, setLoading] = useState(false)
  const [registries, setRegistries]: any = useState({data: [], meta: { total: null }})
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'number', title: '№'},
    {name: 'date', title: 'Дата начала'},
    {name: 'company', title: 'Компания'},
    {name: 'type', title: 'Тип'},
    {name: 'status', title: 'Статус'},
    {name: 'chairman', title: 'Председатель'},
    {name: 'created', title: 'Создан'},
    {name: 'actions', title: ' '},
  ])

  const [columnCompanies, setColumnCompanies] = useState([
    'number',
    'date',
    'company',
    'type',
    'status',
    'chairman',
    'created',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'number', width: mobile ? 170 : 250},
    {columnName: 'date', width: 250},
    {columnName: 'company', width: 250},
    {columnName: 'type', width: 250},
    {columnName: 'status', width: 250},
    {columnName: 'chairman', width: 250},
    {columnName: 'created', width: 250},
    {columnName: 'actions', width: 150, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(RegistryActions.registries({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!registries.meta?.total ? {meta: true} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [IRegistry],
        meta?: {
          total?: number
        },
      }) => {
        setRegistries({
          data: response.data,
          meta: response.meta ?? registries.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.REGISTRIES,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Title
        title={"Инвентаризационные проверки"}
        actions={[
          <Grid item>
            <TextField
              type="text"
              value={value}
              placeholder="Поиск: Номер"
              icon={<Search/>}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />
          </Grid>
        ]}
      />
      <Table
        meta={registries.meta}
        name={'registries'}
        rows={registries.data.map((item: IRegistry) => ({
          id: item.id,
          number: item.id,
          date: new Date(item.date).toLocaleDateString(),
          company: item.company.name,
          type: item.planned ? 'Плановая' : 'Внеплановая',
          status: item.status?.name,
          chairman: item.chairman.name,
          created: new Date(item.created).toLocaleDateString(),
          actions: <Actions
            list={mobile ? {
              button: <IconButton
                onClick={() => {
                  navigate(`/inventory/registry/${item.id}`)
                }}
              >
                <ListIcon />
              </IconButton>
            } : {}}
            edit={{
              button: <Edit
                registry={item}
                onClick={(registry) => {
                  if (registry) {
                    setRegistries({
                      ...registries,
                      ...{data: registries.data.map((el: IRegistry) => (el.id === registry.id) ? registry : el)}
                    })
                  }
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                onClick={() => {
                  setRegistries({
                    ...registries,
                    data: registries.data.filter((el: IRegistry) => el.id !== item.id),
                  })
                }}
              />
            }}
            download={{
              button: <Download
                registry={item}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnCompanies,
          setColumnOrder: setColumnCompanies,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.REGISTRIES}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`/inventory/registry/${row.id}`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          actions: null
        }}
      />
    </Content>
  ) : null
}