import {HttpService} from "../../App/services/http"
import {IRoomInput} from "../interfaces/input/room";
import {IFilter} from "../interfaces/filter";

export const RoomService = {
  rooms,
  room,
  update,
  create,
  markup,
  delete: remove,
  addFile,
  deleteFile,
  versions,
  restore
}

function rooms(filter: IFilter) {
  return HttpService.get(`/rooms`, filter)
    .then(
      response => {
        return response
      })
}

function room(id: number) {
  return HttpService.get(`/room/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IRoomInput) {
  return HttpService.patch(`/room/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IRoomInput) {
  return HttpService.post(`/room`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/room/${id}`)
    .then(
      response => {
        return response
      })
}

function markup(id: number, layerId: string) {
  return HttpService.post(`/markup/room/${id}`, {
    id: layerId
  })
    .then(
      response => {
        return response
      })
}

function addFile(id: number, formData: FormData) {
  return HttpService.post(`/room/${id}/file`, formData, true, true)
    .then(
      response => {
        return response
      })
}

function deleteFile(id: number, fileId: number) {
  return HttpService.delete(`/room/${id}/file/${fileId}`)
    .then(
      response => {
        return response
      })
}

function versions(guid: string) {
  return HttpService.get(`/room/${guid}/versions`)
  .then(
      response => {
        return response
      })
}
function restore(id: number) {
  return HttpService.post(`/room/version/${id}/restore`)
  .then(
      response => {
        return response
      })
}