import React from "react"
import {Navigate, useRoutes} from "react-router";
import History from "../History/History";
import NotFoundPage from "./components/NotFoundPage";
import Login from "../Auth/Login";
import Guest from "../Auth/Guest";
import Account from "../Auth/Account";
import Logout from "../Auth/Logout";
import Layout from "./components/Layout";
import Profile from "../Account/Profile";
import Administration from "../Administration/Administration";
import {Objects as Realty} from "../Object/Objects";
import Editor from "../Editor/Editor";
import Land from "../Object/components/Land/Land";
import Building from "../Object/components/Building/Building";
import Floor from "../Object/components/Floor/Floor";
import Room from "../Object/components/Room/Room";
import Inventory from "../Inventory/Inventory";
import Equipment from "../Object/components/Equipment/Equipment";

export default function Routes(): JSX.Element {
    const routing = useRoutes([
        {
            path: '/',
            element:  <Account roles={'user'}><Layout/></Account>,
            children: [
                {path: '/', element: <Navigate to="/objects" />},
                {path: 'logout', element: <Logout/>},
                {path: 'account/:section', element: <Profile/>},
                {path: 'profile', element: <Navigate replace to="/account/profile"/>},
                {path: 'history/:section/:id', element: <Account roles={['admin', 'working_group', 'chairman', 'dispatcher']}><History/></Account>},
                {path: 'history/:section', element: <Account roles={['admin', 'working_group', 'chairman', 'dispatcher']}><History/></Account>},
                {path: 'history', element: <Account roles={['admin', 'working_group', 'chairman', 'dispatcher']}><Navigate replace to="/history/journal"/></Account>},
                {path: 'inventory/:section', element: <Account roles={['admin', 'working_group', 'chairman']}><Inventory/></Account>},
                {path: 'inventory/:section/:id', element: <Account roles={['admin', 'working_group', 'chairman']}><Inventory/></Account>},
                {path: 'inventory', element: <Account roles={['admin', 'working_group', 'chairman']}><Navigate replace to="/inventory/registries"/></Account>},
                {path: 'administration/:section/:subsection', element: <Account roles={'admin'}><Administration/></Account>},
                {path: 'administration/:section', element: <Account roles={'admin'}><Administration/></Account>},
                {path: 'administration/dictionary', element: <Account roles={'admin'}><Navigate replace to="/administration/dictionary/land"/></Account>},
                {path: 'administration', element: <Account roles={'admin'}><Navigate replace to="/administration/users"/></Account>},
                {path: 'editor', element: <Editor />}
            ]
        },
        {
            path: '/objects', element: <Account roles={'user'}><Realty/></Account>,
            children: [
                {path: '/objects/:id', element: <Land />},
                {path: '/objects/:id/markup', element: <Land />},
                {path: '/objects/:id/building/:building', element: <Building />},
                {path: '/objects/:id/building/:building/markup', element: <Building />},
                {path: '/objects/:id/building/:building/floor/:floor', element: <Floor />},
                {path: '/objects/:id/building/:building/floor/:floor/markup', element: <Floor />},
                {path: '/objects/:id/building/:building/floor/:floor/room/:room', element: <Room />},
                {path: '/objects/:id/building/:building/floor/:floor/room/:room/equipment/:equipment', element: <Equipment />},
            ]
        },
        {path: '/login', element: <Guest><Login/></Guest>},
        {path: '*', element: <NotFoundPage/>}
    ]);

    return <React.Fragment>{routing}</React.Fragment>;
}