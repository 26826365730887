import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { EquipmentActions } from "../../../Realty/actions/equipment";
import Edit from "../../../Realty/components/Equipment/Buttons/Edit";
import Sticker from "../../../Realty/components/Equipment/Buttons/Sticker";
import { IEquipment } from "../../../Realty/interfaces/equipment";
import Information from "../../Information";
import Template from "../../Template";

export default function Equipment(props: {id?: number}): JSX.Element | null {
  const dispatch: any = useDispatch();
  const params = useParams();

  const [equipment, setEquipment] = useState<IEquipment | null>(null);
  const id = Number(params.equipment ?? props.id);

  const getEquipment = async (id: number) => {
    return await dispatch(EquipmentActions.equipment(id))
  }

  useEffect(() => {
    if (id) {
      getEquipment(id).then(response => {
        setEquipment(response)
      })
    }
    // eslint-disable-next-line
  }, [id])

  return equipment ? (
    <Template
      crumbs={[
        ...((params.equipment && equipment.room) ? [
          {
            name: equipment.room.floor.building.land.name?.length ? equipment.room.floor.building.land.name : equipment.room.floor.building.land.cadastralNumber,
            to: `/objects/${equipment.room.floor.building.land.id}`
          },
          {
            name: equipment.room?.floor.building.name,
            to: `/objects/${equipment.room.floor.building.land.id}/building/${equipment.room.floor.building.id}`
          },
          {
            name: equipment.room.floor.name,
            to: `/objects/${equipment.room?.floor.building.land.id}/building/${equipment.room.floor.building.id}/floor/${equipment.room.floor.id}`
          },
          {
            name: equipment.room.name,
            to: `/objects/${equipment.room.floor.building.land.id}/building/${equipment.room.floor.building.id}/floor/${equipment.room.floor.id}/room/${equipment.room.id}`
          },
        ] : []),
        {name: equipment.name}
      ]}
      contents={{
        common: {
          component: <Information
            files={equipment.files}
            updateFilesHandler={() => getEquipment(id).then(response => {
              setEquipment(response)
            })}
            object={{
              type: 'equipment',
              id: equipment.id
            }}
            mainItems={[
              {name: 'Наименование', value: equipment.name},
              {name: 'Тип оборудования', value: equipment.type?.name},
            ]}
            items={[
              {name: 'Компания', value: equipment.room?.floor.building.land.company.name},
              {name: 'Земельный участок', value: equipment.room?.floor.building.land.name ?? equipment.room?.floor.building.land.cadastralNumber},
              {name: 'Здание', value:  equipment.room?.floor.building.name},
              {name: 'Этаж', value: equipment.room?.floor.name},
              {name: 'Помещение', value: equipment.room?.name},
              {name: 'Общее описание', value: equipment.description},
              {name: 'Количество', value: equipment.quantity?.toString()},
              {name: 'Единица измерения', value: equipment.unit?.name},
              {name: 'Наличие', value: equipment.availability ? 'Да' : 'Нет'},
              {name: 'Стоимость', value: equipment.price.toString()},
              {name: 'Серийный номер', value: equipment.serialNumber},
              {name: 'Технические характеристики', value: equipment.specification},
              {name: 'Инвентарный номер', value: equipment.inventoryNumber},
              {
                name: 'Дата принятия к учету',
                value: equipment.accountingDate ? `${(`0${((new Date(equipment.accountingDate)).getMonth() + 1)}`).slice(-2)}.${(new Date(equipment.accountingDate)).getFullYear()}` : null
              },
              {name: 'Целевая функция', value: equipment.function?.name},
              {name: 'Статус', value: equipment.status?.name},
              {name: 'Ответственный', value: equipment.responsible?.name},
              {name: 'Пользователь', value: equipment.user?.name},
              {name: 'Комментарий', value: equipment.comment},
            ]}
          />,
          actions: [
            <React.Fragment>
              <Sticker
                  equipment={equipment}
              />
              <Edit
                  button={true}
                  equipment={equipment}
                  onClick={(equipment) => {
                    setEquipment(equipment)
                  }}
              />
            </React.Fragment>
          ]
        }
      }}
    />
  ) : null
}