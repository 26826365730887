import React, {useEffect, useState} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../../../App/components/Page/DialogContent";
import {Submit} from "../../../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {CloseDialog} from "../../../../App/components/Dialog/CloseDialog";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {transformData} from "../../../../App/helpers/transformData";
import IResponseError from "../../../../App/interfaces/responseError";
import {CompanyEmployeeActions} from "../../../../Company/actions/company.employee";
import {IEmployee} from "../../../../Company/interfaces/employee";
import {Autocomplete} from "../../../../App/components/Input/AsyncAutocomplete";
import {Autocomplete as CustomAutocomplete} from "../../../../App/components/Input/Autocomplete";
import {IRoomEmployeeInput} from "../../../interfaces/input/room/employee";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Person(props: { companyId: number, roomId: number, employee?: IEmployee | null, open: boolean, handleClose: () => void, onClick?: (employee: IEmployee) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {companyId, roomId, employee, onClick} = props
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [savedPeople, setSavedPeople]: any = useState(null);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    if (onClick && savedPeople) {
      onClick(savedPeople)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      room: {
        id: roomId
      },
      employee: employee,
      phones: employee?.phones ?? []
    },
    validationSchema: Yup.object().shape({
      room: Yup.object().required('Поле обязательно к заполнению'),
      employee: Yup.object().required('Поле обязательно к заполнению'),
      phones: Yup.array().nullable()
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = transformData(values)
      dispatch(CompanyEmployeeActions.room(companyId, data.employee.id, (data as IRoomEmployeeInput)))
        .then(
          (employee: IEmployee) => {
            setSubmitting(true)
            setSavedPeople(employee)
            resetForm({
              values: {
                employee: employee,
                phones: employee?.phones ?? [],
              }
            })
          },
          (error: IResponseError) => setErrors(error.errors)
        )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleCloseDialog}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>{(savedPeople?.id || employee?.id) ? 'Редактировать' : 'Добавить'} личность</Title>
                <IconButton
                  onClick={handleCloseDialog}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container spacing={1}>
                <Item item xs={12}>
                  <Autocomplete
                    name={"employee"}
                    disabled={!!employee}
                    label="Сотрудник"
                    setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanyEmployeeActions.items(companyId, {
                      take: 20,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.employee}
                    getOptionLabel={(option: IEmployee) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={12}>
                  <CustomAutocomplete
                    free
                    name="phones"
                    label="Внутренние номера телефонов"
                    setValue={(name: string, value: Array<string> | []) => {
                      formik.setFieldValue(name, value)
                    }}
                    value={formik.values.phones}
                    getOptionLabel={(option: { key: string, name: string }) => option?.name ?? option}
                    multiple={true}
                  />
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
      <CloseDialog
        open={openDialog}
        onClose={onCloseDialog}
        onCloseWithoutSaving={onCloseWithoutSaving}
      />
    </React.Fragment>
  ) : null;
}