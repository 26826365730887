import React, {useEffect, useState} from "react";
import {Table} from "../../../../App/components/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {FiltersActionsTypes} from "../../../../App/interfaces/filter";
import {TableRow} from "@devexpress/dx-react-grid";
import {EquipmentActions} from "../../../actions/equipment";
import {IEquipment} from "../../../interfaces/equipment";
import {IRegistry} from "../../../interfaces/registry";
import {PagerWithContent} from "../../../../App/components/Table/Paging/PagerWithContent";
import {MenuItem} from "@mui/material";
import Select from "../../../../App/components/Input/Select";

const PREFIX = 'Companies'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

interface Props {
  registry: IRegistry
}

export function Equipments(props: Props): JSX.Element | null {
  const {registry} = props
  const dispatch: any = useDispatch()

  const {filter} = useSelector((state: IRootState) => state.filters.registryEquipments)

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('accounted')
  const [equipments, setEquipments]: any = useState({data: [], meta: { total: null }})
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'number', title: '№'},
    {name: 'equipment.name', title: 'Наименование'},
    {name: 'equipment.description', title: 'Общее описание'},
    {name: 'equipment.inventoryNumber', title: 'Инвентарный номер'},
    {name: 'equipment.serialNumber', title: 'Серийный номер'},
    {name: 'equipment.specification', title: 'Тех.характеристики'},
    {name: 'equipment.user.name', title: 'Пользователь МТО'},
    {name: 'equipment.responsible.name', title: 'Мат.ответственное лицо'},
    {name: 'land.address', title: 'Адрес'},
    {name: 'building.name', title: 'Корпус'},
    {name: 'floor.name', title: 'Этаж'},
    {name: 'room.name', title: 'Помещение'},
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'number', width: 250},
    {columnName: 'equipment.name', width: 250},
    {columnName: 'equipment.description', width: 250},
    {columnName: 'equipment.inventoryNumber', width: 250},
    {columnName: 'equipment.serialNumber', width: 250},
    {columnName: 'equipment.specification', width: 250},
    {columnName: 'equipment.user.name', width: 250},
    {columnName: 'equipment.responsible.name', width: 250},
    {columnName: 'land.address', width: 250},
    {columnName: 'building.name', width: 250},
    {columnName: 'floor.name', width: 250},
    {columnName: 'room.name', width: 250},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(EquipmentActions.equipments(registry.id, {
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        type: type,
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        meta: true,
      })).then((response: {
        data: [IEquipment],
        meta?: {
          total?: number
        },
      }) => {
        setEquipments({
          data: response.data,
          meta: response.meta ?? equipments.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading, registry])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, type])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.REGISTRY_EQUIPMENTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Table
      meta={equipments.meta}
      name={'registryEquipments'}
      rows={equipments.data.map((item: IEquipment, index: number) => ({
        number: (index + 1) + ((page - 1) * filter.take),
        'equipment.name': item.equipment.name,
        'equipment.description': item.equipment.description,
        'equipment.inventoryNumber': item.equipment.inventoryNumber,
        'equipment.serialNumber': item.equipment.serialNumber,
        'equipment.specification': item.equipment.specification,
        'equipment.user.name': item.equipment.user?.name,
        'equipment.responsible.name': item.equipment.responsible?.name,
        'land.address': item.room?.floor.building.land.address,
        'building.name': item.room?.floor.building.name,
        'floor.name': item.room?.floor.name,
        'room.name': item.room?.name
      }))}
      columns={columns}
      page={{
        page: page,
        setPage: setPage,
        rowsPerPage: filter.take,
        handlePageSizeChange: handlePageSizeChange
      }}
      columnsSettings={{
        setColumnWidths: setColumnWidths,
        columnWidths: columnWidths,
        hiddenColumnNames: hiddenColumnNames,
        setHiddenColumnNames: setHiddenColumnNames
      }}
      tableHeader={TableHeader}
      filterActionType={FiltersActionsTypes.REGISTRY_EQUIPMENTS}
      classInputContent={classes.inputContent}
      rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
        <MUITable.Row
          tableRow={tableRow}
          className={classes.tableRow}
          row={row}
          children={children}
          style={{'cursor': 'pointer'}}
        />
      )}
      pager={(props: any) => (
        <PagerWithContent {...props}>
          <Select
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setType(event.target.value)}
            value={type}
          >
            {[
              {key: 'accounted', name: 'Учтенное оборудование'},
              {key: 'surplus', name: 'Зафиксированные излишки'},
              {key: 'shortages', name: 'Зафиксированные недостачи'},
              {key: 'moved', name: 'Перемещенное оборудование'},
            ].map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </PagerWithContent>
      )}
      filters={{
        number: null,
        'equipment.name': null,
        'equipment.description': null,
        'equipment.inventoryNumber': null,
        'equipment.serialNumber': null,
        'equipment.specification': null,
        'equipment.user.name': null,
        'equipment.responsible.name': null,
        'land.address': null,
        'building.name': null,
        'floor.name': null,
        'room.name': null,
        actions: null
      }}
    />
  ) : null
}