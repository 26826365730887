import React, {
  useEffect,
  useState
} from "react";
import Template from "../../Template";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { IRoom } from "../../../Realty/interfaces/room";
import { RoomActions } from "../../../Realty/actions/room";
import List from "./List";
import { Create as CreateEquipment } from "../../../Realty/components/Equipment/Buttons/Create";
import { Create as CreateRoom } from "../../../Realty/components/Room/Buttons/Create";
import { Create as CreateRoomPeople } from "../../../Realty/components/Room/People/Buttons/Create";
import { Edit as EditRoom } from "../../../Realty/components/Room/Buttons/Edit";
import Information from "../../Information";
import { IEquipment } from "../../../Realty/interfaces/equipment";
import { LandActionsTypes } from "../../interfaces/land";
import People from "./People";
import {IEmployee} from "../../../Company/interfaces/employee";

export default function Room(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const params = useParams();

  const [room, setRoom] = useState<IRoom | null>(null);
  const [item, setItem] = useState<IEquipment | null>(null);
  const [employee, setEmployee] = useState<IEmployee | null>(null);
  const [loadPeople, setLoadPeople] = useState<boolean>(false);
  const [people, setPeople]: any = useState([])
  const [loadEquipment, setLoadEquipment] = useState<boolean>(false);
  const [equipments, setEquipments]: any = useState([])
  const getRoom = async (id: number) => {
    return await dispatch(RoomActions.room(id))
  }

  useEffect(() => {
    if (params.room) {
      getRoom(Number(params.room)).then(response => {
        setRoom(response)
      })
    }
    // eslint-disable-next-line
  }, [params.room])

  return room ? (
    <Template
      crumbs={[
        {
          name: room.floor.building.land.name?.length ? room.floor.building.land.name : room.floor.building.land.cadastralNumber,
          to: `/objects/${room.floor.building.land.id}`
        },
        {
          name: room.floor.building.name,
          to: `/objects/${room.floor.building.land.id}/building/${room.floor.building.id}`
        },
        {
          name: room.floor.name,
          to: `/objects/${room.floor.building.land.id}/building/${room.floor.building.id}/floor/${room.floor.id}`
        },
        {name: room.name}
      ]}
      contents={{
        common: {
          component: <Information
            files={room.files}
            updateFilesHandler={() => getRoom(Number(params.room)).then(response => {
              setRoom(response)
            })}
            object={{
              type: 'room',
              id: room.id
            }}
            items={[
              {name: 'Наименование', value: room.name},
              {name: 'Тип помещения', value: room.type?.name},
              {name: 'Высота', value: room.height?.toString()},
              {name: 'Площадь кв.м', value: room.square?.toString()},
              {name: 'Подразделения', value: room.subdivision?.name},
              {name: 'Ответственный', value: room.safety?.name},
              {name: 'Тип аренды', value: room.rentalType?.name},
              {name: 'Информация о договорах аренды', value: room.rentalInformation},
              {name: 'Количество рабочих мест всего', value: room.numberWorkplaces?.toString()},
              {name: 'Количество рабочих мест занято', value: room._count.people?.toString()},
              {
                name: 'Дата капитального ремонта',
                value: room.dateOverhaul ? `${(`0${((new Date(room.dateOverhaul)).getMonth() + 1)}`).slice(-2)}.${(new Date(room.dateOverhaul)).getFullYear()}` : null
              },
              {
                name: 'Дата косметического ремонта',
                value: room.dateRedecorating ? `${(`0${((new Date(room.dateRedecorating)).getMonth() + 1)}`).slice(-2)}.${(new Date(room.dateRedecorating)).getFullYear()}` : null
              },
            ]}
            mainItems={[
              {name: 'Наименование', value: room.name},
              {name: 'Тип помещения', value: room.type?.name},
              {name: 'Площадь кв.м', value: room.square?.toString()},
            ]}
          />,
          actions: [
            <EditRoom
              room={room}
              onClick={(room) => {
                setRoom(room)
                dispatch({type: LandActionsTypes.LAND_ROOM, payload: room})
              }}
              button
            />,
            <CreateRoom
              floor={room.floor}
              onClick={(room) => {
                dispatch({type: LandActionsTypes.LAND_ROOM, payload: room})
              }}
            />
          ]
        },
        list: {
          name: 'Оборудование',
          component: <List
            room={room}
            equipment={item}
            equipments={equipments}
            setEquipments={setEquipments}
            loadEquipment={loadEquipment}
            setLoadEquipment={setLoadEquipment}
          />,
          actions: [
            <CreateEquipment
              room={room}
              onClick={(equipment) => {
                setItem(equipment)
              }}
            />
          ]
        },
        people: {
          component: <People
            company={room.floor.building.land.company}
            room={room}
            employee={employee}
            setRoom={setRoom}
            people={people}
            setPeople={setPeople}
            loadPeople={loadPeople}
            setLoadPeople={setLoadPeople}
          />,
          actions: [
            <CreateRoomPeople
              company={room.floor.building.land.company}
              room={room}
              onClick={(employee: IEmployee) => {
                setEmployee(employee)
                if (employee.room) {
                  setRoom(employee.room)
                }
              }}
            />
          ]
        }
      }}
    />
  ) : null
}