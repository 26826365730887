import React, {useEffect, useState} from "react";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import {Drawer as MuiDrawer, Grid, IconButton} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {LandActions} from "../../../Realty/actions/land";
import {Autocomplete} from "../../../App/components/Input/AsyncAutocomplete";
import {ILand} from "../../../Realty/interfaces/land";
import {NavigateFunction, useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {Buildings} from "./Buildings";
import {IRootState} from "../../../App/reducers/store";
import {LandActionsTypes} from "../../interfaces/land";

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)})`,
  [theme.breakpoints.down('sm')]: {
    width: `calc(${theme.spacing(6)})`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      display: 'flex',
      justifyContent: 'space-between',

    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const PREFIX = 'Menu'

const classes = {
  menu: `${PREFIX}-menu`,
}

const Root = styled('div')(() => ({
  paddingTop: '56px',
  [`& .${classes.menu}`]: {
    width: drawerWidth,
    height: "100%",
    background: "white"
  },
}))

const Land = styled(Grid)(() => ({
  padding: "12px",
  height: "72px"
}))

const Structure = styled(Grid)(() => ({
  maxWidth: drawerWidth,
  overflow: 'overlay',
  overflowX: 'hidden'
}))

export default function Menu(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const params = useParams();

  const {land, state} = useSelector((state: IRootState) => state.land)

  const [open, setOpen] = useState(true);
  const isOpen = () => setOpen(prev => !prev)

  useEffect(() => {
    const getLand = async (id: number) => {
      return await dispatch(LandActions.land(id))
    }
    const getLast = async () => {
      return await dispatch(LandActions.lands({take: 1, direction: 'desc'}))
    }

    if (params.id) {
      if (!land || (Number(params.id) !== land.id))
      getLand(Number(params.id)).then(response => {
        dispatch({type: LandActionsTypes.LAND, payload: response})
      })
    } else if (land) {
      navigate(`/objects/${land.id}` + (state?.building ? `/building/${state?.building}` : '') + (state?.floor ? `/floor/${state?.floor}` : '') + (state?.room ? `/room/${state?.room}` : ''))
    } else {
      getLast().then(response => {
        if (response.data.length) {
          const last = response.data.shift()
          navigate(`/objects/${last.id}`)
        }
      })
    }
    // eslint-disable-next-line
  }, [params.id])

  return (
    <Drawer variant="permanent" open={open}>
      <Root>
        {open ?
          <Grid container direction="column" className={classes.menu}>
            <Land>
              <Autocomplete
                name="land"
                label="Земельный участок"
                setValue={(name: string, land: ILand) => {
                  if (land) {
                    navigate(`/objects/${land.id}`)
                  } else {
                    dispatch({type: LandActionsTypes.LAND, payload: null})
                    navigate(`/objects`)
                  }
                }}
                getValues={(search: string) => LandActions.lands({
                  take: 20,
                  ...(search ? {search: search} : {})
                })}
                isOptionEqualToValue={(option: {id: number}, value: {id: number} | null) => {
                  return (option.id === value?.id)
                }}
                value={land}
                getOptionLabel={(option: ILand) => option?.name ? option?.name : (option?.cadastralNumber ?? option)}
              />
            </Land>
            {land ? (
              <Structure>
                <Buildings land={land} />
              </Structure>
            ) : null}
          </Grid>
        : <Grid sx={{my: 40, mx: 'auto', 'writing-mode': 'vertical-lr' }}>
          {land?.cadastralNumber}
        </Grid>}
      </Root> 
      <IconButton onClick={isOpen}
       style={{borderTop: "1px solid #e0e0e0", borderRadius: '0'}}
       >
        {open ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
    </Drawer>
  )
}
