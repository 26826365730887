import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react"
import { useDispatch } from "react-redux";
import { Submit } from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import { EquipmentActions } from "../../../actions/equipment";

export interface IStickersProps {
  data: {
    isSelectAll: boolean
    items: number[]
  }
}

export function Stickers(props: IStickersProps): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {isSelectAll, items} = props.data
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = async () => {
    dispatch(EquipmentActions.stickers(isSelectAll, items)).then((res: any) => {
      const file = new File([res], `stickers.pdf`, {type: "application/pdf"});
      const fileURL = URL.createObjectURL(file);
      const printWindow = window.open(fileURL, "_blank");
      if (printWindow) {
        printWindow.onload = function () {
          printWindow.print();
        };
      }
    });
  };

  return !mobile ? (
      <Access roles={['admin']}>
        <Grid item>
          <Submit
              disabled={(isSelectAll ? false : !items.length)}
              onClick={handleClick}
              variant="contained"
              type="submit"
          >
            Печать стикеров
          </Submit>
        </Grid>
      </Access>
  ) : null
}
