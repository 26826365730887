import React, {useEffect, useState} from "react";
import Template from "../../Template";
import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router";
import {IFloor} from "../../../Realty/interfaces/floor";
import {FloorActions} from "../../../Realty/actions/floor";
import List from "./List";
import {Create as CreateRoom} from "../../../Realty/components/Room/Buttons/Create";
import {Viewer} from "./Viewer";
import {Create as CreateFloor} from "../../../Realty/components/Floor/Buttons/Create";
import {Edit as EditFloor} from "../../../Realty/components/Floor/Buttons/Edit";
import {Edit as EditMarkup} from "../../../Editor/components/Floor/Buttons/Edit";
import Editor from "../../../Editor/components/Floor/Editor";
import Information from "../../Information";
import {IRoom} from "../../../Realty/interfaces/room";
import {LandActionsTypes} from "../../interfaces/land";
import Statistic from "./Statistic";

export default function Floor(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const location = useLocation();
  const params = useParams();

  const [floor, setFloor] = useState<IFloor | null>(null);
  const [item, setItem] = useState<IRoom | null>(null);
  const [editMode, setEditMode] = useState(location.pathname.includes('markup'));
  const getFloor = async (id: number) => {
    return await dispatch(FloorActions.floor(id))
  }

  useEffect(() => {
    if (params.floor) {
      getFloor(Number(params.floor)).then(response => {
        setFloor(response)
      })
    }
    // eslint-disable-next-line
  }, [params.floor])

  useEffect(() => {
    setEditMode(location.pathname.includes('markup'))
  }, [location.pathname])

  return floor ? (
    <Template
      crumbs={[
        {
          name: floor.building.land.name?.length ? floor.building.land.name : floor.building.land.cadastralNumber,
          to: `/objects/${floor.building.land.id}`
        },
        {name: floor.building.name, to: `/objects/${floor.building.land.id}/building/${floor.building.id}`},
        {name: floor.name}
      ]}
      contents={{
        common: {
          component: <Information
            files={floor.files}
            updateFilesHandler={() => getFloor(Number(params.floor)).then(response => {
              setFloor(response)
            })}
            object={{
              type: 'floor',
              id: floor.id
            }}
            mainItems={[
              {name: 'Наименование', value: floor.name},
              {name: 'Тип этажа', value: floor.type?.name},
              {name: 'Площадь кв.м', value: floor.square?.toFixed(2).toString()}
            ]}
            items={[
              {name: 'Наименование', value: floor.name},
              {name: 'Тип этажа', value: floor.type?.name},
              {name: 'Площадь кв.м', value: floor.square?.toFixed(2).toString()},
              {name: 'Дата последнего ремонта', value: floor.dateRepair ? (new Date(floor.dateRepair)).toLocaleDateString() : null},
            ]}
          />,
          actions: [
            <EditFloor
              floor={floor}
              onClick={(floor) => {
                setFloor(floor)
                dispatch({type: LandActionsTypes.LAND_FLOOR, payload: floor})
              }}
              button
            />,
            <CreateFloor
              building={floor.building}
              onClick={(floor) => {
                dispatch({type: LandActionsTypes.LAND_FLOOR, payload: floor})
              }}
            />
          ]
        },
        list: {
          component: <List floor={floor} room={item}/>,
          actions: [
            <CreateRoom
              floor={floor}
              onClick={(room) => {
                setItem(room)
                dispatch({type: LandActionsTypes.LAND_ROOM, payload: room})
              }}
            />
          ]
        },
        statistic: {
          component: <Statistic floor={floor}/>
        },
        scheme: {
          component: editMode ? <Editor/> : <Viewer floor={floor}/>,
          actions: [<EditMarkup id={floor.id} buildingId={floor.building.id} landId={floor.building.land.id}/>]
        }
      }}
    />
  ) : null
}