import React from "react"
import {Submit} from "../../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../../App/components/Svg/AddIcon";
import Access from "../../../../../Auth/components/Access";
import Type from "../../../Equipment/Type";
import {IType} from "../../../../interfaces/Equipment/type";
import Grid from "@mui/material/Grid";

type Props = {
  name: string,
  actions: any,
  onClick?: (item: IType) => void
}

export function Create(props: Props): JSX.Element {
  const { name, actions, onClick } = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <Grid item>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Добавить
        </Submit>
        {open ? <Type open={open} name={name} actions={actions} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  )
}
