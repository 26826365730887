import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {IEquipment} from "../../../../interfaces/equipment";
import {IRegistry} from "../../../../interfaces/registry";
import Equipment from "../Equipment";
import isRole from "../../../../../Auth/hooks/authorize";
import {useSelector} from "react-redux";
import {IRootState} from "../../../../../App/reducers/store";

type Props = {
  registry: IRegistry,
  equipment: IEquipment,
  onClick?: (equipment?: IEquipment) => void
}

export default function Edit(props: Props): JSX.Element | null {
  const { registry, equipment, onClick } = props
  const {account} = useSelector((state: IRootState) => state.account)
  const [isWorkingGroup] = isRole(account, 'working_group');
  const [isChairman] = isRole(account, 'chairman');
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isAccess = () => {
    return (registry?.status?.key === 'during') && (isWorkingGroup || (equipment.surplus && isChairman))
  }

  return isAccess() ? (
    <React.Fragment>
      <IconButton
        onClick={handleOpen}
      >
        <EditIcon/>
      </IconButton>
      {open ? <Equipment registry={registry} equipment={equipment} open={open} handleClose={handleClose} onClick={onClick} /> : null}
    </React.Fragment>
  ) : null;
}
