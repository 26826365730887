import Grid from "@mui/material/Grid";
import React from "react"
import { Submit } from "../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../App/components/Svg/AddIcon";
import Access from "../../../../Auth/components/Access";
import { ICompany } from "../../../interfaces/company";
import Company from "../../Company";

type Props = {
  onClick?: (company: ICompany) => void,
}

export default function Create(props: Props): JSX.Element {
  const {onClick} = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <Grid item>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Добавить
        </Submit>
        {open ? <Company open={open} handleClose={handleClose} onClick={onClick}/> : null}
      </Grid>
    </Access>
  )
}
