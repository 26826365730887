import {HttpService} from "../../App/services/http"
import {IFloorInput} from "../interfaces/input/floor";
import {IFilter} from "../interfaces/filter";

export const FloorService = {
  floors,
  floor,
  update,
  create,
  markup,
  delete: remove,
  addFile,
  deleteFile,
  versions,
  restore
}

function floors(filter: IFilter) {
  return HttpService.get(`/floors`, filter)
    .then(
      response => {
        return response
      })
}

function floor(id: number) {
  return HttpService.get(`/floor/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IFloorInput) {
  return HttpService.patch(`/floor/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IFloorInput) {
  return HttpService.post(`/floor`, data)
    .then(
      response => {
        return response
      })
}

function markup(id: number) {
  return HttpService.get(`/markup/floor/${id}`)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/floor/${id}`)
    .then(
      response => {
        return response
      })
}

function addFile(id: number, formData: FormData) {
  return HttpService.post(`/floor/${id}/file`, formData, true, true)
    .then(
      response => {
        return response
      })
}

function deleteFile(id: number, fileId: number) {
  return HttpService.delete(`/floor/${id}/file/${fileId}`)
    .then(
      response => {
        return response
      })
}

function versions(guid: string) {
  return HttpService.get(`/floor/${guid}/versions`)
  .then(
      response => {
        return response
      })
}
function restore(id: number) {
  return HttpService.post(`/floor/version/${id}/restore`)
  .then(
      response => {
        return response
      })
}