import React, {useState} from "react"
import {
  Dialog as MUIDialog, DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import {IRegistry} from "../../../interfaces/registry";
import {QrScanner} from "@yudiel/react-qr-scanner";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {Close} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {EquipmentActions} from "../../../actions/equipment";
import {IEquipment} from "../../../../Realty/interfaces/equipment";
import {IEquipment as IRegistryEquipment} from "../../../interfaces/equipment";
import Equipment from "./Equipment";
import Search from "./Reader/Search";

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  [theme.breakpoints.down('md')] : {
    fontWeight: 500,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')] : {
    fontWeight: 600,
    fontSize: '20px',
  },
  lineHeight: '32px'
}))

export default function Reader(props: { registry: IRegistry,  open: boolean, handleClose: (equipment?: IEquipment) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {registry, open, handleClose} = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [equipment, setEquipment] = useState<IRegistryEquipment>()
  const [error, setError] = useState<string | null>(null)

  return open ? (
    <Dialog
      fullScreen={mobile}
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Title>Сканер QR кодов</Title>
          <IconButton
            onClick={() => handleClose()}
          >
            <Close/>
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: "100%"}}>
          {error ? (
            <Typography>Данное оборудование не включено в инвентаризационную опись</Typography>
          ) : (
            <QrScanner
              onDecode={(data) => {
                if (registry) {
                  const {number} = JSON.parse(data)
                  if (number) {
                    dispatch(EquipmentActions.equipment(registry.id, number)).then(
                      (response: IRegistryEquipment) => {
                        setEquipment(response)
                      },
                      () => {
                        setError(number)
                      }
                    )
                  }
                }
              }}
              onError={(error) => console.log(error?.message)}
            />
          )}
        </Stack>
        {equipment ? (
          <Equipment
            registry={registry}
            equipment={equipment}
            open={!!equipment}
            handleClose={() => {
              setEquipment(undefined)
            }}
          />
        ) : null}
      </DialogContent>
      {error ? (
        <React.Fragment>
          <Divider/>
          <DialogActions>
            <Search registry={registry} number={error} onClick={(equipment) => handleClose(equipment)} />
          </DialogActions>
        </React.Fragment>
      ) : null}
    </Dialog>
  ) : null;
}