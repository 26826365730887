import React from "react"
import Access from "../../../../Auth/components/Access";
import {IRegistry} from "../../../interfaces/registry";
import {IconButton, MenuItem, MenuList, useMediaQuery, useTheme} from "@mui/material";
import {Downloading} from "@mui/icons-material";
import {Menu} from "../../../../App/components/Menu";

type Props = {
  registry: IRegistry
}

export function Download(props: Props): JSX.Element | null {
  const { registry } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDuring = () => {
    return !mobile && (registry?.status?.key === 'passed')
  }

  return isDuring() ? (
    <Access roles={['admin', 'chairman', 'working_group']}>
      <React.Fragment>
        <IconButton
          onClick={handleClick}
        >
          <Downloading />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList dense>
            <MenuItem
              onClick={() => {
                const link = document.createElement("a");
                link.download = `${registry.id}.xlsx`;
                link.href = `${process.env.REACT_APP_API_URL}/registry/${registry.id}/file`;
                link.click();
                handleClose();
              }}
            >
              Инвентаризационная опись
            </MenuItem>
            {registry.file ? (
              <MenuItem
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = `${process.env.REACT_APP_API_URL}/${registry.file}`;
                  link.click();
                  handleClose();
                }}
              >
                Служебная записка
              </MenuItem>
            ) : null}
          </MenuList>
        </Menu>
      </React.Fragment>
    </Access>
  ) : null
}
