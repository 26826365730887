import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import {Grid} from "@mui/material";
import Title from "../App/components/Table/Title";
import {Paper} from "../App/components/Paper";
import Breadcrumbs from "../App/components/Table/Breadcrumbs";
import {useLocation} from "react-router-dom";
import {NavigateFunction, useNavigate} from "react-router";

const Root = styled(Grid)(() => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  position: "relative"
}))

const PREFIX = 'Content'

const classes = {
  planner: `${PREFIX}-planner`,
}

const Content = styled(Grid)(() => ({
  width: "100%",
  height: "calc(100vh - 172px)",
  minHeight: "calc(100vh - 172px)",
  overflow: "overlay",
  overflowX: "hidden",
  background: "white",
  [`&.${classes.planner}`]: {
    background: '#0e293f'
  }
}));

type Tab = {
  name: string,
  key: 'common' | 'list' | 'scheme' | 'history' | 'statistic' | 'people'
}

const tabs: Array<Tab> = [
  {
    name: 'Общая',
    key: 'common'
  },
  {
    name: 'Список',
    key: 'list'
  },
  {
    name: 'Карта/схема',
    key: 'scheme'
  },
  {
    name: 'История изменений',
    key: 'history'
  },
  {
    name: 'Статистика',
    key: 'statistic'
  },
  {
    name: 'Люди',
    key: 'people'
  }
]

type Props = {
  crumbs: Array<{ name: string, to?: string | null }>
  contents: {
    common: { name?: string, component: JSX.Element, actions?: Array<JSX.Element> },
    list?: { name?: string, component: JSX.Element, actions?: Array<JSX.Element> },
    scheme?: { name?: string, component: JSX.Element, actions?: Array<JSX.Element> },
    history?: { name?: string, component: JSX.Element, actions?: Array<JSX.Element> },
    statistic?: { name?: string, component: JSX.Element, actions?: Array<JSX.Element> },
    people?: { name?: string, component: JSX.Element, actions?: Array<JSX.Element> }
  }
}

export default function Template(props: Props): JSX.Element | null {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const {contents, crumbs} = props
  const params = new URLSearchParams(location.search);

  const getTabIndex = (section?: string | null | 'common' | 'list' | 'scheme' | 'history' | 'statistic' | 'people') => {
    if (section) {
      const index = tabs.filter((item) => contents[item.key]).findIndex(tab => tab.key === section);
      if (index > 0) {
        return index;
      }
    }
    return 0
  }

  const [tab, setTab] = React.useState(getTabIndex(params.get('section') ?? 'scheme'));
  const [content, setContent] = React.useState(contents[tabs.filter((item) => contents[item.key])[tab].key]);
  const [isPlannerMode, setIsPlannerMode] = React.useState((content?.component.type.name === 'FloorEditor') && location.pathname.includes('floor'));

  useEffect(() => {
    setContent(contents[tabs.filter((item) => contents[item.key])[tab].key])
    if (params.has('section')) {
      setTab(getTabIndex(params.get('section')))
      navigate({search: ''});
    }
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    setIsPlannerMode((content?.component.type.name === 'FloorEditor') && location.pathname.includes('floor'))
  }, [content, location.pathname])

  return (
    <Root>
      <Title
        tab={{
          value: tab,
          onChange: (event: React.SyntheticEvent, value: number) => {
            setTab(value);
            if (location.pathname.includes('markup')) {
              navigate(location.pathname.replace('/markup', ''))
            }
          },
          tabs: tabs.filter((item) => contents[item.key]).map((item, index) => ({
            label: contents[item.key]?.name ?? item.name,
            value: index,
            disabled: !contents[item.key]
          }))
        }}
        actions={content?.actions}
      />
      <Breadcrumbs
        crumbs={crumbs}
      />
      <Paper>
        <Content className={isPlannerMode ? classes.planner : undefined}>
          {content?.component}
        </Content>
      </Paper>
    </Root>
  )
}