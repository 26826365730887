import React, {useEffect} from "react"
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../../../App/components/Page/DialogContent";
import {Submit} from "../../../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Autocomplete} from "../../../../App/components/Input/AsyncAutocomplete";
import {CloseDialog} from "../../../../App/components/Dialog/CloseDialog";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {EquipmentActions as RegistryEquipmentActions} from "../../../actions/equipment";
import {transformData} from "../../../../App/helpers/transformData";
import IResponseError from "../../../../App/interfaces/responseError";
import {IRegistry} from "../../../interfaces/registry";
import {IEquipment as IRegistryEquipment} from "../../../interfaces/equipment";
import {IEquipment} from "../../../../Realty/interfaces/equipment";
import {EquipmentActions} from "../../../../Realty/actions/equipment";
import {Create} from "./Buttons/Surplus/Create";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  [theme.breakpoints.down('md')] : {
    fontWeight: 500,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')] : {
    fontWeight: 600,
    fontSize: '20px',
  },
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Surplus(props: { registry: IRegistry,  open: boolean, handleClose: () => void, onClick?: () => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {registry, onClick} = props
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    if (onClick) {
      onClick()
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      equipment: null,
    },
    validationSchema: Yup.object().shape({
      equipment: Yup.object().required('Поле обязательно к заполнению')
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting}) => {
      const data = transformData(values)
      dispatch(RegistryEquipmentActions.surplus(registry.id, data.equipment.id))
        .then(
          (equipment: IRegistryEquipment) => {
            setSubmitting(true)
            handleClose()
          },
          (error: IResponseError) => setErrors(error.errors)
        )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullScreen={mobile}
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleCloseDialog}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
          <DialogTitle>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Title>{mobile ? "Добавить предложение" : "Добавить предложение для излишек"}</Title>
              <IconButton
                onClick={handleCloseDialog}
              >
                <Close/>
              </IconButton>
            </Grid>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Item item xs={12}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={mobile ? 6 : 9}>
                  <Autocomplete
                    disabled={formik.isSubmitting}
                    required
                    name="equipment"
                    label="Найти"
                    setValue={(name: string, value: IEquipment) => formik.setValues({
                      ...formik.values,
                      [name]: value,
                      building: null,
                      floor: null,
                      room: null
                    })}
                    getValues={(search: string) => EquipmentActions.equipments({
                      take: 20,
                      companyId: registry.company.id,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.equipment}
                    getOptionLabel={(option: IEquipment) => option?.inventoryNumber ?? option.name}
                    formik={formik}
                  />
                </Grid>
                <Grid item sx={{ height: "50px" }}>
                  <Create
                    registry={registry}
                    disabled={formik.values.equipment || formik.isSubmitting}
                    onClick={async (equipment) => {
                      await formik.setFieldValue('equipment', equipment)
                      formik.handleSubmit()
                    }}
                  />
                </Grid>
              </Grid>
            </Item>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
              <Grid item>
                <Submit
                  disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Добавить предложение
                </Submit>
              </Grid>
              <Grid item>
                <Submit
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  Закрыть
                </Submit>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <CloseDialog
        open={openDialog}
        onClose={onCloseDialog}
        onCloseWithoutSaving={onCloseWithoutSaving}
      />
    </React.Fragment>
  ) : null;
}