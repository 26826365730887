import React, { useState } from "react"
import { Submit } from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton, LinearProgress,
  Typography, useMediaQuery, useTheme
} from "@mui/material";
import { Upload as UploadFile } from "../../../../App/components/Buttons/Upload";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../../../App/reducers/store";
import { EquipmentActions } from "../../../actions/equipment";
import {ICompany} from "../../../../Company/interfaces/company";
import {Autocomplete} from "../../../../App/components/Input/AsyncAutocomplete";
import {CompanyActions} from "../../../../Company/actions/company";

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  [theme.breakpoints.down('md')] : {
    fontWeight: 500,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')] : {
    fontWeight: 600,
    fontSize: '20px',
  },
  lineHeight: '32px'
}))

const Label = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "20px",
  transform: "translate(0, -1.5px) scale(0.75)",
  transformOrigin: "top left"
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Button = styled(Submit)(() => ({
  minWidth: "110px"
}))

const Progress = styled(LinearProgress)(() => ({
  width: "100%"
}))

type Props = {
  onClick?: () => void
}

export function Upload(props: Props): JSX.Element | null {
  const { onClick } = props
  const dispatch: Dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [company, setCompany] = useState<ICompany>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const handleCloseUpload = () => {
    setFile(null);
    setCompany(undefined);
    setIsSubmit(false);
    if (onClick) {
      onClick()
    }
    setOpen(false);
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) {
      setFile(e.target?.files[0])
    }
    setOpen(Boolean(e.target?.files?.length));
  };

  const upload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('companyId', String(company?.id));
      setIsSubmit(true)
      dispatch(EquipmentActions.import(formData)).then(
        () => {
          handleCloseUpload()
        }
      )
    }
  };

  return !mobile ? (
    <Access roles={['admin']}>
      <Grid item>
        <UploadFile
          onChange={handleUpload}
          fullWidth
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          {`Загрузить XLS`}
        </UploadFile>
        {open ? (
          <Dialog
            fullScreen={mobile}
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={handleCloseUpload}
            onDoubleClick={(e: any) => e.stopPropagation()}
          >
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>Загрузка XLS</Title>
                <IconButton
                  onClick={handleCloseUpload}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="column" alignItems="center" justifyContent="space-between" spacing={3}>
                <Item item>
                  <Autocomplete
                    required
                    name="company"
                    label="Компания"
                    setValue={(name: string, value: ICompany) => setCompany(value)}
                    getValues={(search: string) => CompanyActions.companies({
                      take: 20,
                      ...(search ? {search: search} : {})
                    })}
                    value={company}
                    getOptionLabel={(option: ICompany) => option?.name ?? option}
                  />
                </Item>
                <Item item>
                  <Label>Выбранный файл:</Label>
                  <Typography>{file?.name}</Typography>
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Button
                disabled={!company || isSubmit}
                onClick={upload}
                variant="contained"
                type="submit"
              >
                {isSubmit ? <Progress /> : 'Загрузить'}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Grid>
    </Access>
  ) : null
}
