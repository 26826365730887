import { TableRow } from "@devexpress/dx-react-grid";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Table } from "../../App/components/Table";
import Actions from "../../App/components/Table/Actions";
import { TableHeader } from "../../App/components/Table/TableHeader";
import Title from "../../App/components/Table/Title";
import { FiltersActionsTypes } from "../../App/interfaces/filter";
import { IRootState } from "../../App/reducers/store";
import { CompanyActions } from "../../Company/actions/company";
import { ICompany } from "../../Company/interfaces/company";
import { BuildingActions } from "../../Realty/actions/building";
import { EquipmentActions } from "../../Realty/actions/equipment";
import { FloorActions } from "../../Realty/actions/floor";
import { LandActions } from "../../Realty/actions/land";
import { RoomActions } from "../../Realty/actions/room";
import { IBuilding } from "../../Realty/interfaces/building";
import { IFloor } from "../../Realty/interfaces/floor";
import { ILand } from "../../Realty/interfaces/land";
import { IRoom } from "../../Realty/interfaces/room";
import { IVersion } from "../interfaces/version";
import Restore from "./Card/Buttons/Restore";
import View from "./Card/Buttons/View";

interface CardProps {
  id: string,
  objectTypeId: number
}

const PREFIX = 'Card'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))
export default function Card(props: CardProps): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {id, objectTypeId} = props;
  const theme = useTheme();
  const {filter} = useSelector((state: IRootState) => state.filters.registries)
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false)
  const [versions, setVersions]: any = useState({data: [], meta: {total: null}})
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [page, setPage] = useState(1)

  const [columns]: any = useState([
    {name: 'id', title: '№'},
    {name: 'author', title: 'Автор изменений'},
    {name: 'created', title: 'Дата и время изменения'},
    {name: 'type', title: 'Действие'},
    {name: 'actions', title: ' '},
  ])

  const [columnCompanies, setColumnCompanies] = useState([
    'id',
    'author',
    'created',
    'type',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'id', width: mobile ? 170 : 250},
    {columnName: 'author', width: 250},
    {columnName: 'created', width: 250},
    {columnName: 'type', width: 250},
    {columnName: 'actions', width: 1000, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      let action;
      switch (objectTypeId) {
        case 1:
          action = CompanyActions.versions(id);
          break;
        case 2:
          action = LandActions.versions(id);
          break;
        case 3:
          action = BuildingActions.versions(id);
          break;
        case 4:
          action = FloorActions.versions(id);
          break;
        case 5:
          action = RoomActions.versions(id);
          break;
        case 6:
          action = EquipmentActions.versions(id);
          break;
      }
      dispatch(action).then((response: {
        data: [IVersion],
        meta: {
          total?: number
        },
      }): void => {
        setVersions({
          data: response.data,
          meta: response.meta
        })
        setLoading(true);
      })
    }
    // eslint-disable-next-line
  }, [loading])
  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.VERSIONS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return <Content>
    <Title
        title={"Журнал изменений"}
        actions={[]}
    />
    <Table
        meta={versions.meta}
        name={'companies'}
        rows={versions.data.filter((item:ICompany|ILand|IBuilding|IFloor|IRoom) => item.version).map((item: ICompany|ILand|IBuilding|IFloor|IRoom, index: number) => ({
          id: item.id,
          author: item.version!.author.name,
          type: item.version!.type.name,
          created: new Date(item.created).toLocaleString(),
          actions: <Actions
              restore={{
                button: <Restore
                    id={item.id}
                    objectTypeId={item.version!.objectType.id}
                    setLoading={setLoading}
                />
              }}
              view={(index + 1) < versions.data.length ? {
                button: <View
                    obj1={item}
                    obj2={index === 0 ? versions.data[versions.data.length - 1] : versions.data[index - 1]}
                />
              } : undefined}
          />,
        }))}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: 100,
          handlePageSizeChange: handlePageSizeChange,
        }}
        columnsSettings={{
          columnOrder: columnCompanies,
          setColumnOrder: setColumnCompanies,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        columns={columns}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.VERSIONS}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: {
          row: { guid: string, objectTypeId: number },
          tableRow: TableRow,
          children: JSX.Element | null
        }) => (
            <MUITable.Row
                tableRow={tableRow}
                className={classes.tableRow}
                row={row}
                children={children}
                style={{'cursor': 'pointer'}}
            />
        )}
        filters={{
          id: null,
          author: null,
          created: null,
          type: null,
          actions: null
        }}
    />
  </Content>
}