import {AccountService} from "../services/account"
import {AccountActionsTypes} from "../interfaces/account";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {IPasswordInput} from "../interfaces/input/password";
import {Dispatch} from "react";
import {IAccountInput} from "../interfaces/input/account";

export const AccountActions = {
  account,
  accounts,
  password,
  create,
  update,
  delete: remove,
  filter,
}

function account() {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountService.account()
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function accounts(filter: object) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountService.accounts(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function password(data: IPasswordInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    return AccountService.password(data)
      .then(
        response => {
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Пароль успешно изменен',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
            }
          })
          reject(error)
        }
      )
  })
}

function create(data: IAccountInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Аккаунт добавлен успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function update(id: number, data: IAccountInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountService.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Аккаунт обновлен успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function remove(id: number) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountService.delete(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Аккаунт удален успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.response.data.error)
        }
      )
  })
}

function filter(params: object) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    return AccountService.filter(params)
      .then(
        response => {
          resolve(response)
        },
        error => {
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}