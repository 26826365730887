import React from "react"
import {Grid, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

type Props = {
  name: string,
  value?: string | null,
  action?: JSX.Element | boolean
}

const Title = styled(Typography)(() => ({
  color: "#6E7880",
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  paddingRight: '18px'
}))

const Value = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px'
}))

const Content = styled(Grid)(() => ({
  padding: '8px 0'
}))

export default function Param({name, value, action}: Props): JSX.Element {
  return (
    <React.Fragment>
        <Content container alignItems="center" direction="row">
          <Grid item xs={action ? 3 : 6}>
            <Title >{name}:</Title>
          </Grid>
          <Grid item xs={6}>
            <Value noWrap>{value ?? '—'} </Value>
          </Grid>
          {action ? (
            <Grid item xs={3}>
              <Grid container justifyContent="end" alignItems="center" direction="row">
                <Grid item>
                  {action}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Content>
    </React.Fragment>
  )
}
