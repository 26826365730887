import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IBuilding} from "../../../../Realty/interfaces/building";
import {BuildingActions} from "../../../../Statistic/actions/building";
import PieChart from "../../../../Statistic/components/PieChart";
import {Grid, GridProps} from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props extends GridProps {
  building: IBuilding
}

export default function Type(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {building} = props

  const [loading, setLoading] = useState(false)
  const [types, setTypes] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    const getRooms = async () => {
      return await dispatch(BuildingActions.rooms(building.id))
    }

    if (!loading) {
      getRooms().then(response => {
        setTypes(response.data.map((item: {count: number, type: {id: number, name: string, color: string | null}}) => item.type))
        setData(response.data.length ? response.data.map((item: {count: number, type: {id: number, name: string, color: string | null}}) => ({
          name: item.type.name,
          value: item.count
        })) : [])
        setLoading(true)
      })
    }
  }, [dispatch, building.id, loading])

  useEffect(() => {
    setLoading(false)
  }, [building])

  return data.length ? (
    <Grid item {...props}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Typography>Распределение по типу помещений</Typography>
        <PieChart data={data} types={types} total={'Итого'} />
      </Grid>
    </Grid>
  ) : null
}