import React, {useState} from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import {IRegistry} from "../../../interfaces/registry";
import Grid from "@mui/material/Grid";
import {Complete as CompleteComponent} from "../Complete";
import {useMediaQuery, useTheme} from "@mui/material";

type Props = {
  registry: IRegistry
  setRegistry: (registry: IRegistry) => void
}

export function Complete(props: Props): JSX.Element | null {
  const { registry, setRegistry } = props
  const [isSubmitting] = useState(false)
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isDuring = () => {
    return !mobile && (registry?.status?.key === 'during')
  }

  return isDuring() ? (
    <Access roles={['chairman']}>
      <Grid item>
        <Submit
          disabled={isSubmitting}
          onClick={handleOpen}
          variant="contained"
          type="button"
        >
          Завершить инвентаризацию
        </Submit>
        {open ? (
          <CompleteComponent
            registry={registry}
            open={open}
            handleClose={handleClose}
            onClick={(registry?: IRegistry) => {
              if (registry) {
                setRegistry(registry)
              }
            }}
          />
        ) : null}
      </Grid>
    </Access>
  ) : null
}
