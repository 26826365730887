import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../../Auth/components/Access";
import Position from "../../Position";
import {IPosition} from "../../../interfaces/position";
import {ICompany} from "../../../interfaces/company";

type Props = {
  company: ICompany,
  item: IPosition,
  name: string,
  actions: any,
  onClick?: (item: IPosition) => void
}

export function Edit(props: Props): JSX.Element {
  const {company, item, name, actions, onClick} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <React.Fragment>
        <IconButton
          onClick={handleOpen}
        >
          <EditIcon/>
        </IconButton>
        {open ? <Position open={open} company={company} item={item} name={name} actions={actions} handleClose={handleClose} onClick={onClick} /> : null}
      </React.Fragment>
    </Access>
  )
}
