import {NavigateFunction, useNavigate} from "react-router";
import {styled} from "@mui/material/styles";
import Profile from "./Header/Profile";
import {Navbar} from "./Navbar";
import {AppBar, Box, BoxProps, Grid, Toolbar, Typography,} from '@mui/material';

type Props = {
    path: string
}

type ImgProps = {
  alt: string;
  src: string;
  loading: string
}

const Img = (props: BoxProps & ImgProps): JSX.Element  => {
  const navigate: NavigateFunction = useNavigate();
  return (
    <Box
      component='img'
      sx={{padding: 1, height: '30px', cursor: 'pointer'}}
      onClick={() => navigate('/')}
      {...props}
    />
  )  
}

const StyledAppBar = styled(AppBar)(() => ({
  boxShadow: 'none',
  background: 'white',
  borderBottom: "1px solid #e0e0e1",
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

const StyledToolbar = styled(Toolbar)(() => ({
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

const Title = styled(Typography)(() => ({
  color: "#3668BF",
  padding: "4px 16px 0px 4px",
  fontSize: "0.9rem",
  fontWeight: "600"
}));

export default function Header({path}: Props): JSX.Element {
  return (
    <StyledAppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <StyledToolbar >
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start">
              <Img
                loading="lazy"
                src="/logo.png"
                alt={'АИС PropHub'}
              />
              <Title>PropHub</Title>
              <Navbar/>
            </Grid>
          </Grid>
          <Grid>
            <Profile path={path}/>
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledAppBar>
  );
}
