export interface IEquippedItem {
  value: boolean,
  name: string,
}

export const equippedOptions: IEquippedItem[] = [
  {
    value: true,
    name: "Да"
  },
  {
    value: false,
    name: "Нет"}
]