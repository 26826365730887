import React, {useState} from "react"
import {styled} from "@mui/material/styles";
import {Grid, IconButton, Input, InputBaseComponentProps, Typography} from "@mui/material";
import Param from "../Account/components/Profile/Param";
import Carousel from "react-material-ui-carousel";
import {AddCircle, Cancel, DeleteSweep, Download} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {IFile} from "../Realty/interfaces/file";
import {getAction} from "../App/helpers/action";
import Access from "../Auth/components/Access";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import TextField from "../App/components/Input/TextField";
import {Submit} from "../App/components/Buttons/Submit";
import {AlertActionsTypes} from "../App/interfaces/alert";
import {checkMaxSize} from "../App/helpers/fileStorage";


const Content = styled(Grid)(() => ({
  position: "relative",
  width: "100%",
  padding: "20px",
  height: 'calc(100vh - 172px)',
  minHeight: 'calc(100vh - 172px)',
  overflow: "overlay",
  overflowX: "hidden",
  background: 'white',
}))

const FileName = styled(TextField)(() => ({
  width: "250px",
  height: "36px",
  position: "relative",
  marginRight: "25px",
  marginBottom: "25px"
}))

const CarouselGrid = styled(Grid)(() => ({
  width: "300px",
  height: "300px",
}))


const Image = styled('img')(() => ({
  width: "300px",
  height: "300px",
  borderRadius: "1%"
}))

const ImageButtons = styled(Grid)(() => ({
  background: "#eaeaea",
  marginLeft: "5px",
  padding: "5px",
  borderRadius: "2px"
}))

export default function Information(props: {
  mainItems?: Array<{ name: string, value?: string | null }>,
  items: Array<{ name: string, value?: string | null }>,
  files: Array<IFile>,
  object: {
    type: string,
    id: number
  },
  updateFilesHandler: () => void
}): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {items, mainItems, files, object, updateFilesHandler} = props
  const [document, setDocument]: any = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const avatars = files?.filter(file => (file.type === 'avatar'))
  const documents = files?.filter(file => (file.type === 'document'))

  const avatarHandler = (event: InputBaseComponentProps) => {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList?.length) {
      const file = fileList[0];
      if (checkMaxSize(file, 15)) {
        dispatch({
          type: AlertActionsTypes.ERROR, payload: {
            message: 'Размер файла превышает 15 Мб.',
            type: 'error'
          }
        })
      } else {
        const formData = new FormData();
        formData.append('file', fileList[0]);
        formData.append('type', 'avatar');
        dispatch((getAction(object.type)).addFile(object.id, formData)).then(
          () => updateFilesHandler()
        )
      }
    }
  }

  const documentHandler = (event: InputBaseComponentProps) => {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList?.length) {
      const file = fileList[0];
      if (checkMaxSize(file, 250)) {
        dispatch({
          type: AlertActionsTypes.ERROR, payload: {
            message: 'Размер файла превышает 50 Мб.',
            type: 'error'
          }
        })
        clearDocument()
      } else {
        setDocument(file)
        formik.setFieldValue('file', file)
      }
    }
  }

  const documentDeleteHandler = (id: number) => {
    dispatch((getAction(object.type)).deleteFile(object.id, id)).then(
      () => updateFilesHandler()
    )
  }

  const avatarDeleteHandler = () => {
    if (avatars) {
      const fileId = avatars[carouselIndex].id
      dispatch((getAction(object.type)).deleteFile(object.id, fileId)).then(
        () => updateFilesHandler()
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      file: null
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Введите имя файла'),
    }),
    onSubmit: (values: FormikValues, {setSubmitting}) => {
      setSubmitting(true)
      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('name', values.name);
      formData.append('type', 'document');
      dispatch((getAction(object.type)).addFile(object.id, formData)).then(
        () => {
          setSubmitting(false)
          formik.setFieldValue('name', '')
          clearDocument()
          updateFilesHandler()
        },
        () => setSubmitting(false)
      )
    }
  });

  const clearDocument = () => {
    formik.setFieldValue('file', null)
    setDocument(null)
  }

  const inputProps = {
    ...{
      type: 'file',
      accept: ".jpg,.jpeg,.png,.mov,.gif,.bmp,.webp,.svg,.pdf,.doc,.docx,.xls,.xlsx,.numbers,.ppt,.pptx,.txt",
      onChange: avatarHandler
    }
  }

  const avatarProps = {
    ...inputProps,
    ...{
      accept: ".jpg, .jpeg, .png",
      onChange: avatarHandler
    }
  }

  const documentProps = {
    ...inputProps,
    ...{
      onChange: documentHandler
    }
  }

  return (
    <Content>
      <Grid container direction="row">
        <Grid item xs={5}>
          {mainItems?.map((mainItem, index) => (
            <Param
              key={index}
              {...mainItem}
            />
          ))}
          <Grid container direction="row" justifyContent={"flex-start"} alignItems={"flex-start"}>
            <CarouselGrid>
              {avatars?.length
                ? <Carousel
                  onChange={(now: number | undefined) => setCarouselIndex(now ?? 0)}
                >
                  {avatars?.map((item, index) => <Image
                      key={index}
                      src={`${process.env.REACT_APP_API_URL}/${object.type}/${object.id}/file/${item.id}`}
                      alt={item.name}
                    />
                  )}
                </Carousel>
                : <Carousel indicators={false}>
                  <Image
                    src={`/avatar_not_found.png`}
                    alt={'Нет изображений'}
                  />
                </Carousel>
              }
            </CarouselGrid>
            <Access roles={['admin', 'dispatcher']}>
              <Grid item>
                <ImageButtons container direction="column" justifyContent={"flex-start"} alignItems={"center"}>
                  <IconButton component="label">
                    <AddCircle color={"primary"}/>
                    <Input
                      key={avatars?.length}
                      style={{display: "none"}}
                      inputProps={avatarProps}
                    />
                  </IconButton>
                  <IconButton onClick={avatarDeleteHandler} disabled={!avatars?.length}>
                    <DeleteSweep color={"primary"}/>
                  </IconButton>
                </ImageButtons>
              </Grid>
            </Access>
          </Grid>
          <Grid item style={{marginTop: "50px"}}>
            <Typography style={{color: "#6E7880"}}>Файлы</Typography>
            <Access roles={['admin']}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <FileName
                    label="Название"
                    required
                    name="name"
                    type="text"
                    formik={formik}
                  />
                  {!formik?.values?.file ? (
                    <Submit
                      component="label"
                      color="primary"
                      variant="contained"
                    >
                      Обзор
                      <Input
                        style={{display: "none"}}
                        inputProps={documentProps}
                      />
                    </Submit>
                  ) : null}
                  {formik?.values?.file ? (
                    <React.Fragment>
                      <Submit
                        disabled={formik.isSubmitting}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Загрузить
                      </Submit>
                      <Typography style={{marginLeft: "10px"}}>{document.name}</Typography>
                      <IconButton
                        onClick={clearDocument}
                      >
                        <Cancel/>
                      </IconButton>
                    </React.Fragment>
                  ): null}
                </Grid>
              </form>
            </Access>
            <Grid item style={{marginTop: "20px"}}>
              {documents?.map((document, index) => (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" key={index}>
                  <Typography style={{marginTop: "-5px"}}>{document.name}</Typography>
                  <IconButton
                    color="error"
                    onClick={() => window.open(`${process.env.REACT_APP_API_URL}/${object.type}/${object.id}/file/${document.id}`)}
                  >
                    <Download color={"primary"}/>
                  </IconButton>
                  <Access roles={['admin']}>
                    <IconButton
                      color="error"
                      onClick={() => documentDeleteHandler(document.id)}
                    >
                      <DeleteSweep/>
                    </IconButton>
                  </Access>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          {items?.map((item, index) => (
            <Param
              key={index}
              {...item}
            />
          ))}
        </Grid>
      </Grid>
    </Content>
  );
}
