function onCurrentPageChange(newPage: number, setPage: any) {
    setPage(newPage)
}

function onSelectionChange(numbers: Array<number>, data: Array<number>, selectionData: Array<number>, setSelectionData: any, setSelection: any) {
    data.forEach((id: any, index: number) => {
        if (numbers.indexOf(index) >= 0) {
            if (!selectionData.includes(id)) {
                selectionData.push(id)
            }
        } else {
            const key = selectionData.findIndex(el => el === id)
            if (key >= 0) {
                delete selectionData[key]
            }
        }
    })

    setSelectionData(selectionData.filter((id: number) => id))
    setSelection(numbers)
}

export {onCurrentPageChange, onSelectionChange}
