import { QrCode as QRCodeIcon } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import { Menu } from "@mui/material";
import { IconButton } from "@mui/material";
import React from "react"
import { useDispatch } from "react-redux";
import Access from "../../../../Auth/components/Access";
import { EquipmentActions } from "../../../actions/equipment";
import { IEquipment } from "../../../interfaces/equipment";

type Props = {
  equipment: IEquipment
}
export default function Sticker(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {equipment} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleDownload = (): void => {
    dispatch(EquipmentActions.stickers(false, [equipment.id])).then((res: any) => {
      const download = document.createElement("a");
      download.href = URL.createObjectURL(res);
      download.download = 'stickers.pdf';
      download.click();

    });
  }

  const handlePrint = (): void => {
    dispatch(EquipmentActions.stickers(false, [equipment.id])).then((res: any) => {
      const file = new File([res], `stickers.pdf`, {type: "application/pdf"});
      const fileURL = URL.createObjectURL(file);
      const printWindow = window.open(fileURL, "_blank");
      if (printWindow) {
        printWindow.onload = function () {
          printWindow.print();
        };
      }
    });
  }


  const handleView = (): void => {
    dispatch(EquipmentActions.stickers(false, [equipment.id])).then((res: any) => {
      const file = new File([res], `stickers.pdf`, {type: "application/pdf"});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    });
  }

  return equipment ? (
      <Access roles={['admin', 'working_group', 'chairman']}>
        <React.Fragment>
          <IconButton onClick={handleClick}>
            <QRCodeIcon/>
          </IconButton>
          <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
          >
            <MenuItem onClick={handleView}>Просмотр</MenuItem>
            <MenuItem onClick={handlePrint}>Печать</MenuItem>
            <MenuItem onClick={handleDownload}>Скачать</MenuItem>
          </Menu>
        </React.Fragment>
      </Access>
  ) : null
}
