import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {BuildingActions} from "../../../../Statistic/actions/building";
import {IBuilding} from "../../../../Realty/interfaces/building";
import {Box, Grid, GridProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const TooltipComponent = styled('div')(() => ({
  backgroundColor: 'hsla(0,0%,100%,.8)',
  border: '1px solid #f5f5f5',
  margin: '0',
  padding: '6px 6px 0 6px',
}))

interface Props extends GridProps {
  building: IBuilding
}

export default function WorkloadType(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {building} = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<any>>([])

  useEffect(() => {
    const getRooms = async () => {
      return await dispatch(BuildingActions.workload(building.id))
    }

    if (!loading) {
      getRooms().then(response => {
        setData(response.data.length ? response.data.sort((first: {count: number}, second: {count: number}) => ((first.count < second.count) ? -1 : ((first.count > second.count) ? 1 : 0))).map((item: {count: number, occupied: number, type: {id: number, name: string}}) => ({
          type: item.type.name,
          'Занято': item.occupied,
          'Свободно': item.count - item.occupied
        })) : [])
        setLoading(true)
      })
    }
  }, [dispatch, building.id, loading])

  useEffect(() => {
    setLoading(false)
  }, [building])

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipComponent>
          <Typography>{label}</Typography>
          {payload.map((item: any, index: string) =>
            <Typography key={index} variant="caption" color="text.secondary" component="div" gutterBottom>
              {item.name}: <Box sx={{ color: 'primary.main' }} component="span">{item.value}</Box>
            </Typography>
          )}
        </TooltipComponent>
      );
    }

    return null;
  };

  return data.length ? (
    <Grid item {...props}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Typography>Количество свободных мест здания по типам помещений</Typography>
        <ResponsiveContainer width='100%' aspect={1.0}>
          <BarChart
            data={data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="type" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              align="center"
              iconType='square'
              content={(props) => {
                const { payload } = props;

                return (
                  <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    {
                      payload?.map((entry, index) => (
                        <Grid item key={index}>
                          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
                            <Grid item>
                              <svg width="14" height="14" viewBox="0 0 32 32">
                                <path fill={entry.color} cx="16" cy="16" transform="translate(16, 16)" d="M-16,-16h32v32h-32Z"></path>
                              </svg>
                            </Grid>
                            <Grid item>
                              <Typography variant="caption" style={{color: 'rgba(0, 0, 0, 0.87)'}}>{entry.value}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    }
                  </Grid>
                );
              }}
              formatter={(value: string) => {
                return <Typography variant="caption" style={{color: 'rgba(0, 0, 0, 0.87)'}}>{value}</Typography>;
              }}
            />
            <Bar dataKey="Занято" stackId="all" fill="#ed1c24" label={{ position: 'top' }} />
            <Bar dataKey="Свободно" stackId="all" fill="#22b14c" label={{ position: 'top' }} />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  ) : null
}