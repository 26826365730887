import React, { useEffect } from "react"
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { Close } from '@mui/icons-material';
import { DialogContent } from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import { Submit } from "../../App/components/Buttons/Submit";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { IRoomInput } from "../interfaces/input/room";
import { RoomActions } from "../actions/room";
import { IRoom } from "../interfaces/room";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import { CommonRentalTypeActions } from "../../Dictionaries/actions/common.rental.type";
import { IItem } from "../../Dictionaries/interfaces/item";
import { CompanySubdivisionActions } from "../../Company/actions/company.subdivision";
import { RoomTypeActions } from "../../Dictionaries/actions/room.type";
import { IFloor } from "../interfaces/floor";
import { ICompany } from "../../Company/interfaces/company";
import { ILand } from "../interfaces/land";
import { IBuilding } from "../interfaces/building";
import {
  FormikValues,
  useFormik
} from "formik";
import * as Yup from "yup"
import { ISubdivision } from "../../Company/interfaces/subdivision";
import { CloseDialog } from "../../App/components/Dialog/CloseDialog";
import { transformData } from "../../App/helpers/transformData";
import IResponseError from "../../App/interfaces/responseError";
import {
  DatePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import {CompanyEmployeeActions} from "../../Company/actions/company.employee";
import {IEmployee} from "../../Company/interfaces/employee";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

function ActionList(props: PickersActionBarProps) {
  const {onClear} = props;

  const actions = [
    {text: 'Очистить', method: onClear},
  ];

  return (
    <List>
      {actions.map(({text, method}) => (
        <ListItem key={text} disablePadding>
          <ListItemButton onClick={method}>
            <ListItemText primary={text}/>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default function Room(props: {
  floor?: IFloor,
  room?: IRoom,
  open: boolean,
  handleClose: () => void,
  onClick?: (room: IRoom) => void
}): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {floor, onClick} = props
  const [room, setRoom] = React.useState<IRoom | null | undefined>(props.room);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [savedRoom, setSavedRoom]: any = React.useState(null);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    if (onClick && savedRoom) {
      onClick(savedRoom)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      name: room?.name,
      building: room?.floor?.building ?? floor?.building,
      land: room?.floor?.building?.land ?? floor?.building?.land,
      company: room?.floor?.building?.land?.company ?? floor?.building?.land?.company,
      floor: room?.floor ?? floor,
      type: room?.type,
      height: room?.height,
      square: room?.square,
      subdivision: room?.subdivision,
      safety: room?.safety,
      rentalType: room?.rentalType,
      rentalInformation: room?.rentalInformation,
      rentalPrice1squareMeter: room?.rentalPrice1squareMeter,
      totalDeliveryTimeDays: room?.totalDeliveryTimeDays,
      numberWorkplaces: room?.numberWorkplaces,
      dateOverhaul: room?.dateOverhaul,
      dateRedecorating: room?.dateRedecorating,
    },
    validationSchema: Yup.object().shape({
      land: Yup.object(),
      floor: Yup.object(),
      company: Yup.object(),
      building: Yup.object(),
      name: Yup.string().required('Поле обязательно к заполнению'),
      type: Yup.object().required('Поле обязательно к заполнению'),
      subdivision: Yup.object().required('Поле обязательно к заполнению'),
      safety: Yup.object().required('Поле обязательно к заполнению'),
      rentalPrice1squareMeter: Yup.number().nullable().min(0, 'Значение не может быть меньше 0'),
      totalDeliveryTimeDays: Yup.number().nullable().min(0, 'Значение не может быть меньше 0'),
      height: Yup.number().required('Поле обязательно к заполнению').min(0, 'Значение не может быть меньше 0'),
      square: Yup.number().required('Поле обязательно к заполнению').min(0, 'Значение не может быть меньше 0'),
      dateOverhaul: Yup.string().nullable(),
      dateRedecorating: Yup.string().nullable(),
      numberWorkplaces: Yup.number().when('type', (type, schema) => type.shift()?.requiredWorkplaces ? schema.required('Поле обязательно к заполнению') : schema.nullable()).min(0, 'Значение не может быть меньше 0')
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = transformData(values)

      dispatch(room ? RoomActions.update(room.id, (data as IRoomInput)) : RoomActions.create(data as IRoomInput)).then(
        (room: IRoom) => {
          setSubmitting(true)
          setRoom(room)
          setSavedRoom(room)
          resetForm({
            values: {
              name: room?.name,
              building: room?.floor?.building ?? floor?.building,
              land: room?.floor?.building?.land ?? floor?.building?.land,
              company: room?.floor?.building?.land?.company ?? floor?.building?.land?.company,
              floor: room?.floor ?? floor,
              type: room?.type,
              height: room?.height,
              square: room?.square,
              subdivision: room?.subdivision,
              safety: room?.safety,
              rentalType: room?.rentalType,
              rentalInformation: room?.rentalInformation,
              rentalPrice1squareMeter: room?.rentalPrice1squareMeter,
              totalDeliveryTimeDays: room?.totalDeliveryTimeDays,
              numberWorkplaces: room?.numberWorkplaces,
              dateOverhaul: room?.dateOverhaul,
              dateRedecorating: room?.dateRedecorating,
            }
          })
        },
        (error: IResponseError) => {
          setErrors(error.errors)
          setSubmitting(false)
        },
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleCloseDialog}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>{(savedRoom?.id || room?.id) ? 'Редактировать' : 'Добавить'} помещение</Title>
                <IconButton
                  onClick={handleCloseDialog}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Item item xs={6}>
                  <TextField
                    required
                    name="name"
                    label="Наименование"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    formik={formik}
                    label="Компания"
                    name={"company"}
                    value={formik.values.company}
                    setValue={(name: string, value: ILand) => formik.setFieldValue(name, value)}
                    getOptionLabel={(option: ICompany) => option?.name ?? option}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    name={"land"}
                    formik={formik}
                    label="Земельный участок"
                    value={formik.values.land}
                    subscribeFields={[formik.values.company]}
                    setValue={(name: string, value: ILand) => formik.setFieldValue(name, value)}
                    getOptionLabel={(option: ILand) => option?.name ?? option?.cadastralNumber ?? option}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    label="Здание"
                    formik={formik}
                    name={"building"}
                    value={formik.values.building}
                    subscribeFields={[formik.values.company, formik.values.land]}
                    setValue={(name: string, value: ILand) => formik.setFieldValue(name, value)}
                    getOptionLabel={(option: IBuilding) => option?.name ?? option}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    disabled
                    required
                    label="Этаж"
                    name={"floor"}
                    formik={formik}
                    value={formik.values.floor}
                    getOptionLabel={(option: IFloor) => option?.name ?? option}
                    subscribeFields={[formik.values.company, formik.values.land, formik.values.building]}
                    setValue={(name: string, value: IFloor) => formik.setFieldValue(name, value)}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"type"}
                    label="Тип помещения"
                    required
                    setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => RoomTypeActions.items({
                      take: 20,
                      ...(room ? {exclude: room.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.type}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Высота, м."
                    required
                    name="height"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Площадь кв.м."
                    required
                    name="square"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"subdivision"}
                    required
                    disabled={!formik.values.company}
                    label="Подразделения"
                    setValue={(name: string, value: ISubdivision) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanySubdivisionActions.items(formik.values.company.id, {
                      take: 20,
                      ...(room ? {exclude: room.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.subdivision}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"safety"}
                    required
                    disabled={!formik.values.company}
                    label="Ответственный (Противопожарная безопасность)"
                    setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                      take: 20,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.safety}
                    getOptionLabel={(option: IEmployee) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"rentalType"}
                    label="Тип аренды"
                    setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CommonRentalTypeActions.items({
                      take: 20,
                      ...(room ? {exclude: room.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Информация о договорах аренды"
                    name="rentalInformation"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Стоимость аренды 1 кв.м"
                    name="rentalPrice1squareMeter"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Общий срок сдачи, дней"
                    name="totalDeliveryTimeDays"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Дата капитального ремонта"
                      inputFormat="MM.yyyy"
                      views={['month', 'year']}
                      components={{
                        ActionBar: ActionList,
                      }}
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      renderInput={(params) => <TextField
                        name="dateOverhaul"
                        formik={formik}
                        label={params.label}
                        type={params.type}
                        InputProps={{
                          disableUnderline: !(formik?.touched?.["dateOverhaul"] && formik?.errors?.["dateOverhaul"]),
                          endAdornment: params.InputProps?.endAdornment,
                          onChange: () => {
                          }
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: null,
                          onChange: () => {
                          }
                        }}
                        inputRef={params.inputRef}
                      />}
                      onChange={(e: any) => {
                        formik.setFieldValue('dateOverhaul', e ? new Date(e.getTime() - e.getTimezoneOffset() * 60000) : null, true)
                      }}
                      value={formik.values.dateOverhaul ?? null}
                    />
                  </LocalizationProvider>
                </Item>
                <Item item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Дата косметического ремонта"
                      inputFormat="MM.yyyy"
                      views={['month', 'year']}
                      components={{
                        ActionBar: ActionList,
                      }}
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      renderInput={(params) => <TextField
                        name="dateRedecorating"
                        formik={formik}
                        label={params.label}
                        type={params.type}
                        InputProps={{
                          disableUnderline: !(formik?.touched?.["dateRedecorating"] && formik?.errors?.["dateRedecorating"]),
                          endAdornment: params.InputProps?.endAdornment,
                          onChange: () => {
                          }
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: null,
                          onChange: () => {
                          }
                        }}
                        inputRef={params.inputRef}
                      />}
                      onChange={(e: any) => {
                        formik.setFieldValue('dateRedecorating', e ? new Date(e.getTime() - e.getTimezoneOffset() * 60000) : null, true)
                      }}
                      value={formik.values.dateRedecorating ?? null}
                    />
                  </LocalizationProvider>
                </Item>
                <Item item xs={6}>
                  <TextField
                    required={formik.values.type?.requiredWorkplaces}
                    label="Количество рабочих мест всего"
                    name="numberWorkplaces"
                    type="number"
                    formik={formik}
                  />
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" spacing={4} alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
      <CloseDialog
        open={openDialog}
        onClose={onCloseDialog}
        onCloseWithoutSaving={onCloseWithoutSaving}
      />
    </React.Fragment>
  ) : null;
}