import React from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../App/components/Svg/AddIcon";
import Access from "../../../../Auth/components/Access";
import Position from "../../Position";
import {IPosition} from "../../../interfaces/position";
import {ICompany} from "../../../interfaces/company";
import Grid from "@mui/material/Grid";

type Props = {
  company: ICompany,
  name: string,
  actions: any,
  onClick?: (item: IPosition) => void
}

export function Create(props: Props): JSX.Element {
  const { company, name, actions, onClick } = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <Grid item>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Добавить
        </Submit>
        {open ? <Position open={open} company={company} name={name} actions={actions} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  )
}
