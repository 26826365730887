import React from "react";
import {IconButton} from "@mui/material";
import {Edit as EditIcon} from '@mui/icons-material';

type Props = {
  disabled?: boolean,
  onClick?: () => void,
}

export default function Edit(props: Props): JSX.Element {
  const {disabled, onClick} = props

  return <IconButton
    disabled={!!disabled}
    onClick={onClick}
  >
    <EditIcon/>
  </IconButton>
}
