import {useState} from "react";
import {Link} from 'react-router-dom';
import {useParams} from "react-router";
import {ISideMenuItem, menu} from "../../helpers/menu";
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {
  Divider,
  Drawer as MuiDrawer,
  Grid,
  IconButton as MUIIconButton,
  Typography,
  useMediaQuery, useTheme
} from "@mui/material";
import {cx} from "@emotion/css";

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)})`,
  [theme.breakpoints.down('sm')]: {
    width: `calc(${theme.spacing(6)})`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      display: 'flex',
      justifyContent: 'space-between',

    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const IconButton = styled(MUIIconButton)(() => ({
  gap: "20px",
  display: "flex",
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

const PREFIX = 'SideMenu'

const classes = {
  link: `${PREFIX}-link`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  menu: `${PREFIX}-sideMenu`,
  divider: `${PREFIX}-divider`,
  link_grid: `${PREFIX}-link-grid`,
  item_name: `${PREFIX}-item-name`,
  icon_active: `${PREFIX}-icon_active`,
  divider_active: `${PREFIX}-divider-active`,
  link_grid_active: `${PREFIX}-link-grid-active`,
  item_name_active: `${PREFIX}-item-name-active`,
}

const Root = styled('div')(({theme}) => ({
  paddingTop: '56px',
  [`& .${classes.menu}`]: {
    width: "240px",
    height: "100%",
    background: "white",
  },
  [`& .${classes.link}`]: {
    color: "black",
    minHeight: "30px",
    textDecoration: "none",
  },
  [`& .${classes.title}`]: {
    color: "#5f6367",
    fontSize: "18px",
    fontWeight: "600",
    padding: "40px 20px 24px 20px",
  },
  [`& .${classes.icon}`]: {
    marginRight: "10px",
  },
  [`& .${classes.icon_active}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.divider}`]: {
    width: "4px",
    height: "35px",
    border: "none",
    marginRight: "10px",
    background: "transparent",
  },
  [`& .${classes.divider_active}`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    background: theme.palette.primary.main,
  },
  [`& .${classes.link_grid}`]: {},
  [`& .${classes.link_grid_active}`]: {
    background: '#F1F9FF',
  },
  [`& .${classes.item_name}`]: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    width: "192px"
  },
  [`& .${classes.item_name_active}`]: {
    color: theme.palette.primary.main,
  },
}))

type Props = {
  path: string
}

export default function Menu({path}: Props): JSX.Element | null {
  const params = useParams();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(!mobile);

  const section = `/${params.section}`
  path = path.length > 1 ? '/' + path.split("/")?.[1] : path

  const isOpen = () => setOpen(prev => !prev)
  const isShow = (Object.keys(menu).find(i => i === path)) ? menu[path].items.find((item: ISideMenuItem) => ((section === item.path) || !!item.alias?.includes(section))) : false

  const renderIcon = (icon: OverridableComponent<SvgIconTypeMap> | undefined, isActive: boolean): JSX.Element | null => {
    if (icon) {
      const Component = icon
      return <Component className={isActive ? cx(classes.icon, classes.icon_active) : classes.icon}/>
    }
    return null
  }

  const renderLink = (item: ISideMenuItem, index: number, isActive: boolean, child: boolean): JSX.Element => {  
    return (
      <Grid key={index} style={{width: "240px"}}>
        <Link
          className={classes.link}
          to={`${path}${item.path}`}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={(isActive && (!item?.items || !open) ) ? cx(classes.link_grid, !open && child ? null : classes.link_grid_active ) : classes.link_grid}
          >
            {!open && child ? null :  
              <Divider
                className={(isActive && (!item?.items || !open)) ? cx(classes.divider, classes.divider_active) : classes.divider}
              />
            }
            {renderIcon(item?.icon, isActive)}
            <Typography
              style={{marginLeft: child ? '25px' : '0px', fontSize: child ? "14px" : "16px"}}
              className={isActive ? cx(classes.item_name, classes.item_name_active) : classes.item_name}
              noWrap
            >
              {item.name}
            </Typography>
          </Grid>
        </Link>
        {(isActive && item?.items) ? item.items.map((children: ISideMenuItem, index: number) => {
          const isActive = (`${item.path}/${params?.subsection}` === children.path) || (`/${params?.section}/${params?.subsection}` === children.path)
          return renderLink(children, index, isActive, true)
        }) : null}
      </Grid>
    )
  }

  return isShow ? (      
    <Drawer variant="permanent" open={open}>
      <Root>
        <Grid container direction="column" className={classes.menu}>
          {open ? <Grid className={classes.title}>{menu[path].title}</Grid> : <Grid style={{ padding: "18px"}}></Grid>}
          {menu[path].items.map((item: ISideMenuItem, index: number) => {
            const isActive = ((section === item.path) || !!item.alias?.includes(section))
            return renderLink(item, index, isActive, false)
          })}
        </Grid>
      </Root>
      {!mobile ? (
        <IconButton onClick={isOpen} style={{borderTop: "1px solid #e0e0e0", borderRadius: '0'}}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      ) : null}
    </Drawer>
  ): null   
}
