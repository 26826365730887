import {HttpService} from "../../App/services/http";
import {ILoginState} from "../interfaces/login";

export const AuthServices = {
    login
}
function login(data: ILoginState) {
    return HttpService.post("/login", data)
        .then(
            response => {
                return response
            })
}