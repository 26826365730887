import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {IItem} from "../interfaces/item";
import {Submit} from "../../App/components/Buttons/Submit";
import TextField from "../../App/components/Input/TextField";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import {CompanySubdivisionActions} from "../actions/company.subdivision";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {ISubdivisionInput} from "../interfaces/input/subdivision";
import {CompanyEmployeeActions} from "../actions/company.employee";
import {ICompany} from "../interfaces/company";
import {ISubdivision} from "../interfaces/subdivision";
import {IEmployee} from "../interfaces/employee";

const Column = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

type Props = {
  company: ICompany,
  item?: ISubdivision,
  actions: any,
  name: string,
  open: boolean,
  handleClose: () => void,
  onClick?: (item: ISubdivision) => void
}

export default function Subdivision(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {company, item, actions, name, onClick} = props
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      name: item?.name,
      subdivision: item?.subdivision,
      manager: item?.manager
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues) => {
      dispatch(item ? actions.update(company.id, item.id, (values as ISubdivisionInput)) : actions.create(company.id, values as ISubdivisionInput)).then(
        (item: IItem) => {
          if (onClick) {
            onClick(item)
          }
          handleClose()
        }
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={10}>
                  <Title noWrap>{item?.id ? 'Редактировать' : 'Добавить'} {name}</Title>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClose}
                  >
                    <Close/>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Column>
                  <TextField
                    required
                    name="name"
                    label="Наименование"
                    type="text"
                    formik={formik}
                  />
                </Column>
                <Column>
                  <Autocomplete
                    name={"subdivision"}
                    label="Подразделение"
                    setValue={(name: string, value: ISubdivision) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanySubdivisionActions.items(company.id, {
                      take: 20,
                      ...(item ? {exclude: item.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    getOptionLabel={(option: ISubdivision) => option?.name ?? option}
                    value={formik.values.subdivision}
                    formik={formik}
                  />
                </Column>
                <Column>
                  <Autocomplete
                      name={"manager"}
                      label="Руководитель"
                      setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                      getValues={(search: string) => CompanyEmployeeActions.items(company.id, {
                        take: 20,
                        ...(search ? {search: search} : {})
                      })}
                      getOptionLabel={(option: IEmployee) => option?.name ?? option}
                      value={formik.values.manager}
                      formik={formik}
                  />
                </Column>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Сохранить
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null;
}