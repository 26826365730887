import React from "react"
import {Grid} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';
import Header from '../App/components/Header';
import Menu from "./components/Menu/Menu";

export function Objects(): JSX.Element {
    const location = useLocation();

    return (
        <React.Fragment>
          <Header path={location.pathname}/>
          <Grid style={{flex: "1 1 auto", display: "flex"}}>
            <Menu/>
            <Outlet/>
          </Grid>
        </React.Fragment>
    )
}