import React, {useEffect, useState} from "react"
import {Autocomplete as MUIAutocomplete} from "@mui/material";
import TextField from "./TextField";
import {Dispatch} from "../../reducers/store";
import {useDebouncedCallback} from "use-debounce";
import {useDispatch} from "react-redux";
import {equippedOptions} from "../../../Dictionaries/interfaces/Common/input/equippedItem";

type Props = {
  name: string,
  label: string,
  condition?: boolean,
  options?: any[],
  setValue: any,
  getValues?: (search: string) => (Dispatch: Dispatch) => Promise<unknown>,
  value?: any,
  multiple?: boolean,
  getOptionLabel: any,
  openText?: string,
  isOptionEqualToValue?: (option: any, value: any) => boolean
  required?: boolean,
  disabled?: boolean,
  formik?: any,
  subscribeFields?: Array<string> | null,
}

export function Autocomplete(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {
    name,
    label,
    condition = false,
    multiple = false,
    value,
    setValue,
    getValues,
    getOptionLabel,
    isOptionEqualToValue,
    disabled,
    formik,
    subscribeFields
  } = props
  const [options, setOptions] = useState(props.options ?? [])
  const [input, setInput]: any = useState('');
  const [focus, setFocus]: any = useState(false);
  const [initialize, setInitialize]: any = useState(false);
  const [localSubscribeFields, setLocalSubscribeFields]: any = useState('');

  const debounced = useDebouncedCallback(
      (value) => {
        if (focus) {
          setInput(value);
        }
      },
      900
  );

  const loadData = () => {
    const getData = async () => {
      if (getValues) {
        return await dispatch(getValues(input))
      } else if (!props.options) {
        return new Promise((resolve) => resolve({data: equippedOptions}))
      }
    }

    getData().then(response => {
      setInitialize(true)
      setOptions(response.data)
    })
  }

  useEffect(() => {
    if (focus && (condition || !!input?.length || !initialize || !input?.length)) {
      loadData()
    }
    // eslint-disable-next-line
  }, [condition, input, focus, initialize]);

  useEffect(() => {
    if (!focus) {
      debounced.cancel()
      if (input.length) {
        setInitialize(false)
        setInput('')
      }
    }
    // eslint-disable-next-line
  }, [focus]);

  const getFormikValue = (values: any, name: string) => {
    const path =  name.split('.');

    path.forEach((name: string, index) => {
      if (values.hasOwnProperty(name)) {
        if ((path.length - 1) === index) {
          return  values[name]
        } else {
          values = values[name]
        }
      }
    })

    return null
  }

  useEffect(() => {
    const validFields = JSON.stringify(subscribeFields?.filter((item: any) => item))
    if (initialize && (validFields?.length !== localSubscribeFields?.length)) {
      setLocalSubscribeFields(validFields)
      loadData()
    }
    // eslint-disable-next-line
  }, [subscribeFields]);

  return <MUIAutocomplete
    options={options}
    getOptionLabel={getOptionLabel}
    filterSelectedOptions
    noOptionsText={"Нет данных"}
    onChange={(e, value: any | null) => {
      setValue(name, value)
    }}
    disabled={disabled}
    value={value ?? (formik ? getFormikValue(formik.values, name) : value)}
    multiple={multiple}
    isOptionEqualToValue={isOptionEqualToValue}
    openText={'Показать'}
    clearText={'Убрать'}
    renderInput={params => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          disableUnderline: !(formik?.touched?.[name] && formik?.errors?.[name]),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            debounced(e.target.value)
          },
          onFocus: () => {
            setFocus(true)
            formik?.setFieldTouched(name, true)
          },
          onBlur: () => {
            setFocus(false)
            if (value ?? (formik ? getFormikValue(formik.values, name) : value)) {
              formik?.setFieldTouched(name, false)
            } else {
              formik?.setFieldTouched(name, true)
            }
          }
        }}
        label={label}
        name={name}
        required={props.required}
        fullWidth
        formik={formik}
      />
    )}
  />
}
