import React from "react"
import {Grid} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';
import Menu from '../components/Layout/Menu';
import Header from './Header';

export default function Layout(): JSX.Element {
    const location = useLocation();
    return (
        <React.Fragment>
            <Header path={location.pathname}/>
            <Grid style={{flex: "1 1 auto", display: "flex"}}>
                <Menu path={location.pathname}/>
                <Outlet/>
            </Grid>
        </React.Fragment>
    )
}