import {useEffect} from 'react';
import {NavigateFunction, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {AuthActions} from "./actions/auth";

export default function Logout(): JSX.Element | null {
    const dispatch: any = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        const logout = async () => {
            return await dispatch(AuthActions.logout())
        }

        logout().then(() => navigate('/login'))
    });

    return null
}