import React, {
  useEffect,
  useState
} from "react";
import { styled } from "@mui/material/styles";
import { Table } from "../../../App/components/Table";
import { TableHeader } from "../../../App/components/Table/TableHeader";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Dispatch,
  IRootState
} from "../../../App/reducers/store";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import {
  Grid,
  Typography
} from "@mui/material";
import { FiltersActionsTypes } from "../../../App/interfaces/filter";
import Actions from "../../../App/components/Table/Actions";
import { Edit } from "../../../Realty/components/Room/People/Buttons/Edit";
import Delete from "../../../Realty/components/Room/People/Buttons/Delete";
import { IRoom } from "../../../Realty/interfaces/room";
import { TableRow } from "@devexpress/dx-react-grid";
import { PeopleData } from "../../types/peopleData";
import {ICompany} from "../../../Company/interfaces/company";
import {IPosition} from "../../../Company/interfaces/position";
import {ISubdivision} from "../../../Company/interfaces/subdivision";
import {IEmployee} from "../../../Company/interfaces/employee";
import {CompanyEmployeeActions} from "../../../Company/actions/company.employee";

const PREFIX = 'Equipments'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const NumberWorkplaces = styled(Typography)(() => ({
  marginLeft: "20px",
  color: "rgba(0, 0, 0, 0.6)"
}))

const Content = styled(Grid)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 200px)',
    minHeight: 'calc(100vh - 200px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

type Props = {
  company: ICompany
  room: IRoom
  employee?: IEmployee | null,
  setRoom: (room: IRoom) => void,
  people: PeopleData,
  setPeople: (data: unknown) => void,
  loadPeople: boolean,
  setLoadPeople: (value: boolean) => void
}

export default function People(props: Props): JSX.Element | null {
  const {company, room, setRoom, loadPeople: loading, setLoadPeople: setLoading, people, setPeople} = props

  const dispatch: Dispatch = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.roomPeople)
  const [page, setPage] = useState(1)
  const [, setTableRef] = useState(null)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'full_name', title: 'ФИО'},
    {name: 'position', title: 'Должность'},
    {name: 'subdivision', title: 'Подразделение'},
    {name: 'phones', title: 'Внутренние номера телефонов'},
    {name: 'actions', title: ' '},
  ])

  const [columnEquipments, setColumnEquipments] = useState([
    'full_name',
    'position',
    'subdivision',
    'phones',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'full_name', width: 200},
    {columnName: 'position', width: 200},
    {columnName: 'subdivision', width: 200},
    {columnName: 'phones', width: 300},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(CompanyEmployeeActions.items(company.id, {
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...{meta: true},
        rooms: room.id.toString()
      })).then((people: unknown) => {
        setPeople(people as PeopleData)
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (props.employee && people?.data) {
      setPeople({
        data: [
          props.employee,
          ...((people.data.length === filter.take) ? people.data.slice(0, filter.take) : people.data)
        ],
        meta: {
          total: people.meta.total + 1
        },
      })
    }
    // eslint-disable-next-line
  }, [props.employee])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, room])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.ROOM_PEOPLE,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <NumberWorkplaces>Количество рабочих мест: {room.numberWorkplaces}</NumberWorkplaces>
      <Table
        meta={people.meta}
        name={'equipments'}
        rows={people.data.map((item: IEmployee) => ({
          full_name: item.name,
          position: item.subdivisions?.filter((e: {position: null | IPosition}) => e.position).map((e: {position: null | IPosition}) => e.position?.name).join(', '),
          subdivision: item.subdivisions?.map((e: {subdivision: null | ISubdivision}) => e.subdivision?.name).join(', '),
          phones: item.phones?.join(', '),
          actions: <Actions
            edit={{
              button: <Edit
                companyId={company.id}
                roomId={room.id}
                employee={item}
                onClick={(employee: IEmployee) => {
                  setPeople({
                    ...people,
                    ...{data: people.data.map((el: IEmployee) => (el.id === employee.id) ? employee : el)}
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                roomId={room.id}
                id={item.id}
                onClick={() => {
                  setRoom({
                    ...room,
                    ...{
                      _count: {
                        people: room._count.people - 1
                      }
                    }
                  })
                  setPeople({
                    data: people.data.filter((el: IEmployee) => el.id !== item.id),
                    meta: {
                      total: people.meta.total - 1
                    },
                  })
                }}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        setTableRef={setTableRef}
        columnsSettings={{
          columnOrder: columnEquipments,
          setColumnOrder: setColumnEquipments,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.ROOM_PEOPLE}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: {
          row: object,
          tableRow: TableRow,
          children: JSX.Element | null
        }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          status: {
            name: 'status',
            type: 'values',
          },
          actions: null
        }}
      />
    </Content>
  ) : null
}