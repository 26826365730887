import React from 'react';
import {Grid, Typography} from "@mui/material";
import {styled} from '@mui/material/styles'
import TextField from "../App/components/Input/TextField";
import {Submit} from "../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {AuthActions} from "./actions/auth";
import IResponseError from "../App/interfaces/responseError";
import {NavigateFunction, useNavigate} from "react-router";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {transformData} from "../App/helpers/transformData";
import {ILoginState} from "./interfaces/login";

const PREFIX = 'Login'

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  title_box: `${PREFIX}-title_box`,
  input: `${PREFIX}-input`,
  auth_text: `${PREFIX}-auth_text`,
}

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    height: "100%",
  },
  [`& .${classes.form}`]: {
    backgroundColor: "white",
    marginTop: "10%",
    width: "330px",
    border: "1px solid #E5E5E5",
    borderRadius: "3px",
  },
  [`& .${classes.title_box}`]: {
    zIndex: "10",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px 10px 10px",
    borderBottom: "1px solid #E5E5E5",
  },
  [`& .${classes.input}`]: {
    height: "80px",
    width: "90%",
  },
  [`& .${classes.auth_text}`]: {
    fontFamily: "Open Sans",
  },
}))

const Content = styled(Grid)(() => ({
  padding: '24px'
}))

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Button = styled(Grid)(() => ({
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  color: "#3668BF",
  padding: "4px 16px 0px 8px",
  fontSize: "0.9rem",
  fontWeight: "600"
}));

export default function Login(): JSX.Element {
  const dispatch: any = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      login: Yup.string().required('Поле обязательно к заполнению'),
      password: Yup.string().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting}) => {
      dispatch(AuthActions.login(transformData(values) as ILoginState)).then(
        () => {
          navigate('/')
        },
        (error: IResponseError) => {
          setSubmitting(false)
          if (error?.errors) {
            setErrors(error.errors)
          }
        }
      )
    }
  });

  return (
    <Root>
      <Grid className={classes.container} container justifyContent="center" alignItems="center">
        <Grid className={classes.form}>
          <Grid className={classes.title_box}>
            <img
              height="30px"
              loading="lazy"
              src="/logo.png"
              alt={'АИС PropHub'}
            />
            <Title>PropHub</Title>
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Content container direction="column" alignItems="center" justifyContent="center">
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="login"
                  label="Пользователь"
                  type="text"
                />
              </Item>
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="password"
                  label="Пароль"
                  type="password"
                />
              </Item>
              <Button>
                <Submit
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Войти
                </Submit>
              </Button>
            </Content>
          </form>
        </Grid>
      </Grid>
    </Root>
  );
}