import { TableRow } from "@devexpress/dx-react-grid";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import { useDebounce } from "use-debounce";
import TextField from "../App/components/Input/TextField";
import { Table } from "../App/components/Table";
import Actions from "../App/components/Table/Actions";
import { TableHeader } from "../App/components/Table/TableHeader";
import Title from "../App/components/Table/Title";
import { FiltersActionsTypes } from "../App/interfaces/filter";
import { IRootState } from "../App/reducers/store";
import { CompanyActions } from "./actions/company";
import Create from "./components/Company/Buttons/Create";
import Delete from "./components/Company/Buttons/Delete";
import Edit from "./components/Company/Buttons/Edit";
import { ICompany } from "./interfaces/company";

const PREFIX = 'Companies'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')] : {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')] : {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Companies(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();

  const {filter} = useSelector((state: IRootState) => state.filters.companies)
  
  const [page, setPage] = useState(1)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies]: any = useState({data: [], meta: { total: null }})
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'name', title: 'Название'},
    {name: 'short_name', title: 'Краткое название'},
    {name: 'inn', title: 'ИНН'},
    {name: 'kpp', title: 'КПП'},
    {name: 'contacts', title: 'Контактные данные'},
    {name: 'created', title: 'Создан'},
    {name: 'actions', title: ' '},
  ])

  const [columnCompanies, setColumnCompanies] = useState([
    'name',
    'short_name',
    'inn',
    'kpp',
    'contacts',
    'created',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'name', width: 250},
    {columnName: 'short_name', width: 250},
    {columnName: 'inn', width: 250},
    {columnName: 'kpp', width: 250},
    {columnName: 'contacts', width: 250},
    {columnName: 'created', width: 250},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(CompanyActions.companies({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!companies.meta?.total ? {meta: true} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [ICompany],
        meta?: {
          total?: number
        },
      }) => {
        setCompanies({
          data: response.data,
          meta: response.meta ?? companies.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.COMPANIES,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Title
        title={"Организации"}
        actions={[
          <Grid item key={0}>
            <TextField
              type="text"
              value={value}
              placeholder="Поиск: Название"
              icon={<Search/>}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />
          </Grid>,
          <Create
              key={1}
            onClick={(company) => {
              setCompanies({
                data: [
                  company,
                  ...((companies.data.length === filter.take) ? companies.data.slice(0, filter.take) : companies.data)
                ],
                meta: {
                  total: companies.meta.total + 1
                },
              })
            }}
          />
        ]}
      />
      <Table
          id={companies.data.length}
        meta={companies.meta}
        name={'companies'}
        rows={companies.data.map((item: ICompany) => ({
          id: item.id,
          name: item.name,
          short_name: item.short_name,
          contacts: item.contacts,
          inn: item.inn,
          kpp: item.kpp,
          created: new Date(item.created).toLocaleDateString(),
          actions: <Actions
              edit={{
                button: <Edit
                  company={item}
                  disabled={!!item.deleted}
                  onClick={(company) => {
                    setCompanies({
                      ...companies,
                      ...{data: companies.data.map((el: ICompany) => (el.id === company.id) ? company : el)}
                    })
                  }}
                />
              }}
              delete={{
                button: <Delete
                  id={item.id}
                  disabled={!!item.deleted}
                  onClick={() => {
                    setCompanies({
                      ...companies,
                      data: companies.data.map((el: ICompany) => {
                        if (el.id === item.id) {
                          return {
                            ...el,
                            deleted: new Date()
                          }
                        }

                        return el;
                      }),
                    })
                  }}
                />
              }}
            />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnCompanies,
          setColumnOrder: setColumnCompanies,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.COMPANIES}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`/administration/company/${row.id}`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          status: {
            name: 'status',
            type: 'values',
          },
          actions: null
        }}
      />
    </Content>
  ) : null
}