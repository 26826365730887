import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../../../App/components/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {Grid} from "@mui/material";
import {FiltersActionsTypes} from "../../../App/interfaces/filter";
import Actions from "../../../App/components/Table/Actions";
import {BuildingActions} from "../../../Realty/actions/building";
import {IBuilding} from "../../../Realty/interfaces/building";
import {ILand} from "../../../Realty/interfaces/land";
import {Edit} from "../../../Realty/components/Building/Buttons/Edit";
import Delete from "../../../Realty/components/Building/Buttons/Delete";
import {NavigateFunction, useNavigate} from "react-router";
import {LandActionsTypes} from "../../interfaces/land";
import {TableRow} from "@devexpress/dx-react-grid";

const PREFIX = 'Buildings'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 172px)',
    minHeight: 'calc(100vh - 172px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

type Props = {
  land: ILand,
  building?: IBuilding | null
}

export default function List(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();

  const {land} = props

  const {filter} = useSelector((state: IRootState) => state.filters.buildings)
  const [buildings, setBuildings]: any = useState([])
  const [page, setPage] = useState(1)
  const [, setTableRef] = useState(null)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [loading, setLoading] = useState(false)

  const [columns]: any = useState([
    {name: 'name', title: 'Корпус'},
    {name: 'address', title: 'Адрес'},
    {name: 'land', title: 'Земельный участок'},
    {name: 'type', title: 'Тип здания'},
    {name: 'floorsCount', title: 'Этажи'},
    {name: 'square', title: 'Площадь, кв.м'},
    {name: 'price', title: 'Балансовая стоимость'},
    {name: 'equippedVideo', title: 'Оборудовано системой видеонаблюдения'},
    {name: 'equippedSecurity', title: 'Имеет охрану'},
    {name: 'parking', title: 'Парковка, количество мест'},
    {name: 'internet', title: 'Интернет'},
    {name: 'accessiblePeopleLimitedMobility', title: 'Доступно для маломобильных групп населения'},
    {name: 'initialYear', title: 'Год первоначального ввода в эксплуатацию'},
    {name: 'lastOverhaulYear', title: 'Год последнего капитального ремонта'},
    {name: 'rentalType', title: 'Арендное'},
    {name: 'responsible', title: 'Ответственный'},
    {name: 'safety', title: 'Ответственный (Противопожарная безопасность)'},
    {name: 'actions', title: ' '},
  ])

  const [columnBuildings, setColumnBuildings] = useState([
    'name',
    'address',
    'land',
    'type',
    'floorsCount',
    'square',
    'price',
    'parking',
    'equippedVideo',
    'equippedSecurity',
    'internet',
    'accessiblePeopleLimitedMobility',
    'initialYear',
    'lastOverhaulYear',
    'rentalType',
    'responsible',
    'safety',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'name', width: 200},
    {columnName: 'address', width: 200},
    {columnName: 'land', width: 200},
    {columnName: 'type', width: 200},
    {columnName: 'floorsCount', width: 200},
    {columnName: 'square', width: 200},
    {columnName: 'price', width: 200},
    {columnName: 'parking', width: 200},
    {columnName: 'equippedVideo', width: 200},
    {columnName: 'equippedSecurity', width: 200},
    {columnName: 'internet', width: 200},
    {columnName: 'accessiblePeopleLimitedMobility', width: 200},
    {columnName: 'initialYear', width: 200},
    {columnName: 'lastOverhaulYear', width: 200},
    {columnName: 'rentalType', width: 200},
    {columnName: 'responsible', width: 200},
    {columnName: 'safety', width: 200},
    {columnName: 'actions', width: 150, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(BuildingActions.buildings({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction
        } : {}),
        landId: land.id,
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...{meta: true},
      })).then((buildings: {
        data: [IBuilding],
        meta?: {
          total?: number
        },
      }) => {
        setBuildings(buildings)
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, land])

  useEffect(() => {
    if (props.building && buildings?.data) {
      setBuildings({
        data: [
          props.building,
          ...((buildings.data.length === filter.take) ? buildings.data.slice(0, filter.take) : buildings.data)
        ],
        meta: {
          total: buildings.meta.total + 1
        },
      })
    }
    // eslint-disable-next-line
  }, [props.building])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.BUILDINGS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Table
        meta={buildings.meta}
        name={'buildings'}
        rows={buildings.data.map((item: IBuilding) => ({
          id: item.id,
          name: item.name,
          address: item?.address,
          land: item.land.cadastralNumber,
          type: item.type?.name,
          floorsCount: item._count.floors,
          square: item.square,
          price: item.price,
          parking: item.parking,
          equippedVideo: item.equippedVideo ? 'Да' : 'Нет',
          equippedSecurity: item.equippedSecurity ? 'Да' : 'Нет',
          accessiblePeopleLimitedMobility: item.accessiblePeopleLimitedMobility ? 'Да' : 'Нет',
          internet: item.internet ? 'Да' : 'Нет',
          initialYear: item.initialYear,
          lastOverhaulYear: item.lastOverhaulYear,
          rentalType: item?.rentalType?.name,
          responsible: item?.responsible?.name,
          safety: item?.safety?.name,
          actions: <Actions
            edit={{
              button: <Edit
                building={item}
                onClick={(building) => {
                  dispatch({type: LandActionsTypes.LAND_BUILDING, payload: building})
                  setBuildings({
                    ...buildings,
                    ...{data: buildings.data.map((el: IBuilding) => (el.id === building.id) ? building : el)}
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                onClick={() => {
                  dispatch({type: LandActionsTypes.LAND_BUILDING_DELETE, payload: item.id})
                  setBuildings({
                    data: buildings.data.filter((el: IBuilding) => el.id !== item.id),
                    meta: {
                      total: buildings.meta.total - 1
                    },
                  })
                }}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        setTableRef={setTableRef}
        columnsSettings={{
          columnOrder: columnBuildings,
          setColumnOrder: setColumnBuildings,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.BUILDINGS}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`building/${row.id}?section=list`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          status: {
            name: 'status',
            type: 'values',
          },
          actions: null
        }}
      />
    </Content>
  ) : null
}