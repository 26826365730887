import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FormikValues, useFormik } from "formik";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import * as Yup from "yup";
import { Submit } from "../../../App/components/Buttons/Submit";
import { Autocomplete } from "../../../App/components/Input/AsyncAutocomplete";
import { DialogContent } from "../../../App/components/Page/DialogContent";
import { Paper as BasePaper } from "../../../App/components/Paper";
import { AlertActionsTypes } from "../../../App/interfaces/alert";
import { LoadingActionsTypes } from "../../../App/interfaces/loading";
import IResponseError from "../../../App/interfaces/responseError";
import { FloorActions } from "../../../Realty/actions/floor";
import { RoomActions } from "../../../Realty/actions/room";
import Room from "../../../Realty/components/Room";
import { IFloor } from "../../../Realty/interfaces/floor";
import { IRoom } from "../../../Realty/interfaces/room";

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Page = styled(Grid)(() => ({
  margin: '0',
  width: 'auto',
  height: 'calc(100vh - 175px)',
  [`& .MuiGrid-item`]: {
    height: "100%",
  },
}))

const Paper = styled(BasePaper)(() => ({
  height: '100%'
}))

const Frame = styled('iframe')(() => ({
  border: 'none'
}))

type Area = {
  id: string
}

export default function FloorEditor(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const [element, setElement] = useState<Area | null | undefined>(null)
  const [floor, setFloor] = useState<IFloor | null>(null)
  const [openCreateRoom, setOpenCreateRoom] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const {floor: id} = useParams()

  const messageHandler = (event: {
    data: {
      type: string,
      element?: Area,
      message?: string
    }
  }) => {
    switch (event.data.type) {
      case 'selectRoom':
        if (event?.data?.element) {
          setOpen(true)
          setElement(event.data.element)
        }
        break;
      case 'createRoom':
        if (!floor) {
          dispatch(FloorActions.floor(Number(id))).then(
            (response: IFloor) => {
              setFloor(response)
              setOpenCreateRoom(true)
            }
          )
        } else {
          setOpenCreateRoom(true)
        }
        break;
      case 'alert_error':
        dispatch({
          type: AlertActionsTypes.ERROR, payload: {
            message: event.data.message,
            type: 'error'
          }
        })
        break;
    }
  }


  useEffect(() => {

    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler);
      dispatch({type: LoadingActionsTypes.LOADING, payload: false})
    }
  })

  const handleClose = () => {
    setElement(null)
    setOpen(false);
  };

  const handleCloseCreateRoom = () => {
    setFloor(null)
    setOpenCreateRoom(false)
  };

  const formik = useFormik({
    initialValues: {
      room: null,
    },
    validationSchema: Yup.object().shape({
      room: Yup.object().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues, {setErrors, resetForm}) => {
      if (element) {
        dispatch(RoomActions.markup(values.room.id, element.id)).then(
          () => {
            dispatch(RoomActions.rooms({floorId: Number(id)})).then(
              (response: {
                data: Array<IRoom>
              }) => {
                const reactPlannerFrame = document.getElementById("react_planner_frame") as HTMLIFrameElement;
                reactPlannerFrame?.contentWindow?.postMessage({
                  type: "loadRooms",
                  rooms: response.data
                }, '*');
                resetForm({
                  values: {
                    room: null
                  }
                })
                handleClose()
              }
            )
          },
          (error: IResponseError) => setErrors(error.errors)
        )
      }
    }
  });

  return (
    <Page container justifyContent="center" alignItems="center">
      {openCreateRoom && floor ? <Room
        open={openCreateRoom}
        handleClose={handleCloseCreateRoom}
        onClick={handleCloseCreateRoom}
        floor={floor}
      /> : null}
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>Выбрать помещение</Title>
                <IconButton
                  onClick={handleClose}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Item>
                <Autocomplete
                  name="room"
                  label="Помещение"
                  required
                  setValue={(name: string, value: IRoom) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => RoomActions.rooms({
                    take: 20,
                    sort: 'name',
                    direction: 'asc',
                    ...(search ? {search: search} : {}),
                    ...(id ? {floorId: Number(id)} : {})
                  })}
                  value={formik.values.room}
                  getOptionLabel={(option: IRoom) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
      <Grid item xs={12}>
        <Paper>
          <Frame
            id="react_planner_frame"
            onLoad={() => dispatch({type: LoadingActionsTypes.LOADING, payload: false})}
            src={`${process.env.REACT_APP_PLANNER_URL}/index.html?type=floor&id=${id}`}
            height="100%"
            width="100%"
          />
        </Paper>
      </Grid>
    </Page>
  )
}
