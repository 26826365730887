import React, {useEffect} from "react"
import {
  Checkbox,
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../../App/components/Page/DialogContent";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Submit} from "../../../App/components/Buttons/Submit";
import TextField from "../../../App/components/Input/TextField";
import {IType} from "../../interfaces/Room/type";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {ITypeInput} from "../../interfaces/Room/Input/type";
import Color from "../../../App/components/Input/Color";

const Column = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

type Props = {
  item?: IType,
  actions: any,
  name: string,
  open: boolean,
  handleClose: () => void,
  onClick?: (item: IType) => void
}

export default function Type(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {item, actions, name, onClick} = props
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      name: item?.name,
      color: item?.color ?? '',
      requiredWorkplaces: item?.requiredWorkplaces ?? false
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению'),
      color: Yup.string(),
      requiredWorkplaces: Yup.boolean(),
    }),
    onSubmit: (values: FormikValues) => {
      dispatch(item ? actions.update(item.id, (values as ITypeInput)) : actions.create(values as ITypeInput)).then(
        (item: IType) => {
          if (onClick) {
            onClick(item)
          }
          handleClose()
        }
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={10}>
                  <Title noWrap>{item?.id ? 'Редактировать' : 'Добавить'} {name}</Title>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClose}
                  >
                    <Close/>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Column>
                  <TextField
                    required
                    name="name"
                    label="Наименование"
                    type="text"
                    formik={formik}
                  />
                </Column>
                <Column>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.requiredWorkplaces}
                        onChange={(e: any) => formik.setFieldValue('requiredWorkplaces', e.target.checked)}
                      />
                    }
                    label="Количество рабочих мест обязательно"
                  />
                </Column>
                <Column>
                  <Color
                    label={'Цвет'}
                    value={formik.values.color}
                    onChange={(value: string) => {
                      formik.setFieldValue('color', value)
                    }}
                  />
                </Column>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Сохранить
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null;
}