import React from 'react'

import {styled} from "@mui/material/styles";
import {Table as MUITable} from "@mui/material";

const Table = styled(MUITable)(() => ({
  tableLayout: "fixed",
  borderCollapse: "separate",
  "& .MuiTableCell-stickyHeader": {
    position: "sticky !important",
    backgroundColor: "white",
    zIndex: 600,
    fallbacks: {
      position: "-webkit-sticky"
    },
    "&.TableFixedCell-fixedCell": {
      zIndex: 700,
      "& .ResizingControl-resizeHandle": {
        display: 'none'
      }
    }
  }
}))

export const StickyTable = ({children, forwardedRef, ...restProps}: any) => {
  return (
    <Table
      ref={forwardedRef}
      stickyHeader
      {...restProps}
    >
      {children}
    </Table>
  )
}
