import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../Auth/components/Access";
import Item from "../Item";
import {IItem} from "../../interfaces/item";

type Props = {
  item: IItem,
  name: string,
  actions: any,
  onClick?: (item: IItem) => void
}

export default function Edit(props: Props): JSX.Element {
  const {item, name, actions, onClick} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <React.Fragment>
        <IconButton
          onClick={handleOpen}
        >
          <EditIcon/>
        </IconButton>
        {open ? <Item open={open} item={item} name={name} actions={actions} handleClose={handleClose} onClick={onClick} /> : null}
      </React.Fragment>
    </Access>
  )
}
