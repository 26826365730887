import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../../../App/components/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {Grid} from "@mui/material";
import {FiltersActionsTypes} from "../../../App/interfaces/filter";
import Actions from "../../../App/components/Table/Actions";
import {RoomActions} from "../../../Realty/actions/room";
import {IRoom} from "../../../Realty/interfaces/room";
import {IFloor} from "../../../Realty/interfaces/floor";
import {Edit} from "../../../Realty/components/Room/Buttons/Edit";
import Delete from "../../../Realty/components/Room/Buttons/Delete";
import {NavigateFunction, useNavigate} from "react-router";
import {LandActionsTypes} from "../../interfaces/land";
import {TableRow} from "@devexpress/dx-react-grid";

const PREFIX = 'Rooms'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 172px)',
    minHeight: 'calc(100vh - 172px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

type Props = {
  floor: IFloor
  room?: IRoom | null
}

export default function List(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();

  const {floor} = props

  const {filter} = useSelector((state: IRootState) => state.filters.rooms)
  const [rooms, setRooms]: any = useState([])
  const [page, setPage] = useState(1)
  const [, setTableRef] = useState(null)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)

  const [columns]: any = useState([
    {name: 'name', title: 'Наименование'},
    {name: 'floor', title: 'Этаж'},
    {name: 'type', title: 'Тип помещения'},
    {name: 'height', title: 'Высота (м)'},
    {name: 'square', title: 'Площадь кв.м'},
    {name: 'subdivision', title: 'Подразделение'},
    {name: 'safety', title: 'Ответственный'},
    {name: 'rentalType', title: 'Арендное'},
    {name: 'rentalInformation', title: 'Информация о договорах аренды'},
    {name: 'numberWorkplaces', title: 'Количество рабочих мест всего'},
    {name: 'numberWorkplacesOccupied', title: 'Количество рабочих мест занято'},
    {name: 'numberWorkplacesFree', title: 'Количество рабочих мест свободно'},
    {name: 'rentalPrice1squareMeter', title: 'Стоимость аренды 1 кв.м'},
    {name: 'totalDeliveryTimeDays', title: 'Общее срок сдачи, дней'},
    {name: 'dateOverhaul', title: 'Дата капитального ремонта'},
    {name: 'dateRedecorating', title: 'Дата косметического ремонта'},
    {name: 'actions', title: ' '},
  ])

  const [columnRooms, setColumnRooms] = useState([
    'name',
    'floor',
    'type',
    'height',
    'square',
    'subdivision',
    'safety',
    'rentalType',
    'rentalInformation',
    'numberWorkplaces',
    'numberWorkplacesOccupied',
    'numberWorkplacesFree',
    'rentalPrice1squareMeter',
    'totalDeliveryTimeDays',
    'dateOverhaul',
    'dateRedecorating',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'name', width: 200},
    {columnName: 'floor', width: 200},
    {columnName: 'type', width: 200},
    {columnName: 'height', width: 200},
    {columnName: 'square', width: 200},
    {columnName: 'subdivision', width: 200},
    {columnName: 'safety', width: 200},
    {columnName: 'rentalType', width: 200},
    {columnName: 'rentalInformation', width: 200},
    {columnName: 'numberWorkplaces', width: 200},
    {columnName: 'numberWorkplacesOccupied', width: 200},
    {columnName: 'numberWorkplacesFree', width: 200},
    {columnName: 'rentalPrice1squareMeter', width: 200},
    {columnName: 'totalDeliveryTimeDays', width: 200},
    {columnName: 'dateOverhaul', width: 200},
    {columnName: 'dateRedecorating', width: 200},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(RoomActions.rooms({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        floorId: floor.id,
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...{meta: true},
      })).then((rooms: {
        data: [IRoom],
        meta?: {
          total?: number
        },
      }) => {
        setRooms(rooms)
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, floor])

  useEffect(() => {
    if (props.room && rooms?.data) {
      setRooms({
        data: [
          props.room,
          ...((rooms.data.length === filter.take) ? rooms.data.slice(0, filter.take) : rooms.data)
        ],
        meta: {
          total: rooms.meta.total + 1
        },
      })
    }
    // eslint-disable-next-line
  }, [props.room])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.ROOMS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Table
        meta={rooms.meta}
        name={'rooms'}
        rows={rooms.data.map((item: IRoom) => ({
          id: item.id,
          name: item.name,
          type: item?.type?.name,
          floor: item.floor.name,
          height: item.height,
          square: item.square,
          subdivision: item.subdivision?.name,
          safety: item.safety?.name,
          rentalType: item.rentalType?.name,
          rentalInformation: item.rentalInformation,
          numberWorkplaces: item.numberWorkplaces,
          numberWorkplacesOccupied: item.numberWorkplacesOccupied,
          numberWorkplacesFree: item.numberWorkplacesFree,
          rentalPrice1squareMeter: item.rentalPrice1squareMeter,
          totalDeliveryTimeDays: item.totalDeliveryTimeDays,
          dateOverhaul: item.dateOverhaul ? `${(`0${((new Date(item.dateOverhaul)).getMonth() + 1)}`).slice(-2)}.${(new Date(item.dateOverhaul)).getFullYear()}` : null,
          dateRedecorating: item.dateRedecorating ? `${(`0${((new Date(item.dateRedecorating)).getMonth() + 1)}`).slice(-2)}.${(new Date(item.dateRedecorating)).getFullYear()}` : null,
          actions: <Actions
            edit={{
              button: <Edit
                room={item}
                floor={floor}
                onClick={(room) => {
                  dispatch({type: LandActionsTypes.LAND_ROOM, payload: room})
                  setRooms({
                    ...rooms,
                    ...{data: rooms.data.map((el: IRoom) => (el.id === room.id) ? room : el)}
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                onClick={() => {
                  dispatch({type: LandActionsTypes.LAND_ROOM_DELETE, payload: item.id})
                  setRooms({
                    data: rooms.data.filter((el: IRoom) => el.id !== item.id),
                    meta: {
                      total: rooms.meta.total - 1
                    },
                  })
                }}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        setTableRef={setTableRef}
        columnsSettings={{
          columnOrder: columnRooms,
          setColumnOrder: setColumnRooms,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.ROOMS}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`room/${row.id}?section=list`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          status: {
            name: 'status',
            type: 'values',
          },
          actions: null
        }}
      />
    </Content>
  ) : null
}