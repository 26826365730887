import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import {FloorTypeActions} from "../../Dictionaries/actions/floor.type";
import {DialogContent} from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import {Submit} from "../../App/components/Buttons/Submit";
import {ICompany} from "../../Company/interfaces/company";
import {IItem} from "../../Dictionaries/interfaces/item";
import {IFloorInput} from "../interfaces/input/floor";
import Typography from "@mui/material/Typography";
import {IBuilding} from "../interfaces/building";
import {FormikValues, useFormik} from "formik";
import {FloorActions} from "../actions/floor";
import {styled} from "@mui/material/styles";
import {IFloor} from "../interfaces/floor";
import {Close} from '@mui/icons-material';
import {ILand} from "../interfaces/land";
import {useDispatch} from "react-redux";
import * as Yup from "yup"
import {transformData} from "../../App/helpers/transformData";
import IResponseError from "../../App/interfaces/responseError";
import {CloseDialog} from "../../App/components/Dialog/CloseDialog";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useNavigate} from "react-router";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))
export default function Floor(props: {
  building?: IBuilding,
  floor?: IFloor,
  open: boolean,
  handleClose: () => void,
  onClick?: (floor: IFloor) => void
}): JSX.Element | null {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const {building, onClick} = props

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [floor, setFloor] = React.useState<IFloor | null | undefined>(props.floor);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    if (onClick && floor) {
      onClick(floor)
    }
    setFloor(null)
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      name: floor?.name,
      type: floor?.type,
      square: floor?.square ?? 0,
      building: floor?.building ?? building,
      land: floor?.building?.land ?? building?.land,
      company: floor?.building?.land?.company ?? building?.land?.company,
      dateRepair: floor?.dateRepair,
    },
    validationSchema: Yup.object().shape({
      land: Yup.object(),
      company: Yup.object(),
      building: Yup.object(),
      name: Yup.string().required('Поле обязательно к заполнению').max(512, 'Значение не может быть больше 512 символов'),
      square: Yup.number().min(0, 'Значение не может быть меньше 0'),
      dateRepair: Yup.string().nullable(),
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = transformData(values)
      dispatch(floor ? FloorActions.update(floor?.id, (data as IFloorInput)) : FloorActions.create(data as IFloorInput)).then(
        (floor: IFloor) => {
          setSubmitting(true)
          setFloor(floor)
          resetForm({
            values: {
              name: floor?.name,
              type: floor?.type,
              square: floor?.square ?? 0,
              building: floor?.building ?? building,
              land: floor?.building?.land ?? building?.land,
              company: floor?.building?.land?.company ?? building?.land?.company,
              dateRepair: floor?.dateRepair
            }
          })
        },
        (error: IResponseError) => {
          setErrors(error.errors)
          setSubmitting(false)
        },
      )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleCloseDialog}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <Grid>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>{floor?.id ? 'Редактировать' : 'Добавить'} этаж</Title>
                <IconButton
                  onClick={handleCloseDialog}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Item item xs={6}>
                  <TextField
                    required
                    formik={formik}
                    name="name"
                    label="Наименование"
                    type="string"
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    formik={formik}
                    name={"company"}
                    label="Компания"
                    value={formik.values.company}
                    getOptionLabel={(option: ICompany) => option?.name ?? option}
                    setValue={(name: string, value: ILand) => formik.setFieldValue(name, value)}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    name={"land"}
                    formik={formik}
                    label="Земельный участок"
                    value={formik.values.land}
                    subscribeFields={[formik.values.company]}
                    setValue={(name: string, value: ILand) => formik.setFieldValue(name, value)}
                    getOptionLabel={(option: ILand | null) => option?.name ?? option?.cadastralNumber ?? option}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    label="Здание"
                    formik={formik}
                    name={"building"}
                    value={formik.values.building}
                    subscribeFields={[formik.values.company, formik.values.land]}
                    getOptionLabel={(option: IBuilding) => option?.name ?? option}
                    setValue={(name: string, value: IBuilding) => formik.setFieldValue(name, value)}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"type"}
                    formik={formik}
                    label="Тип этажа"
                    setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => FloorTypeActions.items({
                      take: 20,
                      ...(floor ? {exclude: floor.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Площадь кв.м"
                    name="square"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Дата последнего ремонта"
                      inputFormat="dd.MM.yyyy"
                      renderInput={(params) => <TextField
                        name="dateRepair"
                        formik={formik}
                        label={params.label}
                        type={params.type}
                        InputProps={{
                          disableUnderline: !(formik?.touched?.["dateRepair"] && formik?.errors?.["dateRepair"]),
                          endAdornment: params.InputProps?.endAdornment
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: null
                        }}
                        inputRef={params.inputRef}
                      />}
                      onChange={(e: any) => {
                        formik.setFieldValue('dateRepair', e, true)
                      }}
                      value={formik.values.dateRepair ?? null}/>
                  </LocalizationProvider>
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    type="submit"
                    variant="contained"
                    disabled={!floor?.id || (!formik.isValid || (formik.dirty && !formik.isSubmitting) || (!formik.dirty && formik.isSubmitting))}
                    onClick={() => {
                      handleClose()
                      navigate(`/objects/${floor?.building?.land?.id}/building/${floor?.building?.id}/floor/${floor?.id}/markup?section=scheme`)
                    }}
                  >
                    Перейти к планировке этажа
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Grid>
      </Dialog>
      <CloseDialog
        open={openDialog}
        onClose={onCloseDialog}
        onCloseWithoutSaving={onCloseWithoutSaving}
      />
    </React.Fragment>
  ) : null;
}