import {HttpService} from "../../App/services/http"

export const LandService = {
  update
}

function update(id: number, formData: any) {
  return HttpService.post(`/markup/land/${id}`, formData, true, true)
    .then(
      response => {
        return response
      })
}