import {styled} from "@mui/material/styles";

export const SVG = styled('svg')(() => ({
  margin: 'auto'
}))

export const Group = styled('g')(() => ({

}))

export const Path = styled('path')(() => ({
  fill: 'none',
  strokeLinecap: 'round',
  strokeOpacity: 1,
  pointerEvents: 'all',
  strokeWidth: '2px'
}))