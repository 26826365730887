import React, {
  useEffect,
  useState
} from "react";
import { styled } from "@mui/material/styles";
import { Table } from "../../../App/components/Table";
import { TableHeader } from "../../../App/components/Table/TableHeader";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { IRootState } from "../../../App/reducers/store";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { FiltersActionsTypes } from "../../../App/interfaces/filter";
import Actions from "../../../App/components/Table/Actions";
import { Search } from "@mui/icons-material";
import { Grid, } from "@mui/material";
import TextField from "../../../App/components/Input/TextField";
import Title from "../../../App/components/Table/Title";
import { Create } from "../../../Realty/components/Land/Buttons/Create";
import Delete from "../../../Realty/components/Land/Buttons/Delete";
import { useDebounce } from "use-debounce";
import { TableRow } from "@devexpress/dx-react-grid";
import { LandActions } from "../../../Realty/actions/land";
import { ILand } from "../../../Realty/interfaces/land";
import { Edit } from "../../../Realty/components/Land/Buttons/Edit";

const PREFIX = 'Accounts'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')] : {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')] : {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Lands(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.lands)
  const [lands, setLands]: any = useState({data: [], meta: {total: null}})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);

  const [columns]: any = useState([
    {name: 'name', title: 'Наименование'},
    {name: 'cadastralNumber', title: 'Кадастровый номер'},
    {name: 'company', title: 'Компания'},
    {name: 'status', title: 'Статус объекта'},
    {name: 'category', title: 'Категория земель'},
    {name: 'permission', title: 'Вид разрешенного использования'},
    {name: 'cadastralPrice', title: 'Кадастровая стоимость'},
    {name: 'encumbrances', title: 'Обременения'},
    {name: 'square', title: 'Площадь, кв.м'},
    {name: 'address', title: 'Адрес'},
    {name: 'responsible', title: 'Ответственный'},
    {name: 'actions', title: ' '},
  ])

  const [columnLands, setColumnLands] = useState([
    'name',
    'cadastralNumber',
    'company',
    'status',
    'category',
    'permission',
    'cadastralPrice',
    'encumbrances',
    'square',
    'address',
    'responsible',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'name', width: 250},
    {columnName: 'cadastralNumber', width: 250},
    {columnName: 'company', width: 250},
    {columnName: 'status', width: 250},
    {columnName: 'category', width: 250},
    {columnName: 'permission', width: 250},
    {columnName: 'cadastralPrice', width: 250},
    {columnName: 'encumbrances', width: 250},
    {columnName: 'square', width: 250},
    {columnName: 'address', width: 250},
    {columnName: 'responsible', width: 250},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(LandActions.lands({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!lands.meta?.total ? {meta: true} : {}),
        ...(filter.roles?.length ? {roles: filter.roles.join(',')} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [ILand],
        meta?: {
          total?: number
        },
      }) => {
        setLands({
          data: response.data,
          meta: response.meta ?? lands.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.LANDS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Title
        title={"Земельные участки"}
        actions={[
          <Grid item>
            <TextField
              type="text"
              value={value}
              placeholder="Поиск: Кадастровый номер"
              icon={<Search/>}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />
          </Grid>,
          <Create
            onClick={(land) => {
              setLands({
                data: [
                  land,
                  ...((lands.data.length === filter.take) ? lands.data.slice(0, filter.take) : lands.data)
                ],
                meta: {
                  total: (lands.meta?.total ?? 0) + 1
                },
              })
            }}
          />
        ]}
      />
      <Table
        meta={lands.meta}
        name={'accounts'}
        rows={lands.data.map((item: ILand) => ({
          name: item?.name,
          cadastralNumber: item.cadastralNumber,
          square: item.square,
          company: item.company?.name,
          status: item.status?.name,
          category: item.category?.name,
          permission: item.permission?.name,
          encumbrances: item.encumbrances,
          address: item.address,
          responsible: item.responsible?.name,
          cadastralPrice: item.cadastralPrice,
          created: new Date(item.created).toLocaleDateString(),
          actions: <Actions
            edit={{
              button: <Edit
                button={false}
                land={item}
                onClick={(land) => {
                  setLands({
                    ...lands,
                    data: lands.data.map((el: ILand) => el.id === land.id ? land : el),
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                onClick={() => setLands({
                  ...lands,
                  data: lands.data.filter((el: ILand) => el.id !== item.id),
                })}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnLands,
          setColumnOrder: setColumnLands,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.LANDS}
        getValues={LandActions.lands}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: {
          row: object,
          tableRow: TableRow,
          children: JSX.Element | null
        }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          // roles: {
          //   sort: false,
          //   name: 'roles',
          //   type: 'values',
          // },
          actions: null,
        }}
      />
    </Content>
  ) : null
}