import React from "react";
import {styled} from "@mui/material/styles";
import {Breadcrumbs as MUIBreadcrumbs, Grid, Link as MUILink, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const Content = styled(Grid)(() => ({
  boxShadow: "0px 0px 0px 1px rgb(219 222 223 / 44%)",
  width: "100%",
  display: "grid",
  padding: "10px 20px",
  background: "white",
  alignItems: "center",
  minHeight: "30px",
  minWidth: "calc(100vw - 272px)"
}))

type Props = {
  crumbs: Array<{ name: string, to?: string | null }>
}

export default function Breadcrumbs(props: Props): JSX.Element | null {
  const {crumbs} = props

  return (
    <Content>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <MUIBreadcrumbs separator="›">
            {crumbs.map((crumb, index) => {
              if (crumb.to) {
                return (
                  <MUILink
                    key={index}
                    underline="hover"
                    color="inherit"
                    component={Link}
                    to={crumb.to}
                  >
                    <Typography color="text.secondary">{crumb.name}</Typography>
                  </MUILink>
                )
              } else {
                return <Typography key={index} color="text.primary">{crumb.name}</Typography>
              }
            })}
          </MUIBreadcrumbs>
        </Grid>
      </Grid>
    </Content>
  )
}