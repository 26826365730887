import React from "react"
import {Delete as DeleteIcon} from "@mui/icons-material";
import {IconButton, MenuItem, MenuList} from "@mui/material";
import {Menu} from "../../../../../App/components/Menu";
import {useDispatch} from "react-redux";
import Access from "../../../../../Auth/components/Access";
import {CompanyEmployeeActions} from "../../../../../Company/actions/company.employee";
import {IRoomEmployeeInput} from "../../../../interfaces/input/room/employee";

type Props = {
  id: number,
  roomId: number
  onClick?: () => void
}

export default function Delete(props: Props): JSX.Element {
  const dispatch: any = useDispatch()
  const {id, roomId, onClick} = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | undefined>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Access roles={['admin']}>
      <React.Fragment>
        <IconButton
          onClick={handleClick}
        >
          <DeleteIcon/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList dense>
            <MenuItem
              onClick={() => {
                dispatch(CompanyEmployeeActions.room(roomId, id, { room: {id: null} } as IRoomEmployeeInput)).then(
                  () => {
                    if (onClick) {
                      onClick()
                    }
                  }
                )
              }}
            >
              Подтвердить
            </MenuItem>
            <MenuItem onClick={handleClose}>Отмена</MenuItem>
          </MenuList>
        </Menu>
      </React.Fragment>
    </Access>
  )
}
