import React from 'react'
//eslint-disable-next-line
//@ts-ignore
import {firstRowOnPage, lastRowOnPage} from '@devexpress/dx-grid-core'
import {IconButton} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {Item} from './PageSelector';

const Root = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}))

export const Pagination = ({
    totalPages,
    totalCount,
    pageSize,
    currentPage,
    onCurrentPageChange,
    getMessage,
}: any) => {

    const from = firstRowOnPage(currentPage - 1, pageSize, totalCount)
    const to = lastRowOnPage(currentPage - 1, pageSize, totalCount)

    return (
        <Root>
            <Item>{getMessage('info', {from, to, count: totalCount})}</Item>
                <IconButton
                    color="inherit"
                    disabled={currentPage === 1}
                    onClick={() => (currentPage > 1) && onCurrentPageChange(currentPage - 1)}
                    aria-label="Previous"
                >
                    <ChevronLeft/>
                </IconButton>
                <IconButton
                    color="inherit"
                    disabled={(currentPage === totalPages) || totalCount === 0}
                    onClick={() => (currentPage <= (totalPages - 1)) && onCurrentPageChange(currentPage + 1)}
                    aria-label="Next"
                >
                    <ChevronRight/>
                </IconButton>
        </Root>
    )
}
