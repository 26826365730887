export const calculateRemainingTime = (date: Date): string => {
  const currentDate: Date = new Date();
  const targetDate: Date = new Date(date);
  const timeDifference: number = targetDate.getTime() - currentDate.getTime();

  const secondsOneMinute: number = 60;
  const secondsOneHour: number = 3600;
  const secondsOneDay: number = 86400;
  const secondsOneWeek: number = 604800;
  const secondsOneMonths: number = 2678400;
  const secondsOneYear: number = 31536000;

  if (timeDifference <= 0) {
    return 'Срок действия истек';
  }

  let seconds: number = Math.floor(timeDifference / 1000);
  const remainingTime: string[] = [];


  const years = Math.round(seconds / secondsOneYear);

  if (years > 0) {
    seconds = seconds - (years * secondsOneYear);
    remainingTime.push(`${years} ${getRussianWord(years, ['год', 'года', 'лет'])}`);
  }

  const months = Math.round(seconds / secondsOneMonths);
  if (months > 0) {
    seconds = seconds - (months * secondsOneMonths);
    remainingTime.push(`${months} ${getRussianWord(months, ['месяц', 'месяца', 'месяцев'])}`);
  } else {
    remainingTime.push('')
  }


  const weeks = Math.round(seconds / secondsOneWeek);
  if (weeks > 0) {
    seconds = seconds - (weeks * secondsOneWeek);
    remainingTime.push(`${weeks} ${getRussianWord(weeks, ['неделя', 'недели', 'недель'])}`);
  } else {
    remainingTime.push('')
  }

  const days = Math.round(seconds / secondsOneDay);
  if (days > 0) {
    seconds = seconds - (days * secondsOneDay);
    remainingTime.push(`${days} ${getRussianWord(days, ['день', 'дня', 'дней'])}`);
  } else {
    remainingTime.push('')
  }

  const hours = Math.round(seconds / secondsOneHour);
  if (hours > 0) {
    seconds = seconds - (hours * secondsOneHour);
    remainingTime.push(`${hours} ${getRussianWord(hours, ['час', 'часа', 'часов'])}`);
  } else {
    remainingTime.push('')
  }


  const minutes = Math.round(seconds / secondsOneMinute);
  if (minutes > 0) {
    seconds = seconds - (minutes * secondsOneMinute);
    remainingTime.push(`${minutes} ${getRussianWord(minutes, ['минута', 'минуты', 'минут'])}`);
  } else {
    remainingTime.push('')
  }

  const sec = Math.round(seconds);
  if (sec > 0) {
    remainingTime.push(`${sec} ${getRussianWord(sec, ['секунда', 'секунды', 'секунд'])}`);
  }


  return remainingTime.slice(0, 2).join(' ');
};

const getRussianWord = (number: number, words: string[]): string => {
  const cases: number[] = [2, 0, 1, 1, 1, 2];
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : cases[Math.min(number % 10, 5)]];
};