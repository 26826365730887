import { HttpService } from "../../App/services/http"
import { IFilter } from "../interfaces/filter";
import { IEquipmentInput } from "../interfaces/input/equipment";

export const EquipmentService = {
  equipments,
  equipment,
  number,
  update,
  create,
  delete: remove,
  import: importFile,
  filter,
  addFile,
  deleteFile,
  versions,
  restore,
  stickers
}

function equipments(filter: IFilter) {
  return HttpService.get(`/equipments`, filter)
    .then(
      response => {
        return response
      })
}

function equipment(id: number) {
  return HttpService.get(`/equipment/${id}`)
    .then(
      response => {
        return response
      })
}

function number(number: string) {
  return HttpService.get(`/equipment/number/${number}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IEquipmentInput) {
  return HttpService.patch(`/equipment/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IEquipmentInput) {
  return HttpService.post(`/equipment`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/equipment/${id}`)
    .then(
      response => {
        return response
      })
}

function importFile(formData: FormData) {
  return HttpService.post(`/equipment/import`, formData, true, true)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/equipments/filter", params)
    .then(
      response => {
        return response
      })
}

function addFile(id: number, formData: FormData) {
  return HttpService.post(`/equipment/${id}/file`, formData, true, true)
    .then(
      response => {
        return response
      })
}

function deleteFile(id: number, fileId: number) {
  return HttpService.delete(`/equipment/${id}/file/${fileId}`)
    .then(
      response => {
        return response
      })
}

function versions(guid: string) {
  return HttpService.get(`/equipment/${guid}/versions`)
  .then(
      response => {
        return response
      })
}

function restore(id: number) {
  return HttpService.post(`/equipment/version/${id}/restore`)
  .then(
      response => {
        return response
      })
}

function stickers(isSelectAll: boolean, ids: number[]) {
  return HttpService.post(`/equipments/stickers`, {
    isSelectAll,
    ids
  }, true, false, 'blob')
  .then(
      response => {
        return response
      })
}