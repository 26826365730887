import { TableRow } from "@devexpress/dx-react-grid";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../App/components/Table";
import Actions from "../../../App/components/Table/Actions";
import { TableHeader } from "../../../App/components/Table/TableHeader";
import { FiltersActionsTypes } from "../../../App/interfaces/filter";
import { IRootState } from "../../../App/reducers/store";
import Sticker from "../../../Realty/components/Equipment/Buttons/Sticker";
import { EquipmentActions } from "../../actions/equipment";
import { IEquipment } from "../../interfaces/equipment";
import { IRegistry } from "../../interfaces/registry";
import Edit from "./Equipment/Buttons/Edit";

const PREFIX = 'Equipments'

const classes = {
  content: `${PREFIX}-inputContent`
}

interface Props {
  registry?: IRegistry
  search: string | null,
  update: boolean,
  setUpdate: (update: boolean) => void
}

export function Surpluses(props: Props): JSX.Element | null {
  const {registry, search, update, setUpdate} = props
  const dispatch: any = useDispatch()
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {filter} = useSelector((state: IRootState) => state.filters.registryEquipments)

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [equipments, setEquipments]: any = useState({data: [], meta: { total: null }})
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'number', title: '№'},
    {name: 'equipment.name', title: 'Наименование'},
    {name: 'equipment.description', title: 'Общее описание'},
    {name: 'equipment.inventoryNumber', title: 'Инвентарный номер'},
    {name: 'equipment.serialNumber', title: 'Серийный номер'},
    {name: 'equipment.specification', title: 'Тех.характеристики'},
    {name: 'equipment.user.name', title: 'Пользователь МТО'},
    {name: 'equipment.responsible.name', title: 'Мат.ответственное лицо'},
    {name: 'land.address', title: 'Адрес'},
    {name: 'building.name', title: 'Корпус'},
    {name: 'floor.name', title: 'Этаж'},
    {name: 'room.name', title: 'Помещение'},
    {name: 'actions', title: ' '},
  ])

  const [columnEquipments, setColumnEquipments] = useState([
    'number',
    'equipment.name',
    'equipment.description',
    'equipment.inventoryNumber',
    'equipment.serialNumber',
    'equipment.specification',
    'equipment.user.name',
    'equipment.responsible.name',
    'land.address',
    'building.name',
    'floor.name',
    'room.name',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'number', width: mobile ? 170 : 250},
    {columnName: 'equipment.name', width: 250},
    {columnName: 'equipment.description', width: 250},
    {columnName: 'equipment.inventoryNumber', width: 250},
    {columnName: 'equipment.serialNumber', width: 250},
    {columnName: 'equipment.specification', width: 250},
    {columnName: 'equipment.user.name', width: 250},
    {columnName: 'equipment.responsible.name', width: 250},
    {columnName: 'land.address', width: 250},
    {columnName: 'building.name', width: 250},
    {columnName: 'floor.name', width: 250},
    {columnName: 'room.name', width: 250},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading && registry) {
      const skip = (page - 1) * filter.take
      dispatch(EquipmentActions.equipments(registry.id, {
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        surplus: true,
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!equipments.meta?.total ? {meta: true} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [IEquipment],
        meta?: {
          total?: number
        },
      }) => {
        setEquipments({
          data: response.data,
          meta: response.meta ?? equipments.meta
        })
        setLoading(true)
        setUpdate(true)
      })
    }
    // eslint-disable-next-line
  }, [loading, registry])

  useEffect(() => {
    if (!update) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [update])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.REGISTRY_EQUIPMENTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return (loading && registry) ? (
    <Table
      meta={equipments.meta}
      name={'registryEquipments'}
      rows={equipments.data.map((item: IEquipment, index: number) => ({
        number: (index + 1) + ((page - 1) * filter.take),
        inventoried: !!item.inventoried,
        'equipment.name': item.equipment.name,
        'equipment.description': item.equipment.description,
        'equipment.inventoryNumber': item.equipment.inventoryNumber,
        'equipment.serialNumber': item.equipment.serialNumber,
        'equipment.specification': item.equipment.specification,
        'equipment.user.name': item.equipment.user?.name,
        'equipment.responsible.name': item.equipment.responsible?.name,
        'land.address': item.room?.floor.building.land.address,
        'building.name': item.room?.floor.building.name,
        'floor.name': item.room?.floor.name,
        'room.name': item.room?.name,
        actions: <Actions
          code={{
            button: <Sticker
                equipment={item.equipment}
            />
          }}
          edit={{
            button: <Edit
              registry={registry}
              equipment={item}
              onClick={(equipment) => {
                if (equipment) {
                  setEquipments({
                    ...equipments,
                    ...{data: equipments.data.map((el: IEquipment) => (el.equipment.id === equipment.equipment.id) ? equipment : el)}
                  })
                }
              }}
            />
          }}
        />,
      }))}
      columns={columns}
      page={{
        page: page,
        setPage: setPage,
        rowsPerPage: filter.take,
        handlePageSizeChange: handlePageSizeChange
      }}
      columnsSettings={{
        columnOrder: columnEquipments,
        setColumnOrder: setColumnEquipments,
        setColumnWidths: setColumnWidths,
        columnWidths: columnWidths,
        hiddenColumnNames: hiddenColumnNames,
        setHiddenColumnNames: setHiddenColumnNames
      }}
      tableHeader={TableHeader}
      filterActionType={FiltersActionsTypes.REGISTRY_EQUIPMENTS}
      classInputContent={classes.content}
      rowComponent={({row, tableRow, children}: { row: { inventoried: boolean }, tableRow: TableRow, children: JSX.Element | null }) => (
        <MUITable.Row
          tableRow={tableRow}
          row={row}
          children={children}
          style={{'cursor': 'pointer', backgroundColor: row.inventoried ? "whitesmoke" : "white"}}
        />
      )}
      filters={{
        number: null,
        actions: null
      }}
    />
  ) : null
}