import React, {Fragment, useEffect, useState} from "react"
import {Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import Menu from "./Menu";
import Canvas, {Shape} from "../../Canvas";
import {useParams} from "react-router";
import {BuildingActions} from "../../actions/building";
import {BuildingActions as RealtyBuildingActions} from "../../../Realty/actions/building";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {IBuilding} from "../../../Realty/interfaces/building";

const Page = styled(Grid)(() => ({
  width: 'calc(100% - 260px)',
  height: 'calc(100vh - 172px)',
  [`& .MuiGrid-item`]: {
    height: "100%",
  },
}))

export function Editor(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const params = useParams();

  const [load, setLoad] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [object, setObject] = useState<{id: number} | null>(null);
  const [shapes, setShapes] = useState<{[key: number]: Shape}>({});
  const [file, setFile] = useState();
  const [image, setImage]: any = useState(null);

  useEffect(() => {
    const getData = async (id: number) => {
      return await dispatch(RealtyBuildingActions.markup(id))
    }

    if (!load && params.building) {
      getData(Number(params.building)).then(response => {
        setLoad(true)
        setBuilding(response)
      })
    }
  })

  useEffect(() => {
    if (building && !initialize) {
      if (building.image) {
        setImage(building?.image)
      }
      if (building.shapes?.length) {
        setShapes(building.shapes.reduce((result, shape, index) => {
          return {
            ...result,
            [index + 1]: shape
          }
        }, {}))
      }
      setInitialize(true)
    }
  }, [building, initialize])

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setImage(reader.result)
      };
    } else {
      setImage(null)
    }
  }, [file])

  return (initialize && building) ? (
    <Fragment>
      <Page container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Canvas
            image={image}
            object={object}
            setObject={setObject}
            shapes={shapes}
            setShapes={setShapes}
          />
        </Grid>
      </Page>
      <Menu
        building={building}
        setFile={setFile}
        object={object}
        setObject={setObject}
        handleSubmit={() => {
          const formData = new FormData();

          if (image) {
            formData.append('image', image)
          }

          if (Object.keys(shapes).length) {
            if (Object.entries(shapes).find(([, shape]) => (shape.points.length && !shape.looped))) {
              dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                  message: 'Все фигуры разметки должны быть замкнуты.',
                  type: 'error'
                }
              })

              return;
            }

            formData.append(`shapes`, JSON.stringify(Object.entries(shapes).filter(([, shape]) => shape.points.length).map(([, shape]) => shape)))
          }

          dispatch(BuildingActions.update(building.id, formData)).then(
            () => {
              dispatch({
                type: AlertActionsTypes.SUCCESS, payload: {
                  message: 'Разметка успешно сохранена.',
                  type: 'success'
                }
              })
            }
          )
        }}
      />
    </Fragment>
  ) : null
}