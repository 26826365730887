import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react"
import { Submit } from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import { IRegistry } from "../../../interfaces/registry";
import Registry from "../../Registry";

type Props = {
  registry: IRegistry,
  onClick?: (registry?: IRegistry) => void,
  equipments?: Array<number>
}

export function View(props: Props): JSX.Element | null {
  const { registry, equipments, onClick } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return !mobile ? (
    <Access roles={['admin', 'working_group', 'chairman']}>
      <Grid item>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
        >
          Инвентаризационная опись
        </Submit>
        {open ? <Registry registry={registry} equipments={equipments} open={open} handleClose={handleClose}
                          onClick={onClick} isSelectAll={true}/> : null}
      </Grid>
    </Access>
  ) : null
}
