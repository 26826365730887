export const xml = (node: ChildNode | undefined) => {
  const obj: { [key: string]: any } = {};
  if (node?.childNodes.length) {
    node.childNodes.forEach(child => {
      switch (child.nodeType) {
        case Node.ELEMENT_NODE:
          if (child.childNodes.length > 1) {
            obj[child.nodeName] = xml(child)
          } else {
            const value = Array.from(child.childNodes).shift()
            if (value) {
              switch (value.nodeType) {
                case Node.TEXT_NODE:
                  obj[child.nodeName] = value.nodeValue
              }
            }
          }

          break
      }
    })
  }

  return obj;
}
