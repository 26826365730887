import {useParams} from "react-router";
import Companies from "../Company/Companies";
import Company from "../Company/Company";
import Accounts from "../Account/Accounts";
import Land from "../Dictionaries/Land";
import Building from "../Dictionaries/Building";
import Floor from "../Dictionaries/Floor";
import Room from "../Dictionaries/Room";
import Equipment from "../Dictionaries/Equipment";
import Inventory from "../Dictionaries/Inventory";
import Common from "../Dictionaries/Common";
import Lands from "../Object/components/Land/Lands";

export default function Administration(): JSX.Element | null {
  const params = useParams();

  const getPage = (): JSX.Element | null => {
    switch (params?.section) {
      case 'users':
        return <Accounts />;
      case 'lands':
        return <Lands />;
      case 'companies':
        return <Companies />;
      case 'company':
        return <Company />;
      case 'dictionary':
        switch (params?.subsection) {
          case 'land':
            return <Land />;
          case 'building':
            return <Building />;
          case 'floor':
            return <Floor />;
          case 'room':
            return <Room />;
          case 'equipment':
            return <Equipment />;
          case 'inventory':
            return <Inventory />;
          case 'common':
            return <Common />;
          default:
            return null;
        }
      default:
        return null;
    }
  }

  return getPage();
}