import React, {useEffect} from "react"
import {Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Paper as BasePaper} from "../App/components/Paper";
import {useDispatch} from "react-redux";
import {LoadingActionsTypes} from "../App/interfaces/loading";

const Page = styled(Grid)(() => ({
  margin: '1rem',
  [`& .MuiGrid-item`]: {
    height: "100%",
  },
}))

const Paper = styled(BasePaper)(() => ({
  height: '100%'
}))

const Frame = styled('iframe')(() => ({
  border: 'none'
}))

export default function Editor(): JSX.Element | null {
  const dispatch: any = useDispatch()

  useEffect(() => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})

    return () => dispatch({type: LoadingActionsTypes.LOADING, payload: false})
  })

  return (
    <Page container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Paper>
          <Frame
            onLoad={() => dispatch({type: LoadingActionsTypes.LOADING, payload: false})}
            src="http://localhost:9000"
            height="100%"
            width="100%"
          />
        </Paper>
      </Grid>
    </Page>
  )
}
