export interface IAlertState {
    id: number;
    type: "success" | "error" | "warning";
    title: string;
    message: string;
}
export interface IAlertsState {
    alerts: IAlertState[];
}
export interface IAlertAction {
    type: string,
    payload: IAlertState
}

export enum AlertActionsTypes {
    SUCCESS = 'ALERT_SUCCESS',
    WARNING = 'ALERT_WARNING',
    ERROR = 'ALERT_ERROR',
    CLEAR = 'ALERT_CLEAR',
    CLOSE = 'ALERT_CLOSE',

}