import {styled} from '@mui/material/styles';
import {Button as MUIButton} from "@mui/material";
import React from "react";

export const Submit = styled((props: any) => (
  <MUIButton
    {...props}
  />
))(({ theme }) => ({
    height: "32px",
    background: theme.palette.primary.main,
    "&:hover": {
        background: theme.palette.primary.dark,
        boxShadow: "none"
    },
    textTransform: "none",
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    boxShadow: "none",
    borderRadius: "2px",
    "&.MuiButton-sizeSmall": {
        height: "24px",
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "16px"
    }
}))