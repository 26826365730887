import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import TextField from "../../../App/components/Input/TextField";
import {Search} from "@mui/icons-material";
import Title from "../../../App/components/Table/Title";
import {useDebounce} from "use-debounce";
import {styled} from "@mui/material/styles";
import {Grid} from "@mui/material";
import {IRegistry} from "../../interfaces/registry";
import {RegistryActions} from "../../actions/registry";
import {View} from "./Buttons/View";
import {Inventory} from "./Buttons/Inventory";
import {Complete} from "./Buttons/Complete";
import {Equipments} from "./Equipments";
import {Surpluses} from "./Surpluses";
import {Add} from "./Equipment/Buttons/Surplus/Add";
import Scanner from "./Equipment/Buttons/Scanner";
import File from "./Buttons/File";

const PREFIX = 'Equipments'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')] : {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')] : {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

interface Props {
  id: number
}

export function Template(props: Props): JSX.Element | null {
  const {id} = props
  const [tab, setTab] = React.useState(0);
  const dispatch: any = useDispatch()

  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [registry, setRegistry] = useState<IRegistry>()
  const [update, setUpdate] = useState(false)

  const tabs: Array<{name: string, component: JSX.Element, actions: Array<JSX.Element>}> = [
    {
      name: 'Опись',
      component: <Equipments search={search} registry={registry} update={update} setUpdate={setUpdate} />,
      actions: [
        <Scanner
          registry={registry}
          onClick={(equipment) => {
            if (equipment) {
              setUpdate(false)
            }
          }}
        />
      ]
    },
    {
      name: 'Добавленное оборудование',
      component: <Surpluses search={search} registry={registry} update={update} setUpdate={setUpdate} />,
      actions: [
        <Add
          registry={registry}
          onClick={() => {
            setUpdate(false)
          }}
        />
      ]
    }
  ]

  useEffect(() => {
    if (!registry) {
      dispatch(RegistryActions.registry(id)).then((response: IRegistry) => {
        setRegistry(response)
      })
    }
    // eslint-disable-next-line
  }, [])

  return registry ? (
    <Content>
      <Title
        title={`№ ${registry.id}`}
        tab={{
          value: tab,
          onChange: (event: React.SyntheticEvent, value: number) => {
            setTab(value);
          },
          tabs: tabs.map((item, index) => ({label: item.name, value: index}))
        }}
        actions={[
          <Grid item>
            <TextField
              type="text"
              value={value}
              placeholder="Поиск: Номер"
              icon={<Search/>}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />
          </Grid>,
          <View
            registry={registry}
          />,
          <Inventory
            registry={registry}
            setRegistry={setRegistry}
          />,
          <Complete
            registry={registry}
            setRegistry={setRegistry}
          />,
          <File
            registry={registry}
            setRegistry={setRegistry}
          />,
          ...tabs[tab].actions
        ]}
      />
      {tabs[tab].component}
    </Content>
  ) : null
}