import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react"
import { Submit } from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import Registry from "../../Registry";

type InventoryProps = {
  data: {
    isSelectAll: boolean
    items: number[]
  }
  onClick?: () => void,
}

export function Create(props: InventoryProps): JSX.Element | null {
  const {onClick, data} = props;
  const {isSelectAll, items} = data;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return !mobile ? (
      <Access roles={['admin']}>
        <Grid item>
          <Submit
              disabled={(isSelectAll ? false : !items.length)}
              onClick={handleOpen}
              variant="contained"
              type="submit"
          >
            Сформировать инвентаризационную опись
          </Submit>
          {open ? <Registry equipments={items} isSelectAll={isSelectAll} open={open} handleClose={handleClose}
                            onClick={onClick}/> : null}
        </Grid>
      </Access>
  ) : null
}
