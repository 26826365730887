import { HttpService } from "../../App/services/http"
import { IRegistryInput } from "../interfaces/input/registry";
import { IFilter } from "../interfaces/filter";

export const RegistryService = {
  registries,
  registry,
  inventory,
  complete,
  file,
  update,
  create,
  delete: remove,
  filter
}

function registries(filter: IFilter) {
  return HttpService.get(`/registries`, filter)
    .then(
      response => {
        return response
      })
}

function registry(id: number) {
  return HttpService.get(`/registry/${id}`)
    .then(
      response => {
        return response
      })
}

function inventory(id: number) {
  return HttpService.put(`/registry/${id}/status/inventory`)
    .then(
      response => {
        return response
      })
}

function complete(id: number, data: FormData) {
  return HttpService.post(`/registry/${id}/status/complete`, data, true, true)
    .then(
      response => {
        return response
      })
}

function file(id: number, data: FormData) {
  return HttpService.post(`/registry/${id}/file`, data, true, true)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IRegistryInput) {
  return HttpService.patch(`/registry/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IRegistryInput) {
  return HttpService.post(`/registry`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/registry/${id}`)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/registries/filter", params)
    .then(
      response => {
        return response
      })
}