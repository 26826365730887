import { HttpService } from "../../App/services/http"
import { ICompanyInput } from "../interfaces/input/company";

export const CompanyService = {
  companies,
  company,
  update,
  create,
  delete: deleteCompany,
  versions,
  restore,
  addFile,
  deleteFile
}

function companies(filter: any) {
  return HttpService.get(`/companies`, filter)
    .then(
      response => {
        return response
      })
}

function company(id: number) {
  return HttpService.get(`/company/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: ICompanyInput) {
  return HttpService.patch(`/company/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: ICompanyInput) {
  return HttpService.post(`/company`, data)
    .then(
      response => {
        return response
      })
}

function deleteCompany(id: number) {
  return HttpService.delete(`/company/${id}`)
    .then(
      response => {
        return response
      })
}

function versions(guid: string) {
  return HttpService.get(`/company/${guid}/versions`)
  .then(
      response => {
        return response
      })
}
function restore(id: number) {
  return HttpService.post(`/company/version/${id}/restore`)
  .then(
      response => {
        return response
      })
}

function addFile(id: number, formData: FormData) {
  return HttpService.post(`/company/${id}/file`, formData, true, true)
  .then(
      response => {
        return response
      })
}

function deleteFile(id: number, fileId: number) {
  return HttpService.delete(`/company/${id}/file/${fileId}`)
  .then(
      response => {
        return response
      })
}