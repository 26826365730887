import { DeleteSweep } from "@mui/icons-material";
import { AddCircle } from "@mui/icons-material";
import { Close } from '@mui/icons-material';
import { InputBaseComponentProps } from "@mui/material";
import { Input } from "@mui/material";
import { Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton, } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FormikValues, useFormik } from "formik";
import React, { useEffect } from "react"
import Carousel from "react-material-ui-carousel";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Submit } from "../../App/components/Buttons/Submit";
import TextField from "../../App/components/Input/TextField"
import { DialogContent } from "../../App/components/Page/DialogContent";
import { getAction } from "../../App/helpers/action";
import { checkMaxSize } from "../../App/helpers/fileStorage";
import { transformData } from "../../App/helpers/transformData";
import { AlertActionsTypes } from "../../App/interfaces/alert";
import IResponseError from "../../App/interfaces/responseError";
import Access from "../../Auth/components/Access";
import { CompanyActions } from "../actions/company";
import { ICompany } from "../interfaces/company";
import { ICompanyFile } from "../interfaces/company/file";
import { ICompanyInput } from "../interfaces/input/company";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Kpp = styled(TextField)(() => ({
  '& .MuiFormLabel-root': {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000 !important"
  },
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

const CarouselGrid = styled(Grid)(() => ({
  width: "300px",
  height: "300px",
}))


const Image = styled('img')(() => ({
  width: "300px",
  borderRadius: "1%"
}))

const ImageButtons = styled(Grid)(() => ({
  background: "#eaeaea",
  marginLeft: "5px",
  padding: "5px",
  borderRadius: "2px"
}))

type Props = {
  open: boolean,
  company?: ICompany,
  handleClose: () => void,
  onClick?: (company: ICompany) => void,
}

export default function Company(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {company, onClick} = props
  const [open, setOpen] = React.useState<boolean>(false);
  const [imageFormData, setImageFormData] = React.useState<any>(null);
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
  const avatar = company?.files?.find(file => (file.type === 'avatar'));
  const imageExists = Boolean(avatar || selectedImage);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  const formik = useFormik({
    initialValues: {
      name: company?.name,
      shortName: company?.short_name,
      contacts: company?.contacts,
      inn: company?.inn,
      kpp: company?.kpp,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению'),
      shortName: Yup.string().required('Поле обязательно к заполнению'),
      inn: Yup.string()
      .matches(/^\d{10}$|^\d{12}$/, "ИНН должен быть длиной 10 или 12 символов")
      .required("ИНН обязателен для заполнения"),
      kpp: Yup.string().when("inn", (inn, schema) => inn.toString().length === 10 ? schema.required("Поле обязательно к заполнению").length(9, 'Длина КПП должна быть 9 символов!') : schema.nullable())
    }),
    onSubmit: (values: FormikValues, {setErrors}) => {
      const data = transformData(values)
      dispatch(company
          ? CompanyActions.update(company.id, (data as ICompanyInput))
          : CompanyActions.create(data as ICompanyInput)
      ).then(
          (res: ICompany) => {
            if (imageFormData && !company) {
              dispatch((getAction('company')).addFile(res.id, imageFormData)).then(
                  (file: ICompanyFile) => {
                    res.files = [file]
                    if (onClick) {
                      onClick(res)
                    }
                    handleClose()
                  }
              )
            } else {
              if (onClick) {
                onClick(res)
              }
              handleClose()
            }
          },
          (error: IResponseError) => setErrors(error.errors)
      )
    }
  });
  const avatarHandler = (event: InputBaseComponentProps) => {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList?.length) {
      const file = fileList[0];
      if (checkMaxSize(file, 15)) {
        dispatch({
          type: AlertActionsTypes.ERROR, payload: {
            message: 'Размер файла превышает 15 Мб.',
            type: 'error'
          }
        })
      } else {
        const formData = new FormData();
        formData.append('file', fileList[0]);
        formData.append('type', 'avatar');
        if (company) {
          dispatch((getAction('company')).addFile(company.id, formData)).then(
              (file: ICompanyFile) => {
                if (company.files) {
                  company.files.push(file);
                } else {
                  company.files = [file];
                }
                if (onClick) {
                  onClick(company)
                }
              }
          )
        } else {
          const file = fileList[0];
          const reader = new FileReader();

          reader.onload = function (e) {
            if (e.target) {
              setSelectedImage(e.target.result as string);
            }
          };

          reader.readAsDataURL(file);
          setImageFormData(formData)
        }
      }
    }
  }

  const avatarProps = {
    type: 'file',
    accept: ".jpg, .jpeg, .png",
    onChange: avatarHandler
  }

  const avatarDeleteHandler = () => {
    if (selectedImage) {
      setSelectedImage(null);
      setImageFormData(null);
    }
    if (avatar) {
      const fileId = avatar.id
      dispatch((getAction('company')).deleteFile(company?.id, fileId)).then(
          () => {
            if (company) {
              if (company?.files) {
                company.files = company.files.filter((file: ICompanyFile) => file.id !== fileId);
              } else {
                company.files = [];
              }
              if (onClick) {
                onClick(company)
              }
            }
          }
      )
    }
  }

  return open ? (
      <React.Fragment>
        <Dialog
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>{company?.id ? 'Редактировать' : 'Добавить'} организацию</Title>
                <IconButton
                    onClick={handleClose}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Item>
                  <TextField
                      required
                      formik={formik}
                      name="name"
                      label="Название"
                      type="text"
                  />
                </Item>
                <Item>
                  <TextField
                      required
                      formik={formik}
                      name="shortName"
                      label="Краткое название"
                      type="text"
                  />
                </Item>
                <Item>
                  <TextField
                      required
                      formik={formik}
                      name="contacts"
                      label="Контактные данные"
                      type="text"
                  />
                </Item>
                <Item>
                  <TextField
                      required
                      formik={formik}
                      name="inn"
                      label="ИНН"
                      type="string"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value
                        formik.setValues({
                          ...formik.values,
                          inn: value,
                          kpp: (value.length > 10) ? '' : formik.values.kpp
                        })
                      }}
                  />
                </Item>
                <Item>
                  <Kpp
                      required
                      disabled={(formik?.values?.inn?.toString().length === 12)}
                      formik={formik}
                      name="kpp"
                      label="КПП"
                      type="string"
                  />
                </Item>
              </Grid>
              <Grid container direction="row" justifyContent={"flex-start"} alignItems={"flex-start"}>
                <CarouselGrid id={"carousel"}>
                  <Carousel>
                    <Image
                        src={selectedImage ?? (avatar ? `${process.env.REACT_APP_API_URL}/company/${company?.id}/file/${avatar.id}` : `/avatar_not_found.png`)}
                        alt="Логотип"
                    />
                  </Carousel>
                </CarouselGrid>
                <Access roles={['admin', 'dispatcher']}>
                  <Grid item>
                    <ImageButtons container direction="column" justifyContent={"flex-start"} alignItems={"center"}>
                      {imageExists ? <IconButton onClick={avatarDeleteHandler}>
                        <DeleteSweep color={"primary"}/>
                      </IconButton> : <IconButton component="label">
                        <AddCircle color={"primary"}/>
                        <Input
                            style={{display: "none"}}
                            inputProps={avatarProps}
                        />
                      </IconButton>}
                    </ImageButtons>
                  </Grid>
                </Access>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                      variant="contained"
                      type="submit"
                      disabled={!formik.isValid || !formik.dirty}
                  >
                    {company ? 'Сохранить' : 'Создать'}
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
  ) : null
}
