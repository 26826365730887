import React, {useState} from "react";
import {
    Avatar,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Tooltip, useMediaQuery,
    useTheme
} from '@mui/material';
import {AccountCircle, Logout, Person} from '@mui/icons-material';
import {useSelector} from "react-redux";
import {IRootState} from "../../reducers/store";
import {Link} from "react-router-dom";
import {Menu} from "../Menu";
import {NavigateFunction, useNavigate} from "react-router";
import Access from "../../../Auth/components/Access";

type Props = {
    path: string
}

export default function Profile({path}: Props): JSX.Element | null {
    const {account} = useSelector((state: IRootState) => state.account)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate: NavigateFunction = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return account ? (
        <React.Fragment>
            <Tooltip title="Профиль">
                <IconButton
                    onClick={handleClick}
                >
                    <Avatar>
                        <AccountCircle/>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: '45px' }}
                keepMounted
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuList dense>
                    <MenuItem disabled={true}>
                        <ListItemText primary={account.name || account.login} secondary={account.position} />
                    </MenuItem>
                    <Divider />
                    {mobile ? (
                      <React.Fragment>
                          <MenuItem
                            selected={path.includes('/objects')}
                            onClick={() => {
                                handleClose()
                                navigate('/objects')
                            }}
                          >
                              <ListItemIcon />
                              <ListItemText>Объекты</ListItemText>
                          </MenuItem>
                          <Access roles={['admin', 'working_group', 'chairman']}>
                              <MenuItem
                                selected={path.includes('/inventory')}
                                onClick={() => {
                                    handleClose()
                                    navigate('/inventory')
                                }}
                              >
                                  <ListItemIcon />
                                  <ListItemText>Инвентаризация</ListItemText>
                              </MenuItem>
                          </Access>
                          <Access roles={['admin', 'dispatcher']}>
                              <MenuItem
                                  selected={path.includes('/history')}
                                  onClick={() => {
                                      handleClose()
                                      navigate('/history')
                                  }}
                              >
                                  <ListItemIcon />
                                  <ListItemText>История изменений</ListItemText>
                              </MenuItem>
                          </Access>
                          <Access roles={['admin']}>
                              <MenuItem
                                selected={path.includes('/administration')}
                                onClick={() => {
                                    handleClose()
                                    navigate('/administration')
                                }}
                              >
                                  <ListItemIcon />
                                  <ListItemText>Администрирование</ListItemText>
                              </MenuItem>
                          </Access>
                      </React.Fragment>
                    ) : null}
                    <MenuItem
                        selected={path === '/account/profile'}
                        onClick={() => {
                            handleClose()
                            navigate('/account/profile')
                        }}
                    >
                        <ListItemIcon>
                            <Person fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Профиль</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        component={Link}
                        to="/logout"
                        onClick={handleClose}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Выход</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </React.Fragment>
    ) : null
}
