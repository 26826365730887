import React from "react";
import {styled} from "@mui/material/styles";
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Divider} from "@mui/material";
import {cx} from "@emotion/css";

type Props = {
    to: any,
    className?: string,
    children?: React.ReactNode,
}

const PREFIX = 'Menu'

const classes = {
    divider: `${PREFIX}-divider`,
    divider_active: `${PREFIX}-divider-active`,
    breadcrumb_link: `${PREFIX}-breadcrumb-link`,
    breadcrumb_link_active: `${PREFIX}-breadcrumb-link-active`
}

const Root = styled('div')(({theme}) => ({
    height: "100%",
    [`& .${classes.divider}`]: {
        borderBottom: `4px solid  transparent`,
    },
    [`& .${classes.divider_active}`]: {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        [theme.breakpoints.down('md')]: {
            borderBottom: 'none',
        },
    },
    [`& .${classes.breadcrumb_link}`]: {
        height: "52px",
        display: "flex",
        color: "#495055",
        cursor: "pointer",
        alignItems: "center",
        textDecoration: "none",
    },
    [`& .${classes.breadcrumb_link_active}`]: {
        color: theme.palette.primary.main
    },
}))

export default function Link({children, to, ...props}: Props): JSX.Element {
    const location = useLocation();
    const match = '/' + location.pathname.split("/")[1] === to

    return (
        <Root>
            <Divider
                className={match ? classes.divider_active : classes.divider}
            />
            <RouterLink
                to={to}
                {...props}
                className={match ? cx(classes.breadcrumb_link, classes.breadcrumb_link_active) : classes.breadcrumb_link}
            >
                {children}
            </RouterLink>
        </Root>
    )
}