import {HttpService} from "../../App/services/http"
import {IFilter} from "../../Dictionaries/interfaces/filter";
import {IItemInput} from "../../Dictionaries/interfaces/input/item";

export const CompanySubdivisionService = {
  items,
  item,
  update,
  create,
  delete: remove,
  filter
}

function items(id: number, filter: IFilter) {
  return HttpService.get(`/company/${id}/subdivisions`, filter)
    .then(
      response => {
        return response
      })
}

function item(id: number, subdivision: number) {
  return HttpService.get(`/company/${id}/subdivision/${subdivision}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, subdivision: number, data: IItemInput) {
  return HttpService.put(`/company/${id}/subdivision/${subdivision}`, data)
    .then(
      response => {
        return response
      })
}

function create(id: number, data: IItemInput) {
  return HttpService.post(`/company/${id}/subdivision`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number, subdivision: number) {
  return HttpService.delete(`/company/${id}/subdivision/${subdivision}`)
    .then(
      response => {
        return response
      })
}

function filter(id: number, params: object) {
  return HttpService.get(`/company/${id}/subdivisions/filter`, params)
    .then(
      response => {
        return response
      })
}