import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {IRoomInput} from "../interfaces/input/room";
import {RoomService} from "../services/room";
import {Dispatch} from "../../App/reducers/store";
import {IFilter} from "../interfaces/filter";

export const RoomActions = {
  rooms,
  room,
  update,
  create,
  markup,
  delete: remove,
  addFile,
  deleteFile,
  versions,
  restore
}

function rooms(filter: IFilter) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.rooms(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function room(id: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.room(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function update(id: number, data: IRoomInput) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Помещение успешно обновлено',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function create(data: IRoomInput) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Помещение успешно добавлено',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function remove(id: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.delete(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Помещение успешно удалено',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function markup(id: number, layerId: string) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.markup(id, layerId)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Разметка успешно сохранена',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function addFile(id: number, formData: FormData) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.addFile(id, formData)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function deleteFile(id: number, fileId: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.deleteFile(id, fileId)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}
function versions(guid: string) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.versions(guid)
    .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
    )
  })
}
function restore(id: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return RoomService.restore(id)
    .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
    )
  })
}