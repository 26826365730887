import React from 'react'
import {useSelector} from "react-redux"
import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui"
import {Filter} from "./Filter";
import {IRootState} from "../../reducers/store";
import {Values} from "./Filter/Values";

type Props = {
  column: any
  children: any
  table: 'accounts' | 'companies' | 'landStatuses'
  filterActionType: string
  getValues: any
  filters: any
  empty: boolean
  button: any
}

export const TableHeader = (props: Props) => {
  const {
    column,
    children,
    table,
    filterActionType,
    getValues,
    filters,
    empty,
    ...restProps
  } = props

  const {filter} = useSelector((state: IRootState) => state.filters[table])

  let button = props.button ?? null;
  let type: any = null
  let name = ""
  let active = false

  if (filters && !button && !empty) {
    switch (filters[column.name]?.type) {
      case 'values':
        type = (props: any) => <Values {...props} values={filters[column.name].values} getValues={getValues} title={column}/>
        name = filters[column.name].name
        active = Boolean(((name in filter) && !!filter[name].length))
        break;
      default:
        break;
    }

    button = <Filter
      filter={filter}
      active={((filter.sort.name === column.name) || active)}
      name={column.name}
      order={filters[column.name]?.sort}
      {...(type ? {Content: type} : null)}
      filterActionType={filterActionType}
    />
  }

  if (empty) {
    button = null
  }

  return (
    <TableHeaderRow.Content
      column={column}
      {...restProps}
      style={{
        justifyContent: 'space-between',
        whiteSpace: "normal"
      }}
      align={column.align ?? 'left'}
    >
      {children}
      {button}
    </TableHeaderRow.Content>
  )
}
