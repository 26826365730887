import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IFloor} from "../../../../Realty/interfaces/floor";
import {FloorActions} from "../../../../Statistic/actions/floor";
import PieChart from "../../../../Statistic/components/PieChart";
import {Grid, GridProps} from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props extends GridProps {
  floor: IFloor
}

export default function WorkloadType(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {floor} = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<any>>([])
  const types = [
    {id: 1, name: 'Занято', color: "#ed1c24"},
    {id: 2, name: 'Свободно', color: "#22b14c"}
  ]

  useEffect(() => {
    const getRooms = async () => {
      return await dispatch(FloorActions.workload(floor.id))
    }

    if (!loading) {
      getRooms().then((response: {data: Array<{count: number, occupied: number}>}) => {
        setData(response.data.length ? Object.entries(Object.entries(response.data).reduce((obj, [, item]) => {
          return {
            ...obj,
            'Занято': obj['Занято'] + item.occupied,
            'Свободно': obj['Свободно'] + (item.count - item.occupied)
          }
        }, {
          'Занято': 0,
          'Свободно': 0
        })).map(([name, value])=> ({name: name, value: value})) : [])
        setLoading(true)
      })
    }
  }, [dispatch, floor.id, loading])

  useEffect(() => {
    setLoading(false)
  }, [floor])

  return data.length ? (
    <Grid item {...props}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Typography>Количество свободных мест этажа</Typography>
        <PieChart data={data} types={types} />
      </Grid>
    </Grid>
  ) : null
}