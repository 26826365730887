import React from "react"
import {Submit} from "../../../../../../App/components/Buttons/Submit";
import Access from "../../../../../../Auth/components/Access";
import Grid from "@mui/material/Grid";
import {IRegistry} from "../../../../../interfaces/registry";
import Surplus from "../../Surplus";
import {IconButton, useMediaQuery, useTheme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

type Props = {
  registry?: IRegistry,
  onClick?: () => void,
}

export function Add(props: Props): JSX.Element | null {
  const { registry, onClick } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return registry ? (
    <Access roles={['working_group', 'chairman']}>
      <Grid item>
        {mobile ? (
          <IconButton
            sx={{
              padding: "3px"
            }}
            size="small"
            onClick={handleOpen}
          >
            <AddIcon />
          </IconButton>
        ) : (
          <Submit
            onClick={handleOpen}
            variant="contained"
            type="submit"
          >
            Добавить предложение
          </Submit>
        )}
        {open ? <Surplus registry={registry} open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  ) : null
}
