import {HttpService} from "../../App/services/http"
import {IFilter} from "../interfaces/filter";
import {IItemInput} from "../interfaces/input/item";

export const EquipmentTypeService = {
  items,
  item,
  update,
  create,
  delete: remove,
  filter
}

function items(filter: IFilter) {
  return HttpService.get(`/dictionary/equipment/types`, filter)
    .then(
      response => {
        return response
      })
}

function item(id: number) {
  return HttpService.get(`/dictionary/equipment/type/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IItemInput) {
  return HttpService.put(`/dictionary/equipment/type/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IItemInput) {
  return HttpService.post(`/dictionary/equipment/type`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/dictionary/equipment/type/${id}`)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/dictionary/equipment/types/filter", params)
    .then(
      response => {
        return response
      })
}