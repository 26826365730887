import {styled} from '@mui/material/styles';
import React from "react";
import {MuiColorInput} from "mui-color-input";

const CustomMuiColorInput = styled(MuiColorInput)({
  width: "100%",
  '& .MuiFormLabel-root': {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000 !important",
    '&.Mui-disabled': {
      marginLeft: "0px",
      paddingLeft: "0px",
      marginTop: "0px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px",
    }
  },
  '& .MuiInputBase-input': {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    marginRight: "3px",
    marginLeft: "3px",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  '& .MuiInputBase-root': {
    height: "32px",
    fontFamily: "Open Sans",
    border: "1px solid #E5E5E5",
    borderRadius: "2px",
    '&:before': {
      display: 'contents'
    },
    '&:after': {
      display: 'contents'
    }
  },
  '& .MuiInputAdornment-root': {
    '& .MuiButtonBase-root' : {
      borderRadius: '2px'
    }
  }
});

export default function Color(props: { label: string, value: string, onChange: (value: string) => void }): JSX.Element {
  const {label, value, onChange} = props

  return <CustomMuiColorInput
    size="small"
    variant="standard"
    format="hex"
    label={label}
    value={value}
    onChange={onChange}
  />
}