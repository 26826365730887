import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import {Grid, Tooltip, Typography} from "@mui/material";
import {NavigateFunction, useNavigate, useParams} from "react-router";
import {LandActions as RealtyLandActions} from "../../../Realty/actions/land";
import {Paper} from "../../../App/components/Paper";
import {Shape} from "../../../Editor/Canvas";
import {Path as BasePath, SVG} from "../../../Editor/components/Canvas/Params";
import {BuildingActions} from "../../../Realty/actions/building";
import {IBuilding} from "../../../Realty/interfaces/building";
import Param from "../../../Account/components/Profile/Param";
import Type from "../Building/Statistic/Type";

const PREFIX = 'Viewer'

const classes = {
  block: `${PREFIX}-block`,
  menu: `${PREFIX}-menu`,
  mask: `${PREFIX}-mask`,
  active: `${PREFIX}-active`
}

const Content = styled(Grid)(() => ({
  width: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.block}`]: {
    display: 'grid',
    width: '100%',
    height: 'calc(100vh - 172px)'
  },
  [`& .${classes.menu}`]: {
    overflow: "auto",
  }
}))

const Menu = styled(Grid)(() => ({
  width: "100%",
  overflow: "hidden",
  position: "relative",
  padding: "12px"
}))

const Path = styled(BasePath)(() => ({
  stroke: "white",
  strokeWidth: '1px',
  strokeOpacity: 0.7,
  "&:hover": {
    strokeOpacity: 1,
    strokeWidth: '2px',
    fillOpacity: 0,
    cursor: 'pointer'
  },
  [`&.${classes.active}`]: {
    strokeOpacity: 1,
    strokeWidth: '2px',
    fillOpacity: 0,
    cursor: 'pointer'
  }
}))

const Rect = styled('rect')(() => ({
  fillOpacity: 0,
  transitionProperty: 'fill-opacity',
  transitionTimingFunction: 'ease',
  transitionDuration: '.3s'
}))

export function Viewer(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();

  const params = useParams()
  const [land, setLand]: any = useState(null)
  const [size, setSize] = useState({width: 0, height: 0});
  const [opacity, setOpacity] = useState(0);
  const [object, setObject] = useState<number | null>(null);
  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [id, setId] = useState<number | null>(null);

  const [loading, setLoading] = useState(false)
  const [initialize, setInitialize] = useState(false)

  useEffect(() => {
    const getData = async (id: number) => {
      return await dispatch(RealtyLandActions.markup(id))
    }

    if (!loading && params.id) {
      setInitialize(false)
      setBuilding(null)
      setSize({width: 0, height: 0})
      setOpacity(0)
      getData(Number(params.id)).then(response => {
        setLoading(true)
        setLand(response)
      })
    }
  }, [dispatch, loading, params.id])

  useEffect(() => {
    const getBuilding = async (id: number) => {
      return await dispatch(BuildingActions.building(id))
    }

    if (id) {
      getBuilding(id).then(response => {
        setBuilding(response)
      })
    } else {
      setBuilding(null)
    }
  }, [dispatch, id])

  useEffect(() => {
      setLoading(false)
  }, [params.id])

  const getPaths = () => {
    let data: {[key: number]: { markup: boolean, name?: string, shapes: Array<any> }} = {}
    for (const key in land.shapes) {
      const value: Shape = land.shapes[key]
      data = {
        ...data,
        [value.object.id]: {
          markup: !!value.object?.markup,
          name: value.object?.name,
          shapes: [
            ...(data[value.object.id]?.shapes ?? []),
            {looped: value.looped, points: value.points}
          ]
        }
      }
    }
    return data
  }

  const generatePath = (shapes: Array<Shape>) => {
    return shapes.reduce((d: string, shape) => {
      shape.points.forEach((p: any, i: any) => {
        if (i === 0) {
          d += d.length ? " M " : "M "
        } else {
          d += "L "
        }

        d += `${ p.x } ${ p.y } `
      })

      if (shape.looped && d) d += "Z"

      return d
    }, '')
  }

  const getImage = (image: any) => {
    const img = new Image();

    img.src = image

    img.onload = function () {
      if (!initialize) {
        setInitialize(true)
        setSize({width: img.naturalWidth, height: img.naturalHeight})
      }
    };

    return image
  }

  return loading ? (
    <Content>
      <Paper>
        <Grid container justifyContent="stretch" alignItems="stretch" direction="row">
          <Grid item xs={9} className={classes.block}>
            {!!land.image ? <SVG
              width="100%"
              height="100%"
              viewBox={`0 0 ${size.width} ${size.height}`}
              onClick={() => { setId(null) }}
            >
              <image href={getImage(land.image)} />
              <Rect width="100%" height="100%" style={{ fillOpacity: opacity }} mask={`url(#mask)`} />
              {Object.entries(getPaths()).map(([key, item]) => (
                <Tooltip key={key} title={item.name} followCursor>
                  <Path
                    className={(building?.id === Number(key)) ? classes.active : undefined}
                    onMouseEnter={() => {
                      setObject(Number(key))
                      setOpacity(0.2)
                    }}
                    onMouseLeave={() => {
                      setObject(null)
                      setOpacity(0)
                    }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setId(Number(key))
                    }}
                    onDoubleClick={() => {
                      navigate(`building/${key}?section=scheme`)
                    }}
                    d={generatePath(item.shapes)}
                  />
                </Tooltip>
              ))}
              <defs>
                <mask id="mask">
                  <rect fill="white" width="100%" height="100%" />
                  {Object.entries(getPaths()).map(([key,item]) => (
                    <Path
                      key={key}
                      d={generatePath(item.shapes)}
                      style={{fill: (object === Number(key)) ? 'black' : 'white'}}
                    />
                  ))}
                </mask>
              </defs>
            </SVG> : <Typography style={{textAlign: "center", fontSize: "18px", color: "#6E7880", marginTop: "25%"}}>Карта/схема не составлена</Typography>}
          </Grid>
          {building ? (
            <Grid item xs={3} className={classes.block} style={{position: 'absolute', right: '0'}}>
              <Paper className={classes.menu}>
                <Menu>
                  {[
                    {name: 'Корпус', value: building.name},
                    {name: 'Адрес', value: building.address},
                    {name: 'Тип здания', value: building.type?.name},
                    {name: 'Год первоначального ввода в эксплуатацию', value: building.initialYear?.toString()},
                    {name: 'Балансовая стоимость здания', value: building.price?.toString()},
                    {name: 'Год последнего капитального ремонта', value: building.lastOverhaulYear?.toString()},
                  ].map((item, key: number) => (
                    <Param
                      key={key}
                      {...item}
                    />
                  ))}
                </Menu>
                <Type building={building} />
                <Menu>
                  {[
                    {name: 'Тип аренды', value: building.rentalType?.name},
                    {name: 'Площадь здания кв.м', value: building.square?.toString()},
                    {name: 'Количество этажей (в том числе подземных)', value: building._count?.floors.toString()},
                    {name: 'Парковка, количество мест', value: building.parking?.toString()},
                    {name: 'Имеет видеонаблюдение', value: building.equippedVideo ? 'Да' : 'Нет'},
                    {name: 'Имеет охрану', value: building.equippedSecurity ? 'Да' : 'Нет'},
                    {name: 'Доступно для маломобильных групп населения', value: building.accessiblePeopleLimitedMobility ? 'Да' : 'Нет'},
                    {name: 'Памятник культурного наследия', value: building.culturalHeritageMonument ? 'Да' : 'Нет'},
                  ].map((item, key: number) => (
                    <Param
                      key={key}
                      {...item}
                    />
                  ))}
                </Menu>
              </Paper>
            </Grid>
          ) : <Grid item xs={3} className={classes.block} style={{position: 'absolute', right: '0'}}>
            <Paper className={classes.menu}>
              <Menu>
                {[
                  {name: 'Наименование', value: land.name},
                  {name: 'Кадастровый номер', value: land.cadastralNumber},
                  {name: 'Компания', value: land.company?.name},
                  {name: 'Статус объекта', value: land.status?.name},
                  {name: 'Категория земель', value: land.category?.name},
                  {name: 'Вид разрешенного использования', value: land.permission?.name},
                  {name: 'Кадастровая стоимость', value: land.cadastralPrice?.toString()},
                  {name: 'Обременения', value: land.encumbrances},
                  {name: 'Площадь, кв.м', value: land.square?.toString()},
                  {name: 'Адрес', value: land.address},
                  {name: 'Ответственный', value: land.responsible?.name},
                ].map((item, key: number) => (
                  <Param
                    key={key}
                    {...item}
                  />
                ))}
              </Menu>
            </Paper>
          </Grid>}
        </Grid>
      </Paper>
    </Content>
  ) : null
}