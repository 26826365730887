import {styled} from "@mui/material/styles";
import {Paper as MUIPaper} from '@mui/material';

export const Paper = styled(MUIPaper)(() => ({
    '&.MuiPaper-root': {
        borderRadius: 2,
        boxShadow: '0px 0px 0px 1px rgba(219, 222, 223, 0.44)'
    },
}))
export const TablePaper = styled(MUIPaper)(() => ({
    overflow: "hidden",
    '&.MuiPaper-root': {
        borderRadius: 2,
        boxShadow: '0px 0px 0px 1px rgba(219, 222, 223, 0.44)'
    },
}))