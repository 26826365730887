import React from "react"
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import Access from "../../../../Auth/components/Access";
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { CompanyActions } from "../../../../Company/actions/company";
import { BuildingActions } from "../../../../Realty/actions/building";
import { EquipmentActions } from "../../../../Realty/actions/equipment";
import { FloorActions } from "../../../../Realty/actions/floor";
import { LandActions } from "../../../../Realty/actions/land";
import { RoomActions } from "../../../../Realty/actions/room";

type Props = {
  id: number
  objectTypeId: number
  setLoading: (val: boolean) => void
};


export default function Restore(props: Props): JSX.Element {
  const dispatch: any = useDispatch()
  const {id, objectTypeId, setLoading} = props;

  const handleOpen = () => {
    let action;
    switch (objectTypeId) {
      case 1:
        action = CompanyActions.restore(id);
        break;
      case 2:
        action = LandActions.restore(id);
        break;
      case 3:
        action = BuildingActions.restore(id);
        break;
      case 4:
        action = FloorActions.restore(id);
        break;
      case 5:
        action = RoomActions.restore(id);
        break;
      case 6:
        action = EquipmentActions.restore(id);
        break;
    }
    dispatch(action).then((): void => {
      setLoading(false);
    })
  };


  return (
      <Access roles={['admin']}>
        <React.Fragment>
          <IconButton onClick={handleOpen}>
            <RestorePageIcon/>
          </IconButton>
        </React.Fragment>
      </Access>
  );
}