import {useParams} from "react-router";
import Equipments from "./Equipments";
import {Template as Registry} from "./components/Registry/Template";
import Registries from "./Registries";
import Equipment from "../Object/components/Equipment/Equipment";

export default function Inventory(): JSX.Element | null {
  const params = useParams();

  const getPage = (): JSX.Element | null => {
    switch (params?.section) {
      case 'registry':
        return <Registry id={parseInt(params.id!)} />;
      case 'registries':
        return <Registries />;
      case 'equipments':
        return <Equipments />;
      case 'equipment':
        return <Equipment id={parseInt(params.id!)} />;
      default:
        return null;
    }
  }

  return getPage();
}