import React from "react"
import {Submit} from "../../../../../../App/components/Buttons/Submit";
import Access from "../../../../../../Auth/components/Access";
import Grid from "@mui/material/Grid";
import {IRegistry} from "../../../../../interfaces/registry";
import Equipment from "../../../../../../Realty/components/Equipment";
import {IEquipment} from "../../../../../../Realty/interfaces/equipment";

type Props = {
  registry: IRegistry,
  disabled: boolean
  onClick?: (equipment: IEquipment) => void,
}

export function Create(props: Props): JSX.Element | null {
  const { registry, disabled, onClick } = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return registry ? (
    <Access roles={['working_group', 'chairman']}>
      <Grid item>
        <Submit
          disabled={disabled}
          onClick={handleOpen}
          variant="contained"
          type="button"
        >
          Добавить МТО
        </Submit>
        {open ? <Equipment registry={registry} open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  ) : null
}
