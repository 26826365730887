import {FiltersActionsTypes, IFiltersAction, IFiltersState} from "../interfaces/filter";

const defaultFilter = {
  filter: {
    sort: {
      name: null,
      direction: 'desc'
    },
    skip: 0,
    take: 10,
  }
}

const initialState: IFiltersState = {
  accounts: defaultFilter,
  companies: defaultFilter,
  companySubdivisions: defaultFilter,
  companyEmployees: defaultFilter,
  companyPositions: defaultFilter,
  lands: defaultFilter,
  buildings: defaultFilter,
  floors: defaultFilter,
  rooms: defaultFilter,
  equipments: defaultFilter,
  registries: defaultFilter,
  registryEquipments: defaultFilter,
  landStatuses: defaultFilter,
  landCategories: defaultFilter,
  landPermissions: defaultFilter,
  buildingTypes: defaultFilter,
  floorTypes: defaultFilter,
  roomTypes: defaultFilter,
  equipmentTypes: defaultFilter,
  equipmentStatuses: defaultFilter,
  equipmentPurposes: defaultFilter,
  inventoryFunctions: defaultFilter,
  units: defaultFilter,
  rentalTypes: defaultFilter,
  roomPeople: defaultFilter,
  versions: defaultFilter
}

export const FiltersReducer = (state: IFiltersState = initialState, action: IFiltersAction): IFiltersState => {
  let key = '';
  switch (action.type) {
    case FiltersActionsTypes.ACCOUNTS:
    case FiltersActionsTypes.COMPANIES:
    case FiltersActionsTypes.COMPANY_SUBDIVISIONS:
    case FiltersActionsTypes.COMPANY_EMPLOYEES:
    case FiltersActionsTypes.COMPANY_POSITIONS:
    case FiltersActionsTypes.LANDS:
    case FiltersActionsTypes.BUILDINGS:
    case FiltersActionsTypes.FLOORS:
    case FiltersActionsTypes.ROOMS:
    case FiltersActionsTypes.EQUIPMENTS:
    case FiltersActionsTypes.REGISTRIES:
    case FiltersActionsTypes.REGISTRY_EQUIPMENTS:
    case FiltersActionsTypes.LAND_STATUSES:
    case FiltersActionsTypes.LAND_CATEGORIES:
    case FiltersActionsTypes.LAND_PERMISSIONS:
    case FiltersActionsTypes.BUILDING_TYPES:
    case FiltersActionsTypes.FLOOR_TYPES:
    case FiltersActionsTypes.ROOM_TYPES:
    case FiltersActionsTypes.EQUIPMENT_TYPES:
    case FiltersActionsTypes.EQUIPMENT_STATUSES:
    case FiltersActionsTypes.EQUIPMENT_PURPOSES:
    case FiltersActionsTypes.INVENTORY_FUNCTIONS:
    case FiltersActionsTypes.UNITS:
    case FiltersActionsTypes.RENTAL_TYPES:
    case FiltersActionsTypes.ROOM_PEOPLE:
    case FiltersActionsTypes.VERSIONS:
      key = action.type.replace('_FILTER', '').toLowerCase();
      if (key.includes('_')) {
        const items = key.split('_');
        key = items[0] + items[1].charAt(0).toUpperCase() + items[1].slice(1);
      }

      return {
        ...state,
        ...{
          [key]: {
            filter: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ...state[key].filter,
              ...action.payload
            }
          }
        }
      }
    default:
      return state
  }
}