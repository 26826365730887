import Actions from "../../App/interfaces/actions";
import {AccountActionsTypes, IAccountState} from "../interfaces/account";

const init: IAccountState = {
  account: null
};

export function AccountReducer(state: IAccountState = init, action: Actions): IAccountState {
  switch (action.type) {
    case AccountActionsTypes.FETCH_ACCOUNT:
      return {
        ...state,
          ...{
            account: action.payload
          }
        };
      default:
        return state;
    }
}