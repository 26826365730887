import {HttpService} from "../../App/services/http"
import {IFilter} from "../interfaces/employee/filter";
import {IEmployeeInput} from "../interfaces/input/employee";
import {IRoomEmployeeInput} from "../../Realty/interfaces/input/room/employee";

export const CompanyEmployeeService = {
  items,
  item,
  update,
  room,
  create,
  delete: remove,
  filter
}

function items(id: number, filter: IFilter) {
  return HttpService.get(`/company/${id}/employees`, filter)
    .then(
      response => {
        return response
      })
}

function item(id: number, employee: number) {
  return HttpService.get(`/company/${id}/employee/${employee}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, employee: number, data: IEmployeeInput) {
  return HttpService.put(`/company/${id}/employee/${employee}`, data)
    .then(
      response => {
        return response
      })
}

function room(id: number, employee: number, data: IRoomEmployeeInput) {
  return HttpService.put(`/company/${id}/employee/${employee}/room`, data)
    .then(
      response => {
        return response
      })
}

function create(id: number, data: IEmployeeInput) {
  return HttpService.post(`/company/${id}/employee`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number, employee: number) {
  return HttpService.delete(`/company/${id}/employee/${employee}`)
    .then(
      response => {
        return response
      })
}

function filter(id: number, params: object) {
  return HttpService.get(`/company/${id}/employees/filter`, params)
    .then(
      response => {
        return response
      })
}