import React from "react"
import {QrCodeScanner as QrCodeScannerIcon} from "@mui/icons-material";
import {IconButton as MUIIconButton, useMediaQuery, useTheme} from "@mui/material";

import isRole from "../../../../../Auth/hooks/authorize";
import {useSelector} from "react-redux";
import {IRootState} from "../../../../../App/reducers/store";
import {IRegistry} from "../../../../interfaces/registry";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Reader from "../Reader";
import {IEquipment} from "../../../../../Realty/interfaces/equipment";

const IconButton = styled(MUIIconButton)(() => ({
  padding: "2px",
}))

type Props = {
  registry?: IRegistry,
  onClick?: (equipment?: IEquipment) => void
}

export default function Scanner(props: Props): JSX.Element | null {
  const { registry, onClick } = props
  const {account} = useSelector((state: IRootState) => state.account)
  const [isWorkingGroup] = isRole(account, 'working_group');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (equipment?: IEquipment) => {
    setOpen(false);
    if (onClick) {
      onClick(equipment)
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isAccess = (mobile && ((registry?.status?.key === 'during') && isWorkingGroup))

  return isAccess ? (
    <Grid item>
      <IconButton
        size="small"
        onClick={handleOpen}
      >
        <QrCodeScannerIcon />
      </IconButton>
      {(registry && open) ? <Reader registry={registry} open={open} handleClose={handleClose} /> : null}
    </Grid>
  ) : null;
}
