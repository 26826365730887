import axios, { AxiosError, AxiosResponse, RawAxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";
import * as AppStorage from '../helpers/storage';

export const HttpService = {
  http,
  get,
  post,
  patch,
  put,
  delete: remove,
  getToken
}

interface IToken {
  account: {
    id: number,
  },
  iat: number,
  exp: number,
}

interface IErrorResponse  extends  AxiosResponse {
  data: {
    error: {
      message: string,
      errors: string[]
    }
  }
}

interface IError extends AxiosError{
  response: IErrorResponse
}

async function http(location: string, options: RawAxiosRequestConfig, authorization = true, formData = false) {
  let token: string | null = await AppStorage.get('token.access')

  if (authorization && token) {
    token = await getToken()
  }

  const config: RawAxiosRequestConfig = {
    url: process.env.REACT_APP_API_URL + location,
    headers: {
      'Accept': 'application/json',
      ...(!formData ? {"Content-Type": 'application/json'} : {}),
      ...(authorization && token ? {Authorization: `Bearer ${token}`} : {})
    },
    ...options
  }

  return axios.request(config).then(
    (response) => handleResponse(response, options),
    (error: IError) => handleError(error));
}

async function getToken(): Promise<string | null> {
  const accessToken: string | null = await AppStorage.get('token.access')
  if(accessToken){
    const refreshToken: string | null = AppStorage.get('token.refresh')
    const tokenDecode: IToken = jwt_decode(accessToken)
    if ((tokenDecode.exp - Math.floor(Date.now() / 1000)) <= 0) {
      return await HttpService.post('/token/refresh', {token: refreshToken}, false)
        .then(
          async response => {
            await AppStorage.set('token.access', response?.access)
            return response?.access
          },
          async () => {
            await AppStorage.clear()
            window.location.href = "/login"
          }
        )
    }
  }
  return accessToken
}

function handleError(response: IError) {
    if (response.response) {
        throw response.response.data?.error ?? response.response.data
    }
    throw response
}

function handleResponse(response: AxiosResponse, options: RawAxiosRequestConfig) {
  let data

  switch (response.status) {
    case 204:
      return response
    case 304:
      return response
    case 401:
      AppStorage.clear()
      window.location.href = "/login"
      break
    case 409:
    case 422:
      return response.data.error
    default:
      if (options['responseType']) {
        switch (options.responseType) {
          case 'blob':
            data = new Blob([response.data])
            break
          default:
            data = response.data
        }
      } else {
        data = response.data
      }
  }
  if (!response.status.toString().startsWith('2')) {
    return data ? data.then(Promise.reject.bind(Promise)) : response
  }

  return data
}

function post(location: string, values?: object, authorization = true, formData = false, responseType: 'blob' | null = null) {
  const options: RawAxiosRequestConfig = {
    ...{
      method: "POST",
      data: formData ? values : JSON.stringify(values)
    },
    ...(responseType ? {responseType: responseType} : {})
  }
  return HttpService.http(location, options, authorization, formData)
    .then(response => {
      return response
    })
}

function patch(location: string, values?: object, authorization = true) {
  const options: RawAxiosRequestConfig = {
    method: "PATCH",
    data: JSON.stringify(values)
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

function put(location: string, values?: object, authorization = true): Promise<AxiosResponse> {
  const options: RawAxiosRequestConfig = {
    method: "PUT",
    data: JSON.stringify(values)
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

function get(location: string, params = {}, authorization = true, responseType = null) {
  const options: RawAxiosRequestConfig = {
    ...{
      method: "GET",
      params: params
    },
    ...(responseType ? {responseType: responseType} : {})
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

function remove(location: string, params = {}, authorization = true, key = 'body') {
  const options: RawAxiosRequestConfig = {
    method: "DELETE",
    [key]: (key === 'body') ? JSON.stringify(params) : params,
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}
