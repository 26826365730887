import * as React from 'react';
import {useEffect} from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import {TreeView} from '@mui/lab';
import {ILand} from "../../../Realty/interfaces/land";
import {BuildingActions} from "../../../Realty/actions/building";
import {useDispatch} from "react-redux";
import {IBuilding} from "../../../Realty/interfaces/building";
import {Building} from "./Building";
import {useParams} from "react-router";
import {LandActionsTypes} from "../../interfaces/land";

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

type Props = {
  land: ILand
}

export function Buildings(props: Props) {
  const dispatch: any = useDispatch();
  const params = useParams();

  const {land} = props
  const [expanded, setExpanded] = React.useState<Array<string>>([]);
  const [selected, setSelected] = React.useState<Array<string>>([]);

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    const getBuildings = async () => {
      return await dispatch(BuildingActions.buildings({landId: land.id, take: 20, include: 'floors.rooms'}))
    }

    setExpanded([])
    setSelected([])

    getBuildings().then(response => {
      dispatch({type: LandActionsTypes.LAND_BUILDINGS, payload: response.data})
    })
    // eslint-disable-next-line
  }, [land.id])

  useEffect(() => {
    dispatch({type: LandActionsTypes.LAND_MENU_STATE, payload: params})

    if (params.room) {
      setSelected([`room_${params.room}`])
    } else if (params.floor) {
      setSelected([`floor_${params.floor}`])
    } else if (params.building) {
      setSelected([`building_${params.building}`])
    } else {
      setSelected([])
    }
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    if (land) {
      if (params.room) {

        land.buildings?.every((building: IBuilding) => {
          const floor = building.floors?.find(floor => floor.rooms?.find(room => room.id === Number(params.room)));

          if (floor) {
            setExpanded([...expanded, ...[`building_${building.id}`, `floor_${floor.id}`]]);

            return false;
          }

          return true;
        });
      } else if (params.floor) {
        const build = land.buildings?.find((building: IBuilding) =>
          building.floors?.find(floor => floor.id === Number(params.floor))
        )

        if (build) {
          setExpanded([...expanded, `building_${build.id}`])
        }
      }
    }
    // eslint-disable-next-line
  }, [params, land])

  return (
    <TreeView
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      expanded={expanded}
      selected={selected}
      onNodeSelect={handleSelect}
    >
      {land.buildings?.map((building: IBuilding, index) =>
        <Building
          key={index}
          building={building}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )}
    </TreeView>
  );
}