import {Autocomplete as MUIAutocomplete} from "@mui/material";
import React from "react";
import TextField from "./TextField";

type Props = {
  name: string,
  free?: boolean,
  label: string,
  options?: any[],
  setValue: any,
  value: any,
  multiple?: boolean,
  getOptionLabel: any,
  required?: boolean,
  disableClearable?: any,
  disabled?: boolean,
  formik?: any
}

export function Autocomplete(props: Props): JSX.Element | null {
  const {
    name,
    free = false,
    label,
    options = [],
    setValue,
    value,
    multiple = false,
    getOptionLabel,
    required,
    disableClearable,
    disabled,
    formik
  } = props

  return <MUIAutocomplete
    freeSolo={free}
    options={options}
    getOptionLabel={getOptionLabel}
    filterSelectedOptions
    disableClearable={disableClearable}
    noOptionsText={"Нет данных"}
    onChange={(e, values: any | null) => {
      setValue(name, values ?? null)
    }}
    disabled={disabled}
    value={value}
    multiple={multiple}
    renderInput={params => <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        ...{
          disableUnderline: !(formik?.touched?.[name] && formik?.errors?.[name]),
        }
      }}
      required={required}
      label={label}
      name={name}
      formik={formik}
    />}
  />
}