import React from "react"
import Typography from "@mui/material/Typography";
import {styled} from '@mui/material/styles'
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";

const PREFIX = 'NotFoundPage'

const classes = {
    container: `${PREFIX}-container`,
    bold: `${PREFIX}-bold`,
    info: `${PREFIX}-info`,
    link: `${PREFIX}-link`,
}

const Root = styled('div')(({theme}) => ({
    [`&.${classes.container}`]: {
        width: "100%",
        height: "100%",
    },
    [`& .${classes.bold}`]: {
        marginTop: "15%",
        fontSize: "44px",
        fontWeight: 900,
        lineHeight: 1
    },
    [`& .${classes.info}`]: {
        marginTop: "15px",
        color: "gray"
    },
    [`& .${classes.link}`]: {
        marginTop: "15px",
        background: theme.palette.primary.main,
        color: "white",
        textDecoration: "none",
        padding: "12px",
        borderRadius: "3px",
        transition: "0.5s",
        "&:hover": {
            background: "#3e79e5",
        }
    },
}))

export default function NotFoundPage(): JSX.Element {
    return (
        <Root>
            <Stack className={classes.container} direction="column" alignItems="center" justifyContent="center">
                <Typography className={classes.bold}>Что-то пошло не так</Typography>
                <Typography className={classes.info}>Запрашиваемая вами страница не существует, либо была
                    удалена</Typography>
                <Link className={classes.link} to="/">Перейти на главную</Link>
            </Stack>
        </Root>
    )
}
