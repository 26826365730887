import Actions from "../../App/interfaces/actions";
import {ILandState, LandActionsTypes} from "../interfaces/land";

const init: ILandState = {
  land: null,
  state: null
};

export function LandReducer(state: ILandState = init, action: Actions): ILandState {
  switch (action.type) {
    case LandActionsTypes.LAND_MENU_STATE:
      return {
        ...state,
        ...{
          state: action.payload
        }
      };
    case LandActionsTypes.LAND:
      return {
        ...state,
        ...{
          land: action.payload,
          state: null
        }
      };
    case LandActionsTypes.LAND_BUILDINGS:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            ...{buildings: action.payload}
          }
        } : {})
      };
    case LandActionsTypes.LAND_BUILDING:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            buildings: state.land.buildings?.find(building => (building.id === action.payload.id)) ? state.land.buildings?.map((building) => {
              if (building.id === action.payload.id) {
                return {
                  ...action.payload,
                  floors: building.floors
                }
              }

              return building
            }) : [
              action.payload,
              ...(state.land.buildings ?? [])
            ]
          }
        } : {})
      };
    case LandActionsTypes.LAND_BUILDING_DELETE:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            buildings: state.land.buildings?.filter(building => (building.id !== action.payload))
          }
        } : {})
      };
    case LandActionsTypes.LAND_FLOOR:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            buildings: state.land.buildings?.map((building) => ({
              ...building,
              floors: building.floors?.find(floor => (floor.id === action.payload.id)) ? building.floors?.map((floor) => {
                if (floor.id === action.payload.id) {
                  return {
                    ...action.payload,
                    rooms: floor.rooms
                  }
                }

                return floor
              }) : ((building.id === action.payload.building.id) ? [
                action.payload,
                ...(building.floors ?? [])
              ] : building.floors)
            }))
          }
        } : {})
      };
    case LandActionsTypes.LAND_FLOOR_DELETE:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            buildings: state.land.buildings?.map((building) => ({
              ...building,
              floors: building.floors?.filter(floor => (floor.id !== action.payload))
            }))
          }
        } : {})
      };
    case LandActionsTypes.LAND_ROOM:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            buildings: state.land.buildings?.map((building) => ({
              ...building,
              floors: building.floors?.map((floor) => ({
                ...floor,
                rooms: floor.rooms?.find(room => (room.id === action.payload.id)) ? floor.rooms?.map((room) => {
                  if (room.id === action.payload.id) {
                    return action.payload
                  }

                  return room
                }) : ((floor.id === action.payload.floor.id) ? [
                  action.payload,
                  ...(floor.rooms ?? [])
                ] : floor.rooms)
              }))
            }))
          }
        } : {})
      };
    case LandActionsTypes.LAND_ROOM_DELETE:
      return {
        ...state,
        ...(state.land ? {
          land: {
            ...state.land,
            buildings: state.land.buildings?.map((building) => ({
              ...building,
              floors: building.floors?.map((floor) => ({
                ...floor,
                rooms: floor.rooms?.filter((room) => (room.id !== action.payload))
              }))
            }))
          }
        } : {})
      };
      default:
        return state;
    }
}