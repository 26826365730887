import * as React from 'react';
import {useState} from 'react';
import {TreeItem, TreeItemContentProps, TreeItemProps, useTreeItem} from '@mui/lab';
import {IBuilding} from "../../../Realty/interfaces/building";
import {IFloor} from "../../../Realty/interfaces/floor";
import {IRoom} from "../../../Realty/interfaces/room";
import {styled} from "@mui/material/styles";
import {cx} from "@emotion/css";
import Typography from "@mui/material/Typography";
import {NavigateFunction, useNavigate} from "react-router";
import {Divider} from "@mui/material";

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref,
) {
  const {
    classes,
    className,
    label,
    nodeId,
    onClick,
    onSelect,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (onClick) {
      onClick(event);
    }
    handleExpansion(event);
  };

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleSelection(event);
    if (onSelect) {
      onSelect(event)
    }
  };

  return (
    <div style={{display: 'flex'}}>
      {selected ? (
        <Divider
          orientation='vertical'
          style={{
            position: 'relative',
            width: "4px",
            height: "35px",
            border: "none",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            background: '#3668BF',
          }}
        />
      ) : null}
      <div
        className={cx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
          noWrap
        >
          {label}
        </Typography>
      </div>
    </div>
  );
});

const PREFIX = 'Item'

const classes = {
  room: `${PREFIX}-room`
}

export const Item = styled((props: TreeItemProps) => (
  <TreeItem
    {...props}
    ContentComponent={CustomContent}
    ContentProps={{
      onSelect: props.onSelect
    }}
  />
))(({ theme }) => ({
  [`& .${classes.room}`]: {
    '& .MuiTreeItem-content': {
      paddingLeft: '32px !important',
      maxWidth: '200px !important',
    },
    '& .MuiTreeItem-content.Mui-selected': {
      paddingLeft: '28px !important'
    },
  },
  '& .MuiCollapse-root': {
    marginLeft: 0,
    '& .MuiTreeItem-content': {
      paddingLeft: '27px',
      maxWidth: '205px',
    },
    '& .MuiTreeItem-content.Mui-selected': {
      paddingLeft: '23px',
      '&:hover': {
        background:  'none',
      },
      '&.Mui-selected': {
        background:  '#F1F9FF',
        color: theme.palette.primary.main,
      },
      '&.Mui-focused': {
        background:  '#F1F9FF',
      }
    },
  },
  '& .MuiTreeItem-content': {
    minHeight: '35px',
    maxWidth: '215px',
    paddingLeft: '17px',
    '&:hover': {
      background:  'none',
    },
    '&.Mui-selected': {
      background:  'none',
    },
    '&.Mui-focused': {
      background:  'none',
    }
  },
  '& .MuiTreeItem-content.Mui-selected': {
    paddingLeft: '13px',
    '&:hover': {
      background:  'none',
    },
    '&.Mui-selected': {
      background:  '#F1F9FF',
      color: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      background:  '#F1F9FF',
    }
  },
  '& .MuiTreeItem-label': {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
  },
}));

type Props = {
  building: IBuilding
  expanded: Array<string>
  setExpanded: (nodeIds: Array<string>) => void
}

export function Building(props: Props) {
  const navigate: NavigateFunction = useNavigate();
  const {building, expanded, setExpanded} = props

  const [open, setOpen] = useState(false);

  return (
    <Item
      nodeId={`building_${building.id}`}
      label={building.name}
      onClick={() => {
        if (open) {
          setExpanded(expanded.filter(node => node !== `building_${building.id}`))
        } else {
          setExpanded([...expanded, `building_${building.id}`])
        }

        setOpen(!open)
      }}
      onSelect={() => {
        navigate(`/objects/${building.land.id}/building/${building.id}`)
      }}
    >
      {building.floors?.sort(
        (first, second) => first.name.localeCompare(second.name, undefined, {numeric: true, sensitivity: 'base'})
      ).map((floor: IFloor, index) =>
        <Item
          key={index}
          nodeId={`floor_${floor.id}`}
          label={floor.name}
          onClick={() => {
            if (expanded.find((item) => item === `floor_${floor.id}`)) {
              setExpanded(expanded.filter(node => node !== `floor_${floor.id}`))
            } else {
              setExpanded([...expanded, `floor_${floor.id}`])
            }
          }}
          onSelect={() => {
            navigate(`/objects/${building.land.id}/building/${building.id}/floor/${floor.id}`)
          }}
        >
          {floor.rooms?.sort(
            (first, second) => first.name.localeCompare(second.name, undefined, {numeric: true, sensitivity: 'base'})
          ).map((room: IRoom, key) =>
            <Item
              key={key}
              nodeId={`room_${room.id}`}
              label={room.name}
              className={classes.room}
              onSelect={() => {
                navigate(`/objects/${building.land.id}/building/${building.id}/floor/${floor.id}/room/${room.id}`)
              }}
            />
          )}
        </Item>
      )}
    </Item>
  );
}