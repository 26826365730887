import {styled} from "@mui/material/styles";
import {Submit} from "../Buttons/Submit";
import {Dialog, DialogActions, DialogTitle, Grid, Typography,} from "@mui/material";

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  lineHeight: '32px',
  fontSize: '20px',
  fontWeight: 600,
}))

type Props = {
  open: boolean
  onClose: () => void
  onCloseWithoutSaving: () => void
}


export const CloseDialog = (props: Props): JSX.Element => {
  const {open, onClose, onCloseWithoutSaving} = props

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Title>Закрыть без сохранения?</Title>
          Все несохраненные данные будут утеряны
        </Grid>
      </DialogTitle>
      <DialogActions>
        <Submit
          onClick={onClose}
          variant="contained"
        >
          Отмена
        </Submit>
        <Submit
          autoFocus
          variant="contained"
          onClick={onCloseWithoutSaving}
        >
          Закрыть
        </Submit>
      </DialogActions>
    </Dialog>
  );
};