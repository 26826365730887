import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {
  AccountCircleOutlined,
  Article,
  Description,
  Book,
  BusinessOutlined, Landscape,
  LocationOnOutlined,
  MeetingRoomOutlined,
  Person,
  PlumbingOutlined,
  StairsOutlined,
  StoreOutlined
} from '@mui/icons-material';

type Props = {
  [key: string]: {
    title: string,
    items: ISideMenuItem[]
  }
}

export interface ISideMenuItem {
  name: string,
  path: string,
  alias?: Array<string>,
  icon?: OverridableComponent<SvgIconTypeMap>,
  items?: ISideMenuItem[]
}

export const menu: Props = {
  '/object': {
    title: 'Объекты',
    items: [
      {name: 'Земельные участки', path: '/lands', alias: ['/land'], icon: LocationOnOutlined},
      {name: 'Здания', path: '/buildings', alias: ['/building'], icon: BusinessOutlined},
      {name: 'Этажи', path: '/floors', icon: StairsOutlined},
      {name: 'Помещения', path: '/rooms', icon: MeetingRoomOutlined},
      {name: 'Оборудование', path: '/equipments', icon: PlumbingOutlined},
    ]
  },
  '/account': {
    title: 'Настройки профиля',
    items: [
      {name: 'Профиль', path: '/profile', icon: Person}
    ]
  },
  '/history': {
    title: 'История изменений',
    items: [
      {name: 'Журнал изменений', path: '/journal', alias: ['/journal'], icon: Description},
    ]
  },
  '/inventory': {
    title: 'Инвентаризация',
    items: [
      {name: 'Инвентаризация', path: '/registries', alias: ['/registry'], icon: Description},
      {name: 'Реестр МТО', path: '/equipments', alias: ['/equipment'], icon: Article},
    ]
  },
  '/administration': {
    title: 'Администрирование',
    items: [
      {name: 'Пользователи', path: '/users', icon: AccountCircleOutlined},
      {name: 'Организации', path: '/companies', alias: ['/company'] , icon: StoreOutlined},
      {name: 'Земельные участки', path: '/lands', icon: Landscape},
      {
        name: 'Справочники', path: '/dictionary', icon: Book,
        items: [
          {name: 'Земельный участок', path: '/dictionary/land'},
          {name: 'Здание', path: '/dictionary/building'},
          {name: 'Этаж', path: '/dictionary/floor'},
          {name: 'Помещение', path: '/dictionary/room'},
          {name: 'Оборудование', path: '/dictionary/equipment'},
          {name: 'Инвентаризация', path: '/dictionary/inventory'},
          {name: 'Общие', path: '/dictionary/common'},
        ]
      }
    ]
  }
}

