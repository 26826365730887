import Link from "./Link";
import {styled} from "@mui/material/styles";
import Access from "../../Auth/components/Access";
import {Box, Grid as MUIGrid} from '@mui/material';

const Grid = styled(MUIGrid)(() => ({
  gap: "20px",
  display: "flex",
  '@media all': {
    minHeight: 56,
    maxHeight: 56,
  },
}));

export function Navbar(): JSX.Element {
    return (
      <Box sx={{paddingLeft: 10, margin: 0, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <Grid>
          <Link to={"/objects"}>Объекты</Link>
            <Access roles={['admin', 'dispatcher']}><Link to={"/history"}>История изменений</Link></Access>
          <Access roles={['admin', 'working_group', 'chairman']}><Link to={"/inventory"}>Инвентаризация</Link></Access>
          <Access roles={'admin'}><Link to={"/administration"}>Администрирование</Link></Access>
        </Grid>
      </Box>
    );
}
