import React from "react"
import {Divider, Grid, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {IRootState} from "../App/reducers/store";
import {styled} from "@mui/material/styles";
import {Paper as BasePaper} from "../App/components/Paper";
import Param from "./components/Profile/Param";
import ChangePassword from "./components/Profile/Buttons/ChangePassword";

const Title = styled(Grid)(() => ({
    margin: "8px 16px",
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '32px',
    color:' #495055'
}))

const Content = styled(Grid)(() => ({
    margin: "8px 16px",
}))

const Page = styled(Grid)(() => ({
    padding: '1rem',
    [`& .MuiGrid-item`]: {
        height: "100%",
    },
}))

const Paper = styled(BasePaper)(() => ({
    width: '100%',
    height: '100%',
}))

export default function Profile(): JSX.Element | null {
    const {account} = useSelector((state: IRootState) => state.account)

    return account ? (
        <Page container justifyContent="center" alignItems="center">
            <Grid item xs={6} >
                <Paper>
                    <Grid container justifyContent="stretch" alignItems="stretch" direction="column">
                        <Title>
                            <Typography variant="h6" noWrap>Профиль</Typography>
                        </Title>
                        <Divider />
                        <Content>
                            <Grid container justifyContent="stretch" alignItems="stretch" direction="column">
                                <Param
                                    name={'Имя пользователя'}
                                    value={account.login}
                                    action
                                />
                                <Param
                                    name={'ФИО'}
                                    value={account.name}
                                    action
                                />
                                <Param
                                    name={'Должность'}
                                    value={account.position}
                                    action
                                />
                                <Param
                                    name={'Электронная почта'}
                                    value={account.email}
                                    action
                                />
                                <Param
                                    name={'Роли'}
                                    value={account.roles?.map(role => role.name).join(', ')}
                                    action
                                />
                                <Param
                                    name={'Пароль'}
                                    action={<ChangePassword />}
                                />
                            </Grid>
                        </Content>
                    </Grid>
                </Paper>
            </Grid>
        </Page>
    ) : null
}
