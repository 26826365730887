export interface IFilter {
  sort: {
    direction?: string,
    name?: string | null
  },
  skip: number,
  take: number,

  [key: string]: any,
}

export interface IFiltersState {
  [key: string]:  {
    filter: IFilter
  }
}

export interface IFiltersAction {
  type: string,
  payload: {
    sort: {
      direction?: string,
      name?: string | null
    },
    skip: number,
    take: number,
  }
}

export enum FiltersActionsTypes {
  ACCOUNTS = 'ACCOUNTS_FILTER',
  COMPANIES = 'COMPANIES_FILTER',
  COMPANY_EMPLOYEES = 'COMPANY_EMPLOYEES_FILTER',
  COMPANY_POSITIONS = 'COMPANY_POSITIONS_FILTER',
  COMPANY_SUBDIVISIONS = 'COMPANY_SUBDIVISIONS_FILTER',
  LANDS = 'LANDS_FILTER',
  BUILDINGS = 'BUILDINGS_FILTER',
  FLOORS = 'FLOORS_FILTER',
  ROOMS = 'ROOMS_FILTER',
  EQUIPMENTS = 'EQUIPMENTS_FILTER',
  REGISTRIES = 'REGISTRIES_FILTER',
  REGISTRY_EQUIPMENTS = 'REGISTRY_EQUIPMENTS_FILTER',
  LAND_STATUSES = 'LAND_STATUSES_FILTER',
  LAND_CATEGORIES = 'LAND_CATEGORIES_FILTER',
  LAND_PERMISSIONS = 'LAND_PERMISSIONS_FILTER',
  BUILDING_TYPES = 'BUILDING_TYPES_FILTER',
  FLOOR_TYPES = 'FLOOR_TYPES_FILTER',
  ROOM_TYPES = 'ROOM_TYPES_FILTER',
  ROOM_PEOPLE = 'ROOM_PEOPLE',
  EQUIPMENT_TYPES = 'EQUIPMENT_TYPES_FILTER',
  EQUIPMENT_STATUSES = 'EQUIPMENT_STATUSES_FILTER',
  EQUIPMENT_PURPOSES = 'EQUIPMENT_PURPOSES_FILTER',
  INVENTORY_FUNCTIONS = 'INVENTORY_FUNCTIONS_FILTER',
  UNITS = 'UNITS_FILTER',
  RENTAL_TYPES = 'RENTAL_TYPES_FILTER',
  VERSIONS = 'VERSIONS_FILTER',
}