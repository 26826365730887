import React from "react"
import {Button, Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../../../App/components/Page/DialogContent";
import TextField from "../../../../App/components/Input/TextField";
import {Submit} from "../../../../App/components/Buttons/Submit";
import IResponseError from "../../../../App/interfaces/responseError";
import {useDispatch} from "react-redux";
import {AccountActions} from "../../../actions/account";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {IPasswordInput} from "../../../interfaces/input/password";
import {Dispatch} from "../../../../App/reducers/store";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {transformData} from "../../../../App/helpers/transformData";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function ChangePassword(): JSX.Element {
  const dispatch: Dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  const formik = useFormik({
    initialValues: {
      current: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      current: Yup.string().required('Поле обязательно к заполнению'),
      password: Yup.string().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues, {setErrors}) => {
      dispatch(AccountActions.password(transformData(values) as IPasswordInput)).then(
        () => {
          handleClose()
        },
        (error: IResponseError) => setErrors(error.errors)
      )
    }
  });

  return (
    <React.Fragment>
      <Button onClick={handleOpen}>Изменить</Button>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item xs={10}>
                <Title noWrap>Изменить пароль</Title>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={handleClose}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="current"
                  label="Текущий пароль"
                  type="password"
                />
              </Item>
              <Item>
                <TextField
                  required
                  formik={formik}
                  name="password"
                  label="Новый пароль"
                  type="password"
                />
              </Item>
            </Grid>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Grid container direction="row" alignItems="center" justifyContent="end">
              <Grid item>
                <Submit
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Изменить
                </Submit>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
