import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react"
import Access from "../../../../Auth/components/Access";
import { ICompany } from "../../../interfaces/company";
import Company from "../../Company";

type Props = {
  company: ICompany,
  disabled? : boolean,
  onClick?: (company: ICompany) => void,
}

export default function Edit(props: Props): JSX.Element {
  const {company, disabled = false, onClick} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <React.Fragment>
        <IconButton
          disabled={disabled}
          onClick={handleOpen}
        >
          <EditIcon/>
        </IconButton>
        {open ? <Company company={company} open={open} handleClose={handleClose} onClick={onClick}/> : null}
      </React.Fragment>
    </Access>
  )
}
