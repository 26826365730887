import React from "react";
import {ISvg} from "../../interfaces/svg";

function AddIcon(props: ISvg): JSX.Element {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3332 8.37484H9.62484V0.666504H8.37484V8.37484H0.666504V9.62484H8.37484V17.3332H9.62484V9.62484H17.3332V8.37484Z"
        fill="white"/>
    </svg>
  );
}

export default AddIcon;
