import React from "react";
import {styled} from "@mui/material/styles";
import {Grid, Tab as MUITab, Tabs as MUITabs} from "@mui/material";
import {IRoom} from "../../../../Realty/interfaces/room";
import Equipment from "./List/Equipment";
import People from "./List/People";
import {ICompany} from "../../../../Company/interfaces/company";

const Content = styled(Grid)(() => ({
  borderTop: "1px solid #eff0f1",
  overflow: "hidden",
  maxHeight: "calc(50% + 40px)",
  paddingTop: "12px"
}))

const List = styled(Grid)(() => ({
  maxWidth: "100%",
}))

const Tabs = styled(MUITabs)(({theme}) => ({
  minHeight: "32px",
  [`& .MuiTabs-indicator`]: {
    backgroundColor: theme.palette.primary
  }
}))

const Tab = styled(MUITab)(() => ({
  minHeight: "32px",
  padding: "4px 12px"
}))

type Props = {
  company: ICompany
  room: IRoom
}

export function Data(props: Props): JSX.Element | null {
  const {company, room} = props

  const [tab, setTab] = React.useState(0);

  const tabs: Array<{name: string, key: 'list' | 'people', content: JSX.Element | null}> = [
    {
      name: 'Оборудование',
      key: 'list',
      content: <Equipment room={room} />
    },
    {
      name: 'Люди',
      key: 'people',
      content: <People company={company} room={room} />
    }
  ]

  return (
    <Content>
      <Grid container direction="column" alignItems="center" justifyContent="stretch">
        <Grid item>
          <Tabs value={tab} onChange={(event: React.SyntheticEvent, value: number) => setTab(value)}>
            {tabs.map((item, index) => <Tab key={index} label={item.name} value={index} />)}
          </Tabs>
        </Grid>
        <List>
          {tabs[tab].content}
        </List>
      </Grid>
    </Content>
  )
}