import {ILand} from "../../Realty/interfaces/land";

export interface ILandState {
  land: ILand | null,
  state: { id: number, building?: number, floor?: number, room?: number  } | null
}

export enum LandActionsTypes {
  LAND = 'LAND',
  LAND_BUILDINGS = 'LAND_BUILDINGS',
  LAND_BUILDING = 'LAND_BUILDING',
  LAND_BUILDING_DELETE = 'LAND_BUILDING_DELETE',
  LAND_FLOOR = 'LAND_FLOOR',
  LAND_FLOOR_DELETE = 'LAND_FLOOR_DELETE',
  LAND_ROOM = 'LAND_ROOM',
  LAND_ROOM_DELETE = 'LAND_ROOM_DELETE',
  LAND_MENU_STATE = 'LAND_MENU_STATE',
}