import React from 'react'
import classNames from 'clsx'

import {PageSizeSelector} from './PageSizeSelector'
import {Pagination} from './Pagination'
import {PageSelector} from "./PageSelector";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const PREFIX = 'Pager'

const classes = {
  pager: `${PREFIX}-pager`,
}

const Root = styled(Grid)(() => ({
  [`&.${classes.pager}`]: {
    padding: '10px',
    backgroundColor: 'white',
    borderTop: '1px solid #e0e0e0'
  },
}))

export const PagerWithContent = ({
  currentPage,
  pageSizes,
  totalPages,
  pageSize,
  onCurrentPageChange,
  onPageSizeChange,
  totalCount,
  getMessage,
  className,
  children,
  ...restProps
}: any
) => {
  return (
    <Root container direction="row" alignItems="center" justifyContent="space-between" className={classNames(classes.pager, className)}>
      <Grid item>
        {children}
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <PageSelector
            totalPages={totalPages}
            totalCount={totalCount}
            currentPage={currentPage}
            onCurrentPageChange={(page: any) => onCurrentPageChange(page)}
          />
          <Pagination
            pageSize={pageSize}
            totalPages={totalPages}
            totalCount={totalCount}
            getMessage={getMessage}
            currentPage={currentPage}
            onCurrentPageChange={(page: any) => onCurrentPageChange(page)}
          />
          {!!pageSizes.length && (
            <PageSizeSelector
              pageSize={pageSize}
              pageSizes={pageSizes}
              getMessage={getMessage}
              onPageSizeChange={onPageSizeChange}
            />
          )}
        </Grid>
      </Grid>
    </Root>
  )
}
