export const AuthorizationServices = {
    access,
    ability,
    roles,
    permissions
}

function access(
    account: { roles?: Array<{ id: number, key: string, name: string, permissions?: Array<{ id: number, key: string, name: string }> }>, permissions?: Array<{ id: number, key: string, name: string }> },
    Roles?: Array<string> | string,
    Permissions?: Array<string> | string,
    require= false
): boolean {
    if (Roles) {
        if (Permissions) {
            return ability(account, Roles, Permissions, require)
        }

        return roles(account, Roles, require)
    } else if (Permissions) {
        return permissions(account, Permissions, require)
    }

    return false
}

function ability(
    account: { roles?: Array<{ id: number, key: string, name: string, permissions?: Array<{ id: number, key: string, name: string }> }>, permissions?: Array<{ id: number, key: string, name: string }> },
    Roles: Array<string> | string,
    Permissions: Array<string> | string,
    require= false
): boolean {
    let access = false

    if (roles(account, Roles, require)) {
        access = true
    }

    if (permissions(account, Permissions, require)) {
        if (require && access) {
            access = true
        }
    } else if (require) {
        access = false
    }

    return access
}

function roles(
    account: { roles?: Array<{ id: number, key: string, name: string, permissions?: Array<{ id: number, key: string, name: string }> }>, permissions?: Array<{ id: number, key: string, name: string }> },
    params: Array<string> | string,
    require= false
): boolean {
    if (Array.isArray(params)) {
        for (const role of params) {
            const has = roles(account, role)

            if (has && !require) {
                return true
            } else if (!has && require) {
                return false
            }
        }

        return require
    } else {
        if (account.roles) {
            for (const role of account.roles) {
                if (role.key === params) {
                    return true
                }
            }
        }
    }

    return false
}

function permissions(
    account: { roles?: Array<{ id: number, key: string, name: string, permissions?: Array<{ id: number, key: string, name: string }> }>, permissions?: Array<{ id: number, key: string, name: string }> },
    params: Array<string> | string,
    require = false
): boolean
{
    if (Array.isArray(params)) {
        for (const permission of params) {
            const has = permissions(account, permission)

            if (has && !require) {
                return true
            } else if (!has && require) {
                return false
            }
        }

        return require
    } else {
        if (account.roles) {
            for (const role of account.roles) {
                if (role.permissions) {
                    for (const permission of role.permissions) {
                        if (permission.key === params) {
                            return true
                        }
                    }
                }
            }
        }

        if (account.permissions) {
            for (const permission of account.permissions) {
                if (permission.key === params) {
                    return true
                }
            }
        }
    }

    return false
}
