import React from "react";
import {styled} from "@mui/material/styles";
import {
  Grid,
  Tab as MUITab,
  Tabs as MUITabs,
  Typography as MUITypography,
  useMediaQuery,
  useTheme
} from "@mui/material";

const Tabs = styled(MUITabs)(({theme}) => ({
  [theme.breakpoints.down('md')] : {
    minHeight: "24px",
  },
  [theme.breakpoints.up('md')] : {
    minHeight: "32px",
  },
  [`& .MuiTabs-indicator`]: {
    backgroundColor: theme.palette.primary
  }
}))

const Tab = styled(MUITab)(({theme}) => ({
  [theme.breakpoints.down('md')] : {
    minHeight: "24px",
    padding: "0 12px"
  },
  [theme.breakpoints.up('md')] : {
    minHeight: "32px",
    padding: "4px 12px"
  },
}))

const Panel = styled(Grid)(() => ({
  padding: "0 1rem"
}))

const Content = styled(Grid)(({theme}) => ({
  [theme.breakpoints.down('md')] : {
    gap: "14px",
    padding: "14px",
  },
  [theme.breakpoints.up('md')] : {
    gap: "20px",
    padding: "20px",
  },
  boxShadow: "0px 0px 0px 1px rgb(219 222 223 / 44%)",
  width: "100%",
  display: "grid",
  background: "white",
  alignItems: "center",
  minHeight: "72px",
  minWidth: "calc(100vw - 272px)"
}))

const Typography = styled(MUITypography)(({theme}) => ({
  [theme.breakpoints.down('md')] : {
    fontWeight: 500,
    fontSize: '16px',
  },
  [theme.breakpoints.up('md')] : {
    fontWeight: "600",
    fontSize: "18px",
  },
  fontStyle: "normal",
  fontFamily: "Open Sans",
}))

type Props = {
  title?: string | JSX.Element,
  tab?: { value: number, onChange: (event: React.SyntheticEvent, value: number) => void, tabs: Array<{ label: string, value: number, disabled?: boolean }> },
  actions?: Array<JSX.Element>
}

export default function Title(props: Props): JSX.Element | null {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const {title, tab, actions} = props

  return (
    <Content>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={mobile ? 1: 2}>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="stretch">
            {title ? (
              <Grid item>
                <Typography>{title}</Typography>
              </Grid>
            ) : null}
            <Panel>
              {tab ? (
                <Tabs value={tab.value} onChange={tab.onChange}>
                  {tab.tabs.map((item, index) => <Tab key={index} label={item.label} disabled={item.disabled} value={item.value} />)}
                </Tabs>
              ) : null}
            </Panel>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="end" spacing={mobile ? 1 : 2}>
            {actions?.map((action, index) => (action))}
          </Grid>
        </Grid>
      </Grid>
    </Content>
  )
}