import {HttpService} from "../../App/services/http"
import {IBuildingInput} from "../interfaces/input/bulding";
import {IFilter} from "../interfaces/filter";

export const BuildingService = {
  buildings,
  building,
  update,
  create,
  delete: remove,
  markup,
  addFile,
  deleteFile,
  versions,
  restore
}

function buildings(filter: IFilter) {
  return HttpService.get(`/buildings`, filter)
    .then(
      response => {
        return response
      })
}

function building(id: number) {
  return HttpService.get(`/building/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IBuildingInput) {
  return HttpService.patch(`/building/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IBuildingInput) {
  return HttpService.post(`/building`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/building/${id}`)
    .then(
      response => {
        return response
      })
}

function markup(id: number) {
  return HttpService.get(`/markup/building/${id}`)
    .then(
      response => {
        return response
      })
}

function addFile(id: number, formData: FormData) {
  return HttpService.post(`/building/${id}/file`, formData, true, true)
    .then(
      response => {
        return response
      })
}

function deleteFile(id: number, fileId: number) {
  return HttpService.delete(`/building/${id}/file/${fileId}`)
    .then(
      response => {
        return response
      })
}

function versions(guid: string) {
  return HttpService.get(`/building/${guid}/versions`)
  .then(
      response => {
        return response
      })
}
function restore(id: number) {
  return HttpService.post(`/building/version/${id}/restore`)
  .then(
      response => {
        return response
      })
}