import React, { useEffect, useState } from "react";
import { Table } from "../App/components/Table";
import { TableHeader } from "../App/components/Table/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { calculateRemainingTime } from "../App/helpers/date";
import { IRootState } from "../App/reducers/store";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { FiltersActionsTypes } from "../App/interfaces/filter";
import Actions from "../App/components/Table/Actions";
import TextField from "../App/components/Input/TextField";
import { List as ListIcon, Search } from "@mui/icons-material";
import Title from "../App/components/Table/Title";
import { useDebounce } from "use-debounce";
import { styled } from "@mui/material/styles";
import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { TableRow } from "@devexpress/dx-react-grid";
import { NavigateFunction, useNavigate } from "react-router";
import { VersionActions } from "./actions/versions";
import { IVersion } from "./interfaces/version";
import { Link } from "react-router-dom";

const PREFIX = 'Versions'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
  link: `${PREFIX}-link`,
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  },
  [`& .${classes.link}`]: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}))

export default function Journal(): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {filter} = useSelector((state: IRootState) => state.filters.versions)

  const [page, setPage] = useState(1)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [loading, setLoading] = useState(false)
  const [versions, setVersions]: any = useState({data: [], meta: {total: null}})
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'number', title: '№'},
    {name: 'author', title: 'Автор изменений'},
    {name: 'roles', title: 'Роль пользователя'},
    {name: 'created', title: 'Дата и время изменения'},
    {name: 'type', title: 'Тип'},
    {name: 'object', title: 'Объект'},
    {name: 'shelfLife', title: 'Срок хранения'},
    {name: 'actions', title: ' '},
  ])

  const [columnCompanies, setColumnCompanies] = useState([
    'number',
    'author',
    'roles',
    'created',
    'type',
    'object',
    'shelfLife',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'number', width: mobile ? 170 : 250},
    {columnName: 'author', width: 250},
    {columnName: 'roles', width: 250},
    {columnName: 'created', width: 250},
    {columnName: 'type', width: 250},
    {columnName: 'object', width: 250},
    {columnName: 'shelfLife', width: 250},
    {columnName: 'actions', width: 150, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take;
      dispatch(VersionActions.versions({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: IVersion[],
        meta: {
          total?: number
        },
      }): void => {
        setVersions({
          data: response.data,
          meta: response.meta
        })
        setLoading(true);
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.VERSIONS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  const getPath = (objectTypeId: number, object: {
    id: number,
    land?: {
      id: number
    },
    building?: {
      id: number,
      land: {
        id: number
      },
    },
    floor?: {
      id: number,
      building: {
        id: number,
        land: {
          id: number
        },
      },
    },
  }): string => {
    switch (objectTypeId) {
      case 1:
        return `/administration/company/${object.id}`;
      case 2:
        return `/objects/${object.id}`;
      case 3:
        return `/objects/${object?.land?.id}/building/${object.id}`;
      case 4:
        return `/objects/${object?.building?.land?.id}/building/${object?.building?.id}/floor/${object.id}`;
      case 5:
        return `/objects/${object?.floor?.building?.land?.id}/building/${object?.floor?.building?.id}/floor/${object?.floor?.id}/room/${object.id}`;
      case 6:
        return `/inventory/equipment/${object.id}`;
      default:
        return '/404';
    }
  }

  return loading ? (
      <Content>
        <Title
            title={"Журнал изменений"}
            actions={[
              <Grid item>
                <TextField
                    type="number"
                    value={value}
                    placeholder="Поиск: Номер"
                    icon={<Search/>}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value
                      setValue(value.length ? value : '')
                    }}
                />
              </Grid>
            ]}
        />
        <Table
            meta={versions.meta}
            name={'companies'}
            rows={versions.data.map((item: IVersion) => ({
              id: item.id,
              number: item.id,
              author: item.author.name,
              object: <Link className={classes.link}
                            to={getPath(item.objectType.id, item.original)}>{item.objectType.name}:
                №{item.original.id}</Link>,
              type: item.type.name,
              roles: item.author.roles?.map(role => role.name).join(', '),
              shelfLife: `${calculateRemainingTime(item.shelfLife).trim()}, Удалится: ${new Date(item.shelfLife).toLocaleDateString()}`,
              objectTypeId: item.objectType.id,
              guid: item.original.guid,
              created: new Date(item.created).toLocaleString(),
              actions: <Actions
                  list={mobile ? {
                    button: <IconButton
                        onClick={() => {
                          navigate(`/history/card/${item.original.guid}?objectTypeId=${item.objectType.id}`)
                        }}
                    >
                      <ListIcon/>
                    </IconButton>
                  } : {}}
              />,
            }))}
            columns={columns}
            page={{
              page: page,
              setPage: setPage,
              rowsPerPage: filter.take,
              handlePageSizeChange: handlePageSizeChange
            }}
            columnsSettings={{
              columnOrder: columnCompanies,
              setColumnOrder: setColumnCompanies,
              setColumnWidths: setColumnWidths,
              columnWidths: columnWidths,
              hiddenColumnNames: hiddenColumnNames,
              setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={FiltersActionsTypes.VERSIONS}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}: {
              row: { guid: string, objectTypeId: number },
              tableRow: TableRow,
              children: JSX.Element | null
            }) => (
                <MUITable.Row
                    tableRow={tableRow}
                    className={classes.tableRow}
                    row={row}
                    onDoubleClick={() => {
                      navigate(`/history/card/${row.guid}?objectTypeId=${row.objectTypeId}`)
                    }}
                    children={children}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{
              number: null,
              author: null,
              roles: null,
              created: null,
              type: null,
              object: null,
              shelfLife: null,
              actions: null,
            }}
        />
      </Content>
  ) : null
}