import React, {useEffect} from "react"
import {Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton,} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import {Submit} from "../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ILand} from "../interfaces/land";
import {ILandInput} from "../interfaces/input/land";
import {LandActions} from "../actions/land";
import {LandStatusesActions} from "../../Dictionaries/actions/land.statuses";
import {LandPermissionsActions} from "../../Dictionaries/actions/land.permission";
import {LandCategoriesActions} from "../../Dictionaries/actions/land.category";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import {CompanyActions} from "../../Company/actions/company";
import {ICompany} from "../../Company/interfaces/company";
import {IItem} from "../../Dictionaries/interfaces/item";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {transformData} from "../../App/helpers/transformData";
import IResponseError from "../../App/interfaces/responseError";
import {Upload} from "../../App/components/Buttons/Upload";
import {xml} from "../../App/helpers/xml";
import {CompanyEmployeeActions} from "../../Company/actions/company.employee";
import {IEmployee} from "../../Company/interfaces/employee";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Land(props: { land?: ILand, open: boolean, handleClose: () => void, onClick?: (land: ILand) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {onClick} = props
  const [land, setLand] = React.useState<ILand | null | undefined>(props.land);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      name: land?.name,
      cadastralNumber: land?.cadastralNumber,
      company: land?.company,
      status: land?.status,
      permission: land?.permission,
      category: land?.category,
      cadastralPrice: land?.cadastralPrice,
      square: land?.square,
      address: land?.address,
      encumbrances: land?.encumbrances,
      responsible: land?.responsible,
    },
    validationSchema: Yup.object().shape({
      cadastralNumber: Yup.string().required('Поле обязательно к заполнению'),
      company: Yup.object().required('Поле обязательно к заполнению'),
      status: Yup.object().required('Поле обязательно к заполнению'),
      permission: Yup.object().required('Поле обязательно к заполнению'),
      category: Yup.object().required('Поле обязательно к заполнению'),
      cadastralPrice: Yup.number().required('Поле обязательно к заполнению').min(0, 'Значение не может быть меньше 0'),
      square: Yup.number().required('Поле обязательно к заполнению').min(0, 'Значение не может быть меньше 0'),
      address: Yup.string().required('Поле обязательно к заполнению'),
      encumbrances: Yup.string().required('Поле обязательно к заполнению'),
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting}) => {
      const data = transformData(values)
      dispatch(land ? LandActions.update(land.id, (data as ILandInput)) : LandActions.create(data as ILandInput)).then(
        (land: ILand) => {
          setLand(land)
          if (onClick) {
            onClick(land)
          }
          handleClose()
        },
        (error: IResponseError) => {
          setErrors(error.errors)
          setSubmitting(false)
        },
      )
    }
  });

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file)

      reader.onload = function () {
        const parser = new DOMParser();
        if (typeof reader.result === "string") {
          const doc = parser.parseFromString(reader.result, "text/xml");
          const obj = xml(Array.from(doc.childNodes).shift())

          formik.setValues({
            ...formik.values,
            cadastralNumber: obj?.land_record?.object?.common_data?.cad_number ?? formik.values.cadastralNumber,
            cadastralPrice: obj?.land_record?.cost?.value ? Number(obj?.land_record?.cost?.value) : formik.values.cadastralPrice,
            square: obj?.land_record?.params?.area?.value ? Number(obj?.land_record?.params?.area?.value) : formik.values.square,
            address: obj?.land_record?.address_location?.address?.readable_address ?? formik.values.address,
            encumbrances: obj?.land_record?.special_notes ?? formik.values.encumbrances,
          })
        }
      }
    }
    // eslint-disable-next-line
  }, [file])

  const handleClose = () => {
    setOpen(false);
    props.handleClose()
  };

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>{land?.id ? 'Редактировать' : 'Добавить'} земельный участок</Title>
                <IconButton
                  onClick={handleClose}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container spacing={3}>
                <Item item xs={6}>
                  <TextField
                    name="name"
                    label="Наименование"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    required
                    name="cadastralNumber"
                    label="Кадастровый номер"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"company"}
                    label="Компания"
                    required
                    setValue={(name: string, value: ICompany) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanyActions.companies({
                      take: 20,
                      active: true,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.company}
                    getOptionLabel={(option: ICompany) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"status"}
                    label="Статус объекта"
                    required
                    setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => LandStatusesActions.items({
                      take: 20,
                      ...(land ? {exclude: land.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.status}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"category"}
                    label="Категория земель"
                    required
                    setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => LandCategoriesActions.items({
                      take: 20,
                      ...(land ? {exclude: land.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.category}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"permission"}
                    label="Вид разрешенного использования"
                    required
                    setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => LandPermissionsActions.items({
                      take: 20,
                      ...(land ? {exclude: land.id} : {}),
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.permission}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    required
                    name="cadastralPrice"
                    label="Кадастровая стоимость"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    required
                    name="encumbrances"
                    label="Обременения"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    required
                    name="square"
                    label="Площадь, кв.м"
                    formik={formik}
                    type="number"
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    required
                    name="address"
                    label="Адрес"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    disabled={!formik.values.company}
                    name={"responsible"}
                    label="Ответственный"
                    setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company?.id, {
                      take: 20,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.responsible}
                    getOptionLabel={(option: IEmployee) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="end" spacing={2}>
                <Grid item>
                  <Upload
                    disabled={formik.isSubmitting}
                    accept={'text/xml'}
                    onChange={(e: any) => {
                      if (e.target.files && e.target.files.length) {
                        setFile(e.target.files[0])
                      }
                    }}
                  >
                    Импорт XML
                  </Upload>
                </Grid>
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
    </React.Fragment>
  ) : null;
}
