import {ILoginState} from "../interfaces/login";
import {AuthServices} from "../services/auth";
import * as AppStorage from "../../App/helpers/storage";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {AccountActionsTypes} from "../../Account/interfaces/account";

export const AuthActions = {
    login,
    logout
}

function login(data: ILoginState) {
    return (dispatch: any) => new Promise((resolve, reject) => {
        return AuthServices.login(data)
            .then(
                response => {
                    AppStorage.set('token.access', response.tokens.access)
                    AppStorage.set('token.refresh', response.tokens.refresh)
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: AlertActionsTypes.ERROR, payload: {
                            title: "Авторизация",
                            message: error.message,
                        }
                    })
                    reject(error)
                }
            )
    })
}

function logout() {
    return async (dispatch: any) => {
        AppStorage.clear()
        dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: null})
    }
}