import { CustomPaging, IntegratedSelection, IntegratedSummary, PagingState, SelectionState, SummaryState } from '@devexpress/dx-react-grid'
import { DragDropProvider, PagingPanel, Table as MUITable, TableBandHeader, TableColumnReordering, TableColumnResizing, TableColumnVisibility, TableFixedColumns, TableHeaderRow, TableSelection, VirtualTable, } from "@devexpress/dx-react-grid-material-ui";


import { Grid } from "@mui/material";
import React from "react"
import { onCurrentPageChange } from "../helpers/table";
import { TablePaper } from "./Paper";
import { Grid as GridTable } from "./Table/Grid";
import { Pager } from "./Table/Paging/Pager";
import { StickyTable } from "./Table/StickyTable";
import { TableHeader } from "./Table/TableHeader";

export const Table = (props: any) => {
  const {
    meta,
    name,
    rows,
    columns,
    columnBands,
    page,
    pager,
    selection,
    totalSummaryItems,
    summaryCalculator,
    tableColumnExtensions,
    columnsSettings,
    filterActionType,
    getValues,
    onSelectionChange,
    classInputContent,
    rowComponent,
    filters,
  } = props

  const TableHeaderContent = ({column, children, ...restProps}: any) => {
    return (
      <TableHeader
        column={column}
        table={name}
        getValues={getValues}
        filterActionType={filterActionType}
        {...(filters ? {filters: filters} : null)}
        {...(filters && Object.prototype.hasOwnProperty.call(filters, column.name) ? {button: filters[column.name]?.button} : null)}
        {...(filters && Object.prototype.hasOwnProperty.call(filters, column.name) ? {empty: !filters[column.name]} : false)}
        {...restProps}
      >
        {children}
      </TableHeader>
    )
  };

  const getColumnsNames = (columns: any): any => {
    return columns.map((column: any) => column.name)
  }

  const BandCell = ({children, tableRow, tableColumn, column, classes, ...restProps}: any) => {
    return (
      <TableBandHeader.Cell
        {...restProps}
        column={column}
      >
        <strong>
          {children}
        </strong>
      </TableBandHeader.Cell>
    );
  };

  return (
    <TablePaper>
      <Grid item className={classInputContent}>
        <GridTable rows={rows} columns={columns}>
          <PagingState
            currentPage={page.page}
            onCurrentPageChange={e => onCurrentPageChange(e, page.setPage)}
            pageSize={page.rowsPerPage}
            onPageSizeChange={page.handlePageSizeChange}
          />
          <CustomPaging totalCount={meta.total ?? 0}
          />
          {selection ? (
            <SelectionState
                selection={selection}
              onSelectionChange={onSelectionChange}
            />
          ) : null}
          {(columnsSettings.columnOrder && columnsSettings.setColumnOrder) ? <DragDropProvider/> : null}
          {totalSummaryItems ? <SummaryState totalItems={totalSummaryItems}/> : null}
          {summaryCalculator ? <IntegratedSummary calculator={summaryCalculator}/> : null}
          <MUITable
            noDataCellComponent={() => {
              return null;
            }}
            tableComponent={StickyTable}
            rowComponent={rowComponent ?? null}
            columnExtensions={tableColumnExtensions ?? null}
          />
          <VirtualTable
            noDataCellComponent={() => {
              return null;
            }}
            tableComponent={StickyTable}
            rowComponent={rowComponent ?? null}
            columnExtensions={tableColumnExtensions ?? null}
          />
          {(columnsSettings.columnOrder && columnsSettings.setColumnOrder)
            ? <TableColumnReordering order={columnsSettings.columnOrder} onOrderChange={columnsSettings.setColumnOrder}/>
            : <TableColumnReordering defaultOrder={getColumnsNames(columns)}/>
          }
          <TableColumnResizing
            columnWidths={columnsSettings.columnWidths}
            onColumnWidthsChange={columnsSettings.setColumnWidths}
          />
          <TableHeaderRow contentComponent={TableHeaderContent}/>
          {(columnsSettings.hiddenColumnNames && columnsSettings.setHiddenColumnNames)
            ? <TableColumnVisibility hiddenColumnNames={columnsSettings.hiddenColumnNames} onHiddenColumnNamesChange={columnsSettings.setHiddenColumnNames}/>
            : <TableColumnVisibility defaultHiddenColumnNames={getColumnsNames(columns)}/>
          }
          {selection ? <IntegratedSelection/> : null}
          {selection ? (
              <TableSelection
                  showSelectAll
              />
          ) : null}
          {columnBands ? <TableBandHeader
            columnBands={columnBands}
            cellComponent={BandCell}
          /> : null}
          {columnsSettings.columnWidths.filter((column: { columnName: string, width: number, align?: string }) => column.align).length ? (
            <TableFixedColumns
              leftColumns={[TableSelection.COLUMN_TYPE ,...columnsSettings.columnWidths.filter((column: { columnName: string, width: number, align?: string }) => (column.align === 'left')).map((column: { columnName: string, width: number, align?: string }) => column.columnName)]}
              rightColumns={columnsSettings.columnWidths.filter((column: { columnName: string, width: number, align?: string }) => (column.align === 'right')).map((column: { columnName: string, width: number, align?: string }) => column.columnName)}
            />
          ) : null}
          <PagingPanel
            containerComponent={pager ?? Pager}
            messages={{
              showAll: 'Все',
              rowsPerPage: 'Записей на странице: ',
              info: (parameters: { from: number; to: number; count: number; }) => `${parameters.from}-${parameters.to} из ${parameters.count}`
            }}
            pageSizes={[10, 50, 100, 200]}
          />
        </GridTable>
      </Grid>
    </TablePaper>
  )
}
