import {FormikValues} from "formik";

export const transformData = (values: FormikValues): FormikValues => {
  const data = {
    ...values
  }
  for (const key in data) {
    switch (key) {
      case 'company':
      case 'status':
      case 'permission':
      case 'category':
      case 'type':
      case 'subdivision':
      case 'responsible':
      case 'chairman':
      case 'user':
      case 'safety':
      case 'rentalType':
      case 'floor':
      case 'building':
      case 'purpose':
      case 'function':
      case 'room':
      case 'unit':
      case 'land':
      case 'registry':
        data[`${key}Id`] = data[key]?.id ?? null
        delete data[key]
        break
      case 'dateRepair':
        if (data[key]) {
          const date = typeof data[key] === 'string' ? new Date(data[key]) : data[key];
          date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
          date.setMinutes((date.getHours() - date.getTimezoneOffset()) % 60);
          data[key] = date
        }
        break
      default:
        if (data[key] === '') {
          data[key] = null
        }
        break
    }
  }
  return data
}
