import {HttpService} from "../../App/services/http"
import {IPasswordInput} from "../interfaces/input/password";
import {IAccountInput} from "../interfaces/input/account";

export const AccountService = {
  account,
  accounts,
  password,
  create,
  update,
  delete: remove,
  filter
}

function account() {
  return HttpService.get("/account")
    .then(
      response => {
        return response
      })
}

function accounts(filter: object) {
  return HttpService.get("/accounts", filter)
    .then(
      response => {
        return response
      })
}

function password(data: IPasswordInput) {
  return HttpService.put("/account/password", data, true)
    .then(
      response => {
        return response
      })
}

function create(data: IAccountInput) {
  return HttpService.post("/account", data, true)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IAccountInput) {
  return HttpService.put(`/account/${id}`, data, true)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/account/${id}`, {}, true)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/accounts/filter", params)
    .then(
      response => {
        return response
      })
}