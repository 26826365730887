import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk, {ThunkDispatch} from "redux-thunk";
import logger from "redux-logger";
import {LoadingReducer} from "./loading";
import {ILoadingState} from "../interfaces/loading";
import {IAccountState} from "../../Account/interfaces/account";
import {AccountReducer} from "../../Account/reducers/account";
import {AlertReducer} from "./alert";
import {IAlertsState} from "../interfaces/alert";
import {IFiltersState} from "../interfaces/filter";
import {FiltersReducer} from "./filter";
import {LandReducer} from "../../Object/reducers/land";
import {ILandState} from "../../Object/interfaces/land";

const rootReducer = combineReducers<IRootState>({
  account: AccountReducer,
  land: LandReducer,
  loading: LoadingReducer,
  alerts: AlertReducer,
  filters: FiltersReducer
});

export interface IRootState {
  account: IAccountState,
  land: ILandState,
  loading: ILoadingState,
  alerts: IAlertsState,
  filters: IFiltersState
}

export type RootState = ReturnType<typeof rootReducer>;
type AppAction = ReturnType<typeof store.dispatch>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger),
);

export type Dispatch = ThunkDispatch<RootState, never, AppAction>;