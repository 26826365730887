import React, {
  useEffect,
  useState
} from "react";
import { styled } from "@mui/material/styles";
import { Table } from "../../../App/components/Table";
import { TableHeader } from "../../../App/components/Table/TableHeader";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Dispatch,
  IRootState
} from "../../../App/reducers/store";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { Grid } from "@mui/material";
import { FiltersActionsTypes } from "../../../App/interfaces/filter";
import Actions from "../../../App/components/Table/Actions";
import { EquipmentActions } from "../../../Realty/actions/equipment";
import { IEquipment } from "../../../Realty/interfaces/equipment";
import { IRoom } from "../../../Realty/interfaces/room";
import Edit from "../../../Realty/components/Equipment/Buttons/Edit";
import Delete from "../../../Realty/components/Equipment/Buttons/Delete";
import { TableRow } from "@devexpress/dx-react-grid";
import { EquipmentData } from "../../types/equipmentData";
import {NavigateFunction, useNavigate} from "react-router";

const PREFIX = 'Equipments'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 172px)',
    minHeight: 'calc(100vh - 172px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

type Props = {
  room: IRoom
  equipment?: IEquipment | null
  equipments: EquipmentData,
  setEquipments: (data: unknown) => void,
  loadEquipment: boolean,
  setLoadEquipment: (value: boolean) => void
}

export default function List(props: Props): JSX.Element | null {
  const navigate: NavigateFunction = useNavigate();
  const {room, loadEquipment: loading, setLoadEquipment: setLoading, equipments, setEquipments} = props

  const dispatch: Dispatch = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.equipments)
  const [page, setPage] = useState(1)
  const [, setTableRef] = useState(null)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [columns]: any = useState([
    {name: 'name', title: 'Наименование'},
    {name: 'type', title: 'Тип'},
    {name: 'function', title: 'Целевая функция'},
    {name: 'storageOnly', title: 'Только хранение'},
    {name: 'price', title: 'Стоимость'},
    {name: 'responsible', title: 'Ответственный'},
    {name: 'commissioningDate', title: 'Срок оплаты'},
    {name: 'actions', title: ' '},
  ])

  const [columnEquipments, setColumnEquipments] = useState([
    'name',
    'type',
    'function',
    'storageOnly',
    'price',
    'commissioningDate',
    'responsible',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'name', width: 200},
    {columnName: 'type', width: 200},
    {columnName: 'function', width: 200},
    {columnName: 'storageOnly', width: 200},
    {columnName: 'price', width: 200},
    {columnName: 'commissioningDate', width: 200},
    {columnName: 'responsible', width: 200},
    {columnName: 'actions', width: 100, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(EquipmentActions.equipments({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        roomId: room.id,
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...{meta: true},
      })).then((equipments: unknown) => {
        setEquipments(equipments as EquipmentData)
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, room])

  useEffect(() => {
    if (props.equipment && equipments?.data) {
      setEquipments({
        data: [
          props.equipment,
          ...((equipments.data.length === filter.take) ? equipments.data.slice(0, filter.take) : equipments.data)
        ],
        meta: {
          total: equipments.meta.total + 1
        },
      })
    }
    // eslint-disable-next-line
  }, [props.equipment])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.EQUIPMENTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Table
        meta={equipments.meta}
        name={'equipments'}
        rows={equipments.data.map((item: IEquipment) => ({
          id: item.id,
          name: item.name,
          type: item.type?.name,
          function: item.function?.name,
          storageOnly: item.storageOnly ? 'Да' : 'Нет',
          price: item.price,
          commissioningDate: new Date(item.commissioningDate).toLocaleDateString(),
          responsible: item.responsible?.name,
          actions: <Actions
            edit={{
              button: <Edit
                room={room}
                equipment={item}
                onClick={(equipment) => {
                  setEquipments({
                    ...equipments,
                    ...{data: equipments.data.map((el: IEquipment) => (el.id === equipment.id) ? equipment : el)}
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                onClick={() => {
                  setEquipments({
                    data: equipments.data.filter((el: IEquipment) => el.id !== item.id),
                    meta: {
                      total: equipments?.meta?.total - 1
                    },
                  })
                }}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        setTableRef={setTableRef}
        columnsSettings={{
          columnOrder: columnEquipments,
          setColumnOrder: setColumnEquipments,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.EQUIPMENTS}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: {
          row: { id: number },
          tableRow: TableRow,
          children: JSX.Element | null
        }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`equipment/${row.id}`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          status: {
            name: 'status',
            type: 'values',
          },
          actions: null
        }}
      />
    </Content>
  ) : null
}