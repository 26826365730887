import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../../Auth/components/Access";
import Land from "../../Land";
import {ILand} from "../../../interfaces/land";
import {Submit} from "../../../../App/components/Buttons/Submit";
import Grid from "@mui/material/Grid";

type Props = {
  land: ILand,
  onClick?: (land: ILand) => void
  button?: boolean
}

export function Edit(props: Props): JSX.Element {
  const {land, onClick, button = false} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={['admin']}>
      <Grid item>
        {button
          ? (
            <Submit
              variant="contained"
              onClick={handleOpen}
            >
              Редактировать
            </Submit>
          )
          : (
          <IconButton
            onClick={handleOpen}
          >
            <EditIcon/>
          </IconButton>
          )
        }
        {open ? <Land land={land} open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  )
}
