import React, {useEffect, useRef, useState} from "react"
import {styled} from "@mui/material/styles";
import {Paper as BasePaper} from "../App/components/Paper";
import {Group, Path as BasePath, SVG} from "./components/Canvas/Params";
import {Tools} from "./components/Canvas/Tools";
import {ZoomIn, ZoomOut} from "@mui/icons-material";
import {cx} from "@emotion/css";

const classes = {
  active: `Canvas-active`,
  disabled: `Path-active`,
  error: `Path-error`,
}

const Paper = styled(BasePaper)(() => ({
  height: '100%',
  overflow: 'overlay',
  display: 'grid'
}))

const Path = styled(BasePath)(({theme}) => ({
  stroke: theme.palette.primary.dark,
  [`&.${classes.disabled}`]: {
    strokeWidth: '1px'
  },
  [`&.${classes.error}`]: {
    stroke: theme.palette.error.main
  },
}))

const Circle = styled('circle')(({theme}) => ({
  cursor: 'pointer',
  fill: '#fff',
  opacity: 0.5,
  stroke: '#555',
  strokeWidth: '2px',
  transition: 'fill .2s',
  ':hover': {
    fill: theme.palette.primary.dark
  },
  [`&.${classes.active}`]: {
    fill: theme.palette.primary.main,
    ':hover': {
      fill: theme.palette.primary.dark
    },
  },
}))

export type Shape = {
  id?: number
  looped: boolean
  points: Array<{x: number, y: number}>
  object: {id: number, markup?: boolean, name?: string}
}

type Props = {
  image: any,
  object: {id: number} | null,
  setObject: (object: {id: number, name?: string} | null) => void
  shapes: {[key: number]: Shape}
  setShapes: (shapes: {[key: number]: Shape}) => void
}

export default function Canvas(props: Props): JSX.Element | null {
  const {image, object, setObject, shapes, setShapes} = props

  const radius = 6;
  const [load, setLoad] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [size, setSize] = useState({width: 0, height: 0});
  const [ctrl, setCtrl] = useState(false);
  const [shift, setShift] = useState(false);
  const [count, setCount] = useState(shapes ? Object.keys(shapes).length : 0);
  const [active, setActive] = useState<{point: number, shape: number} | null>(null)
  const [dragged, setDragged] = useState(false)
  const [hold, setHold] = useState(false)
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState<{top: number, left: number, x: number, y: number}>({left: 0, top: 0, x: 0, y: 0});

  const canvas = useRef(null);
  const ref = useRef(null);

  const handleKeyDown = (e: any) => {
    if (e.ctrlKey) {
      setCtrl(true);
    }
    if (e.shiftKey) {
      setShift(true);
    }
  }

  const handleKeyUp = (e: any) => {
    if (!e.ctrlKey) {
      setCtrl(false);
    }
    if (!e.shiftKey) {
      setShift(false);
    }
    if (e.code === 'Delete') {
      removeActivePoint()
    }
  }

  useEffect(() => {
    if (!load && initialize) {
      setShapes({})
      setScale(1)
      setActive(null)
    }
    // eslint-disable-next-line
  }, [load])

  useEffect(() => {
    setLoad(false)
  }, [image])

  useEffect(() => {
    setActive(null)
  }, [object])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    }
  })

  function getRect(element: any) {
    if (!element) {
      return {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
      }
    }

    return element.getBoundingClientRect()
  }

  const getMouseCoords = (e: any) => {
    const rect: any = getRect(ref.current);

    const x = Math.round((e.pageX - rect.left) / scale);
    const y = Math.round((e.pageY - rect.top) / scale);

    return { x, y }
  };

  const setPointCoords = (coords: {x: number, y: number}) => {
    if (active) {
      setShapes({
        ...shapes,
        ...{
          [active.shape]: {
            ...shapes[active.shape],
            ...{
              points: shapes[active.shape].points.map((point, i) => {
                if (i === active.point) {
                  return {
                    x: coords.x,
                    y: coords.y
                  }
                }
                return point
              })
            }
          }
        }
      })
    }
  };

  const looped = (active: { shape: number, point: number }, point: {x: number, y: number}): number | null => {
    if (active.shape in shapes) {
      if (shapes[active.shape].points.length > 2) {
        if ((active.point === 0) || ((active.point + 1) === shapes[active.shape].points.length)) {
          const target = (active.point === 0) ? shapes[active.shape].points[shapes[active.shape].points.length - 1] : shapes[active.shape].points[0]
          if (((point.x >= (target.x - radius)) && (point.x <= (target.x + radius))) && ((point.y >= (target.y - radius)) && (point.y <= (target.y + radius)))) {
            return (active.point === 0) ? shapes[active.shape].points.length - 1 : 0
          }
        }
      }
    }

    return null
  }

  const magnetize = (active: { shape: number, point: number } | null, coords: {x: number, y: number}): {x: number, y: number} => {
    if (shift) {
      Object.entries(shapes).filter(([shape]) => (Number(shape) !== active?.shape)).forEach(([, shape]) => {
        const point = shape.points.find((point) => (((coords.x >= (point.x - radius)) && (coords.x <= (point.x + radius))) && ((coords.y >= (point.y - radius)) && (coords.y <= (point.y + radius)))))
        if (point) {
          coords = point
        }
      })
    }

    return coords
  }

  const addPoint = (e: any) => {
    if (ctrl && object) {
      const coords = magnetize(active, getMouseCoords(e))

      if (count && active) {
        const point = looped(active, coords)

        setShapes({
          ...shapes,
          ...{
            [active.shape]: {
              ...shapes[active.shape],
              ...((point !== null)
                  ? {looped: true}
                  : {points: [
                      ...shapes[active.shape].points.slice(0, (active.point + 1)),
                      coords,
                      ...shapes[active.shape].points.slice(active.point + 1)
                    ]}
              )
            }
          }
        })
        setActive({
          ...active,
          ...{
            point: (point !== null) ? point : (active.point + 1)
          }
        })
      } else {
        setCount(count + 1)
        setShapes({
          ...shapes,
          [count + 1]: {
            looped: false,
            points: [coords],
            object: object
          }
        })
        setActive({point: 0, shape: count + 1})
      }
    } else {
      setActive(null)
    }
  };

  const removeActivePoint = () => {
    if (active) {
      const looped = shapes[active.shape].looped

      const data = looped
        ? [
          ...shapes[active.shape].points.slice(active.point + 1),
          ...shapes[active.shape].points.slice(0, active.point)
        ]
        : [
          ...shapes[active.shape].points.slice(0, active.point),
          ...shapes[active.shape].points.slice(active.point + 1)
        ];

      setShapes({
        ...shapes,
        ...{
          [active.shape]: {
            ...shapes[active.shape],
            points: data,
            looped: false
          }
        }
      })

      if (data.length) {
        setActive({
          ...active,
          ...{
            point: (looped || !active.point) ? 0 : active.point - 1
          }
        })
      } else {
        setActive(null)
      }
    }
  };

  const handleMouseMove = (e: any) => {
    if (!ctrl) {
      if (dragged) {
        setPointCoords(magnetize(active, getMouseCoords(e)))
      } else if (hold) {
        const element: any = canvas.current
        if (element) {
          const dx = e.clientX - position.x;
          const dy = e.clientY - position.y;

          element.scrollTop = position.top - dy;
          element.scrollLeft = position.left - dx;
        }
      }
    }
  };

  const handleMouseDown = (e: any) => {
    if (!ctrl) {
      setHold(true)
      const element: any = canvas.current
      if (element) {
        setPosition({
          left: element.scrollLeft,
          top: element.scrollTop,
          x: e.clientX,
          y: e.clientY
        })
      }
    }
  };

  const handleResize = (mode: 'in' | 'out') => {
    switch (mode) {
      case "in":
        if (scale < 3) {
          setScale(scale + 0.05)
        }
        break;
      case "out":
        if (scale > 0.5) {
          setScale(scale - 0.05)
        }
        break;
    }
  };

  const generatePath = (shape: Shape) => {
    return shape.points.reduce((d: string, p: any, i: number) => {
      if (i === 0) {
        d += d.length ? " M " : "M "
      } else {
        d += "L "
      }

      d += `${ p.x * scale } ${ p.y * scale } `

      if (shape.points.length === (i + 1) && shape.looped) {
        d += 'Z'
      }

      return d;
    }, '')
  }

  const getGroupDisabledShapes = () => {
    let data: {[key: number]: { markup: boolean, name?: string, shapes: Array<any> }} = {}
    for (const key in shapes) {
      const value: Shape = shapes[key]
      if (value.object.id !== object?.id) {
        data = {
          ...data,
          [value.object.id]: {
            markup: !!value.object?.markup,
            name: value.object?.name,
            shapes: [
              ...(data[value.object.id]?.shapes ?? []),
              {looped: value.looped, points: value.points}
            ]
          }
        }
      }
    }
    return data
  }

  const setDraggedPoint = (shape: number, index: any) => {
    if (!ctrl) {
      setActive({
        shape: shape,
        point: index
      })
      setDragged(true)
    }
  };

  const cancelHold = () => {
    if (hold) {
      setHold(false)
    }
  };

  const cancelDragging = () => {
    setDragged(false)
    cancelHold()
  };

  function Point(props: any) {
    const {index, shape} = props

    return (
      <Circle
        className={((shape === active?.shape) && (index === active?.point)) ? classes.active : undefined}
        onMouseDown={ () => props.setDraggedPoint(shape, props.index) }
        cx={ props.x * scale }
        cy={ props.y * scale }
        r={ radius }
      />
    )
  }

  const getPoints = (key: number, shape: Shape) => {
    return shape.points.map((p, i) => (
      <Point
        key={i}
        shape={key}
        index={i}
        x={p.x}
        y={p.y}
        setDraggedPoint={setDraggedPoint}
      />
    ))
  }

  const getImage = (image: any) => {
    const img = new Image();

    img.src = image

    img.onload = function () {
      if (!load) {
        setLoad(true)
        setInitialize(true)
        setSize({width: img.naturalWidth, height: img.naturalHeight})
      }
    };

    return image
  }

  return (
    <Paper
      ref={canvas}
      onMouseUp={cancelDragging}
      onMouseLeave={cancelHold}
      style={{
        cursor: (!ctrl && !dragged && hold) ? 'grabbing' : 'default'
      }}
    >
      {image ? (
        <SVG
          width={size.width}
          height={size.height}
          ref={ref}
          onClick={(e: any) => { addPoint(e); }}
          onMouseMove={(e: any) => handleMouseMove(e)}
          onMouseDown={ handleMouseDown }
          style={{
            width: `${size.width + (size.width * (scale - 1))}px`,
            height: `${size.height + (size.height * (scale - 1))}px`
          }}
        >
          <image
            href={getImage(image)}
            style={{
              width: `${size.width + (size.width * (scale - 1))}px`,
              height: `${size.height + (size.height * (scale - 1))}px`
            }}
          />
          {Object.entries(shapes).filter(([, shape]) => (shape.object?.id === object?.id)).map(([key, shape], index) => {
            return (
                <Group key={index}>
                  <Path
                    {...(((active?.shape !== Number(key)) && !shape.looped) ? {className: classes.error} : {})}
                    d={generatePath(shape)}
                  />
                  { getPoints(Number(key), shape) }
                </Group>
            )
          })}
          <Group>
            {Object.entries(getGroupDisabledShapes()).map(([id, obj]) => {
              return obj.shapes.map((shape, index) => (
                <Path
                    key={index}
                    onClick={() => {
                      if (!ctrl) {
                        setObject({id: Number(id), name: obj.name})
                      }
                    }}
                    className={!shape.looped ? cx(classes.disabled, classes.error) : classes.disabled}
                    style={{cursor: !ctrl ? 'pointer' : 'default'}}
                    d={generatePath(shape)}
                />
              ))
            })}
          </Group>
        </SVG>
      ) : null}
      <Tools
        buttons={[
          {
            value: "ZoomIn",
            disabled: (!image || (scale >= 3)),
            icon: <ZoomIn />,
            onClick: () => handleResize('in')
          },
          {
            value: "ZoomOut",
            disabled: (!image || (scale <= 0.5)),
            icon: <ZoomOut />,
            onClick: () => handleResize('out')
          }
        ]}
      />
    </Paper>
  )
}
