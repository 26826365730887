import React from "react";
import {styled} from "@mui/material/styles";
import {Grid} from "@mui/material";
import {IBuilding} from "../../../Realty/interfaces/building";
import Type from "./Statistic/Type";
import WorkloadType from "./Statistic/WorkloadType";
import Workload from "./Statistic/Workload";
import Repair from "./Statistic/Repair";

const Content = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}))

type Props = {
  building: IBuilding
}

export default function Statistic(props: Props): JSX.Element | null {
  const { building } = props

  return (
    <Content container direction="row" justifyContent="center" alignItems="center">
      <Type building={building} xs={4} />
      <Workload building={building} xs={4} />
      <WorkloadType building={building} xs={4} />
      <Repair building={building} xs={4} />
    </Content>
  )
}