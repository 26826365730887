import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react"
import Access from "../../../../Auth/components/Access";
import { IRegistry } from "../../../interfaces/registry";
import Registry from "../../Registry";

type Props = {
  registry: IRegistry,
  onClick?: (registry?: IRegistry) => void,
  equipments?: Array<number>
}

export default function Edit(props: Props): JSX.Element {
  const {registry, equipments, onClick} = props

  const [open, setOpen] = React.useState(false);

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  return (
      <Access roles={['admin']}>
        <React.Fragment>
          <IconButton
              onClick={handleOpen}
          >
            <EditIcon/>
          </IconButton>
          {open ? <Registry
              registry={registry}
              equipments={equipments}
              open={open}
              handleClose={handleClose}
              onClick={onClick}
              isSelectAll={true}
          /> : null}
        </React.Fragment>
      </Access>
  )
}
