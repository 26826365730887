import {styled} from '@mui/material/styles';
import {DialogContent as MIUDialogContent} from "@mui/material";

export const DialogContent = styled(MIUDialogContent)(({theme}) => ({
  overflow: "overlay",
  [theme.breakpoints.down('md')] : {
    maxHeight: "calc(100% - 170px)",
    height: "calc(100% - 170px)",
  },
  [theme.breakpoints.up('md')] : {
    maxHeight: "526px",
  },
}))