import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {Search} from "@mui/icons-material";
import {Grid} from "@mui/material";
import {useDebounce} from "use-debounce";
import {IItem} from "./interfaces/item";
import {IRootState} from "../App/reducers/store";
import {TableHeader} from "../App/components/Table/TableHeader";
import Actions from "../App/components/Table/Actions";
import {Table} from "../App/components/Table";
import Title from "../App/components/Table/Title";
import TextField from "../App/components/Input/TextField";
import {FiltersActionsTypes} from "../App/interfaces/filter";
import {InventoryFunctionActions} from "./actions/inventory.function";
import {CommonUnitActions} from "./actions/common.unit";
import Create from "./components/Buttons/Create";
import Edit from "./components/Buttons/Edit";
import Delete from "./components/Buttons/Delete";
import {TableRow} from "@devexpress/dx-react-grid";

const PREFIX = 'DictionaryMain'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(({theme}) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')] : {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')] : {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Inventory(): JSX.Element | null {
  const tabs = [
    {
      name: 'Целевые функции',
      actions: InventoryFunctionActions,
      table: 'inventoryFunctions',
      filter: FiltersActionsTypes.INVENTORY_FUNCTIONS,
      item: {
        name: 'целевую функцию'
      }
    },
    {
      name: 'Единицы измерения',
      actions: CommonUnitActions,
      table: 'units',
      filter: FiltersActionsTypes.UNITS,
      item: {
        name: 'единицу измерения'
      }
    }
  ]

  const dispatch: any = useDispatch()
  const [tab, setTab] = React.useState(0);
  const {filter} = useSelector((state: IRootState) => state.filters[tabs[tab].table])
  const [items, setItems]: any = useState({data: [], meta: {total: null}})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);

  const columns = [
    {name: 'id', title: 'Идентификатор'},
    {name: 'name', title: 'Наименование'},
    {name: 'created', title: 'Дата создания'},
    {name: 'actions', title: ' '},
  ]

  const [columnOrder, setColumnOrder] = useState([
    'id',
    'name',
    'created',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'id', width: 200},
    {columnName: 'name', width: 450},
    {columnName: 'created', width: 250},
    {columnName: 'actions', width: 300, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(tabs[tab].actions.items({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!items.meta?.total ? {meta: true} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [IItem],
        meta?: {
          total?: number
        },
      }) => {
        setItems({
          data: response.data,
          meta: response.meta ?? items.meta
        })
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setItems({data: [], meta: {total: null}})
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [tab])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: tabs[tab].filter,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return (
    <Content>
      <Title
        title={'Инвентаризация'}
        tab={{
          value: tab,
          onChange: (event: React.SyntheticEvent, value: number) => {
            setTab(value);
          },
          tabs: tabs.map((item, index) => ({label: item.name, value: index}))
        }}
        actions={[
          <Grid item>
            <TextField
              type="text"
              value={value}
              placeholder="Поиск: Наименование"
              icon={<Search/>}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />
          </Grid>,
          <Create
            name={tabs[tab].item.name}
            actions={tabs[tab].actions}
            onClick={(item) => {
              setItems({
                data: [
                  item,
                  ...((items.data.length === filter.take) ? items.data.slice(0, filter.take) : items.data)
                ],
                meta: {
                  total: items.meta.total + 1
                },
              })
            }}
          />
        ]}
      />
      <Table
        meta={items.meta}
        name={tabs[tab].table}
        rows={items.data.map((item: IItem) => ({
          id: item.id,
          name: item.name,
          created: new Date(item.created).toLocaleDateString(),
          actions: <Actions
            edit={{
              button: <Edit
                item={item}
                name={tabs[tab].item.name}
                actions={tabs[tab].actions}
                onClick={(item) => {
                  setItems({
                    ...items,
                    ...{data: items.data.map((el: IItem) => (el.id === item.id) ? item : el)}
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                actions={tabs[tab].actions}
                onClick={() => {
                  setItems({
                    data: items.data.filter((el: IItem) => el.id !== item.id),
                    meta: {
                      total: items.meta.total - 1
                    },
                  })
                }}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnOrder,
          setColumnOrder: setColumnOrder,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={tabs[tab].filter}
        getValues={tabs[tab].actions.filter}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: object, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          actions: null,
        }}
      />
    </Content>
  )
}