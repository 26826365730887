import { HttpService } from "../../App/services/http"
import { IFilter } from "../../Inventory/interfaces/filter";

export const VersionService = {
	versions
}

function versions(filter: IFilter) {
	return HttpService.get(`/versions`, filter)
		.then(
			response => {
				return response
			})
}