import React from "react";
import {Grid} from "@mui/material";
import Edit from "./Actions/Edit";
import Delete from "./Actions/Delete";
import Restore from "./Actions/Restore";
import View from "./Actions/View";

type Props = {
  edit?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  delete?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  markup?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  code?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  list?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  download?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  view?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
  restore?: {
    button?: JSX.Element,
    disabled?: boolean,
    onClick?: () => void,
  },
}

export default function Actions(props: Props): JSX.Element {
  return <Grid container justifyContent={"flex-start"} alignItems={"center"}>
    {props.list?.button ? (props.list.button) : null}
    {props.code?.button ? (props.code.button) : null}
    {props.edit ? (
      props.edit.button ?? <Edit disabled={props.edit.disabled} onClick={props.edit.onClick} />
    ) : null}
    {props.restore ? (
        props.restore.button ?? <Restore disabled={props.restore.disabled} onClick={props.restore.onClick} />
    ) : null}
    {props.view ? (
        props.view.button ?? <View disabled={props.view.disabled} onClick={props.view.onClick} />
    ) : null}
    {props.delete ? (
      props.delete.button ?? <Delete disabled={props.delete.disabled} onClick={props.delete.onClick} />
    ) : null}
    {props.markup?.button ? (props.markup.button) : null}
    {props.download?.button ? (props.download.button) : null}
  </Grid>
}