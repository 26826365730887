import React, {useState} from "react"

import {useDispatch} from "react-redux";
import {IRegistry} from "../../../../interfaces/registry";
import {Submit} from "../../../../../App/components/Buttons/Submit";
import {EquipmentActions} from "../../../../../Realty/actions/equipment";
import {IEquipment} from "../../../../../Realty/interfaces/equipment";
import Equipment from "../../../../../Realty/components/Equipment";
import {EquipmentActions as RegistryEquipmentActions} from "../../../../actions/equipment";

export default function Search(props: { registry: IRegistry, number: string, onClick: (equipment?: IEquipment) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {registry, number, onClick} = props
  const [equipment, setEquipment] = useState<IEquipment>()
  const [disabled, setDisabled] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Submit
        disabled={disabled}
        onClick={() => {
          dispatch(EquipmentActions.number(number)).then(
            (response: IEquipment) => {
              setEquipment(response)
            },
            () => {
              setDisabled(true)
            }
          )
        }}
        variant="contained"
        type="submit"
      >
        {disabled ? "Не найдено" : "Искать МТО в базе"}
      </Submit>
      {equipment ? (
        <Equipment
          registry={registry}
          equipment={equipment}
          open={!!equipment}
          handleClose={() => {
            setEquipment(undefined)
            onClick()
          }}
          onClick={(equipment: IEquipment) => {
            dispatch(RegistryEquipmentActions.add(registry.id, equipment.id)).then(
              () => {
                onClick(equipment)
              },
              () => {
                onClick()
              }
            )
          }}
        />
      ) : null}
    </React.Fragment>
  );
}