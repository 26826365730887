import React, {useEffect} from "react"
import {Checkbox, Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton} from "@mui/material";
import {Close} from '@mui/icons-material';
import {equippedOptions, IEquippedItem} from "../../Dictionaries/interfaces/Common/input/equippedItem";
import {DialogContent} from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import {Submit} from "../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {IBuilding} from "../interfaces/building";
import {IBuildingInput} from "../interfaces/input/bulding";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import {Autocomplete as CustomAutocomplete} from "../../App/components/Input/Autocomplete";
import {BuildingTypeActions} from "../../Dictionaries/actions/building.type";
import {IItem} from "../../Dictionaries/interfaces/item";
import {CommonRentalTypeActions} from "../../Dictionaries/actions/common.rental.type";
import {ILand} from "../interfaces/land";
import {ICompany} from "../../Company/interfaces/company";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {BuildingActions} from "../actions/building";
import {transformData} from "../../App/helpers/transformData";
import IResponseError from "../../App/interfaces/responseError";
import {CloseDialog} from "../../App/components/Dialog/CloseDialog";
import {Upload} from "../../App/components/Buttons/Upload";
import {Link as RouterLink} from "react-router-dom";
import {xml} from "../../App/helpers/xml";
import {CompanyEmployeeActions} from "../../Company/actions/company.employee";
import {IEmployee} from "../../Company/interfaces/employee";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

const RowDirectionItem = styled(TextField)(() => ({
  width: "234px",
}))

const CheckBoxLabel = styled(Typography)(() => ({
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  lineHeight: '32px',
  fontWeight: 600,
}))

const Link = styled(RouterLink)(() => ({
  textDecoration: 'auto',
}));

export default function Building(props: { land?: ILand | null, building?: IBuilding, open: boolean, handleClose: () => void, onClick?: (building: IBuilding) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {onClick, land} = props

  const [building, setBuilding] = React.useState<IBuilding | null | undefined>(props.building);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [savedBuilding, setSavedBuilding]: any = React.useState(null);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: building?.name,
      address: building?.address,
      cadastralNumber: building?.cadastralNumber,
      land: building?.land ?? land,
      company: building?.land.company ?? land?.company,
      type: building?.type,
      square: building?.square,
      price: building?.price,
      parking: building?.parking,
      floors: building?._count.floors,
      floorAmount: building?.floorAmount ?? 0,
      isFloorGenerate: building?.isFloorGenerate ?? true,
      equippedVideo: building?.equippedVideo,
      equippedSecurity: building?.equippedSecurity,
      accessiblePeopleLimitedMobility: building?.accessiblePeopleLimitedMobility,
      culturalHeritageMonument: building?.culturalHeritageMonument ?? false,
      initialYear: building?.initialYear,
      lastOverhaulYear: building?.lastOverhaulYear,
      rentalType: building?.rentalType,
      internet: building?.internet,
      responsible: building?.responsible,
      safety: building?.safety,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению').max(512, 'Значение не может быть больше 512 символов'),
      address: Yup.string().required('Поле обязательно к заполнению'),
      cadastralNumber: Yup.string(),
      company: Yup.object().required('Поле обязательно к заполнению'),
      land: Yup.object().required('Поле обязательно к заполнению'),
      type: Yup.object().required('Поле обязательно к заполнению'),
      price: Yup.number().nullable().min(0, 'Значение не может быть меньше 0'),
      square: Yup.number()
        .required('Поле обязательно к заполнению')
        .min(0, 'Значение не может быть меньше 0'),
      initialYear: Yup.number()
        .nullable()
        .min(1500, 'Минимальный год постройки 1500')
        .max(new Date().getFullYear(), `Максимальный год постройки ${new Date().getFullYear()}`),
      lastOverhaulYear: Yup.number()
        .nullable()
        .min(1500, 'Минимальный год ремонта 1500')
        .max(new Date().getFullYear(), `Максимальный год ремонта ${new Date().getFullYear()}`),
      isFloorGenerate: Yup.boolean(),
      equippedVideo: Yup.boolean().required('Поле обязательно к заполнению'),
      equippedSecurity: Yup.boolean().required('Поле обязательно к заполнению'),
      parking: Yup.number().nullable().min(0, 'Значение не может быть меньше 0'),
      floorAmount: Yup.number()
        .required('Поле обязательно к заполнению')
        .min(0, 'Значение не может быть меньше 0'),
      accessiblePeopleLimitedMobility: Yup.boolean().required('Поле обязательно к заполнению'),
      internet: Yup.boolean(),
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting}) => {
      const data = transformData(values)
      dispatch(building
        ? BuildingActions.update(building.id, (data as IBuildingInput))
        : BuildingActions.create(data as IBuildingInput)
      )
        .then(
          (building: IBuilding) => {
            setSubmitting(true)
            setBuilding(building)
            setSavedBuilding(building)
          },
          (error: IResponseError) => {
            setErrors(error.errors)
            setSubmitting(false)
          },
        )
    }
  });

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file)

      reader.onload = function () {
        const parser = new DOMParser();
        if (typeof reader.result === "string") {
          const doc = parser.parseFromString(reader.result, "text/xml");
          const obj = xml(Array.from(doc.childNodes).shift())

          formik.setValues({
            ...formik.values,
            address: obj?.build_record?.address_location?.address?.readable_address ?? formik.values.address,
            square: obj?.build_record?.params?.area ? Number(obj?.build_record?.params?.area) : formik.values.square,
            price: obj?.build_record?.cost?.value ? Number(obj?.build_record?.cost?.value) : formik.values.price,
            floors: obj?.build_record?.params?.floors ? Number(obj?.build_record?.params?.floors) : formik.values.floors,
            floorAmount: obj?.build_record?.params?.floors ? Number(obj?.build_record?.params?.floors) : formik.values.floorAmount,
            initialYear: obj?.build_record?.params?.year_built ? Number(obj?.build_record?.params?.year_built) : formik.values.initialYear,
            lastOverhaulYear: obj?.build_record?.params?.year_commisioning ? Number(obj?.build_record?.params?.year_commisioning) : formik.values.lastOverhaulYear,
          })
        }
      }
    }
    // eslint-disable-next-line
  }, [file])

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = () => {
    if (onClick && savedBuilding) {
      onClick(savedBuilding)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  return open ? (
    <React.Fragment>
      <Dialog
        onDoubleClick={(e: any) => e.stopPropagation()}
        fullWidth
        open={props.open}
        maxWidth={'md'}
        onClose={handleCloseDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Title>
                  {(savedBuilding?.id || building?.id) ? 'Редактировать' : 'Добавить'} здание
                </Title>
                <IconButton onClick={handleCloseDialog}>
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container spacing={3}>
                <Item item xs={6}>
                  <TextField
                    required
                    name="name"
                    label="Корпус"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    name="cadastralNumber"
                    label="Кадастровый номер"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    required
                    name="address"
                    label="Адрес"
                    type="text"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    formik={formik}
                    name={'company'}
                    label="Компания"
                    getOptionLabel={(option: ICompany) =>
                      option?.name ?? option
                    }
                    value={formik.values.company}
                    setValue={(name: string, value: ILand) =>
                      formik.setFieldValue(name, value)
                    }
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    required
                    disabled
                    name={'land'}
                    formik={formik}
                    label="Земельный участок"
                    subscribeFields={[formik.values.company]}
                    setValue={(name: string, value: ILand) =>
                      formik.setFieldValue(name, value)
                    }
                    value={formik.values.land}
                    getOptionLabel={(option: ILand) =>
                      option?.name ?? option?.cadastralNumber ?? option
                    }
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={'type'}
                    label="Тип здания"
                    required
                    setValue={(name: string, value: IItem) =>
                      formik.setFieldValue(name, value)
                    }
                    getValues={(search: string) =>
                      BuildingTypeActions.items({
                        take: 20,
                        ...(building ? {exclude: building.id} : {}),
                        ...(search ? {search: search} : {}),
                      })
                    }
                    value={formik.values.type}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Год первоначального ввода в эксплуатацию"
                    name="initialYear"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={'rentalType'}
                    label="Тип аренды"
                    setValue={(name: string, value: IItem) =>
                      formik.setFieldValue(name, value)
                    }
                    getValues={(search: string) =>
                      CommonRentalTypeActions.items({
                        take: 20,
                        ...(building ? {exclude: building.id} : {}),
                        ...(search ? {search: search} : {}),
                        ...(formik.values?.rentalType
                          ? {buildingId: formik.values.rentalType.id}
                          : {}),
                      })
                    }
                    value={formik.values.rentalType}
                    getOptionLabel={(option: IItem) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Площадь здания кв.м"
                    required
                    name="square"
                    type="number"
                    formik={formik}
                  />
                </Item>
                {building?.id ? (
                  <Item item xs={6}>
                    <TextField
                      required
                      disabled
                      name="floors"
                      type="number"
                      formik={formik}
                      label="Количество этажей (в том числе подземных)"
                    />
                  </Item>
                ) : (
                  <React.Fragment>
                    <Grid container item xs={6} direction="row" alignItems="flex-start" justifyContent="space-between">
                      <RowDirectionItem
                        required
                        type="number"
                        formik={formik}
                        name="floorAmount"
                        label="Количество этажей (в том числе подземных)"
                      />
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{marginTop: '10px', width: '146px'}}
                      >
                        <Checkbox
                          checked={formik.values.isFloorGenerate}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('isFloorGenerate', e.target.checked)}
                        />
                        <CheckBoxLabel>Создать этажи</CheckBoxLabel>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <Item item xs={6}>
                  <TextField
                    name="price"
                    label="Балансовая стоимость здания"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Год последнего капитального ремонта"
                    name="lastOverhaulYear"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    label="Парковка, количество мест"
                    name="parking"
                    type="number"
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <CustomAutocomplete
                    required
                    name={'equippedVideo'}
                    options={equippedOptions}
                    label="Имеет видеонаблюдение"
                    formik={formik}
                    value={
                      (formik.values.equippedVideo !== undefined)
                        ? formik.values.equippedVideo
                          ? 'Да'
                          : 'Нет'
                        : undefined
                    }
                    getOptionLabel={(option: IEquippedItem) =>
                      option?.name ?? option
                    }
                    setValue={(name: string, value: IEquippedItem) => {
                      formik.setFieldValue(name, value?.value, true);
                    }}
                  />
                </Item>
                <Item item xs={6}>
                  <CustomAutocomplete
                    required
                    label="Имеет охрану"
                    name={'equippedSecurity'}
                    options={equippedOptions}
                    formik={formik}
                    value={
                      (formik.values.equippedSecurity !== undefined)
                        ? formik.values.equippedSecurity
                          ? 'Да'
                          : 'Нет'
                        : undefined
                    }
                    getOptionLabel={(option: IEquippedItem) =>
                      option?.name ?? option
                    }
                    setValue={(name: string, value: IEquippedItem) => {
                      formik.setFieldValue(name, value?.value, true);
                    }}
                  />
                </Item>
                <Item item xs={6}>
                  <CustomAutocomplete
                    required
                    options={equippedOptions}
                    name={'accessiblePeopleLimitedMobility'}
                    label="Доступно для маломобильных групп населения"
                    formik={formik}
                    getOptionLabel={(option: IEquippedItem) =>
                      option?.name ?? option
                    }
                    value={
                      (formik.values.accessiblePeopleLimitedMobility !== undefined)
                        ? formik.values.accessiblePeopleLimitedMobility
                          ? 'Да'
                          : 'Нет'
                        : undefined
                    }
                    setValue={(name: string, value: IEquippedItem) => {
                      formik.setFieldValue(name, value?.value, true);
                    }}
                  />
                </Item>
                <Item item xs={6}>
                  <CustomAutocomplete
                    options={equippedOptions}
                    name={'internet'}
                    label="Наличие интернета"
                    formik={formik}
                    disableClearable={true}
                    getOptionLabel={(option: IEquippedItem) =>
                      option?.name ?? option
                    }
                    value={
                      (formik.values.internet !== undefined)
                        ? formik.values.internet
                          ? 'Да'
                          : 'Нет'
                        : undefined
                    }
                    setValue={(name: string, value: IEquippedItem) => {
                      formik.setFieldValue(name, value?.value, true);
                    }}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"responsible"}
                    disabled={!formik.values.company}
                    label="Ответственный"
                    setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                      take: 20,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.responsible}
                    getOptionLabel={(option: IEmployee) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Autocomplete
                    name={"safety"}
                    disabled={!formik.values.company}
                    label="Ответственный (Противопожарная безопасность)"
                    setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                    getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                      take: 20,
                      ...(search ? {search: search} : {})
                    })}
                    value={formik.values.safety}
                    getOptionLabel={(option: IEmployee) => option?.name ?? option}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                    <Checkbox
                      sx={{pl: 0}}
                      checked={formik.values.culturalHeritageMonument}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('culturalHeritageMonument', e.target.checked)}
                    />
                    <CheckBoxLabel>Памятник культурного наследия</CheckBoxLabel>
                  </Grid>
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Link to={`/object/building/${savedBuilding?.id}/markup`}>
                    <Submit
                      disabled={!formik.isValid || (formik.dirty && !formik.isSubmitting) || (!formik.dirty && formik.isSubmitting) || formik.values.floorAmount === 0}
                      variant="contained"
                    >
                      Добавить этажи на схему
                    </Submit>
                  </Link>
                </Grid>
                <Grid item>
                  <Upload
                    disabled={formik.isSubmitting}
                    accept={'text/xml'}
                    onChange={(e: any) => {
                      if (e.target.files && e.target.files.length) {
                        setFile(e.target.files[0])
                      }
                    }}
                  >
                    Импорт XML
                  </Upload>
                </Grid>
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
      <CloseDialog
        open={openDialog}
        onClose={onCloseDialog}
        onCloseWithoutSaving={onCloseWithoutSaving}
      />
    </React.Fragment>
  ) : null;
}