import React, {useState} from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import Access from "../../../../Auth/components/Access";
import {IRegistry} from "../../../interfaces/registry";
import {useDispatch} from "react-redux";
import {RegistryActions} from "../../../actions/registry";
import Grid from "@mui/material/Grid";
import {useMediaQuery, useTheme} from "@mui/material";

type Props = {
  registry: IRegistry
  setRegistry: (registry: IRegistry) => void
}

export function Inventory(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const { registry, setRegistry } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const isDraft = () => {
    return !mobile && (!registry || (registry?.status?.key === 'draft'))
  }

  return isDraft() ? (
    <Access roles={['working_group']}>
      <Grid item>
        <Submit
          disabled={isSubmitting}
          onClick={() => {
            setIsSubmitting(true)
            dispatch(RegistryActions.inventory(registry.id)).then((response: IRegistry) => {
              setRegistry(response)
              setIsSubmitting(false)
            })
          }}
          variant="contained"
          type="submit"
        >
          Начать инвентаризацию
        </Submit>
      </Grid>
    </Access>
  ) : null
}
