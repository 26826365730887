import {styled} from "@mui/material/styles";
import {Box, Button} from '@mui/material';
import {Config} from "../../config";
import React from "react";

const Component = styled(Box)(() => ({
  [`& .MuiButtonBase-root`]: {
    height: "32px",
    background: Config.colors.PRIMARY_COLOR,
    "&:hover": {
      background: Config.colors.PRIMARY_HOVER_COLOR,
      boxShadow: "none"
    },
    textTransform: "none",
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    boxShadow: "none",
    borderRadius: "2px",
    "&.MuiButton-sizeSmall": {
      height: "24px",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "16px"
    }
  },
}));

type Props = {
  children: string,
  accept?: string,
  disabled?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  fullWidth?: boolean
}

export function Upload(props: Props): JSX.Element {
  const {children, accept = 'image/*', disabled = false, onChange, fullWidth = false} = props

  return (
    <Component>
      <Button disabled={disabled} fullWidth={fullWidth} variant="contained" component="label">
          {children}<input hidden accept={accept} multiple type="file" onChange={onChange} onClick={(e: any) => (e.target.value = null)} />
      </Button>
    </Component>
  );
}
