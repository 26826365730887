import React from "react";
import {Cell, Legend, Pie, PieChart as Chart, ResponsiveContainer, Tooltip} from 'recharts';
import Typography from "@mui/material/Typography";
import {Box, Grid} from "@mui/material";
import {styled} from "@mui/material/styles";

const TooltipComponent = styled('div')(() => ({
  backgroundColor: 'hsla(0,0%,100%,.8)',
  border: '1px solid #f5f5f5',
  margin: '0',
  padding: '6px 6px 0 6px',
}))

type Props = {
  data: Array<{name: string, value: number}>,
  types: Array<{id: number, name?: string, color?: string | null}>,
  total?: string
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, value } = props

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return value ? (
    <text x={x} y={y} fill="white" stroke="black" strokeWidth="1" paintOrder="stroke" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  ) : null;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipComponent>
        {label ? <Typography>{label}</Typography> : null}
        {payload.map((item: any, index: string) =>
          <Typography key={index} variant="caption" color="text.secondary" component="div" gutterBottom>
            {item.name}: <Box sx={{ color: 'primary.main' }} component="span">{item.value}</Box>
          </Typography>
        )}
      </TooltipComponent>
    );
  }

  return null;
};

export default function PieChart(props: Props): JSX.Element | null {
  const {data, types, total} = props

  return (
    <ResponsiveContainer width='100%' aspect={1.0}>
      <Chart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          dataKey="value"
        >
          {types.map((type) =>
            <Cell key={`cell-${type.id}`} fill={type?.color ?? "#3668BF"} strokeWidth={1} />
          )}
        </Pie>
        {total ? (
          <Pie
            data={[
              {name: total, value: data.reduce((total: number, item) => (total + item.value), 0)}
            ]}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={(props: any) => {
              const { cx, cy, value } = props
              return (
                <text x={cx} y={cy} fill="#3d405c" textAnchor="middle" dominantBaseline="central">
                  <tspan fontSize="16">{value}</tspan>
                </text>
              )
            }}
            dataKey="value"
            nameKey="name"
            outerRadius="30%"
            fill="#fff"
          />
        ) : null}
        <Tooltip content={<CustomTooltip />} />
        <Legend
          align="center"
          iconType='square'
          content={(props) => {
            const { payload } = props;

            return (
              <React.Fragment>
                {total ? (
                  <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    <Typography style={{color: 'rgba(0, 0, 0, 0.87)'}}>Итого: {data.reduce((total: number, item) => (total + item.value), 0)}</Typography>
                  </Grid>
                ) : null}
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                  {
                    payload?.filter((entry) => (entry.value !== total)).map((entry, index) => (
                      <Grid item key={index}>
                        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
                          <Grid item>
                            <svg width="14" height="14" viewBox="0 0 32 32">
                              <path fill={entry.color} cx="16" cy="16" transform="translate(16, 16)" d="M-16,-16h32v32h-32Z"></path>
                            </svg>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" style={{color: 'rgba(0, 0, 0, 0.87)'}}>{entry.value}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
              </React.Fragment>
            );
          }}
          formatter={(value: string) => {
            return (value !== total) ? <Typography variant="caption" style={{color: 'rgba(0, 0, 0, 0.87)'}}>{value}</Typography> : null;
          }}
        />
      </Chart>
    </ResponsiveContainer>
  )
}