import React, {useEffect} from "react"
import {
  Dialog as MUIDialog, DialogActions, DialogTitle, Divider, Grid, IconButton, Input, InputBaseComponentProps,
} from "@mui/material";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import {CloseDialog} from "../../../App/components/Dialog/CloseDialog";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {IRegistry} from "../../interfaces/registry";
import {Close} from "@mui/icons-material";
import {DialogContent} from "../../../App/components/Page/DialogContent";
import TextField from "../../../App/components/Input/TextField";
import {Submit} from "../../../App/components/Buttons/Submit";
import Typography from "@mui/material/Typography";
import IResponseError from "../../../App/interfaces/responseError";
import {RegistryActions} from "../../actions/registry";
import {checkMaxSize} from "../../../App/helpers/fileStorage";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {Equipments} from "./Complete/Equipments";

const Item = styled(Grid)(() => ({
  height: "160px",
  width: "100%",
}))

const Title = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export function Complete(props: { registry: IRegistry, open: boolean, handleClose: () => void, onClick?: (registry?: IRegistry) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const {registry, onClick} = props
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = (registry?: IRegistry) => {
    if (onClick && registry) {
      onClick(registry)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      discrepancies: '',
      conclusion: '',
      file: null
    },
    validationSchema: Yup.object().shape({
      discrepancies: Yup.string().nullable(),
      conclusion: Yup.string().required('Поле обязательно к заполнению'),
      file: Yup.mixed().nullable()
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = new FormData();
      data.append('discrepancies', values.discrepancies);
      data.append('conclusion', values.conclusion);
      data.append('file', values.file);
      dispatch(RegistryActions.complete(registry.id, data))
        .then(
          (registry: IRegistry) => {
            setSubmitting(true)
            handleClose(registry)
          },
          (error: IResponseError) => setErrors(error.errors)
        )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={open}
        onClose={handleCloseDialog}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>Итоги инвентаризации</Title>
                <IconButton
                  onClick={handleCloseDialog}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Equipments registry={registry} />
                </Grid>
                <Item item xs={6}>
                  <TextField
                    name="discrepancies"
                    label="Объяснение причин расхождения"
                    type="text"
                    multiline
                    maxRows={4}
                    rows={4}
                    formik={formik}
                  />
                </Item>
                <Item item xs={6}>
                  <TextField
                    name="conclusion"
                    label="Заключение комиссии"
                    type="text"
                    multiline
                    maxRows={4}
                    rows={4}
                    formik={formik}
                  />
                </Item>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
                <Grid item>
                  <Submit
                    color="primary"
                    component="label"
                    variant="contained"
                  >
                    Прикрепить служебную записку
                    <Input
                      style={{display: "none"}}
                      inputProps={{
                        type: 'file',
                        accept: ".pdf",
                        onChange: (event: InputBaseComponentProps) => {
                          const element = event.currentTarget as HTMLInputElement;
                          const fileList: FileList | null = element.files;
                          if (fileList?.length) {
                            const file = fileList[0];
                            if (checkMaxSize(file, 50)) {
                              dispatch({
                                type: AlertActionsTypes.ERROR, payload: {
                                  message: 'Размер файла превышает 50 Мб.',
                                  type: 'error'
                                }
                              })
                            } else {
                              formik.setFieldValue('file', file)
                            }
                          }
                        }
                      }}
                    />
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Подтвердить
                  </Submit>
                </Grid>
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </form>
      </Dialog>
      <CloseDialog
        open={openDialog}
        onClose={onCloseDialog}
        onCloseWithoutSaving={onCloseWithoutSaving}
      />
    </React.Fragment>
  ) : null;
}