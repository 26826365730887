import { TableRow } from "@devexpress/dx-react-grid";
import { Table as MUITable } from "@devexpress/dx-react-grid-material-ui";
import { Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import { useDebounce } from "use-debounce";
import TextField from "../App/components/Input/TextField";
import { Table } from "../App/components/Table";
import Actions from "../App/components/Table/Actions";
import { TableHeader } from "../App/components/Table/TableHeader";
import Title from "../App/components/Table/Title";
import { FiltersActionsTypes } from "../App/interfaces/filter";
import { ISelectPage } from "../App/interfaces/select";
import { ISelect } from "../App/interfaces/select";
import { IRootState } from "../App/reducers/store";
import { EquipmentActions } from "../Realty/actions/equipment";
import { Create } from "../Realty/components/Equipment/Buttons/Create";
import Delete from "../Realty/components/Equipment/Buttons/Delete";
import Edit from "../Realty/components/Equipment/Buttons/Edit";
import Sticker from "../Realty/components/Equipment/Buttons/Sticker";
import { Stickers } from "../Realty/components/Equipment/Buttons/Stickers";
import { Upload as EquipmentUpload } from "../Realty/components/Equipment/Buttons/Upload";
import { IEquipment } from "../Realty/interfaces/equipment";
import { Create as Inventory } from "./components/Registry/Buttons/Create";

const PREFIX = 'Equipments'

const classes = {
  content: `${PREFIX}-content`,
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`
}

const Content = styled(Grid)(({theme}) => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    [theme.breakpoints.down('md')] : {
      height: 'calc(100vh - 147px)',
      minHeight: 'calc(100vh - 147px)',
    },
    [theme.breakpoints.up('md')] : {
      height: 'calc(100vh - 128px)',
      minHeight: 'calc(100vh - 128px)',
    },
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

export default function Equipments(): JSX.Element | null {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: any = useDispatch()
  const {filter} = useSelector((state: IRootState) => state.filters.equipments)
  const [items, setItems] = useState<{data: Array<IEquipment>, meta?: { total?: number | null }}>({data: [], meta: { total: null }})
  const [page, setPage] = useState(1)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [search] = useDebounce(value, 900);
  const [selection, setSelection] = useState<Array<any>>([])
  const [selectionTable, setSelectionTable] = useState<ISelect>({pages: [], isSelectAll: false})

  const columns = [
    {name: 'land.name', title: 'Земельный участок', filter: 'lands'},
    {name: 'land.responsible', title: 'Ответственный'},
    {name: 'floor', title: 'Этаж', filter: 'floors'},
    {name: 'building.address', title: 'Адрес', filter: 'buildings'},
    {name: 'building.cadastralNumber', title: 'Кадастровый номер'},
    {name: 'building.name', title: 'Корпус'},
    {name: 'building.responsible', title: 'Ответственный'},
    {name: 'building.safety', title: 'Ответственный (Противопожарная безопасность)'},
    {name: 'room.number', title: '№ помещения', filter: 'rooms'},
    {name: 'room.type', title: 'Тип помещения'},
    {name: 'room.safety', title: 'Ответственный (Противопожарная безопасность)'},
    {name: 'room.subdivision', title: 'Наименование'},
    {name: 'room.subdivision.manager', title: 'Руководитель'},
    {name: 'equipment.type', title: 'Тип'},
    {name: 'equipment.name', title: 'Наименование'},
    {name: 'equipment.inventoryNumber', title: 'Инвентарный номер'},
    {name: 'equipment.description', title: 'Общее'},
    {name: 'equipment.serialNumber', title: 'Серийный номер'},
    {name: 'equipment.specification', title: 'Тех.характеристики'},
    {name: 'equipment.producingDate', title: 'Дата производства'},
    {name: 'equipment.guarantee', title: 'Гарантия'},
    {name: 'equipment.accountingDate', title: 'Дата принятия к учету'},
    {name: 'equipment.price', title: 'Балансовая стоимость'},
    {name: 'equipment.availability', title: 'Факт. наличие'},
    {name: 'equipment.quantity', title: 'Кол-во'},
    {name: 'equipment.unit', title: 'Ед. измерения'},
    {name: 'equipment.status', title: 'Статус'},
    {name: 'equipment.function', title: 'Целевая функция'},
    {name: 'equipment.responsible.name', title: 'ФИО', filter: 'responsible'},
    {name: 'equipment.responsible.position', title: 'Должность'},
    {name: 'equipment.responsible.subdivision', title: 'Подразделение', filter: 'subdivisions'},
    {name: 'equipment.user.name', title: 'ФИО'},
    {name: 'equipment.user.position', title: 'Должность'},
    {name: 'equipment.user.subdivision', title: 'Подразделение'},
    {name: 'equipment.comment', title: 'Комментарии'},
    {name: 'actions', title: ' '},
  ]

  const [columnBands] = useState([
    {
      title: 'Земельный участок',
      children: [
        { columnName: 'land.name' },
        { columnName: 'land.responsible' },
      ],
    },
    {
      title: 'Здание',
      children: [
        { columnName: 'building.address' },
        { columnName: 'building.address' },
        { columnName: 'building.cadastralNumber' },
        { columnName: 'building.name' },
        { columnName: 'building.responsible' },
        { columnName: 'building.safety' }
      ],
    },
    {
      title: 'Помещение',
      children: [
        { columnName: 'room.number' },
        { columnName: 'room.type' },
        { columnName: 'room.safety' },
        {
          title: 'Подразделение',
          children: [
            { columnName: 'room.subdivision' },
            { columnName: 'room.subdivision.manager' },
          ],
        },
      ],
    },
    {
      title: 'Оборудование (МТО)',
      children: [
        { columnName: 'equipment.type' },
        { columnName: 'equipment.name' },
        { columnName: 'equipment.inventoryNumber' },
        {
          title: 'Описание',
          children: [
            { columnName: 'equipment.description' },
            { columnName: 'equipment.serialNumber' },
            { columnName: 'equipment.specification' },
            { columnName: 'equipment.producingDate' },
            { columnName: 'equipment.guarantee' },
            { columnName: 'equipment.accountingDate' },
            { columnName: 'equipment.price' },
          ],
        },
        { columnName: 'equipment.availability' },
        { columnName: 'equipment.quantity' },
        { columnName: 'equipment.unit' },
        { columnName: 'equipment.status' },
        { columnName: 'equipment.function' },
        {
          title: 'Мат.ответственное лицо',
          children: [
            { columnName: 'equipment.responsible.name' },
            { columnName: 'equipment.responsible.position' },
            { columnName: 'equipment.responsible.subdivision' },
          ],
        },
        {
          title: 'Пользователь МТО',
          children: [
            { columnName: 'equipment.user.name' },
            { columnName: 'equipment.user.position' },
            { columnName: 'equipment.user.subdivision' },
          ],
        },
        { columnName: 'equipment.comment' },
      ],
    }
  ]);

  const [columnOrder, setColumnOrder] = useState([
    'equipment.type',
    'equipment.name',
    'equipment.inventoryNumber',
    'equipment.description',
    'equipment.serialNumber',
    'equipment.specification',
    'equipment.producingDate',
    'equipment.guarantee',
    'equipment.accountingDate',
    'equipment.price',
    'equipment.availability',
    'equipment.quantity',
    'equipment.unit',
    'equipment.status',
    'equipment.function',
    'equipment.responsible.name',
    'equipment.responsible.position',
    'equipment.responsible.subdivision',
    'equipment.user.name',
    'equipment.user.position',
    'equipment.user.subdivision',
    'equipment.comment',
    'land.name',
    'land.responsible',
    'floor',
    'building.address',
    'building.cadastralNumber',
    'building.name',
    'building.responsible',
    'building.safety',
    'room.number',
    'room.type',
    'room.safety',
    'room.subdivision',
    'room.subdivision.manager',
    'actions'
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'land.name', width: 200},
    {columnName: 'land.responsible', width: 200},
    {columnName: 'floor', width: 200},
    {columnName: 'building.address', width: 200},
    {columnName: 'building.cadastralNumber', width: 200},
    {columnName: 'building.name', width: 200},
    {columnName: 'building.responsible', width: 200},
    {columnName: 'building.safety', width: 400},
    {columnName: 'room.number', width: 200},
    {columnName: 'room.type', width: 200},
    {columnName: 'room.safety', width: 400},
    {columnName: 'room.subdivision', width: 200},
    {columnName: 'room.subdivision.manager', width: 200},
    {columnName: 'equipment.type', width: 200},
    {columnName: 'equipment.name', width: 200},
    {columnName: 'equipment.inventoryNumber', width: 200},
    {columnName: 'equipment.description', width: 200},
    {columnName: 'equipment.serialNumber', width: 200},
    {columnName: 'equipment.specification', width: 200},
    {columnName: 'equipment.producingDate', width: 200},
    {columnName: 'equipment.guarantee', width: 200},
    {columnName: 'equipment.accountingDate', width: 200},
    {columnName: 'equipment.price', width: 250},
    {columnName: 'equipment.availability', width: 200},
    {columnName: 'equipment.quantity', width: 200},
    {columnName: 'equipment.unit', width: 200},
    {columnName: 'equipment.status', width: 200},
    {columnName: 'equipment.function', width: 200},
    {columnName: 'equipment.responsible.name', width: 200},
    {columnName: 'equipment.responsible.position', width: 200},
    {columnName: 'equipment.responsible.subdivision', width: 200},
    {columnName: 'equipment.user.name', width: 200},
    {columnName: 'equipment.user.position', width: 200},
    {columnName: 'equipment.user.subdivision', width: 200},
    {columnName: 'equipment.comment', width: 200},
    {columnName: 'actions', width: 150, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {

      const skip = (page - 1) * filter.take
      dispatch(EquipmentActions.equipments({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...(!items.meta?.total ? {meta: true} : {}),
        ...(filter.lands?.length ? {lands: filter.lands.join(',')} : {}),
        ...(filter.buildings?.length ? {buildings: filter.buildings.join(',')} : {}),
        ...(filter.floors?.length ? {floors: filter.floors.join(',')} : {}),
        ...(filter.rooms?.length ? {rooms: filter.rooms.join(',')} : {}),
        ...(filter.responsible?.length ? {responsible: filter.responsible.join(',')} : {}),
        ...(filter.subdivisions?.length ? {subdivisions: filter.subdivisions.join(',')} : {}),
        ...(search?.length ? {search: search} : {}),
      })).then((response: {
        data: [IEquipment],
        meta?: {
          total?: number
        },
      }) => {
        setItems({
          data: response.data,
          meta: response.meta ?? items.meta
        })
        const idx: number = selectionTable.pages.findIndex((item: ISelectPage): boolean => item.page === page);
        const indexses: number[] = response.data.map((item: IEquipment, idx: number) => idx);
        if (selectionTable.isSelectAll) {
          if (idx !== -1) {
            setSelection(selectionTable.pages[idx].selection);
          } else {
            setSelectionTable((prevState: ISelect) => {
              prevState.pages.push({
                page,
                isSelectAll: true,
                selection: indexses,
                notItems: response.data.filter((item: IEquipment, index: number) => !indexses.includes(index)).map((item) => item.id),
                items: response.data.filter((item: IEquipment, index: number) => indexses.includes(index)).map((item) => item.id),
              });
              return prevState;
            });
            setSelection(indexses);
          }
        } else {
          if (idx !== -1) {
            setSelection(selectionTable.pages[idx].selection);
          } else {
            setSelectionTable((prevState: ISelect) => {
              prevState.pages.push({
                page,
                isSelectAll: false,
                selection: [],
                notItems: [],
                items: []
              });
              return prevState;
            });
            setSelection([]);
          }
        }

        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setItems({
        data: items.data,
        meta: { total:  null }
      })
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, search])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.EQUIPMENTS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  const handleSelection = (val: Array<number>): void => {
    setSelectionTable((prevState: ISelect) => {
      const idx: number = prevState.pages.findIndex((item: ISelectPage): boolean => item.page === page);
      if (idx !== -1) {
        const pages: ISelectPage = prevState.pages[idx];
        const diff: number = val.length - pages.selection.length;
        if (diff === 1 || diff === -1) {
          prevState.pages[idx].isSelectAll = false;
        } else if (diff === items.data.length) {
          prevState.pages[idx].isSelectAll = true;
        }
        prevState.pages[idx].selection = val;
        prevState.pages[idx].notItems = items.data.filter((item: IEquipment, index: number) => !val.includes(index)).map((item) => item.id);
        prevState.pages[idx].items = items.data.filter((item: IEquipment, index: number) => val.includes(index)).map((item) => item.id);
      } else {
        prevState.pages.push({
          page,
          isSelectAll: val.length === items.data.length,
          selection: val,
          notItems: items.data.filter((item: IEquipment, index: number) => !val.includes(index)).map((item) => item.id),
          items: items.data.filter((item: IEquipment, index: number) => val.includes(index)).map((item) => item.id)
        });
      }
      prevState.isSelectAll = val.length === items.data.length ? true : prevState.isSelectAll
      setSelection(val);
      return prevState;
    })
  }

  const getEquipmentsSelect = () => {
    let items: number[] = [];
    selectionTable.pages.forEach((item: ISelectPage): void => {
      items = selectionTable.isSelectAll ? items.concat(item.notItems) : items.concat(item.items);
    })
    return {isSelectAll: selectionTable.isSelectAll, items: items};
  }

  return (
    <Content>
      <Title
        title={"Реестр МТО"}
        actions={[
          <Grid item>
            <TextField
              type="text"
              value={value}
              placeholder="Поиск"
              icon={<Search />}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setValue(value.length ? value : '')
              }}
            />
          </Grid>,
          <EquipmentUpload
            onClick={() => setLoading(false)}
          />,
          <Create
            onClick={(item) => {
              setItems({
                data: [
                  item,
                  ...((items.data.length === filter.take) ? items.data.slice(0, filter.take) : items.data)
                ],
                meta: {
                  total: (items.meta?.total ?? 0) + 1
                },
              })
            }}
          />,
          <Inventory
              data={getEquipmentsSelect()}
              onClick={(): void => {
                setSelection([])
              }}
          />,
          <Stickers
              data={getEquipmentsSelect()}
          />,
        ]}
      />
      <Table
        meta={items.meta}
        name={'equipments'}
        selection={selection}
        onSelectionChange={handleSelection}
        rows={loading ? items.data.map((item: IEquipment) => {
          const room = item.room;
          const floor = room?.floor;
          const building = floor?.building;

          return {
            id: item.id,
            'land.name': building?.land.cadastralNumber,
            'land.responsible': building?.land.responsible?.name,
            'floor': floor?.name,
            'building.address': building?.address,
            'building.cadastralNumber': building?.cadastralNumber,
            'building.name': building?.name,
            'building.responsible': building?.responsible?.name,
            'building.safety': building?.safety?.name,
            'room.number': room?.name,
            'room.type': room?.type.name,
            'room.safety': room?.safety?.name,
            'room.subdivision': room?.subdivision?.name,
            'room.subdivision.manager': room?.subdivision?.manager?.name,
            'equipment.type': item.type?.name,
            'equipment.name': item.name,
            'equipment.inventoryNumber': item.index ? `${item.inventoryNumber} (${item.index})` : item.inventoryNumber,
            'equipment.description': item.description,
            'equipment.serialNumber': item.serialNumber,
            'equipment.specification': item.specification,
            'equipment.producingDate': item.producingDate ? new Date(item.producingDate).toLocaleDateString() : null,
            'equipment.guarantee': item.guarantee ? 'Да' : 'Нет',
            'equipment.accountingDate': item.accountingDate ? new Date(item.accountingDate).toLocaleDateString() : null,
            'equipment.price': item.price,
            'equipment.availability': item.availability ? 'Да' : 'Нет',
            'equipment.quantity': item.quantity,
            'equipment.unit': item.unit?.name,
            'equipment.status': item.status?.name,
            'equipment.function': item.function?.name,
            'equipment.responsible.name': item.responsible?.name,
            'equipment.responsible.position': item.responsible?.subdivisions?.map(subdivision => subdivision.position?.name).join(','),
            'equipment.responsible.subdivision': item.responsible?.subdivisions?.map(subdivision => subdivision.subdivision?.name).join(','),
            'equipment.user.name': item.user?.name,
            'equipment.user.position': item.user?.subdivisions?.map(subdivision => subdivision.position?.name).join(','),
            'equipment.user.subdivision': item.user?.subdivisions?.map(subdivision => subdivision.subdivision?.name).join(','),
            'equipment.comment': item.comment,
            actions: <Actions
              code={{
                button: <Sticker
                    equipment={item}
                />
              }}
              edit={{
                button: <Edit
                  equipment={item}
                  onClick={(item) => {
                    setItems({
                      ...items,
                      ...{data: items.data.map((el: IEquipment) => (el.id === item.id) ? item : el)}
                    })
                  }}
                />
              }}
              delete={{
                button: <Delete
                  id={item.id}
                  onClick={() => {
                    setItems({
                      data: items.data.filter((el: IEquipment) => el.id !== item.id),
                      meta: {
                        total: (items.meta?.total ?? 0) - 1
                      },
                    })
                  }}
                />
              }}
            />,
          }
        }) : []}
        columns={columns}
        columnBands={columnBands}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        columnsSettings={{
          columnOrder: columnOrder,
          setColumnOrder: setColumnOrder,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.EQUIPMENTS}
        getValues={EquipmentActions.filter}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`/inventory/equipment/${row.id}`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          select: null,
          'land.name': {
            sort: true,
            name: 'lands',
            type: 'values',
          },
          'building.address': {
            sort: true,
            name: 'buildings',
            type: 'values',
          },
          'floor': {
            sort: true,
            name: 'floors',
            values: {
             buildings: filter.buildings
            },
            type: 'values',
          },
          'room.number': {
            sort: true,
            name: 'rooms',
            values: {
              floors: filter.floors,
              buildings: filter.buildings
            },
            type: 'values',
          },
          'equipment.responsible.name': {
            sort: true,
            name: 'responsible',
            type: 'values',
          },
          'equipment.responsible.subdivision': {
            sort: false,
            name: 'subdivisions',
            type: 'values',
          },
          'room.subdivision': null,
          'room.subdivision.manager': null,
          'equipment.responsible.position': null,
          'equipment.user.position': null,
          'equipment.user.subdivision': null,
          actions: null,
        }}
      />
    </Content>
  )
}