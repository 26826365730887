import {HttpService} from "../../App/services/http"

export const BuildingService = {
  rooms,
  workload,
  repair
}

function rooms(id: number) {
  return HttpService.get(`/statistic/building/${id}/rooms`)
    .then(
      response => {
        return response
      })
}

function workload(id: number) {
  return HttpService.get(`/statistic/building/${id}/workload`)
    .then(
      response => {
        return response
      })
}

function repair(id: number) {
  return HttpService.get(`/statistic/building/${id}/repair`)
    .then(
      response => {
        return response
      })
}