export interface IAccount {
  id: number,
  login: string,
  blocked: boolean,
  name: string,
  email: string,
  position: string,
  roles?: Array<{ id: number, key: string, name: string, permissions?: Array<{ id: number, key: string, name: string }> }>,
  companies?: Array<{ id: number, name: string }>,
  permissions?: Array<{ id: number, key: string, name: string }>,
  created: Date,
  updated: Date,
}

export interface IAccountState {
  account: IAccount | null
}

export enum AccountActionsTypes {
  FETCH_ACCOUNT = 'FETCH_ACCOUNT'
}