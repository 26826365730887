import React from "react"
import {Submit} from "../../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../../App/components/Svg/AddIcon";
import Access from "../../../../../Auth/components/Access";
import Person from "../Person";
import {IRoom} from "../../../../interfaces/room";
import {ICompany} from "../../../../../Company/interfaces/company";
import {IEmployee} from "../../../../../Company/interfaces/employee";

type Props = {
  onClick?: (employee: IEmployee) => void
  room: IRoom,
  company: ICompany
}

export function Create(props: Props): JSX.Element {
  const {company, room, onClick} = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={['admin']}>
      <React.Fragment>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          disabled={room._count.people >= room.numberWorkplaces}
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Добавить
        </Submit>
        {open ? <Person open={open} handleClose={handleClose} onClick={onClick} roomId={room.id} companyId={company.id}/> : null}
      </React.Fragment>
    </Access>
  )
}
