import {useParams} from "react-router";
import { useSearchParams } from "react-router-dom";
import Card from "./components/Card";
import Journal from "./Journal";

export default function History(): JSX.Element | null {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const getPage = (): JSX.Element | null => {
    switch (params?.section) {
      case 'journal':
        return <Journal/>;
      case 'card':
        const objectTypeId = searchParams.get('objectTypeId');
        return <Card id={String(params.id)} objectTypeId={Number(objectTypeId)}/>;
      default:
        return null;
    }
  }

  return getPage();
}