import {useEffect, useState} from "react"
import * as AppStorage from "../App/helpers/storage"
import {AccountActions} from "../Account/actions/account";
import {useDispatch, useSelector} from "react-redux";
import {NavigateFunction, useNavigate} from "react-router";
import {IRootState} from "../App/reducers/store";
import {AuthorizationServices} from "./services/authorization";
import {AuthActions} from "./actions/auth";

export default function Account(props: { children: JSX.Element, roles?: Array<string> | string , permissions?: Array<string> | string }): JSX.Element | null {
    const dispatch: any = useDispatch();
    const {roles, permissions, children} = props
    const {account} = useSelector((state: IRootState) => state.account)
    const navigate: NavigateFunction = useNavigate();
    const [allowed, setAllowed] = useState(false);

    // eslint-disable-next-line
    useEffect(() => {
        const token: string | null = AppStorage.get('token.access');

        if (account) {
            if (account.blocked) {
                dispatch(AuthActions.logout()).then(() => navigate('/'))
            }

            if (roles || permissions) {
                if (AuthorizationServices.access(account, roles, permissions)) {
                    setAllowed(true)
                } else {
                    navigate('/');
                }
            } else {
                setAllowed(true);
            }
        } else if (token) {
            const getAccount = async () => {
                return await dispatch(AccountActions.account());
            }

            getAccount().catch(() => {
                dispatch(AuthActions.logout()).then(() => navigate('/'))
            })
        } else {
            navigate('/login');
        }
    });

    return allowed ? children : null
}
