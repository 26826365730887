import React, {Fragment, useEffect, useState} from "react"
import {Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import Menu from "./Menu";
import Canvas, {Shape} from "../../Canvas";
import {useParams} from "react-router";
import {LandActions} from "../../actions/land";
import {LandActions as RealtyLandActions} from "../../../Realty/actions/land";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {ILand} from "../../../Realty/interfaces/land";

const Page = styled(Grid)(() => ({
  width: 'calc(100% - 260px)',
  height: 'calc(100vh - 172px)',
  [`& .MuiGrid-item`]: {
    height: "100%",
  },
}))

export function Editor(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const params = useParams();

  const [load, setLoad] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [land, setLand] = useState<ILand | null>(null);
  const [object, setObject] = useState<{id: number} | null>(null);
  const [shapes, setShapes] = useState<{[key: number]: Shape}>({});
  const [file, setFile] = useState();
  const [image, setImage]: any = useState(null);

  useEffect(() => {
    const getData = async (id: number) => {
      return await dispatch(RealtyLandActions.markup(id))
    }

    if (!load && params.id) {
      getData(Number(params.id)).then(response => {
        setLoad(true)
        setLand(response)
      })
    }
  })

  useEffect(() => {
    if (land && !initialize) {
      if (land.image) {
        setImage(land?.image)
      }
      if (land.shapes?.length) {
        setShapes(land.shapes.reduce((result, shape, index) => {
          return {
            ...result,
            [index + 1]: shape
          }
        }, {}))
      }
      setInitialize(true)
    }
  }, [land, initialize])

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setImage(reader.result)
      };
    } else {
      setImage(null)
    }
  }, [file])

  return (initialize && land) ? (
    <Fragment>
      <Page container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Canvas
            image={image}
            object={object}
            setObject={setObject}
            shapes={shapes}
            setShapes={setShapes}
          />
        </Grid>
      </Page>
      <Menu
        land={land}
        setFile={setFile}
        object={object}
        setObject={setObject}
        handleSubmit={() => {
          const formData = new FormData();

          if (image) {
            formData.append('image', image)
          }

          if (Object.keys(shapes).length) {
            if (Object.entries(shapes).find(([, shape]) => (shape.points.length && !shape.looped))) {
              dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                  message: 'Все фигуры разметки должны быть замкнуты.',
                  type: 'error'
                }
              })

              return;
            }

            formData.append(`shapes`, JSON.stringify(Object.entries(shapes).filter(([, shape]) => shape.points.length).map(([, shape]) => shape)))
          }

          dispatch(LandActions.update(land.id, formData)).then(
            () => {
              dispatch({
                type: AlertActionsTypes.SUCCESS, payload: {
                  message: 'Разметка успешно сохранена.',
                  type: 'success'
                }
              })
            }
          )
        }}
      />
    </Fragment>
  ) : null
}