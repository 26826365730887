import {styled} from '@mui/material/styles';
import {Input, Select as MuiSelect} from "@mui/material";
import React from "react";

const CustomMuiSelect = styled(MuiSelect)({
  '&.MuiInputBase-root': {
    height: "32px",
    borderRadius: "2px",
    padding: "0 0 0 10px",
    border: "1px solid #e5e5e5",
}
})

export default function Select(props: any): JSX.Element {
  return <CustomMuiSelect
  input={(
      <Input disableUnderline/>
  )}
    {...props}
  ></CustomMuiSelect>
}