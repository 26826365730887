import { AlertActionsTypes } from "../../App/interfaces/alert";
import { LoadingActionsTypes } from "../../App/interfaces/loading";
import { Dispatch } from "../../App/reducers/store";
import { IFilter } from "../interfaces/equipment/filter";
import { IEquipmentInput } from "../interfaces/input/equipment";
import { EquipmentService } from "../services/equipment";

export const EquipmentActions = {
  equipments,
  equipment,
  number,
  update,
  create,
  delete: remove,
  import: importFile,
  filter,
  addFile,
  deleteFile,
  versions,
  restore,
  stickers
}

function equipments(filter: IFilter) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.equipments(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function equipment(id: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.equipment(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function number(number: string) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.number(number)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error.message)
        }
      )
  })
}

function update(id: number, data: IEquipmentInput) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Оборудование обновлено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function create(data: IEquipmentInput) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Оборудование добавлено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function remove(id: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.delete(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Оборудование удалено успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function importFile(formData: FormData) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})

    return EquipmentService.import(formData)
      .then(
        (response) => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: `Загружено: ${response.correct} из ${response.items}`,
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function filter(params: object) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    return EquipmentService.filter(params)
      .then(
        response => {
          resolve(response)
        },
        error => {
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function addFile(id: number, formData: FormData) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.addFile(id, formData)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function deleteFile(id: number, fileId: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.deleteFile(id, fileId)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function versions(guid: string) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.versions(guid)
    .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
    )
  })
}

function restore(id: number) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.restore(id)
    .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
    )
  })
}

function stickers(isSelectAll: boolean, ids: number[]) {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return EquipmentService.stickers(isSelectAll, ids)
    .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})

          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
    )
  })
}