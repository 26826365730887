import {HttpService} from "../../App/services/http"
import {IFilter} from "../interfaces/filter";
import {IPositionInput} from "../interfaces/input/position";

export const CompanyPositionService = {
  items,
  item,
  update,
  create,
  delete: remove,
  filter
}

function items(id: number, filter: IFilter) {
  return HttpService.get(`/company/${id}/positions`, filter)
    .then(
      response => {
        return response
      })
}

function item(id: number, position: number) {
  return HttpService.get(`/company/${id}/position/${position}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, position: number, data: IPositionInput) {
  return HttpService.put(`/company/${id}/position/${position}`, data)
    .then(
      response => {
        return response
      })
}

function create(id: number, data: IPositionInput) {
  return HttpService.post(`/company/${id}/position`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number, position: number) {
  return HttpService.delete(`/company/${id}/position/${position}`)
    .then(
      response => {
        return response
      })
}

function filter(id: number, params: object) {
  return HttpService.get(`/company/${id}/positions/filter`, params)
    .then(
      response => {
        return response
      })
}