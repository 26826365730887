import { Close } from '@mui/icons-material';
import { Checkbox, Dialog as MUIDialog, DialogActions, DialogTitle, Divider, FormControlLabel, Grid, IconButton, useMediaQuery, useTheme, } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormikValues, useFormik } from "formik";
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Submit } from "../../App/components/Buttons/Submit";
import { CloseDialog } from "../../App/components/Dialog/CloseDialog";
import { Autocomplete } from "../../App/components/Input/AsyncAutocomplete";
import TextField from "../../App/components/Input/TextField"
import { DialogContent } from "../../App/components/Page/DialogContent";
import { transformData } from "../../App/helpers/transformData";
import IResponseError from "../../App/interfaces/responseError";
import { IRootState } from "../../App/reducers/store";
import Access from "../../Auth/components/Access";
import isRole from "../../Auth/hooks/authorize";
import { CompanyActions } from "../../Company/actions/company";
import { CompanyEmployeeActions } from "../../Company/actions/company.employee";
import { ICompany } from "../../Company/interfaces/company";
import { IEmployee } from "../../Company/interfaces/employee";
import { RegistryActions } from "../actions/registry";
import { IRegistryInput } from "../interfaces/input/registry";
import { IRegistry } from "../interfaces/registry";

const Item = styled(Grid)(() => ({
  height: "100%",
  minHeight: "80px",
  width: "100%",
}))

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  [theme.breakpoints.down('md')]: {
    fontWeight: 500,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')]: {
    fontWeight: 600,
    fontSize: '20px',
  },
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

type RegistryProps = {
  registry?: IRegistry,
  isSelectAll: boolean
  equipments?: Array<number>,
  open: boolean,
  handleClose: () => void,
  onClick?: (registry?: IRegistry) => void
}

export default function Registry(props: RegistryProps): JSX.Element | null {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const {account} = useSelector((state: IRootState) => state.account)
  const {registry, equipments, onClick, isSelectAll} = props
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isAdmin] = isRole(account, 'admin');

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])

  const handleClose = (registry?: IRegistry) => {
    if (onClick && registry) {
      onClick(registry)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const isAccess = () => {
    return isAdmin && (!registry || (registry?.status?.key === 'draft'))
  }

  const formik = useFormik({
    initialValues: {
      company: registry?.company,
      planned: registry?.planned ?? false,
      date: registry ? new Date(registry.date) : new Date(),
      chairman: registry?.chairman,
      members: registry ? registry.members : [],
      ...(!registry ? {equipments: equipments} : {})
    },
    validationSchema: Yup.object().shape({
      company: Yup.object().required('Поле обязательно к заполнению'),
      date: Yup.string().required('Поле обязательно к заполнению'),
      planned: Yup.boolean(),
      chairman: Yup.object().required('Поле обязательно к заполнению'),
      members: Yup.array().of(Yup.object().shape({
        id: Yup.number().required('Поле обязательно к заполнению'),
      })).required('Поле обязательно к заполнению'),
      ...(!registry ? {equipments: Yup.array().required('Поле обязательно к заполнению')} : {})
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting}): void => {
      const data = transformData({
        ...values,
        members: values.members.map((member: IEmployee) => member.id),
        isSelectAll
      })
      dispatch(registry ? RegistryActions.update(registry.id, data as IRegistryInput) : RegistryActions.create(data as IRegistryInput))
      .then(
          (registry: IRegistry) => {
            setSubmitting(true)
            handleClose(registry)
          },
          (error: IResponseError) => setErrors(error.errors)
      )
    }
  });

  return open ? (
      <React.Fragment>
        <Dialog
            fullScreen={mobile}
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={handleCloseDialog}
            onDoubleClick={(e: any) => e.stopPropagation()}
        >
          <form onSubmit={formik.handleSubmit} style={{height: "100%"}}>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Title>Инвентаризационная опись</Title>
                <IconButton
                    onClick={handleCloseDialog}
                >
                  <Close/>
                </IconButton>
              </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent>
              <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                <Item item xs={mobile ? 12 : 6}>
                  <Autocomplete
                      required
                      disabled={!isAccess()}
                      name="company"
                      label="Компания"
                      setValue={(name: string, value: ICompany) => formik.setValues({
                        ...formik.values,
                        [name]: value,
                        chairman: null,
                        members: []
                      })}
                      getValues={(search: string) => CompanyActions.companies({
                        take: 20,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.company}
                      getOptionLabel={(option: ICompany) => option?.name ?? option}
                      formik={formik}
                  />
                </Item>
                <Grid item xs={mobile ? 12 : 6}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            disabled={!isAccess()}
                            checked={formik.values.planned}
                            onChange={(e: any) => formik.setFieldValue('planned', e.target.checked)}
                        />
                      }
                      label="Плановая"
                  />
                </Grid>
                <Grid item xs={mobile ? 12 : 6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disabled={!isAccess()}
                        label="Дата начала"
                        renderInput={(params) => <TextField
                            name={"date"}
                            required
                            disabled={!isAccess()}
                            formik={formik}
                            label={params.label}
                            type={params.type}
                            InputProps={{
                              disableUnderline: !(formik?.touched?.["date"] && formik?.errors?.["date"]),
                              endAdornment: params.InputProps?.endAdornment
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: null
                            }}
                            inputRef={params.inputRef}
                        />
                        }
                        onChange={(e: any) => {
                          formik.setFieldValue('date', e, true)
                        }}
                        value={formik.values.date}/>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={mobile ? 12 : 6}>
                  <Autocomplete
                      required
                      name={"chairman"}
                      disabled={!formik.values.company || !isAccess()}
                      label="Председатель комиссии"
                      setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                      getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                        take: 20,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.chairman}
                      getOptionLabel={(option: IEmployee) => option?.name ?? option}
                      formik={formik}
                  />
                </Grid>
                <Grid item xs={mobile ? 12 : 6}>
                  <Autocomplete
                      name={"members"}
                      multiple={true}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled={!formik.values.company || !isAccess()}
                      label="Члены комиссии"
                      setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                      getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                        take: 20,
                        ...(search ? {search: search} : {})
                      })}
                      value={formik.values.members}
                      getOptionLabel={(option: IEmployee) => option?.name ?? option}
                      formik={formik}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                  {!registry ? (
                      <Typography fontSize="14px">
                        Кол-во записей: {equipments?.length}
                      </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  <Grid container spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                    <Access roles={['admin']}>
                      <Grid item>
                        <Submit
                            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                            variant="contained"
                            type="submit"
                        >
                          Сохранить
                        </Submit>
                      </Grid>
                    </Access>
                    {!mobile ? (
                        <Grid item>
                          <Submit
                              variant="contained"
                              onClick={handleCloseDialog}
                          >
                            Закрыть
                          </Submit>
                        </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
        {openDialog ? (
            <CloseDialog
                open={openDialog}
                onClose={onCloseDialog}
                onCloseWithoutSaving={onCloseWithoutSaving}
            />
        ) : null}
      </React.Fragment>
  ) : null;
}