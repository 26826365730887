import {HttpService} from "../../App/services/http"
import {IFilter} from "../interfaces/filter";
import {ITypeInput} from "../interfaces/Room/Input/type";

export const RoomTypeService = {
  items,
  item,
  update,
  create,
  delete: remove,
  filter
}

function items(filter: IFilter) {
  return HttpService.get(`/dictionary/room/types`, filter)
    .then(
      response => {
        return response
      })
}

function item(id: number) {
  return HttpService.get(`/dictionary/room/type/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: ITypeInput) {
  return HttpService.put(`/dictionary/room/type/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: ITypeInput) {
  return HttpService.post(`/dictionary/room/type`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/dictionary/room/type/${id}`)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/dictionary/room/types/filter", params)
    .then(
      response => {
        return response
      })
}