import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../../../Auth/components/Access";
import {Submit} from "../../../../../App/components/Buttons/Submit";
import Person from "../Person";
import {IEmployee} from "../../../../../Company/interfaces/employee";

type Props = {
  companyId: number,
  roomId: number,
  employee?: IEmployee | null,
  onClick?: (employee: IEmployee) => void
  button?: boolean
}

export function Edit(props: Props): JSX.Element {
  const {companyId, roomId, employee, onClick, button = false} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={['admin']}>
      <React.Fragment>
        {button
          ? (
            <Submit
              variant="contained"
              onClick={handleOpen}
            >
              Редактировать
            </Submit>
          )
          : (
            <IconButton
              onClick={handleOpen}
            >
              <EditIcon/>
            </IconButton>
          )
        }
        {open ?
          <Person roomId={roomId} companyId={companyId} employee={employee} open={open} handleClose={handleClose} onClick={onClick}/> : null}
      </React.Fragment>
    </Access>
  )
}
