import { HttpService } from "../../App/services/http"
import { IFilter } from "../interfaces/filter";
import {IEquipmentInput} from "../interfaces/input/equipment";

export const EquipmentService = {
  equipments,
  equipment,
  add,
  surplus,
  update,
  filter
}

function equipments(id: number, filter: IFilter) {
  return HttpService.get(`/registry/${id}/equipments`, filter)
    .then(
      response => {
        return response
      })
}

function equipment(id: number, number: string) {
  return HttpService.get(`/registry/${id}/equipment/${number}`)
    .then(
      response => {
        return response
      })
}

function add(id: number, equipment: number) {
  return HttpService.post(`/registry/${id}/equipment/${equipment}`)
    .then(
      response => {
        return response
      })
}

function surplus(id: number, equipment: number) {
  return HttpService.patch(`/registry/${id}/equipment/${equipment}/surplus`)
    .then(
      response => {
        return response
      })
}

function update(id: number, equipment: number, data: IEquipmentInput) {
  return HttpService.patch(`/registry/${id}/equipment/${equipment}`, data)
    .then(
      response => {
        return response
      })
}

function filter(id: number, params: object) {
  return HttpService.get(`/registry/${id}/equipments/filter`, params)
    .then(
      response => {
        return response
      })
}