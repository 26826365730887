import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from '@mui/material/styles'
import {Provider} from "react-redux";
import {store} from "./App/reducers/store";
import {theme} from "./App/styles/theme";
import {BrowserRouter} from "react-router-dom";
import Loading from "./App/components/Loading";
import Alerts from "./App/components/Alerts";
import './index.css';
import Routes from './App/routes';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.Fragment>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Loading/>
                <Alerts/>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </React.Fragment>
);