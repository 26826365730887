import {HttpService} from "../../App/services/http"
import {IFilter} from "../interfaces/filter";
import {IItemInput} from "../interfaces/input/item";

export const FloorTypeService = {
  items,
  item,
  update,
  create,
  delete: remove,
  filter
}

function items(filter: IFilter) {
  return HttpService.get(`/dictionary/floor/types`, filter)
    .then(
      response => {
        return response
      })
}

function item(id: number) {
  return HttpService.get(`/dictionary/floor/type/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IItemInput) {
  return HttpService.put(`/dictionary/floor/type/${id}`, data)
    .then(
      response => {
        return response
      })
}

function create(data: IItemInput) {
  return HttpService.post(`/dictionary/floor/type`, data)
    .then(
      response => {
        return response
      })
}

function remove(id: number) {
  return HttpService.delete(`/dictionary/floor/type/${id}`)
    .then(
      response => {
        return response
      })
}

function filter(params: object) {
  return HttpService.get("/dictionary/floor/types/filter", params)
    .then(
      response => {
        return response
      })
}