import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableHead } from "@mui/material";
import { Table } from "@mui/material";
import { Paper } from "@mui/material";
import { TableContainer } from "@mui/material";
import React from "react"
import { IconButton } from "@mui/material";
import Access from "../../../../Auth/components/Access";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ICompany } from "../../../../Company/interfaces/company";
import { IBuilding } from "../../../../Realty/interfaces/building";
import { IEquipment } from "../../../../Realty/interfaces/equipment";
import { IFloor } from "../../../../Realty/interfaces/floor";
import { ILand } from "../../../../Realty/interfaces/land";
import { IRoom } from "../../../../Realty/interfaces/room";

type Props = {
  obj1: ICompany | ILand | IBuilding | IFloor | IRoom | IEquipment;
  obj2: ICompany | ILand | IBuilding | IFloor | IRoom | IEquipment;
};

interface IDiffObject {
  name: string
  last: any
  new: any
}

const keys: { [key: string]: string } = {
  name: 'Наименование',
  short_name: 'Краткое наименование',
  inn: 'ИНН',
  kpp: 'КПП',
  responsible: 'Ответсвенный',
  cadastralNumber: 'Кадастровый номер',
  square: 'Площадь',
  cadastralPrice: 'Кадастровая стоимость',
  address: 'Адрес',
  status: 'Статус',
  category: 'Категория',
  permission: 'Разрешение',
  encumbrances: 'Обременения',
  company: 'Компания',
  land: 'Земельный участок',
  type: 'Тип',
  price: 'Цена',
  equippedVideo: 'Имеет видеонаблюдение',
  equippedSecurity: 'Охрана',
  accessiblePeopleLimitedMobility: 'Доступно для маломобильных групп населения',
  internet: 'Интернет',
  initialYear: 'Год первоначального ввода в эксплуатацию',
  lastOverhaulYear: 'Год последнего капитального ремонта',
  rentalType: 'Тип аренды',
  parking: 'Парковка, количество мест',
  safety: 'Ответственный (Противопожарная безопасность)',
  culturalHeritageMonument: 'Памятник культурного наследия',
  building: 'Здание',
  dateRepair: 'Дата последнего ремонта',
  floor: 'Этаж',
  height: 'Высота',
  subdivision: 'Подразделения',
  rentalInformation: 'Информация о договорах аренды',
  numberWorkplaces: 'Количество рабочих мест всего',
  numberWorkplacesOccupied: 'Количество рабочих занято',
  numberWorkplacesFree: 'Количество рабочих свободно',
  rentalPrice1squareMeter: 'Стоимость аренды 1 кв.м',
  totalDeliveryTimeDays: 'Общий срок сдачи, дне',
  dateOverhaul: 'Дата капитального ремонта',
  dateRedecorating: 'Дата косметического ремонта',
  description: 'Описание',
  quantity: 'Количество',
  serialNumber: 'Серийный номер',
  inventoryNumber: 'Инвентарный номер',
  specification: 'Технические характеристики',
  producingDate: 'Дата производства',
  accountingDate: 'Дата принятия к учету',
  function: 'Целевая функция',
  comment: 'Комментарий',
  unit: 'Единица измерения',
  availability: 'Наличие',
  guarantee: 'Гарантия',
  user: 'Пользователь',
  deleted: 'Удален'
};
const ignoreKeys = [
  'id',
  'created',
  'updated',
  'versionId',
  'version',
  'isLastVersion',
  'image',
  'files',
  'markup',
  'isFloorGenerate',
  'markupId',
  'floors',
  'rooms',
  'buildings',
  'lands',
  'areaId',
  'shapeId',
  '_count',
  'people',
  'companyId',
  'floorId',
  'roomId',
  'landId',
  'buildingId',
  'unitId',
  'statusId',
  'typeId',
];

export default function View(props: Props): JSX.Element {
  const {obj1, obj2} = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const compareObjects = (): IDiffObject[] => {
    const results: IDiffObject[] = [];
    for (const key in obj1) {
      if (
          !ignoreKeys.includes(key) && obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) &&
          obj1[key as keyof typeof obj1] !== obj2[key as keyof typeof obj2]
      ) {
        let approve = true;
        if ((key === 'company' && "company" in obj1 && "company" in obj2 && obj1?.company?.id === obj2?.company?.id) ||
            (key === 'land' && "land" in obj1 && "land" in obj2 && obj1.land?.id === obj2.land?.id) ||
            (key === 'building' && "building" in obj1 && "building" in obj2 && obj1.building?.id === obj2.building?.id) ||
            (key === 'floor' && "floor" in obj1 && "floor" in obj2 && obj1.floor?.id === obj2.floor?.id) ||
            (key === 'room' && "room" in obj1 && "room" in obj2 && obj1.room?.id === obj2.room?.id) ||
            (key === 'category' && "category" in obj1 && "category" in obj2 && obj1.category.id === obj2.category.id) ||
            (key === 'status' && "status" in obj1 && "status" in obj2 && obj1.status?.id === obj2.status?.id) ||
            (key === 'permission' && "permission" in obj1 && "permission" in obj2 && obj1.permission.id === obj2.permission.id) ||
            (key === 'responsible' && "responsible" in obj1 && "responsible" in obj2 && obj1.responsible?.id === obj2.responsible?.id) ||
            (key === 'safety' && "safety" in obj1 && "safety" in obj2 && obj1.safety?.id === obj2.safety?.id) ||
            (key === 'rentalType' && "rentalType" in obj1 && "rentalType" in obj2 && obj1.rentalType?.id === obj2.rentalType?.id) ||
            (key === 'function' && "function" in obj1 && "function" in obj2 && obj1.function?.id === obj2.function?.id) ||
            (key === 'unit' && "unit" in obj1 && "unit" in obj2 && obj1.unit?.id === obj2.unit?.id) ||
            (key === 'user' && "user" in obj1 && "user" in obj2 && obj1.user?.id === obj2.user?.id) ||
            (key === 'type' && "type" in obj1 && "type" in obj2 && obj1.type?.id === obj2.type?.id)
        ) {
          approve = false;
        }
        if (approve) {
          results.push({
            name: getName(key),
            new: getValue(key, obj1[key as keyof typeof obj1]),
            last: getValue(key, obj2[key as keyof typeof obj2]),
          });
        }
      }
    }
    return results;
  };

  const getValue = (key: string, value: any): string => {
    switch (key) {
      case 'category':
      case 'status':
      case 'permission':
      case 'company':
      case 'responsible':
      case 'safety':
      case 'rentalType':
      case 'type':
      case 'land':
      case 'building':
      case 'subdivision':
      case 'floor':
      case 'function':
      case 'unit':
      case 'room':
      case 'user':
        if (value === null) {
          return '';
        }
        return value.name;
      case 'culturalHeritageMonument':
      case 'internet':
      case 'accessiblePeopleLimitedMobility':
      case 'equippedVideo':
      case 'availability':
      case 'guarantee':
        return value ? 'Да' : 'Нет';
      case 'deleted':
      case 'accountingDate':
      case 'producingDate':
      case 'dateOverhaul':
      case 'dateRedecorating':
      case 'dateRepair':
        if (value === null) {
          return '';
        }
        return new Date(value).toLocaleString()
      default:
        return value;
    }
  }
  const getName = (key: string): string => keys[key as keyof typeof keys] || 'Неизвестный';

  return (
      <Access roles={['admin']}>
        <React.Fragment>
          <IconButton onClick={handleOpen}>
            <RemoveRedEyeIcon/>
          </IconButton>
          {open ? (
              <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Параметр</TableCell>
                      <TableCell>Старое значение</TableCell>
                      <TableCell>Новое значение</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {compareObjects().map((row: IDiffObject, index: number) => (
                        <TableRow
                            key={index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {row.new}
                          </TableCell>
                          <TableCell>
                            {row.last}
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          ) : null}
        </React.Fragment>
      </Access>
  );
}