import React from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../App/components/Svg/AddIcon";
import Access from "../../../../Auth/components/Access";
import {IEquipment} from "../../../interfaces/equipment";
import {IRoom} from "../../../interfaces/room";
import Equipment from "../../Equipment";
import Grid from "@mui/material/Grid";
import {useMediaQuery, useTheme} from "@mui/material";

type Props = {
  onClick?: (equipment: IEquipment) => void
  room?: IRoom
}

export function Create(props: Props): JSX.Element | null {
  const { room, onClick } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return !mobile ? (
    <Access roles={['admin']}>
      <Grid item>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Добавить
        </Submit>
        {open ? <Equipment open={open} handleClose={handleClose} onClick={onClick} room={room} /> : null}
      </Grid>
    </Access>
  ) : null
}
