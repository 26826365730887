export const Config = {
    colors: {
        PRIMARY_COLOR: '#3668BF',
        PRIMARY_HOVER_COLOR: "#3e79e5",
        SUCCESS_COLOR: '#00C851',
        SUCCESS_HOVER_COLOR: "#00a64d",
        WARNING_COLOR: '#FF9800',
        WARNING_HOVER_COLOR: "#ff9e00",
        ERROR_COLOR: '#F44336',
        ERROR_HOVER_COLOR: "#d32f2f",
    }
}