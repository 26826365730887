import React from "react"
import Access from "../../../../Auth/components/Access";
import {IRegistry} from "../../../interfaces/registry";
import {Grid, Input, InputBaseComponentProps} from "@mui/material";
import {checkMaxSize} from "../../../../App/helpers/fileStorage";
import {AlertActionsTypes} from "../../../../App/interfaces/alert";
import {Submit} from "../../../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {RegistryActions} from "../../../actions/registry";

type Props = {
  registry: IRegistry,
  setRegistry: (registry: IRegistry) => void
}

export default function File(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch();
  const { registry, setRegistry } = props

  const isAccess = !registry?.file && (registry?.status?.key === 'passed')

  const formik = useFormik({
    initialValues: {
      file: null
    },
    validationSchema: Yup.object().shape({
      file: Yup.mixed().required()
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = new FormData();
      data.append('file', values.file);
      dispatch(RegistryActions.file(registry.id, data))
        .then(
          (registry: IRegistry) => {
            setRegistry(registry)
            setSubmitting(false)
          },
          () => {
            setSubmitting(false)
          }
        )
    }
  });

  return isAccess ? (
    <Grid item>
      <Access roles={['admin', 'chairman', 'working_group']}>
        <form onSubmit={formik.handleSubmit}>
          <Submit
            disabled={formik.isSubmitting}
            color="primary"
            component="label"
            variant="contained"
          >
            Прикрепить служебную записку
            <Input
              style={{display: "none"}}
              inputProps={{
                type: 'file',
                accept: ".pdf",
                onChange: async (event: InputBaseComponentProps) => {
                  const element = event.currentTarget as HTMLInputElement;
                  const fileList: FileList | null = element.files;
                  if (fileList?.length) {
                    const file = fileList[0];
                    if (checkMaxSize(file, 50)) {
                      dispatch({
                        type: AlertActionsTypes.ERROR, payload: {
                          message: 'Размер файла превышает 50 Мб.',
                          type: 'error'
                        }
                      })
                    } else {
                      await formik.setFieldValue('file', file)
                      formik.handleSubmit()
                    }
                  }
                }
              }}
            />
          </Submit>
        </form>
      </Access>
    </Grid>
  ) : null
}
