import {CSSObject, styled, Theme} from "@mui/material/styles";
import {Divider, Drawer as MuiDrawer, Grid, IconButton, Typography} from "@mui/material";
import {Upload} from "../../../App/components/Buttons/Upload";
import {Autocomplete} from "../../../App/components/Input/AsyncAutocomplete";
import React, {useEffect, useState} from "react";
import {BuildingActions} from "../../../Realty/actions/building";
import {Submit} from "../../../App/components/Buttons/Submit";
import {IBuilding} from "../../../Realty/interfaces/building";
import {Close} from "@mui/icons-material";
import {NavigateFunction, useNavigate} from "react-router";
import {ILand} from "../../../Realty/interfaces/land";

const drawerWidth = 260;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)})`,
  [theme.breakpoints.down('sm')]: {
    width: `calc(${theme.spacing(6)})`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Container = styled(Grid)(() => ({
  paddingTop: '56px',
  width: "260px",
  height: "100%",
  background: "white",
  borderRight: "1px solid #e0e0e1",
}))

const Title = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  fontStyle: "normal",
  fontFamily: "Open Sans",
}))

const Item = styled(Grid)(() => ({
  padding: "12px"
}))

const Input = styled(Grid)(() => ({
  padding: "12px",
  height: "72px"
}))

type Props = {
  land?: ILand | null,
  object: {id: number, name?: string} | null
  setFile: (file: any) => void
  setObject: (object: {id: number, name: string} | null) => void
  handleSubmit: () => void
}

export default function Menu(props: Props): JSX.Element | null {
  const navigate: NavigateFunction = useNavigate();

  const {land, object, setObject, handleSubmit} = props

  const [file, setFile] = useState<File | null>();

  useEffect(() => {
    props.setFile(file)
    // eslint-disable-next-line
  }, [file])

  return (
    <Drawer anchor="right" variant="permanent" open={true}>
      <Container container direction="column">
        <Item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Title>Разметка</Title>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`/objects/${land?.id}?section=scheme`)
                }}
              >
                <Close/>
              </IconButton>
            </Grid>
          </Grid>
        </Item>
        <Divider />
        <Item>
          <Upload
            fullWidth
            accept="image/*"
            onChange={(e: any) => {
              if (e.target.files && e.target.files.length) {
                setFile(e.target.files[0]);
              }
            }}
          >
            Выбрать изображение
          </Upload>
        </Item>
        <Input>
          <Autocomplete
            name={"building"}
            label="Здание"
            setValue={(name: string, value: { id: number, name: string }) => {
              setObject(value ? {id: value.id, name: value.name} : null)
            }}
            getValues={(search: string) => BuildingActions.buildings({
              take: 20,
              lands: land?.id.toString(),
              ...(search ? {search: search} : {})
            })}
            isOptionEqualToValue={(option: { id: number, name: string }, value: { id: number, name: string } | null) => {
              return (option.id === value?.id)
            }}
            value={object}
            getOptionLabel={(option: IBuilding) => option?.name ?? option}
          />
        </Input>
        <Item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Submit
              style={{color: "black", background: "#F9FAFA", borderColor: "#DBDEDF"}}
              variant="contained"
              type="submit"
              onClick={() => {
                setFile(null)
              }}
            >
              Очистить
            </Submit>
            <Submit
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Сохранить
            </Submit>
          </Grid>
        </Item>
      </Container>
    </Drawer>
  )
}
