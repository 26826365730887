import React, {useEffect} from "react"
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Close} from '@mui/icons-material';
import {DialogContent} from "../../App/components/Page/DialogContent";
import TextField from "../../App/components/Input/TextField"
import {Submit} from "../../App/components/Buttons/Submit";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {IEquipmentInput} from "../interfaces/input/equipment";
import {IEquipment} from "../interfaces/equipment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Autocomplete} from "../../App/components/Input/AsyncAutocomplete";
import {CloseDialog} from "../../App/components/Dialog/CloseDialog";
import {IRoom} from "../interfaces/room";
import {EquipmentTypeActions} from "../../Dictionaries/actions/equipment.type";
import {IItem} from "../../Dictionaries/interfaces/item";
import {ICompany} from "../../Company/interfaces/company";
import {ILand} from "../interfaces/land";
import {IBuilding} from "../interfaces/building";
import {IFloor} from "../interfaces/floor";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {EquipmentActions} from "../actions/equipment";
import {transformData} from "../../App/helpers/transformData";
import IResponseError from "../../App/interfaces/responseError";
import {CompanyEmployeeActions} from "../../Company/actions/company.employee";
import {CompanyActions} from "../../Company/actions/company";
import {LandActions} from "../actions/land";
import {BuildingActions} from "../actions/building";
import {RoomActions} from "../actions/room";
import {FloorActions} from "../actions/floor";
import {InventoryFunctionActions} from "../../Dictionaries/actions/inventory.function";
import {EquipmentStatusActions} from "../../Dictionaries/actions/equipment.status";
import {Autocomplete as CustomAutocomplete} from "../../App/components/Input/Autocomplete";
import {equippedOptions, IEquippedItem} from "../../Dictionaries/interfaces/Common/input/equippedItem";
import {CommonUnitActions} from "../../Dictionaries/actions/common.unit";
import {IEmployee} from "../../Company/interfaces/employee";
import {IRegistry} from "../../Inventory/interfaces/registry";

const Item = styled(Grid)(() => ({
  height: "80px",
  width: "100%",
}))

const Title = styled(Typography)(({theme}) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  [theme.breakpoints.down('md')] : {
    fontWeight: 500,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')] : {
    fontWeight: 600,
    fontSize: '20px',
  },
  lineHeight: '32px'
}))

const Dialog = styled(MUIDialog)(() => ({
  '& .MuiPaper-root': {
    overflowY: "unset",
    borderRadius: `2px`,
  },
  '& .MuiDialogActions-root': {
    padding: '12px 24px'
  }
}))

export default function Equipment(props: { registry?: IRegistry, room?: IRoom, equipment?: IEquipment, open: boolean, handleClose: () => void, onClick?: (equipment: IEquipment) => void }): JSX.Element | null {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const {registry, room, onClick} = props
  const [equipment, setEquipment] = React.useState<IEquipment | null | undefined>(props.equipment);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [savedEquipment, setSavedEquipment]: any = React.useState(null);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open)
    }
  }, [props.open])


  const handleClose = () => {
    if (onClick && savedEquipment) {
      onClick(savedEquipment)
    }
    setOpen(false);
    props.handleClose()
  };

  const onCloseWithoutSaving = () => {
    setOpen(false)
    props.handleClose()
    setOpenDialog(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseDialog = () => {
    (formik.dirty && !formik.isSubmitting) ? setOpenDialog(true) : handleClose()
  }

  const formik = useFormik({
    initialValues: {
      company: registry ? registry.company :(equipment?.company ?? equipment?.room?.floor?.building?.land?.company ?? room?.floor?.building?.land?.company),
      land: equipment?.room?.floor?.building?.land ?? room?.floor?.building?.land,
      building: equipment?.room?.floor?.building ?? room?.floor?.building,
      floor: equipment?.room?.floor ?? room?.floor,
      unit: equipment?.unit,
      room: equipment?.room ?? room,
      name: equipment?.name,
      description: equipment?.description,
      comment: equipment?.comment,
      specification: equipment?.specification,
      type: equipment?.type,
      function: equipment?.function,
      status: equipment?.status,
      producingDate: equipment?.producingDate ?? new Date(),
      guarantee: equipment?.guarantee,
      availability: equipment?.availability,
      accountingDate: equipment?.accountingDate ?? new Date(),
      quantity: equipment?.quantity,
      price: equipment?.price,
      responsible: equipment?.responsible,
      user: equipment?.user,
      registry: registry,
      inventoryNumber: equipment?.inventoryNumber,
      serialNumber: equipment?.serialNumber
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Поле обязательно к заполнению'),
      description: Yup.string().nullable(),
      comment: Yup.string().nullable(),
      specification: Yup.string().nullable(),
      company: Yup.object().required('Поле обязательно к заполнению'),
      land: Yup.object().required('Поле обязательно к заполнению'),
      building: Yup.object().required('Поле обязательно к заполнению'),
      floor: Yup.object().required('Поле обязательно к заполнению'),
      unit: Yup.object().nullable(),
      room: Yup.object().required('Поле обязательно к заполнению'),
      type: Yup.object().required('Поле обязательно к заполнению'),
      function: Yup.object().nullable(),
      status: Yup.object().nullable(),
      producingDate: Yup.string().nullable(),
      guarantee: Yup.boolean().required('Поле обязательно к заполнению'),
      availability: Yup.boolean().required('Поле обязательно к заполнению'),
      accountingDate: Yup.string().nullable(),
      quantity: Yup.number().nullable().min(0, 'Значение не может быть меньше 0'),
      price: Yup.number().required('Поле обязательно к заполнению').min(0, 'Значение не может быть меньше 0'),
      responsible: Yup.object().nullable(),
      user: Yup.object().nullable(),
      registry: Yup.object().nullable(),
      inventoryNumber: Yup.string().nullable(),
      serialNumber: Yup.string().nullable(),
    }),
    onSubmit: (values: FormikValues, {setErrors, setSubmitting, resetForm}) => {
      const data = transformData(values)
      dispatch(equipment
        ? EquipmentActions.update(equipment.id, (data as IEquipmentInput))
        : EquipmentActions.create(data as IEquipmentInput)
      )
        .then(
          (equipment: IEquipment) => {
            setSubmitting(true)
            setEquipment(equipment)
            setSavedEquipment(equipment)
            resetForm({
              values: {
                name: equipment?.name,
                description: equipment?.description,
                comment: equipment?.comment,
                specification: equipment?.specification,
                type: equipment?.type,
                quantity: equipment?.quantity,
                price: equipment?.price,
                function: equipment?.function,
                status: equipment?.status,
                room: equipment?.room ?? room,
                responsible: equipment?.responsible,
                user: equipment?.user,
                inventoryNumber: equipment?.inventoryNumber,
                serialNumber: equipment?.serialNumber,
                floor: equipment?.room?.floor ?? room?.floor,
                unit: equipment?.unit,
                producingDate: equipment?.producingDate ?? null,
                guarantee: equipment?.guarantee,
                availability: equipment?.availability,
                accountingDate: equipment?.accountingDate ?? null,
                building: equipment?.room?.floor?.building ?? room?.floor?.building,
                land: equipment?.room?.floor?.building?.land ?? room?.floor?.building?.land,
                company: equipment?.company ?? equipment?.room?.floor?.building?.land?.company ?? room?.floor?.building?.land?.company,
              }
            })
          },
          (error: IResponseError) => setErrors(error.errors)
        )
    }
  });

  return open ? (
    <React.Fragment>
      <Dialog
        fullScreen={mobile}
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleCloseDialog}
        onDoubleClick={(e: any) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
          <DialogTitle>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Title>
                {(!!registry && !!equipment) ? "Оборудование" : `${(savedEquipment?.id || equipment?.id) ? 'Редактировать' : 'Добавить'} оборудование`}
              </Title>
              <IconButton
                onClick={handleCloseDialog}
              >
                <Close/>
              </IconButton>
            </Grid>
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <Grid container spacing={3}>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  required
                  disabled={registry && !!equipment}
                  name="name"
                  label="Наименование"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name="type"
                  label="Тип оборудования"
                  required
                  disabled={!!registry && !!equipment}
                  setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => EquipmentTypeActions.items({
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  getOptionLabel={(option: IItem) => option?.code ? `${option.code} ${option.name}` : option.name}
                  value={formik.values.type}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  required
                  name="company"
                  disabled={!!registry}
                  label="Компания"
                  setValue={(name: string, value: ICompany) => formik.setValues({
                    ...formik.values,
                    [name]: value,
                    land: null,
                    building: null,
                    floor: null,
                    room: null
                  })}
                  getValues={(search: string) => CompanyActions.companies({
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.company}
                  getOptionLabel={(option: ICompany) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  required
                  disabled={!formik.values.company || (!!registry && !!equipment)}
                  name="land"
                  label="Земельный участок"
                  setValue={(name: string, value: ILand) => formik.setValues({
                    ...formik.values,
                    [name]: value,
                    building: null,
                    floor: null,
                    room: null
                  })}
                  getValues={(search: string) => LandActions.lands({
                    take: 20,
                    companyId: formik.values.company?.id,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.land}
                  subscribeFields={[formik.values.company]}
                  getOptionLabel={(option: ILand) => option?.name ?? option?.cadastralNumber ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  required
                  disabled={!formik.values.land || (!!registry && !!equipment)}
                  label="Здание"
                  name="building"
                  setValue={(name: string, value: IBuilding) => formik.setValues({
                    ...formik.values,
                    [name]: value,
                    floor: null,
                    room: null
                  })}
                  getValues={(search: string) => BuildingActions.buildings({
                    take: 20,
                    landId: formik.values.land?.id,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.building}
                  subscribeFields={[formik.values.company, formik.values.land]}
                  getOptionLabel={(option: IBuilding) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  required
                  disabled={!formik.values.building || (!!registry && !!equipment)}
                  name="floor"
                  label="Этаж"
                  setValue={(name: string, value: IFloor) => formik.setValues({
                    ...formik.values,
                    [name]: value,
                    room: null
                  })}
                  getValues={(search: string) => FloorActions.floors({
                    take: 20,
                    buildingId: formik.values.building?.id,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.floor}
                  subscribeFields={[formik.values.company, formik.values.land, formik.values.building]}
                  getOptionLabel={(option: IFloor) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  required
                  disabled={!formik.values.floor || (!!registry && !!equipment)}
                  name="room"
                  label="Помещение"
                  setValue={(name: string, value: IRoom) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => RoomActions.rooms({
                    take: 20,
                    floorId: formik.values.floor?.id,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.room}
                  subscribeFields={[formik.values.company, formik.values.land, formik.values.building, formik.values.floor]}
                  getOptionLabel={(option: IRoom) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  disabled={!!registry && !!equipment}
                  name="description"
                  label="Общее описание"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  disabled={!!registry && !!equipment}
                  name="quantity"
                  label="Количество"
                  type="number"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name="unit"
                  label="Единица измерения"
                  disabled={!!registry && !!equipment}
                  setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => CommonUnitActions.items({
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.unit}
                  subscribeFields={[formik.values.company, formik.values.land, formik.values.building, formik.values.floor]}
                  getOptionLabel={(option: IItem) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <CustomAutocomplete
                  name={'availability'}
                  required
                  disabled={!!registry && !!equipment}
                  options={equippedOptions}
                  label="Наличие"
                  formik={formik}
                  value={
                    (formik.values.availability !== undefined)
                      ? formik.values.availability
                        ? 'Да'
                        : 'Нет'
                      : undefined
                  }
                  getOptionLabel={(option: IEquippedItem) =>
                    option?.name ?? option
                  }
                  setValue={(name: string, value: IEquippedItem) => {
                    formik.setFieldValue(name, value?.value, true);
                  }}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  name="price"
                  required
                  disabled={!!registry && !!equipment}
                  label="Стоимость"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  name="serialNumber"
                  disabled={!!registry && !!equipment}
                  label="Серийный номер"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  name="specification"
                  disabled={!!registry && !!equipment}
                  label="Технические характеристики"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={!!registry && !!equipment}
                    label="Дата производства"
                    renderInput={(params) => <TextField
                      name={"producingDate"}
                      formik={formik}
                      label={params.label}
                      type={params.type}
                      InputProps={{
                        disableUnderline: !(formik?.touched?.["producingDate"] && formik?.errors?.["producingDate"]),
                        endAdornment: params.InputProps?.endAdornment
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: null
                      }}
                      inputRef={params.inputRef}
                    />
                    }
                    onChange={(e: any) => {
                      formik.setFieldValue('producingDate', e, true)
                    }}
                    value={formik.values.producingDate}/>
                </LocalizationProvider>
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <CustomAutocomplete
                  name={'guarantee'}
                  required
                  disabled={!!registry && !!equipment}
                  options={equippedOptions}
                  label="Гарантия"
                  formik={formik}
                  value={
                    (formik.values.guarantee !== undefined)
                      ? formik.values.guarantee
                        ? 'Да'
                        : 'Нет'
                      : undefined
                  }
                  getOptionLabel={(option: IEquippedItem) =>
                    option?.name ?? option
                  }
                  setValue={(name: string, value: IEquippedItem) => {
                    formik.setFieldValue(name, value?.value, true);
                  }}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  name="inventoryNumber"
                  disabled={!!registry && !!equipment}
                  label="Инвентарный номер"
                  type="text"
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Дата принятия к учету"
                    disabled={!!registry && !!equipment}
                    renderInput={(params) => <TextField
                      name={"accountingDate"}
                      formik={formik}
                      label={params.label}
                      type={params.type}
                      InputProps={{
                        disableUnderline: !(formik?.touched?.["accountingDate"] && formik?.errors?.["accountingDate"]),
                        endAdornment: params.InputProps?.endAdornment
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: null
                      }}
                      inputRef={params.inputRef}
                    />
                    }
                    onChange={(e: any) => {
                      formik.setFieldValue('accountingDate', e, true)
                    }}
                    value={formik.values.accountingDate}/>
                </LocalizationProvider>
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name="function"
                  disabled={!!registry && !!equipment}
                  label="Целевая функция"
                  setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => InventoryFunctionActions.items({
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  getOptionLabel={(option: IItem) => option?.name ?? option}
                  value={formik.values.function}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name="status"
                  disabled={!!registry && !!equipment}
                  label="Статус"
                  setValue={(name: string, value: IItem) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => EquipmentStatusActions.items({
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.status}
                  getOptionLabel={(option: IItem) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name={"responsible"}
                  disabled={!formik.values.company || (!!registry && !!equipment)}
                  label="Ответственный"
                  setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.responsible}
                  getOptionLabel={(option: IEmployee) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <Autocomplete
                  name={"user"}
                  disabled={!formik.values.company || (!!registry && !!equipment)}
                  label="Пользователь"
                  setValue={(name: string, value: IEmployee) => formik.setFieldValue(name, value)}
                  getValues={(search: string) => CompanyEmployeeActions.items(formik.values.company.id, {
                    take: 20,
                    ...(search ? {search: search} : {})
                  })}
                  value={formik.values.user}
                  getOptionLabel={(option: IEmployee) => option?.name ?? option}
                  formik={formik}
                />
              </Item>
              <Item item xs={mobile ? 12 : 6}>
                <TextField
                  disabled={!!registry && !!equipment}
                  name="comment"
                  label="Комментарии"
                  type="text"
                  formik={formik}
                />
              </Item>
            </Grid>
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="end">
              <Grid item>
                {(!!registry && !!equipment) ? (
                  <Submit
                    variant="contained"
                    onClick={() => {
                      if (onClick) {
                        onClick(equipment)
                      }
                    }}
                  >
                    Добавить в опись
                  </Submit>
                ) : (
                  <Submit
                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Сохранить
                  </Submit>
                )}
              </Grid>
              {!mobile ? (
                <Grid item>
                  <Submit
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    Закрыть
                  </Submit>
                </Grid>
              ) : null}
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {openDialog ? (
        <CloseDialog
          open={openDialog}
          onClose={onCloseDialog}
          onCloseWithoutSaving={onCloseWithoutSaving}
        />
      ) : null}
    </React.Fragment>
  ) : null;
}