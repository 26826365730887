import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../../../Auth/components/Access";
import Type from "../../../Equipment/Type";
import {ITypeInput} from "../../../../interfaces/Equipment/input/type";
import {IType} from "../../../../interfaces/Equipment/type";

type Props = {
  item: IType,
  name: string,
  actions: any,
  onClick?: (item: ITypeInput) => void
}

export function Edit(props: Props): JSX.Element {
  const {item, name, actions, onClick} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={'admin'}>
      <React.Fragment>
        <IconButton
          onClick={handleOpen}
        >
          <EditIcon/>
        </IconButton>
        {open ? <Type open={open} item={item} name={name} actions={actions} handleClose={handleClose} onClick={onClick} /> : null}
      </React.Fragment>
    </Access>
  )
}
