import React from "react"
import {Edit as EditIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import Access from "../../../../Auth/components/Access";
import {IBuilding} from "../../../interfaces/building";
import Building from "../../Building";
import {Submit} from "../../../../App/components/Buttons/Submit";
import {ILand} from "../../../interfaces/land";
import Grid from "@mui/material/Grid";

type Props = {
  land?: ILand | null,
  building: IBuilding,
  onClick?: (building: IBuilding) => void
  button?: boolean
}

export function Edit(props: Props): JSX.Element {
  const {land, building, onClick, button = false} = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={['admin']}>
      <Grid item>
        {button
          ? (
            <Submit
              variant="contained"
              onClick={handleOpen}
            >
              Редактировать
            </Submit>
          )
          : (
            <IconButton
              onClick={handleOpen}
            >
              <EditIcon/>
            </IconButton>
          )
        }
        {open ? <Building land={land} building={building} open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  )
}
