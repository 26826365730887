import React from 'react'
import {styled} from "@mui/material/styles";

const Component = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .TableContainer-root': {
    overflow: 'overlay !important'
  },
  '& .MuiTableCell-root': {
    padding: '6px'
  },
}))

export const Root = ({children, ...restProps}: any) => {
  return <Component
    {...restProps}
  >
    {children}
  </Component>
}
