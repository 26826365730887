import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button, Grid, Slide, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Param from "../../../Account/components/Profile/Param";
import { FacebookCircularProgress } from "../../../App/components/Loading";
import { Paper } from "../../../App/components/Paper";
import { IFloor } from "../../../Realty/interfaces/floor";
import { IRoom } from "../../../Realty/interfaces/room";
import Workload from "./Statistic/Workload";
import { Data } from "./Viewer/Data";

const PREFIX = 'Viewer'

const classes = {
  block: `${PREFIX}-block`,
  menu: `${PREFIX}-menu`,
  paper: `${PREFIX}-paper`,
  mask: `${PREFIX}-mask`
}

const Frame = styled('iframe')(() => ({
  border: 'none'
}))

const Loading = styled(Grid)(() => ({
  top: "0",
  left: "0",
  width: "100vw",
  height: "100vh",
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(241,241,241,0.3)",
}))

const Menu = styled(Grid)(() => ({
  width: "100%",
  overflow: "auto",
  maxHeight: "50%",
  position: "relative",
  padding: "12px"
}))

const Floor = styled(Grid)(() => ({
  width: "100%",
  overflow: "hidden",
  position: "relative",
  padding: "12px"
}))

const LegendButtonUp = styled(Button)(() => ({
  zIndex: 1000,
  position: 'absolute',
  bottom: 0,
  color: "rgb(128 128 128)",
  background: "white",
  borderRadius: "2px",
  height: "40px"
}))

const LegendButtonDown = styled(Button)(() => ({
  zIndex: 1000,
  color: "rgb(128 128 128)",
  background: "white",
  borderRadius: '0px',
  borderTopRightRadius: '2px',
  border: '1px solid #e1e0e0',
  borderBottom: 'none',
  boxShadow: 'none',
  height: "40px"
}))

const Content = styled(Grid)(() => ({
  width: "100%",
  overflowX: "hidden",
  position: "relative",
  [`& .${classes.paper}`]: {
    display: 'grid',
    height: 'calc(100vh - 172px)'
  },
  [`& .${classes.block}`]: {
    display: 'grid',
    width: '100%',
    height: 'calc(100vh - 172px)'
  },
  [`& .${classes.menu}`]: {
    overflow: "auto",
  }
}))

type Props = {
  floor: IFloor
}

type eventMessage = {
  data: {
    room?: IRoom
    type: string
  }
}

export function Viewer(props: Props): JSX.Element | null {
  const navigate = useNavigate();
  const {floor} = props
  const [loading, setLoading] = useState(!floor?.markupId);
  const [showLegend, setShowLegend] = useState(true);
  const [room, setRoom]: any = useState(null);

  useEffect(() => {
    window.addEventListener('message', (event: eventMessage) => {
      const data = event.data

      switch (data.type) {
        case 'viewRoom':
          if (data.room?.id) {
            setRoom(data.room)
          }
          break;
        case 'goRoom':
          navigate(`/objects/${data.room?.floor.building.land.id}/building/${data.room?.floor.building.id}/floor/${data.room?.floor.id}/room/${data.room?.id}`)
          break;
        case 'hideRoom':
          setRoom(null)
          break;
      }
    })
  })

  const getRoomTypes = (): JSX.Element[] => {
    const useTypes: number[] = [];
    const content: JSX.Element[] = [];
    floor?.rooms?.forEach((room: IRoom) => {
      if (room.areaId && room?.type?.id && !useTypes.find((id: number) => id === room.type.id)) {
        useTypes.push(room.type.id)
        content.push(<Grid item style={{padding: "20px"}} key={room.type.id}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid style={{background: room.type.color ?? 'black', width: '60px', height: '20px'}}></Grid>
            <Typography style={{marginLeft: "10px"}}>{room.type.name}</Typography>
          </Grid>
        </Grid>)
      }
    });
    return content
  }

  return <Content>
    {!loading ? <Loading>
      <FacebookCircularProgress/>
    </Loading> : null}
    <Paper className={classes.paper}>
      <Grid container justifyContent="stretch" alignItems="stretch" direction="row">
        <Grid item xs={9} className={classes.block}>
          {floor.markupId ? <Frame
            onLoad={() => {
              setLoading(true)
            }}
            src={`${process.env.REACT_APP_PLANNER_URL}/index.html?type=floor&id=${floor.id}&mode=view`}
            height="100%"
            width="100%"
          /> : <Typography style={{textAlign: "center", fontSize: "18px", color: "#6E7880", marginTop: "25%"}}>Карта/схема не составлена</Typography>}
        </Grid>
        {room ? (
          <Grid item xs={3} className={classes.block} style={{position: 'absolute', right: '0'}}>
            <Paper className={classes.menu}>
              <Menu>
                {[
                  {name: 'Наименование', value: room.name},
                  {name: 'Тип помещения', value: room.type?.name},
                  {name: 'Высота', value: room.height},
                  {name: 'Площадь кв.м', value: room.square},
                  {name: 'Подразделение', value: room.subdivision?.name},
                  {name: 'Ответственный', value: room.safety?.name},
                  {name: 'Арендное', value: room?.rentalType?.name},
                  {name: 'Информация о договорах аренды', value: room.rentalInformation},
                  {name: 'Количество рабочих мест всего', value: room.numberWorkplaces},
                  {name: 'Количество рабочих мест занято', value: room._count.people},
                  {
                    name: 'Количество рабочих мест свободно',
                    value: (room.numberWorkplaces - room._count.people)
                  },
                  {name: 'Стоимость аренды 1 кв.м', value: room.rentalPrice1squareMeter},
                  {name: 'Общее срок сдачи, дней', value: room.totalDeliveryTimeDays},
                ].map((item, key: number) => (
                  <Param
                    key={key}
                    {...item}
                  />
                ))}
              </Menu>
              <Data company={floor.building.land.company} room={room}/>
            </Paper>
          </Grid>
        ) : <Grid xs={3} className={classes.block} style={{position: 'absolute', right: '0'}}>
          <Paper className={classes.menu}>
            <Floor>
              {[
                {name: 'Наименование', value: floor.name},
                {name: 'Тип этажа', value: floor.type?.name},
                {name: 'Площадь кв.м', value: floor.square?.toFixed(2).toString()},
                {
                  name: 'Дата последнего ремонта',
                  value: floor.dateRepair ? (new Date(floor.dateRepair)).toLocaleDateString() : null
                },
              ].map((item, key: number) => (
                <Param
                  key={key}
                  {...item}
                />
              ))}
              <Workload floor={floor}/>
            </Floor>
          </Paper>
        </Grid>}
      </Grid>
      {floor.rooms && floor.rooms.filter((item: IRoom) => item.areaId && item?.type?.id).length ? <LegendButtonUp
        endIcon={<ExpandLess/>}
        variant="contained"
        color="inherit"
        onClick={() => setShowLegend(true)}
      >
        Типы помещений
      </LegendButtonUp> : null}
      <Slide direction="up" in={showLegend} mountOnEnter unmountOnExit>
        <Grid style={{position: 'absolute', zIndex: 1000, bottom: 0}} container justifyContent="flex-start" alignItems="center" direction="row">
          <LegendButtonDown
            endIcon={<ExpandMore/>}
            variant="contained"
            color="inherit"
            onClick={() => setShowLegend(false)}
          >
            Типы помещений
          </LegendButtonDown>
          <Grid style={{background: "white", border: '1px solid #e1e0e0'}} container direction="row" justifyContent="flex-start" alignItems="center">
            {getRoomTypes()}
          </Grid>
        </Grid>
      </Slide>
    </Paper>
  </Content>
}