import React, {useEffect, useState} from "react";
import Template from "../../Template";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";
import {IBuilding} from "../../../Realty/interfaces/building";
import {BuildingActions} from "../../../Realty/actions/building";
import List from "./List";
import {Viewer} from "./Viewer";
import {Create as CreateFloor} from "../../../Realty/components/Floor/Buttons/Create";
import {Create as CreateBuilding} from "../../../Realty/components/Building/Buttons/Create";
import {Edit as EditBuilding} from "../../../Realty/components/Building/Buttons/Edit";
import {Edit as EditMarkup} from "../../../Editor/components/Building/Buttons/Edit";
import {Editor} from "../../../Editor/components/Building/Editor";
import {IFloor} from "../../../Realty/interfaces/floor";
import {LandActionsTypes} from "../../interfaces/land";
import {IRootState} from "../../../App/reducers/store";
import Information from "../../Information";
import Statistic from "./Statistic";

export default function Building(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const params = useParams();
  const location = useLocation();

  const {land} = useSelector((state: IRootState) => state.land)

  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [item, setItem] = useState<IFloor | null>(null);
  const [editMode, setEditMode] = useState(location.pathname.includes('markup'));

  const getBuilding = async (id: number) => {
    return await dispatch(BuildingActions.building(id))
  }

  useEffect(() => {
    if (params.building) {
      getBuilding(Number(params.building)).then(response => {
        setBuilding(response)
      })
    }
    // eslint-disable-next-line
  }, [params.building])

  useEffect(() => {
    setEditMode(location.pathname.includes('markup'))
  }, [location.pathname])

  return building ? (
    <Template
      crumbs={[
        {
          name: building.land.name?.length ? building.land.name : building.land.cadastralNumber,
          to: `/objects/${building.land.id}`
        },
        {name: building.name}
      ]}
      contents={{
        common: {
          component: <Information
            files={building.files}
            updateFilesHandler={() => getBuilding(Number(params.building)).then(response => {
              setBuilding(response)
            })}
            object={{
              type: 'building',
              id: building.id
            }}

            mainItems={[
              {name: 'Адрес', value: building.address},
              {name: 'Тип здания', value: building.type?.name},
              {name: 'Общая площадь кв.м', value: building.square?.toString()},
            ]}
            items={[
              {name: 'Корпус', value: building.name},
              {name: 'Адрес', value: building.address},
              {name: 'Тип здания', value: building.type?.name},
              {name: 'Год первоначального ввода в эксплуатацию', value: building.initialYear?.toString()},
              {name: 'Тип аренды', value: building.rentalType?.name},
              {name: 'Площадь здания кв.м', value: building.square?.toString()},
              {name: 'Количество этажей (в том числе подземных)', value: building._count?.floors?.toString()},
              {name: 'Балансовая стоимость здания', value: building.price?.toString()},
              {name: 'Год последнего капитального ремонта', value: building.lastOverhaulYear?.toString()},
              {name: 'Парковка, количество мест', value: building.parking?.toString()},
              {name: 'Имеет видеонаблюдение', value: building.equippedVideo ? 'Да' : 'Нет'},
              {name: 'Имеет охрану', value: building.equippedSecurity ? 'Да' : 'Нет'},
              {name: 'Доступно для маломобильных групп населения', value: building.accessiblePeopleLimitedMobility ? 'Да' : 'Нет'},
              {name: 'Наличие интернета', value: building.internet ? 'Да' : 'Нет'},
              {name: 'Ответственный', value: building.responsible?.name},
              {name: 'Ответственный (Противопожарная безопасность)', value: building.safety?.name},
            ]}
          />,
          actions: [
            <EditBuilding
              land={land}
              building={building}
              onClick={(building) => {
                setBuilding(building)
                dispatch({type: LandActionsTypes.LAND_BUILDING, payload: building})
              }}
              button
            />,
            <CreateBuilding
              land={land}
              onClick={(building) => {
                dispatch({type: LandActionsTypes.LAND_BUILDING, payload: building})
              }}
            />
          ]
        },
        list: {
          component: <List building={building} floor={item}/>,
          actions: [
            <CreateFloor
              building={building}
              onClick={(floor) => {
                setItem(floor)
                dispatch({type: LandActionsTypes.LAND_FLOOR, payload: floor})
              }}
            />
          ]
        },
        statistic: {
          component: <Statistic building={building}/>
        },
        scheme: {
          component: editMode ? <Editor/> : <Viewer/>,
          actions: [<EditMarkup id={building.id} landId={building.land.id}/>]
        }
      }}
    />
  ) : null
}