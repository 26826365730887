import React, {useEffect, useState} from 'react'
import {styled} from "@mui/material/styles";
import {IconButton, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import TextField from "../../Input/TextField";

const CurrentPage = styled('div')(() => ({
  gap: '10px',
  display: 'flex',
  alignItems: 'center',
  'input': {
    width: '3rem',
    height: '32px',
    padding: '0 0 0 10px'
  },
})) 

export const Item = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  fontStyle: 'normal',
  paddingRight: '10px',
  fontFamily: 'Open Sans',
}))

export const PageSelector = ({
  currentPage,
  totalPages,
  onCurrentPageChange,
  totalCount
}: any) => {

  const [page, setPage] = useState(currentPage)

  useEffect(() => {
      setPage(currentPage)
  }, [currentPage])

  return (
    <CurrentPage>
        <Item>Страница</Item>
        <TextField
          value={page}
          type="number"
          inputProps={{
            step: 1,
            min: 1
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPage((e.target.value && (!isNaN(Number(e.target.value)) && (Number(e.target.value) > 0))) ? Number(e.target.value) : '')}
        />
        <IconButton
          color="inherit"
          aria-label="Следующая"
          onClick={() => onCurrentPageChange(page)}
          disabled={((page === (totalPages + 1)) || (totalCount === 0)) || !page || (page > totalPages) || (page === 0) || (page === currentPage)}
        >
          <ChevronRight/>
        </IconButton>
    </CurrentPage>
  )
}
