import React, {useEffect, useState} from "react";
import Template from "../../Template";
import {useDispatch} from "react-redux";
import {NavigateFunction, useLocation, useNavigate, useParams} from "react-router";
import {ILand} from "../../../Realty/interfaces/land";
import {LandActions} from "../../../Realty/actions/land";
import List from "./List";
import {Viewer} from "./Viewer";
import {Create as CreateLand} from "../../../Realty/components/Land/Buttons/Create";
import {Edit as EditLand} from "../../../Realty/components/Land/Buttons/Edit";
import {Create as CreateBuilding} from "../../../Realty/components/Building/Buttons/Create";
import {Edit as EditMarkup} from "../../../Editor/components/Land/Buttons/Edit";
import {IBuilding} from "../../../Realty/interfaces/building";
import {Editor} from "../../../Editor/components/Land/Editor";
import {LandActionsTypes} from "../../interfaces/land";
import Information from "../../Information";

export default function Land(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const params = useParams();

  const [land, setLand] = useState<ILand | null>(null);
  const [item, setItem] = useState<IBuilding | null>(null);
  const [editMode, setEditMode] = useState(location.pathname.includes('markup'));

  const getLand = async (id: number) => {
    return await dispatch(LandActions.land(id))
  }

  useEffect(() => {
    if (params.id) {
      getLand(Number(params.id)).then(response => {
        setLand(response)
      })
    }
    // eslint-disable-next-line
  }, [params.id])

  useEffect(() => {
    setEditMode(location.pathname.includes('markup'))
  }, [location.pathname])

  return land ? (
    <Template
      crumbs={[
        {name: land.name?.length ? land.name : land.cadastralNumber}
      ]}
      contents={{
        common: {
          component: <Information
            files={land.files}
            updateFilesHandler={() => getLand(Number(params.id)).then(response => {
              setLand(response)
            })}
            object={{
              type: 'land',
              id: land.id
            }}
            items={[
              {name: 'Наименование', value: land.name},
              {name: 'Кадастровый номер', value: land.cadastralNumber},
              {name: 'Компания', value: land.company?.name},
              {name: 'Статус объекта', value: land.status?.name},
              {name: 'Категория земель', value: land.category?.name},
              {name: 'Вид разрешенного использования', value: land.permission?.name},
              {name: 'Кадастровая стоимость', value: land.cadastralPrice?.toString()},
              {name: 'Обременения', value: land.encumbrances},
              {name: 'Площадь, кв.м', value: land.square?.toString()},
              {name: 'Адрес', value: land.address},
              {name: 'Ответственный', value: land.responsible?.name},
            ]}
          />,
          actions: [
            <EditLand
              land={land}
              onClick={(land) => {
                setLand(land)
              }}
              button
            />,
            <CreateLand
              onClick={(land) => {
                navigate(`/objects/${land.id}`)
              }}
            />
          ]
        },
        list: {
          component: <List land={land} building={item}/>,
          actions: [
            <CreateBuilding
              land={land}
              onClick={(building) => {
                setItem(building)
                dispatch({type: LandActionsTypes.LAND_BUILDING, payload: building})
              }}
            />
          ]
        },
        scheme: {
          component: editMode ? <Editor/> : <Viewer/>,
          actions: [<EditMarkup id={land.id}/>]
        }
      }}
    />
  ) : null
}