import React from "react";
import {IconButton} from "@mui/material";
import RestorePageIcon from '@mui/icons-material/RestorePage';

type Props = {
  disabled?: boolean,
  onClick?: () => void,
}

export default function Restore(props: Props): JSX.Element {
  const {disabled, onClick} = props

  return <IconButton
    disabled={!!disabled}
    onClick={onClick}
  >
    <RestorePageIcon/>
  </IconButton>
}
