import React from "react";
import {IconButton, MenuItem, MenuList} from "@mui/material";
import {Delete as DeleteIcon} from '@mui/icons-material';
import {Menu} from "../../Menu";

type Props = {
  disabled?: boolean,
  onClick?: () => void,
}

export default function Delete(props: Props): JSX.Element {
  const {disabled, onClick} = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        disabled={!!disabled}
        onClick={handleClick}
      >
        <DeleteIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
          <MenuItem onClick={onClick}>Подтвердить</MenuItem>
          <MenuItem onClick={handleClose}>Отмена</MenuItem>
        </MenuList>
      </Menu>
    </React.Fragment>
  )
}
