import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {Table} from "../../../App/components/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../App/reducers/store";
import {Table as MUITable} from "@devexpress/dx-react-grid-material-ui";
import {Grid} from "@mui/material";
import {FiltersActionsTypes} from "../../../App/interfaces/filter";
import Actions from "../../../App/components/Table/Actions";
import {FloorActions} from "../../../Realty/actions/floor";
import {IFloor} from "../../../Realty/interfaces/floor";
import {IBuilding} from "../../../Realty/interfaces/building";
import {Edit} from "../../../Realty/components/Floor/Buttons/Edit";
import Delete from "../../../Realty/components/Floor/Buttons/Delete";
import {NavigateFunction, useNavigate} from "react-router";
import {LandActionsTypes} from "../../interfaces/land";
import {TableRow} from "@devexpress/dx-react-grid";

const PREFIX = 'Floors'

const classes = {
  inputContent: `${PREFIX}-inputContent`,
  tableRow: `${PREFIX}-tableRow`,
}

const Content = styled(Grid)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  [`& .${classes.inputContent}`]: {
    width: "100%",
    height: 'calc(100vh - 172px)',
    minHeight: 'calc(100vh - 172px)',
    overflow: "overlay",
    overflowX: "hidden",
    background: 'white',
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
    textDecoration: "none"
  }
}))

type Props = {
  building: IBuilding
  floor?: IFloor | null
}

export default function List(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const navigate: NavigateFunction = useNavigate();

  const {building} = props

  const {filter} = useSelector((state: IRootState) => state.filters.floors)
  const [floors, setFloors]: any = useState([])
  const [page, setPage] = useState(1)
  const [, setTableRef] = useState(null)
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const [loading, setLoading] = useState(false)

  const [columns]: any = useState([
    {name: 'name', title: 'Наименование'},
    {name: 'building', title: 'Здание'},
    {name: 'type', title: 'Тип этажа'},
    {name: 'square', title: 'Площадь, кв.м'},
    {name: 'dateRepair', title: 'Дата последнего ремонта'},
    {name: 'actions', title: ' '},
  ])

  const [columnFloors, setColumnFloors] = useState([
    'name',
    'building',
    'type',
    'square',
    'dateRepair',
    'actions',
  ])

  const [columnWidths, setColumnWidths] = useState([
    {columnName: 'name', width: 200},
    {columnName: 'building', width: 200},
    {columnName: 'type', width: 200},
    {columnName: 'square', width: 200},
    {columnName: 'dateRepair', width: 200},
    {columnName: 'actions', width: 200, align: 'right'},
  ])

  useEffect(() => {
    if (!loading) {
      const skip = (page - 1) * filter.take
      dispatch(FloorActions.floors({
        ...(filter.sort.name ? {
          sort: filter.sort.name,
          direction: filter.sort.direction,
        } : {}),
        buildingId: building.id,
        take: filter.take,
        ...(skip ? {skip: skip} : {}),
        ...{meta: true},
      })).then((floors: {
        data: [IFloor],
        meta?: {
          total?: number
        },
      }) => {
        setFloors(floors)
        setLoading(true)
      })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading) {
      setPage(1)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [filter, building])

  useEffect(() => {
    if (props.floor && floors?.data) {
      setFloors({
        data: [
          props.floor,
          ...((floors.data.length === filter.take) ? floors.data.slice(0, filter.take) : floors.data)
        ],
        meta: {
          total: floors.meta.total + 1
        },
      })
    }
    // eslint-disable-next-line
  }, [props.floor])

  const handlePageSizeChange = (newRowsPerPage: number) => {
    dispatch({
      type: FiltersActionsTypes.FLOORS,
      payload: {
        ...filter,
        take: newRowsPerPage,
      }
    })
  }

  return loading ? (
    <Content>
      <Table
        meta={floors.meta}
        name={'floors'}
        rows={floors.data.map((item: IFloor) => ({
          id: item.id,
          name: item?.name,
          building: item.building.name,
          type: item?.type?.name,
          square: item.square,
          dateRepair: item.dateRepair ? (new Date(item.dateRepair)).toLocaleDateString() : null,
          actions: <Actions
            edit={{
              button: <Edit
                floor={item}
                onClick={(floor) => {
                  dispatch({type: LandActionsTypes.LAND_FLOOR, payload: floor})
                  setFloors({
                    ...floors,
                    ...{data: floors.data.map((el: IFloor) => (el.id === floor.id) ? floor : el)}
                  })
                }}
              />
            }}
            delete={{
              button: <Delete
                id={item.id}
                onClick={() => {
                  dispatch({type: LandActionsTypes.LAND_FLOOR_DELETE, payload: item.id})
                  setFloors({
                    data: floors.data.filter((el: IFloor) => el.id !== item.id),
                    meta: {
                      total: floors.meta.total - 1
                    },
                  })
                }}
              />
            }}
          />,
        }))}
        columns={columns}
        page={{
          page: page,
          setPage: setPage,
          rowsPerPage: filter.take,
          handlePageSizeChange: handlePageSizeChange
        }}
        setTableRef={setTableRef}
        columnsSettings={{
          columnOrder: columnFloors,
          setColumnOrder: setColumnFloors,
          setColumnWidths: setColumnWidths,
          columnWidths: columnWidths,
          hiddenColumnNames: hiddenColumnNames,
          setHiddenColumnNames: setHiddenColumnNames
        }}
        tableHeader={TableHeader}
        filterActionType={FiltersActionsTypes.FLOORS}
        classInputContent={classes.inputContent}
        rowComponent={({row, tableRow, children}: { row: { id: number }, tableRow: TableRow, children: JSX.Element | null }) => (
          <MUITable.Row
            tableRow={tableRow}
            className={classes.tableRow}
            row={row}
            onDoubleClick={() => {
              navigate(`floor/${row.id}?section=list`)
            }}
            children={children}
            style={{'cursor': 'pointer'}}
          />
        )}
        filters={{
          status: {
            name: 'status',
            type: 'values',
          },
          actions: null
        }}
      />
    </Content>
  ) : null
}