import React from "react";
import {styled} from "@mui/material/styles";
import {Grid} from "@mui/material";
import Type from "./Statistic/Type";
import {IFloor} from "../../../Realty/interfaces/floor";
import WorkloadType from "./Statistic/WorkloadType";
import Workload from "./Statistic/Workload";
import Repair from "./Statistic/Repair";
import QualityIndex from "./Statistic/QualityIndex";

const Content = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}))

type Props = {
  floor: IFloor
}

export default function Statistic(props: Props): JSX.Element | null {
  const { floor } = props

  return (
    <Content container direction="row" justifyContent="center" alignItems="center">
      <Type floor={floor} xs={4} />
      <Workload floor={floor} xs={4} />
      <WorkloadType floor={floor} xs={4} />
      <Repair floor={floor} xs={4} />
      <QualityIndex floor={floor} xs={4} />
    </Content>
  )
}