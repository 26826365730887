import React from "react"
import {Submit} from "../../../../App/components/Buttons/Submit";
import AddIcon from "../../../../App/components/Svg/AddIcon";
import Access from "../../../../Auth/components/Access";
import {ILand} from "../../../interfaces/land";
import Land from "../../Land";
import Grid from "@mui/material/Grid";

type Props = {
  onClick?: (land: ILand) => void
}

export function Create(props: Props): JSX.Element {
  const { onClick } = props

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Access roles={['admin']}>
      <Grid item>
        <Submit
          onClick={handleOpen}
          variant="contained"
          type="submit"
          endIcon={<AddIcon width={12} height={12}/>}
        >
          Добавить
        </Submit>
        {open ? <Land open={open} handleClose={handleClose} onClick={onClick} /> : null}
      </Grid>
    </Access>
  )
}
