import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IFloor} from "../../../../Realty/interfaces/floor";
import {FloorActions} from "../../../../Statistic/actions/floor";
import PieChart from "../../../../Statistic/components/PieChart";
import {Grid, GridProps} from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props extends GridProps {
  floor: IFloor
}

const types = [
  {id: 1, name: 'Плохое', color: '#EC808D'},
  {id: 2, name: 'Удовлетворительное', color: '#FACD91'},
  {id: 3, name: 'Нормальное', color: '#FFFF80'},
  {id: 4, name: 'Хорошее', color: '#CAF982'},
  {id: 5, name: 'Отличное', color: '#95F204'},
]

export default function QualityIndex(props: Props): JSX.Element | null {
  const dispatch: any = useDispatch()
  const {floor} = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<{name: string, value: number}>>([])

  useEffect(() => {
    const getRooms = async () => {
      return await dispatch(FloorActions.quality(floor.id))
    }

    if (!loading) {
      getRooms().then((response: {data: {[id: number]: number}}) => {
        setData(Object.entries(response.data).reduce((values: Array<{name: string, value: number}>, [id, count]) => {
          const type = types.find(type => type.id === Number(id))
          if (type && (count > 0)) {
            values.push({
              name: type.name,
              value: count
            })
          }
          return values
        }, []))
        setLoading(true)
      })
    }
  }, [dispatch, floor.id, loading])

  useEffect(() => {
    setLoading(false)
  }, [floor])

  return data.length ? (
    <Grid item {...props}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Typography>Индекс качества рабочего места</Typography>
        <PieChart data={data} types={types} />
      </Grid>
    </Grid>
  ) : null
}